<template>
  <div>
    <cs-alert-panel v-if="!valid" type="warning" :text="$t('Index of lines are not valid!')" />

    <transition-group name="fader" tag="div">
      <v-row v-for="(item, index) in products" :key="item.id" dense class="fader-item" align="center">
        <v-hover v-slot="{ hover }">
          <v-sheet class="full-width" :class="hover ? 'focused' : ''">
            <v-row align="center" class="pa-2">
              <v-col class="text-h4 text-center shrink d-flex">
                <v-btn icon :disabled="index <= 0" @click="moveLineUp(index)">
                  <v-icon>
                    $vuetify.icons.up
                  </v-icon>
                </v-btn>

                <div class="mx-2">
                  {{ item[SORT_BY] + 1 }}
                </div>

                <v-btn icon :disabled="index + 1 >= products.length" @click="moveLineDown(index)">
                  <v-icon>
                    $vuetify.icons.down
                  </v-icon>
                </v-btn>
              </v-col>

              <v-col class="text-h5 font-weight-bold text-truncate">
                <sca-product-identity :value="item.ref" :label="item.name" :company="customer" show-avatar />
              </v-col>

              <v-col class="text-truncate">
                {{ item.caption }}
              </v-col>

              <v-col class="text-truncate">
                {{ item.customer_caption }}
              </v-col>

              <v-col class="shrink">
                <sca-sphere-identity :value="item.id_sphere" />
              </v-col>

              <v-col class="shrink text-no-wrap">
                {{ $t('Qty:') }} {{ item.quantity }}
              </v-col>
            </v-row>
          </v-sheet>
        </v-hover>
      </v-row>
    </transition-group>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'CartLinesMover',
  props: {
    cart: { type: Object, required: true }
  },
  data () {
    return {
      SORT_BY: 'index_order',
      internalCart: null,
      products: null,
      valid: true
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me'
    }),
    customer () { return this.internalCart?.code || this.me.company }
  },
  watch: {
    cart: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.setCart(newValue)
        }
      }
    }
  },
  methods: {
    checkValidity () {
      let passed = true
      let i = 0
      while (passed && i < this.products.length) {
        passed = _.find(this.products, { [this.SORT_BY]: i })
        i++
      }
      return passed
    },
    getProducts () {
      return this.products
    },
    async moveLineDown (index) {
      this.swapProduct(this.products[index], this.products[index + 1])
    },
    async moveLineUp (index) {
      this.swapProduct(this.products[index], this.products[index - 1])
    },
    setCart (cart) {
      this.internalCart = { ...cart }
      this.products = _.sortBy([...cart.products], this.SORT_BY)
      this.valid = this.checkValidity()
    },
    swapProduct (product1, product2) {
      const itemIndex = product1[this.SORT_BY]
      if (product1.origin === undefined) product1.origin = itemIndex
      product1[this.SORT_BY] = product2[this.SORT_BY]
      if (product2.origin === undefined) product2.origin = product2[this.SORT_BY]
      product2[this.SORT_BY] = itemIndex
      this.products = _.sortBy(this.products, this.SORT_BY)
    }
  }
}
</script>
