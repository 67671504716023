<template>
  <div>
    <sca-modal-dialog :visible="show" max-width="60%" @closeDialog="close" @saveDialog="save" :can-save="canSave" :is-saving="isSaving" :action="action" :external-id="externalId()">
      <span slot="title">
        {{ followup.subject_email || followup.id || '' }} - {{ $t('Email') }}
      </span>

      <div slot="content">
        <v-form ref="follow-up-form" v-model="valid" lazy-validation>
          <v-container fluid>
            <v-row dense align="end">
              <v-col cols="12" md="6">
                <sca-customer-select v-model="followup.code" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" @input="onChangeCustomer" show-email show-phone link="emit" @link-click="openCompany(followup.code)" />
              </v-col>

              <v-col cols="12" md="6">
                <sca-users-select v-model="localUser" :filter-company="followup.code" show-email :label="$t('User')" :rules="[$stratus.services.form.rules.required]" class="required" link="emit" @link-click="openUser(localUser)" />
              </v-col>

              <v-col cols="12">
                <v-text-field v-model="followup.email_user" :label="$t('User email')" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.email]" class="required" />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <div class="text-h6">
                  {{ $t('From: ') }} {{ followup.from_email }}
                </div>
                <div v-if="followup.recipient_ids?.length" class="mt-2">
                  <v-row no-gutters>
                    {{ $t('To: ') }}
                    <sca-user-identity v-for="user in followup.recipient_ids" :key="user" :value="user" class="ml-2" show-card dense show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(user)" />
                  </v-row>
                </div>
              </v-col>

              <v-col cols="12" class="mt-4 text-h5">
                {{ followup.subject_email }}
              </v-col>

              <v-col cols="12">
                <v-tabs v-model="tab" color="transparent" hide-slider>
                  <cs-loader-tab href="#tab-content" :header="$t('Content')" icon="icon-opened-email" />
                  <cs-loader-tab href="#tab-raw-text" :header="$t('Raw text')" icon="icon-file" />
                  <cs-loader-tab v-if="followup.attachments?.length" href="#tab-attachment" :header="$t('Attachments')" icon="icon-attachment" :loading="loadingAttachments" :count="followup.attachments.length" @load="loadFollowUpAttachmentsMeta(followup.attachments)" />

                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-content">
                      <v-card flat tile class="pa-1">
                        <div v-html="sanitizeBody(followup.body_email)" />
                      </v-card>
                    </v-tab-item>

                    <v-tab-item value="tab-raw-text">
                      <pre :class="dark ? 'black' : ''">{{ followup.body_email }}</pre>
                    </v-tab-item>

                    <v-tab-item value="tab-attachment">
                      <v-expand-transition>
                        <v-row v-if="loadingAttachmentsCount > 0" dense align="center" justify="center">
                          <v-col cols="12" sm="6" md="3">
                            <div text dense class="ma-4">
                              <cs-icon-loading small left show-text />
                              <v-progress-linear :value="percentLoadingAttachmentsCount" />
                            </div>
                          </v-col>
                        </v-row>
                      </v-expand-transition>

                      <v-row dense>
                        <v-col cols="12" class="pa-4">
                          <cs-attachments-list :id="followup.id" :value="followup.attachments" />
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <cs-expand-list v-if="followup?.opportunity_ids?.length > 0" :items="followup?.opportunity_ids" row>
          <template v-slot:expand-list-item="{ item }">
            <sca-opportunity-identity :value="item" :label="item" show-avatar link="emit" @link-click="openOpportunity(item)" />
          </template>
        </cs-expand-list>
      </div>

      <sca-footer-create-update-at-by slot="footer" v-model="followup" :link-user="isLord" />
    </sca-modal-dialog>

    <csm-company-dialog ref="company-dialog" />
    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'

let previousId = null

export default {
  name: 'FollowUpEmailDialog',
  data () {
    return {
      tmpAttachmentList: [],
      attachmentsMeta: [],
      loadingAttachments: false,
      loadingAttachmentsCount: 0,
      loadingPdf: false,
      localUser: null,
      isSaving: false,
      show: false,
      followup: {},
      tab: 'tab-content',
      valid: false
    }
  },
  computed: {
    action () {
      if (this.canUpdate) return this.$t('Update')
      return this.$t('Details')
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    percentLoadingAttachmentsCount () {
      return this.followup?.attachments?.length ? 100 * (this.followup.attachments.length - this.loadingAttachmentsCount) / this.followup.attachments.length : 0
    }
  },
  async mounted () {
    if (this.$route?.name === 'followup-email' && this.$route.params.id) {
      if (this.$route.params.id !== previousId) {
        previousId = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.openId(this.$route.params.id)
      }
    }
  },
  methods: {
    close (saved = false) {
      this.show = false
      this.resolve(saved)
      this.$emit('close', saved)
    },
    externalId () {
      return this.followup.id ? 'followup-email/' + this.followup.id : ''
    },
    mimeIsImage (meta) {
      return meta && meta.mime && meta.mime.includes('image/')
    },
    mimeIsPdf (meta) {
      return meta && meta.mime && meta.mime.includes('application/pdf')
    },
    loadFollowUpAttachmentsMeta (attachments) {
      if (!attachments || attachments.length === 0) return
      this.loadingAttachments = true
      this.attachmentsMeta = []
      this.tmpAttachmentList = [...attachments]
    },
    async onChangeCustomer () {
      // Do not modify if a sales person is already input
      if (!this.followup.code || this.followup.id_lord_salesperson) return null
      try {
        const salesPersonId = await this.$store.dispatch('$alto-companies/getSalesPerson', this.followup.code)
        if (_.isNumber(salesPersonId) && salesPersonId >= 0) {
          this.followup.id_lord_salesperson = salesPersonId
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    async open (followup = {}) {
      await this.reset()
      this.followup = { ...followup }
      this.followup.state = followup.state
      this.localUser = followup.id_user
      this.show = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async openId (id) {
      try {
        const followup = await this.$store.dispatch('$alto-followups/email', id)
        this.open(followup)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reset () {
      this.followup = {}
      this.isSaving = false
      this.valid = false
      this.localUser = null
      this.tab = 'tab-content'
      if (this.$refs['follow-up-form']) await this.$refs['follow-up-form'].reset()
    },
    sanitizeBody (content) {
      if (!content) return ''
      // Sanitize and replace \n by html tag BR
      // Windows use \r\n, Linux/Unix use \n and MacOS use \r
      return this.$stratus.services.strings
        .stripHtmlTags(content)
        .replace(/\r\n/g, '<br>')
        .replace(/\r/g, '<br>')
        .replace(/\n/g, '<br>')
    },
    save () {
      if (!this.$refs['follow-up-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return
      }

      this.isSaving = true
      const data = { ...this.followup }
      data.id_user = this.localUser
      // Validation is OK
      this.$store.dispatch('followups/saveEmail', data)
        .then(response => {
          this.resolve(response)
          this.close(true)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    }
  }
}
</script>
