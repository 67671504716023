import { render, staticRenderFns } from "./TicketsAutomationList.vue?vue&type=template&id=64869ef5"
import script from "./TicketsAutomationList.vue?vue&type=script&lang=js"
export * from "./TicketsAutomationList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports