<template v-slot:expand="props">
  <div>
    <div class="text-subtitle-1 mb-2">
      {{ $t('Sub-product discount') }}
    </div>
    <div v-for="(input, index) in product.main" :key="index" :class="dense ? '' : 'mt-4 px-2'">
      <csm-dyna-product-discount-recursive :ref="`${uuid}-SP-${index}`" v-model="product.main[index]" :super-indent="true" :customer="customer" :dense="dense" :hide-checkbox="hideCheckbox" :readonly="readonly" @change="changeProduct" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ProductSubDiscount',
  components: {
    'csm-dyna-product-discount-recursive': () => import(/* webpackChunkName: "components" */ './DynaProductDiscountRecursive')
  },
  props: {
    dense: { type: Boolean, default: false },
    customer: { type: String, required: true },
    hideCheckbox: { type: Boolean, default: false },
    product: { type: Object, default () { return {} } },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      debug: [],
      uuid: this.$stratus.uuid(),
      productToReturn: {},
      mainProductCustom: []
    }
  },
  methods: {
    build () {
      this.debug = []
      // Récupérer tous les sous-produits de ce produit-ci
      _.forEach(this.product.main, (sp, index) => {
        this.debug.push(this.$refs[`${this.uuid}-SP-${index}`][0].build())
      })
      return _.cloneDeep(this.debug) // Get a fresh new object, without ref link
    },
    changeProduct () {
      this.product.main = this.build()
      this.$emit('change', this.product)
    },
    hasThisRef (main, ref) {
      let index = 0
      while (index < main.length) {
        if (main[index].ref === ref) return index
        index++
      }
      return null
    },
    setCheckbox (value) {
      _.forEach(this.product.main, (sp, index) => {
        this.debug.push(this.$refs[`${this.uuid}-SP-${index}`][0].setCheckbox(value))
      })
    }
  },
  created () {
    this.uuid = this.$stratus.uuid()
  }
}
</script>
