<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :mini-variant="drawerMini" app stateless disable-resize-watcher :width="drawerWidth">
      <div class="pa-2 d-flex">
        <v-img v-if="!drawerMini" :src="`/img/brand-2024-${isDark ? 'dark' : 'light'}.svg?1`" :max-width="200" :height="56" />
        <div :class="drawerMini ? 'ml-auto mr-auto' : 'ml-auto'">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click.native.stop="toggleDrawerMini" class="clickable menu-icon--text" :class="drawerMini ? 'rotate-once-180' : ''">
                $vuetify.icons.left
              </v-icon>
            </template>
            <span>{{ drawerMini ? $t('Expand') : $t('Minimize') }}</span>
          </v-tooltip>
        </div>
      </div>

      <sca-app-menu :menu="currentMenu" brand-website :mini-variant="drawerMini" :external-links="externalLinks" />
    </v-navigation-drawer>

    <v-slide-y-transition>
      <v-app-bar fixed flat app :extension-height="appBarHeight">
        <v-icon v-show="!drawer" dark @click.native.stop="drawer = !drawer" class="menu-icon--text">
          $vuetify.icons.right
        </v-icon>

        <img class="beta-icon" v-if="isBeta" src="/img/beta.png">
        <v-toolbar-title>
          <span class="clickable" :class="isDark ? 'white--text' : 'black--text'" @click="goToHome">{{ $t('Scalair Manager') }}</span>
          <span class="text-subtitle-1">{{ pageTitle ? ' — ' + pageTitle : '' }}</span>
        </v-toolbar-title>

        <v-spacer />
        <v-chip v-if="$store.getters.appConfig.env !== 'production'" label small>
          {{ $store.getters.appConfig.env }}-{{ $store.getters.appConfig.appVersion }}
        </v-chip>
        <span v-if="isLord && fillingCache" class="text-caption">
          <cs-icon-loading show-text small />
        </span>

        <v-scroll-y-transition>
          <div v-if="globalMessage" class="highlighted-panel">
            {{ globalMessage }}
          </div>
        </v-scroll-y-transition>

        <v-spacer />

        <csm-cart-menu ref="global-cart-menu" />

        <div class="ml-4">
          <sca-notifications-menu-sse v-if="isLogged" />
        </div>

        <sca-connection v-if="isLogged" ref="connection-control" :sentry="Sentry" @login="onLogin" @logout="onLogout" confirm-logout show-theme show-zoom identifier-text="Email" identifier-is-email>
          <template slot="prepend-menu-connected">
            <v-list dense>
              <v-list-item @click="openPreferences">
                <v-icon small left>
                  $vuetify.icons.settings
                </v-icon>
                <v-list-item-title>
                  {{ $t('Preferences') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="canChameleon" @click="connectAs">
                <v-icon small left>
                  $vuetify.icons.chameleon
                </v-icon>
                <v-list-item-title>
                  {{ $t('Connect as...') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </template>
        </sca-connection>
        <v-btn v-else-if="$route.path !== '/' && $route.name !== 'secret'" rounded text color="warning" to="/">
          {{ $t('Session expired!') }}
        </v-btn>

        <div slot="extension" class="banner-container">
          <sca-banners :height="appBarHeight" :delay-for-next="10" @loaded="({count}) => bannerCount = count" />
        </div>
      </v-app-bar>
    </v-slide-y-transition>

    <v-main ref="app-main-container" id="app-main-container" class="app-main-container mt-3">
      <v-row v-if="loadQuestionnaires" no-gutters justify="center">
        <v-col cols="12" lg="8">
          <csm-ticket-questionnaires />
        </v-col>
      </v-row>

      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
    </v-main>

    <cs-simple-notifications group="cs-notifications" position="bottom center" :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'" :show-bug-report-on-error="isLogged" @openBugReport="openBugReport">
      <template v-slot:append="{ data }">
        <v-row v-if="data?.tickets?.length > 0" class="mt-2" align="center" justify="center" no-gutters>
          <div v-for="(ticket, index) in data.tickets" :key="`ticket-${index}`">
            <v-btn small rounded class="mx-2 main-button" @click="openTicket({ id: ticket?.id || ticket })">
              <v-icon small color="background" left>
                $vuetify.icons.read
              </v-icon>
              {{ $t('Open ticket') }}
            </v-btn>
            <v-btn small rounded class="mx-2 main-button" @click="copyURLToClipboard('tickets/' + ticket?.id || ticket)">
              <v-icon small color="background" left>
                $vuetify.icons.link
              </v-icon>
              {{ $t('Copy URL') }}
            </v-btn>
          </div>
        </v-row>
      </template>
    </cs-simple-notifications>

    <sca-bug-report ref="bug-report" />
    <sca-preferences-dialog ref="preferences-dialog" @close="closePrefs" />
    <sca-share-secret-dialog ref="share-secret" />
    <sca-reveal-secret-dialog ref="reveal-secret" />
    <sca-connect-as-dialog ref="connect-as-dialog" />
    <csm-ticket-dialog ref="ticket-dialog" />
  </v-app>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { mapGetters } from 'vuex'

import config from '@/config'
import CartMenu from '@/components/Carts/CartMenu.vue'

const API_CHECK_DELAY = 10000 // 10 seconds
const GLOBAL_MESSAGE_VISIBILITY_DELAY = 2000 // 2 seconds

export default {
  components: {
    'csm-cart-menu': CartMenu, // No lazy import, we have a ref on this.
    'csm-ticket-questionnaires': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketQuestionnaires')
  },
  data () {
    return {
      HELPER_TIMEOUT: 20 * 1000,
      Sentry,
      bannerCount: 0,
      canChameleon: false,
      config,
      contactMenuVisible: false,
      currentMenu: [],
      drawer: true, // Navigation drawer open/close
      element: null,
      fillingCacheEnabled: true,
      fillingCache: false,
      globalMessage: null,
      isLogged: false,
      loadQuestionnaires: false,
      showFullscreenHelp: false,
      user: null
    }
  },
  watch: {
    globalMessage (newValue, oldValue) {
      if (newValue !== oldValue) {
        setTimeout(() => { this.globalMessage = null }, GLOBAL_MESSAGE_VISIBILITY_DELAY)
      }
    },
    isDark (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$stratus.services.charts.applyFontProps(newValue)
      }
    },
    isLord (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.buildMenu(true)
      }
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isLord: '$stratus-states/isLord',
      menu: 'menu/app'
    }),
    appBarHeight () { return this.bannerCount ? '108px' : '' },
    drawerMini () { return this.$store.getters['$stratus-states/isSmallAppDrawer'] },
    drawerWidth () {
      const appFontSize = this.$store.getters['$stratus-states/appFontSize'] || this.$store.getters['$stratus-states/appFontSizeDefault']
      return appFontSize ? Math.max(appFontSize * 16, 250) : 250
    },
    externalLinks () {
      return [
        { href: config.urlScalairDashboard, help: this.$t('Dashboard'), img: '/img/scalair/dashboard.png?1' },
        { href: config.urlScalairStatus, help: this.$t('Scalair Status'), img: '/img/scalair/status.png?1' }
      ]
    },
    isBeta () { return this.$store.getters.appConfig.beta },
    isChameleon () { return this.$stratus.services.auth.isChameleon() },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    pageTitle () { return this.$store.getters['$stratus-states/pageTitle'] }
  },
  methods: {
    async applyPreferences () {
      if (this.$refs['preferences-dialog']) this.$refs['preferences-dialog'].apply()
      this.$vuetify.lang.current = this.$store.getters['$stratus-states/locale']
      this.$root.$i18n.locale = this.$store.getters['$stratus-states/locale']
      this.$i18n.locale = this.$store.getters['$stratus-states/locale']
      this.$store.dispatch('$stratus-states/applyAppFontSize')
      this.$vuetify.theme.dark = this.isDark
    },
    buildMenu (rebuild = false) {
      if (rebuild) this.$store.commit('menu/build')
      if (this.isLogged && this.me.id) {
        this.currentMenu = this.drawerMini ? this.menu.CONNECTED_MINI : this.menu.CONNECTED
      } else {
        this.currentMenu = this.drawerMini ? this.menu.DISCONNECTED_MINI : this.menu.DISCONNECTED
      }
    },
    async closePrefs () {
      await this.$store.dispatch('$stratus-states/getMe')
      this.applyPreferences()
      if (this.$refs['connection-control']) this.$refs['connection-control'].refresh()
    },
    copyURLToClipboard (path) {
      const url = window.location.origin + '/#/' + path
      if (this.$stratus.services.clipboard.copyText(url)) {
        this.$stratus.services.notify.success(this.$t('URL {url} copied to clipboard.', { url }))
      } else {
        this.$stratus.services.notify.error(this.$t('Error copying to clipboard!'))
      }
    },
    connectAs () {
      if (this.$refs['connect-as-dialog']) {
        this.$refs['connect-as-dialog'].open()
          .then(({ confirmed }) => {
            if (confirmed) window.location.reload()
          })
      }
    },
    goToHome () {
      this.$router
        .push({ name: 'home' }).catch((error) => { console.warn(error) })
    },
    onKeyUp (event) {
      if (event.key === 's' && event.altKey && this.$refs['share-secret']) {
        this.globalMessage = this.$t('Share a secret')
        this.$nextTick(() => {
          this.$refs['share-secret'].open()
        })
      }
    },
    onLogin (error) {
      if (!error && this.$route.name === 'home') {
        window.location.reload()
      }
      this.isLogged = this.$stratus.services.auth.isLogged()
    },
    onLogout (reason, error, response) {
      if (error) {
        console.error(error)
      }
      if (reason) {
        this.$stratus.services.notify.warning(this.$t(reason), { duration: -1 })
      } else {
        this.$router.push('/').catch((error) => { console.warn(error) })
        if (response && response.fromChameleon) {
          this.$stratus.services.notify.warning(this.$t('Returning to your account.'), { duration: -1 })
          setTimeout(() => window.location.reload(), 3000)
        }
      }
      this.isLogged = this.$stratus.services.auth.isLogged()
      this.buildMenu()
    },
    openBugReport (data) {
      this.$root['bug-report'].open(data)
    },
    openPreferences (options) {
      if (this.$refs['preferences-dialog']) this.$refs['preferences-dialog'].open(options).then()
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    toggleDrawerMini () {
      this.$store.commit('$stratus-states/toggleSmallAppDrawer')
      this.buildMenu(false)
    },
    async verifyAPIConnection () {
      this.isLogged = this.$stratus.services.auth.isLogged()
      if (this.isLogged) {
        const me = await this.$store.dispatch('$stratus-states/getMe')
        if (!me?.email) {
          console.warn('[manager] API checking...')
          setTimeout(this.verifyAPIConnection, API_CHECK_DELAY)
          return
        }
        if (this.$alto.services.routes.connectionForbidden.call(this, me)) { return }

        if (this.isLord) this.fillingCache = true
        this.buildMenu(true)

        if (this.fillingCacheEnabled) {
          // Force filling all caches
          await this.$stratus.fetchCaches(true, this.isLord)
          await this.$alto.fetchCaches(true, this.isLord)
          if (this.isLord) {
            try {
              await this.$store.dispatch('newsletters/list')
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
          }
        }

        this.canChameleon = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS_AUTH, this.$alto.API_PERMISSIONS.AUTH_AS) && !this.isChameleon
        await this.$store.dispatch('$alto-preferences/load')
        this.applyPreferences()
        if (this.isLord) this.fillingCache = false
      } else {
        this.buildMenu(true)
      }
    }
  },
  created () {
    this.$store.commit('$stratus-states/setBrowserTitle', this.$i18n.t('Scalair Manager'))
    this.isLogged = this.$stratus.services.auth.isLogged()
    this.$root['preferences-dialog'] = this.$refs['preferences-dialog']

    if (this.$route?.name === 'ticket' && this.$route.params.id) this.fillingCacheEnabled = false // Ticket dialog load cache itself

    this.verifyAPIConnection()
  },
  mounted () {
    const { name, version, isValid } = this.$stratus.services.browser.checkBrowser()
    if (!isValid) {
      this.$stratus.services.notify.error(this.$t('This browser ({name} {version}) is too old or not supported. This application will not work properly!', { name, version }))
    }
    this.$vuetify.theme.dark = this.isDark
    if (this.$route?.name === 'preferences-notifications') {
      this.$nextTick(() => { this.openPreferences({ tabNotifications: true }) })
    } else if (this.$route?.name === 'ticket-questionnaire') {
      this.loadQuestionnaires = false
    } else if (this.$route?.name !== 'secret') {
      this.$nextTick(() => { this.loadQuestionnaires = true })
    }

    this.$root['global-cart-menu'] = this.$refs['global-cart-menu']

    // Capture key in all application
    document.addEventListener('keyup', this.onKeyUp)
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.onKeyUp)
  }
}
</script>
