<template>
  <sca-modal-dialog :visible="visible" :loading="loading || isSaving" :can-save="canSave" :is-saving="isSaving" :max-width="$vuetify.breakpoint.lgAndUp ? '50%' : ''" @closeDialog="closeDialog()" @saveDialog="saveDialog" :external-id="externalId()">
    <div slot="title" class="d-flex align-center">
      {{ lead.id }}
      <sca-user-identity v-if="lead.id_user" :value="lead.id_user" class="mx-4" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(lead.id_user)" />
      <span v-else class="mr-4">
        {{ lead.email }}
      </span>
      <span class="mr-2">
        — {{ lead.state === LEAD_STATE_CONTACT ? $t('Contact') : $t('Lead') }}
      </span>
      {{ isDirty() ? '*' : '' }}
    </div>

    <template #subtitle>
      <div v-if="lead.id_user" class="highlighted-panel">
        <div class="text-caption">
          {{ $t('This lead has already been associated with a company.') }}
        </div>
        <sca-company-identity :value="lead.code" dense show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(lead.code)" />
      </div>
    </template>

    <template v-if="!lead.id_user" #buttons-header>
      <cs-action-button v-if="lead.id && lead.state === LEAD_STATE_CONTACT" text icon="$vuetify.icons.leads" :loading="loading || isSaving" help="Change this contact to lead..." @click="changeToLead" />

      <cs-action-button v-if="lead.email && lead.firstname && lead.lastname && lead.company_name" text icon="$vuetify.icons.company" :loading="loading || isSaving" help="Turning into a suspect..." @click="createCompany" />
    </template>

    <template v-if="errors" #content-fixed>
      <sca-field-error-panel :value="errors" />
    </template>

    <div slot="content">
      <v-form ref="lead-form" v-model="valid" lazy-validation :disabled="!canSave">
        <v-row align="end" dense>
          <v-col v-if="lead.state !== LEAD_STATE_CONTACT" cols="12" md="6" lg="2">
            <v-select v-model="lead.state" :items="leadStates" :label="$t('State')">
              <template #selection="{item}">
                {{ $t(item) }}
              </template>
              <template #item="{item}">
                {{ $t(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <v-text-field v-model="lead.company_name" :label="$t('Company')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <v-text-field v-model="lead.firstname" :label="$t('Firstname')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <div class="text-h6">
              <v-text-field v-model="lead.lastname" :label="$t('Lastname')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
            </div>
          </v-col>
        </v-row>

        <v-row align="start" dense>
          <v-col cols="12" md="6" lg="2" class="text-center">
            {{ $t('Score') }}
            <v-avatar size="96" class="mx-4">
              <span class="text-h3">
                {{ lead.score || 0 }}
              </span>
            </v-avatar>
          </v-col>

          <v-col>
            <v-row align="center" dense>
              <v-col cols="12" md="6" lg="4">
                <div class="text-h6">
                  <v-text-field v-model="lead.function" :label="$t('Function')" :rules="[$stratus.services.form.rules.max(128)]" :readonly="!canSave" />
                </div>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="lead.email" :label="$t('Email')" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.email]" class="required" :readonly="!canSave" />
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="lead.phone" :label="$t('Phone')" :readonly="!canSave" />
              </v-col>
            </v-row>

            <v-row align="center" dense>
              <v-col cols="12" md="6" lg="4">
                <sca-sales-person-select v-model="lead.id_lord_salesperson" clearable :label="$t('Sales Manager')" :readonly="!canSave" show-email show-phone show-company show-card link="emit" @link-click="openUser(lead.id_lord_salesperson)" />
              </v-col>

              <v-col cols="12" lg="8">
                <v-text-field v-model="lead.comment" :label="$t('Comment')" :rules="[$stratus.services.form.rules.max(4095)]" :readonly="!canSave" counter="4095" auto-grow />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" class="transparent" hide-slider>
            <v-tab href="#tab-campaigns">
              <v-icon left small>
                $vuetify.icons.marketingEvent
              </v-icon>
              {{ $t('Campaigns') }}
            </v-tab>

            <v-tab href="#tab-score-details">
              <v-icon left small>
                $vuetify.icons.details
              </v-icon>
              {{ $t('Score details') }}
            </v-tab>

            <v-tab href="#tab-history">
              <v-icon left small>
                $vuetify.icons.history
              </v-icon>
              {{ $t('History') }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-4">
            <v-tab-item value="tab-campaigns">
              <div class="text-caption">
                {{ $t('Related campaigns') }}
              </div>
              <sca-marketing-event-select v-model="lead.marketing_events_ids" clearable multiple dense />

              <csm-marketing-emailing-panel v-if="lead.brevo_last_date_data_recovery" outlined>
                <template #content>
                  <div class="d-flex align-center">
                    <div>
                      {{ $t('Last refresh on: {date}', { date: $stratus.dt(lead.brevo_last_date_data_recovery).format('LLL') }) }}
                    </div>

                    <v-btn-toggle v-model="brevoStat" dense class="transparent ml-4" @change="refreshBrevoStatDetails">
                      <v-btn small text :value="STATE_SENT" :class="brevoStat === STATE_SENT ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                        {{ $t('Sent: ') }} {{ lead.brevo_nb_sent }}
                      </v-btn>
                      <v-btn small text :value="STATE_DELIVERED" :class="brevoStat === STATE_DELIVERED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                        {{ $t('Delivered: ') }} {{ lead.brevo_nb_delivered }}
                      </v-btn>
                      <v-btn small text :value="STATE_CLICKED" :class="brevoStat === STATE_CLICKED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                        {{ $t('Clickers: ') }} {{ lead.brevo_nb_clicking }}
                      </v-btn>
                      <v-btn small text :value="STATE_OPENED" :class="brevoStat === STATE_OPENED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                        {{ $t('Opening: ') }} {{ lead.brevo_nb_opening }}
                      </v-btn>
                      <v-btn small text :value="STATE_UNSUSCRIBED" :class="brevoStat === STATE_UNSUSCRIBED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                        {{ $t('Unsubscribing: ') }} {{ lead.brevo_nb_unsubscribing }}
                      </v-btn>
                    </v-btn-toggle>
                  </div>
                </template>
              </csm-marketing-emailing-panel>

              <v-row v-if="brevoStat" no-gutters>
                <v-col v-if="brevoStat === STATE_CLICKED" cols="12">
                  <v-radio-group v-model="sortByLinks" dense row>
                    <v-radio :value="0" :label="$t('Sort by campaign')" />
                    <v-radio :value="1" :label="$t('Sort by link')" />
                  </v-radio-group>

                  <div v-for="(item, index) in brevoStatsClicked" :key="index">
                    <v-row v-if="sortByLinks === 0" align="center" dense>
                      <v-col class="shrink text-no-wrap">
                        <csm-marketing-campaign-link :value="item.campaign_id" prepend-text show-arrow />
                        <v-chip v-if="item.count > 1" x-small>
                          {{ item.count }}
                        </v-chip>
                      </v-col>
                      <v-col>
                        <div v-for="url in item.urls" :key="`${index}-${url}`">
                          <a :href="url.url" target="_ext" class="hover-underline-from-center">
                            <v-icon small>
                              $vuetify.icons.link
                            </v-icon>
                            {{ url.url }}
                            <v-chip v-if="url.count > 1" x-small>
                              {{ url.count }}
                            </v-chip>
                          </a>
                          <span class="text-caption color2--text">
                            <cs-expand-list :items="url.events_time" row>
                              <template v-slot:expand-list-item="{ item: d }">
                                {{ $stratus.dt(d).format('LLL') }}
                              </template>
                            </cs-expand-list>
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-else align="center" dense>
                      <v-col class="shrink text-no-wrap">
                        <a :href="item.url" target="_ext" class="hover-underline-from-center">
                          <v-icon small>
                            $vuetify.icons.link
                          </v-icon>
                          {{ item.url }}
                        </a>
                        <v-chip v-if="item.count > 1" x-small>
                          {{ item.count }}
                        </v-chip>
                      </v-col>
                      <v-col>
                        <div v-for="campaign in item.campaigns" :key="`${index}-${campaign.campaign_id}`">
                          <csm-marketing-campaign-link :value="campaign.campaign_id" append-text show-arrow />
                          <v-chip v-if="campaign.count > 1" x-small>
                            {{ campaign.count }}
                          </v-chip>
                          <span class="text-caption color2--text">
                            <cs-expand-list :items="campaign.events_time" row>
                              <template v-slot:expand-list-item="{ item: d }">
                                {{ $stratus.dt(d).format('LLL') }}
                              </template>
                            </cs-expand-list>
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col v-else-if="!brevoStats?.length">
                  {{ $t('No data...') }}
                </v-col>
                <v-col v-else cols="12">
                  {{ $t('Open campaign in Brevo for') }}
                  <csm-marketing-campaign-link v-for="(item, index) in brevoStats" :key="index" :count="item.count || 0" :value="item.campaign_id" look-chips no-text :label="$stratus.dt(item.event_time).format('LLL')" />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-score-details">
              <div v-if="!lead.score_detail?.length" class="text-center">
                {{ $t(loading ? 'Loading...' : 'No data...') }}
              </div>
              <v-virtual-scroll v-else :bench="1" :items="leadScoreDetailSorted" height="400" item-height="50">
                <template v-slot:default="{ index, item }">
                  <v-row :key="index" align="center" class="mt-2" no-gutters>
                    <v-col cols="1" class="text-center">
                      <v-avatar size="36">
                        {{ item.score || 0 }}
                      </v-avatar>
                    </v-col>

                    <v-col>
                      {{ item.description }}
                      <div class="color2--text">
                        {{ $stratus.dt(item.date).format('LLL') }}
                      </div>
                    </v-col>

                    <v-col>
                      <csm-marketing-campaign-link v-if="item.campaign_id" :value="item.campaign_id" show-arrow append-text />

                      <div v-if="item.url">
                        <a :href="item.url" target="_ext" class="hover-underline-from-center pt-1">
                          <v-icon small class="top-2">
                            $vuetify.icons.link
                          </v-icon>
                          {{ $t('Open link') }}
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-virtual-scroll>
            </v-tab-item>

            <v-tab-item value="tab-history">
              Stay tuned for History
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <csm-marketing-event-dialog ref="marketing-event-dialog" />
      <cs-confirm-dialog ref="marketing-confirm-dialog" />
      <csm-company-dialog ref="marketing-company-dialog" @closeDialog="closeDialog({ lead })" />
      <csm-user-dialog ref="marketing-user-dialog" />
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const STATE_SENT = 1
const STATE_DELIVERED = 2
const STATE_CLICKED = 3
const STATE_OPENED = 4
const STATE_UNSUSCRIBED = 5

export default {
  name: 'MarketingLeadForm',
  components: {
    'csm-marketing-campaign-link': () => import(/* webpackChunkName: "components" */ './MarketingCampaignLink'),
    'csm-marketing-emailing-panel': () => import(/* webpackChunkName: "components" */ './MarketingEmailingPanel'),
    'csm-marketing-event-dialog': () => import(/* webpackChunkName: "components" */ './MarketingEventDialog')
  },
  props: {
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      LEAD_STATE_CONTACT: this.$alto.defines.MARKETING.LEAD_STATE_CONTACT,
      LEAD_STATE_HANDLED: this.$alto.defines.MARKETING.LEAD_STATE_HANDLED,
      LEAD_STATE_UNHANDLED: this.$alto.defines.MARKETING.LEAD_STATE_UNHANDLED,
      STATE_SENT,
      STATE_DELIVERED,
      STATE_CLICKED,
      STATE_OPENED,
      STATE_UNSUSCRIBED,
      brevoStat: null,
      brevoStats: [],
      sortByLinks: 0,
      errors: null,
      isSaving: false,
      lead: {},
      leadStates: [],
      tab: 'tab-campaigns',
      valid: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),

    brevoStatsClicked () {
      if (this.brevoStat !== STATE_CLICKED) return []
      return this.sortByLinks ? this.brevoStats?.by_link : this.brevoStats?.by_campaign
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_LEADS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_LEADS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    leadScoreDetailSorted () {
      return _.sortBy(this.lead.score_detail, score => -this.$stratus.dt(score.date))
    }
  },
  methods: {
    changeToLead () {
      this.$set(this.lead, 'state', this.LEAD_STATE_UNHANDLED)
    },
    closeDialog ({ lead, forceClose } = {}) {
      if (forceClose || !this.canSave || !this.isDirty()) {
        this.$emit('closeDialog', lead)
        return
      }

      this.$refs['marketing-confirm-dialog'].open(this.$t('Modifications detected'), this.$t('Do you confirm the loss of your modifications?'))
        .then(confirmed => {
          if (confirmed) {
            this.$emit('closeDialog', lead)
          }
        })
    },
    async createCompany () {
      this.errors = null
      if (!this.lead.id_user && this.lead.email && this.lead.firstname && this.lead.lastname && this.lead.company_name) {
        const confirmed = await this.$refs['marketing-confirm-dialog'].open(this.$t('Confirm'), this.$t('Do you confirm the creation of the company from {name}?', { name: `${this.lead.id || ''} ${this.lead.company_name}` }))
        if (confirmed) {
          try {
            const savedLead = await this.saveDialog(false)
            this.reset(savedLead)
            this.isSaving = true
            // First a dry run to detect errors
            const response = await this.$store.dispatch('$alto-marketing/createCompanyFromLead', { lead: this.sanitize(savedLead) })
            if (response?.errors) {
              const errList = Object.keys(response.errors)
              if (errList.length) {
                this.errors = response.errors // Houston, we've got a problem!
              } else {
                // We can really create the company
                const newCompany = await this.$store.dispatch('$alto-marketing/createCompanyFromLead', { lead: savedLead, dryRun: false })
                this.$stratus.services.notify.success(this.$t('Company {code} — {name} created.', newCompany))
                this.openCompany(newCompany.code)
              }
            }
          } catch (error) {
            this.$stratus.services.notify.error(error)
          } finally {
            this.isSaving = false
          }
        }
      }
    },
    display (show = false) {
      this.visible = show
    },
    externalId () {
      return this.lead.id ? 'marketing-lead/' + this.lead.id : ''
    },
    isDirty () {
      if (!this.originalLead) return false
      return !_.isEqual(this.sanitize(this.lead), this.originalLead)
    },
    openCompany (id) {
      if (this.$refs['marketing-company-dialog']) this.$refs['marketing-company-dialog'].open(id)
    },
    openEvent (id) {
      this.$refs['marketing-event-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['marketing-user-dialog']) this.$refs['marketing-user-dialog'].open(id)
    },
    refreshBrevoStatDetails () {
      switch (this.brevoStat) {
        case STATE_SENT:
          this.brevoStats = this.lead.brevo_stats.messages_sent
          break
        case STATE_DELIVERED:
          this.brevoStats = this.lead.brevo_stats.delivered
          break
        case STATE_CLICKED:
          this.brevoStats = this.lead.brevo_stats.clicked
          break
        case STATE_OPENED:
          this.brevoStats = this.lead.brevo_stats.opened
          break
        case STATE_UNSUSCRIBED:
          this.brevoStats = this.lead.brevo_stats.unsubscriptions
          break
        default:
          this.brevoStats = []
          this.brevoStat = null
      }
    },
    resetOriginal () {
      this.originalLead = { ...this.sanitize(this.lead) }
    },
    reset (lead = {}) {
      this.tab = 'tab-campaigns'
      this.brevoStat = null
      this.brevoStats = []
      this.sortByLinks = 0
      this.isSaving = false
      this.errors = null
      this.lead = { ...lead }
      this.resetOriginal()
    },
    sanitize (lead) {
      const result = {
        ...lead,
        date_begin: this.$stratus.dt(lead.date_begin).isValid() ? this.$stratus.dt(lead.date_begin).format('YYYY-MM-DD') : '',
        date_end: this.$stratus.dt(lead.date_end).isValid() ? this.$stratus.dt(lead.date_end).format('YYYY-MM-DD') : '',
        marketing_events_ids: lead.marketing_events_ids,
        name: this.$stratus.services.strings.stripHtmlTags(lead.name)
      }
      if (lead.id) result.id = lead.id
      return result
    },
    async saveDialog (close = true) {
      if (!this.canSave) return false

      if (!this.$refs['lead-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return false
      }

      this.isSaving = true
      try {
        const newLead = await this.$store.dispatch('$alto-marketing/saveLead', this.sanitize(this.lead))
        this.resetOriginal()
        if (close) this.closeDialog({ lead: newLead })
        return newLead
      } catch (error) {
        this.$stratus.services.notify.error(error)
        return false
      } finally {
        this.isSaving = false
      }
    }
  },
  created () {
    this.leadStates = [this.LEAD_STATE_UNHANDLED, this.LEAD_STATE_HANDLED]
  }
}
</script>
