<template>
  <sca-modal-dialog :visible="visible" @closeDialog="closeDialog" @saveDialog="saveDialog" :can-save="canSave" :is-saving="isSaving" :action="action" width="50%" :max-width="$vuetify.breakpoint.lgAndUp ? '80%' : ''" :external-id="externalId">
    <span slot="title">
      {{ title }}
      <span class="text-caption">{{ this.$t('Contract') }}</span>
    </span>

    <v-tabs hide-slider slot="tabs" color="transparent" v-model="tab" show-arrows>
      <cs-validation-tab href="#tab-info" icon="icon-contract" :label="$t('Information')" :error="tabErrors['tab-info']" />
      <cs-validation-tab href="#tab-document" icon="icon-invoice" :label="$t('Documents')" :error="tabErrors['tab-documents']" />
    </v-tabs>

    <div slot="content" v-if="visible" class="pt-4">
      <v-form ref="ContractForm" v-model="valid" lazy-validation>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-info" eager>
            <v-row align="end">
              <v-col cols="4" sm="4">
                <sca-customer-select v-model="contract.code" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" tab="tab-info" :readonly="!canSave" show-email show-phone link="emit" @link-click="openCompany(contract.code)" />
              </v-col>
              <v-col cols="12" sm="4">
                <v-select v-model="contract.type" :item-value="'id'" :items="listTypes" :label="$t('Type')" :rules="[$stratus.services.form.rules.required]" class="required" tab="tab-info" :readonly="!canSave" />
              </v-col>
            </v-row>

            <v-row align="baseline">
              <v-col cols="12" sm="4">
                <v-text-field v-model="contract.number" :label="$t('Contract\'s number')" :counter="64" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.max(64)]" class="required" tab="tab-info" :readonly="!canSave" />
              </v-col>
              <v-col cols="12" sm="8">
                <sca-contract-select v-model="contract.additional_clause" :label="$t('Additional clauses')" tab="tab-info" multiple :clearable="canSave" :readonly="!canSave" :show-company="isLord" :company="contract.code" :exclude-ids="[contract.id]" />
              </v-col>
            </v-row>

            <v-row align="start">
              <v-col cols="12" sm="4">
                <sca-contract-select v-model="contract.contract" :label="$t('Principal\'s contract')" tab="tab-info" :clearable="canSave" :readonly="!canSave" :show-company="isLord" :company="contract.code" :exclude-ids="[contract.id].concat(contract.additional_clause)" />
              </v-col>
              <v-col cols="12" sm="8" class="d-flex align-center">
                <v-checkbox v-model="contract.indication_syntec" :indeterminate="syntecMustBeSet" :label="$t('Syntec indication')" tab="tab-info" :readonly="!canSave" />
                <cs-alert-panel v-if="isLord && syntecMustBeSet" type="warning" class="ml-4" :text="$t('A value must be chosen for «{name}».', { name:$t('Syntec indication') })" />
              </v-col>
            </v-row>

            <v-row>
              <v-col v-if="contract.status" cols="12">
                <v-stepper :value="statusIndex">
                  <v-stepper-header>
                    <v-stepper-step :step="1" complete color="accent" complete-icon="icon-checkmark-circle">
                      {{ $t('To be signed') }}
                    </v-stepper-step>

                    <v-divider />

                    <v-stepper-step :step="2" :complete="contract.status !== 'to_sign'" color="success" complete-icon="icon-checkmark-circle">
                      {{ $t('Signed') }}
                    </v-stepper-step>

                    <v-divider />

                    <v-stepper-step v-show="contract.status === 'terminated'" :step="3" :complete="contract.status === 'terminated'" color="grey" complete-icon="icon-checkmark-circle">
                      {{ $t('Finished') }}
                    </v-stepper-step>
                  </v-stepper-header>
                </v-stepper>
              </v-col>

              <v-col cols="12" sm="4" v-if="contract.status && contract.status !== 'to_sign'" class="text-left">
                <div>{{ $t('Signature\'s date:') }}</div>
                <div class="text-body-1">
                  {{ contract.sign_date ? $stratus.dt(contract.sign_date).format('LL') : $t('None') }}
                </div>
              </v-col>
              <v-col cols="12" sm="4" v-if="contract.status && contract.status !== 'to_sign'" class="text-center">
                <div>{{ $t('Begin\'s date:') }}</div>
                <div class="text-body-1">
                  {{ contract.begin_date ? $stratus.dt(contract.begin_date).format('LL') : $t('None') }}
                </div>
              </v-col>
              <v-col cols="12" sm="4">
                <div v-if="contract.status && contract.status === 'terminated'" class="text-right">
                  <div>{{ $t('End\'s date:') }}</div>
                  <div class="text-body-1">
                    {{ contract.end_date ? $stratus.dt(contract.end_date).format('LL') : $t('None') }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="canSave">
              <v-col cols="12" sm="12">
                <div v-if="contract.status && contract.status === 'to_sign'">
                  {{ $t('This contract must be signed to take effect.') }}

                  <v-menu v-model="menu" :close-on-click="false" :close-on-content-click="false" transition="slide-y-transition" offset-y top min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" rounded>
                        {{ $t('Sign this contract...') }}
                      </v-btn>
                    </template>
                    <v-card outlined class="pa-2">
                      <v-row>
                        <v-col cols="12" sm="6">
                          <p>{{ $t('Signature\'s date:') }} {{ contract.sign_date ? $stratus.dt(contract.sign_date).format('LL') : $t('None') }}</p>
                          <cs-date-picker-panel v-model="contract.sign_date" no-title scrollable />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <p>{{ $t('Begin\'s date:') }} {{ contract.begin_date ? $stratus.dt(contract.begin_date).format('LL') : $t('None') }}</p>
                          <cs-date-picker-panel v-model="contract.begin_date" no-title scrollable />
                        </v-col>
                      </v-row>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn text class="main-button" @click="cancelMenu">
                          {{ $t('Cancel') }}
                        </v-btn>
                        <v-btn rounded class="main-button" :disabled="!contract.sign_date || !contract.begin_date" @click="signContract">
                          {{ $t('Sign') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
                <div v-else-if="contract.status && contract.status === 'signed'">
                  <v-menu v-model="menu" :close-on-click="false" :close-on-content-click="false" transition="slide-y-transition" offset-y top min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" rounded>
                        {{ $t('Finish this contract...') }}
                      </v-btn>
                    </template>
                    <v-card outlined class="pa-2">
                      <p>{{ $t('End\'s date:') }} {{ contract.end_date ? $stratus.dt(contract.end_date).format('LL') : $t('None') }}</p>
                      <cs-date-picker-panel v-if="canSave" v-model="contract.end_date" :min="minDateEnd()" no-title scrollable />

                      <v-card-actions>
                        <v-spacer />
                        <v-btn text class="main-button" @click="cancelMenu">
                          {{ $t('Cancel') }}
                        </v-btn>
                        <v-btn rounded class="main-button" @click="finishContract">
                          {{ $t('Finish') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </div>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="tab-document" eager>
            <v-row no-gutters class="mb-4">
              <template v-if="contract.files?.length">
                <v-col cols="12" md="6" class="text-h6">
                  {{ $t('This contract') }}
                  <cs-attachments-list :id="contract.id" :value="contract.files" :can-delete="canDeleteDocument" can-download @delete="deleteDocument" />
                </v-col>
              </template>

              <template v-else-if="canSave">
                <v-col>
                  <p>{{ $t('Add a document:') }}</p>
                  <cs-file-uploader v-model="files" :file-size-limit="ALL_FILE_SIZE_LIMIT" use-isu />
                </v-col>
              </template>
            </v-row>

            <v-row v-if="mainContracts.length" align="start" no-gutters>
              <v-col cols="12" class="text-h6">
                {{ $t('Principal\'s contract') }}
              </v-col>

              <v-col v-for="mainContract in mainContracts" :key="mainContract.uuid" cols="12" md="6" class="pa-4">
                <div class="text-subtitle-1">
                  {{ mainContract.text }}
                </div>
                <cs-attachments-list :id="mainContract.id" :value="mainContract.files" can-download />
              </v-col>
            </v-row>

            <v-row v-if="additionalContracts.length" align="start" no-gutters>
              <v-col cols="12" class="text-h6">
                {{ $t('Additional clauses') }} <v-chip small>
                  {{ additionalContracts.length }}
                </v-chip>
              </v-col>

              <v-col v-for="subContract in additionalContracts" :key="subContract.uuid" cols="12" md="6" class="pa-4">
                <div class="text-subtitle-1">
                  {{ subContract.text }}
                </div>
                <cs-attachments-list :id="subContract.id" :value="subContract.files" can-download />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
      <csm-company-dialog ref="company-dialog" />
    </div>

    <sca-footer-create-update-at-by v-model="contract" slot="footer" :link-user="isLord" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

const STEPS = ['to_sign', 'signed', 'terminated']

export default {
  name: 'CsmContractForm',
  props: {
    contract: { type: Object, required: true },
    update: { type: Boolean, default: false },
    show: { type: Boolean, default: true },
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      ALL_FILE_SIZE_LIMIT: 20 * 1000 * 1000, // 20MB
      active: [],
      mainContracts: [],
      additionalContracts: [],
      files: [],
      valid: true,
      isSaving: false,
      isSearching: false,
      listTypes: [],
      listStatus: [],
      menu: false,
      pagination: {
        descending: false,
        rowsPerPage: -1 // -1 for All
      },
      search: '',
      showFile: true,
      tab: '',
      tabErrors: {},
      useHQAddress: false
    }
  },
  computed: {
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canSave () {
      return (this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CONTRACTS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CONTRACTS, this.$alto.API_PERMISSIONS.UPDATE))
    },
    canDeleteDocument () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CONTRACTS, this.$alto.API_PERMISSIONS.DELETE_FILE)
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    title () {
      return this.$stratus.services.strings.stripHtmlTags(`${this.contract.number || ''}`)
    },
    selected () {
      if (!this.active || !this.active.length) return undefined
      return this.active[0]
    },
    statusIndex () {
      return STEPS.indexOf(this.contract.status) + 1
    },
    syntecMustBeSet () { return this.contract.indication_syntec === undefined || this.contract.indication_syntec === null }
  },
  methods: {
    closeDialog (saved) {
      this.$emit('closeDialog', saved)
    },
    cancelMenu () {
      // Reset date to null
      if (this.contract.status === 'to_sign') {
        this.contract.sign_date = null
        this.contract.begin_date = null
        this.contract.end_date = null
      } else if (this.contract.status === 'signed') {
        this.contract.end_date = null
      }
      this.menu = false
    },
    async deleteDocument ({ id, attachment }) {
      if (!id || !attachment) return

      try {
        const contract = await this.$store.dispatch('$alto-contracts/deleteDocument', { id, file: attachment })
        this.files = []
        this.$emit('change', contract)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    externalId () {
      if (!this.contract.id) return
      return [this.title, this.contract.id ? 'contracts/' + this.contract.id : '']
    },
    finishContract () {
      this.contract.status = 'terminated'
      this.menu = false
    },
    getContractTypeName (id) {
      return (_.find(this.listTypes, { id }) || {}).text
    },
    signContract () {
      this.contract.status = 'signed'
      this.menu = false
    },
    async loadLists () {
      this.isSaving = true
      try {
        await this.$store.dispatch('$alto-contracts/loadTypes')
        this.listTypes = this.$store.getters['$alto-contracts/types']

        await this.$store.dispatch('$alto-contracts/loadStatus')
        this.listStatus = this.$store.getters['$alto-contracts/status']

        this.isSaving = false
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.isSaving = false
      }
    },
    async loadContracts (contract) {
      if (!contract) return
      // Do not recurse to avoid infinite loop in contracts links!
      this.additionalContracts = []
      this.mainContracts = []

      // Defines the main contract
      if (contract.contract) {
        const c = await this.$store.dispatch('$alto-contracts/getById', contract.contract)
        if (c) {
          this.mainContracts.push({
            ...c,
            uuid: this.$stratus.uuid(),
            text: c.number + ` (${this.getContractTypeName(c.type)})`
          })
        }
      }

      _.forEach(contract.additional_clause, async id => {
        // Get contract by its number
        const c = await this.$store.dispatch('$alto-contracts/getById', id)
        if (c) {
          this.additionalContracts.push({
            ...c,
            uuid: this.$stratus.uuid(),
            text: c.number + ` (${this.getContractTypeName(c.type)})`
          })
        }
      })
    },
    onChangeMails (value) {
      this.contract.mail_accounting = value
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    minDateEnd () {
      if (this.contract.begin_date) return this.$stratus.dt(this.contract.begin_date).startOf('day').toISOString()
      if (this.contract.sign_date) return this.$stratus.dt(this.contract.sign_date).startOf('day').toISOString()
      return this.$stratus.dt().toISOString()
    },
    async reset (contract) {
      // Go to first tab
      this.tab = 'tab-info'
      this.tabErrors = {}
      if (this.$refs.ContractForm) await this.$refs.ContractForm.reset()
      if (contract) {
        this.loadContracts(contract)
      }
    },
    async saveDialog () {
      if (this.syntecMustBeSet) {
        this.$stratus.services.notify.error(this.$t('A value must be chosen for «{name}».', { name: this.$t('Syntec indication') }))
        return
      }

      this.isSaving = true
      // Sanitize
      this.contract = this.$stratus.services.strings.stripHtmlTags(this.contract)
      // Validate?
      if (!this.$refs.ContractForm.validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.tabErrors = this.$stratus.services.form.tabsWithError(this.$refs.ContractForm)
        this.isSaving = false
        return
      }

      // Force status on new contract
      this.contract.status = this.contract.status || 'to_sign'
      if (this.contract.status !== 'terminated') this.contract.end_date = null

      try {
        await this.$store.dispatch('$alto-contracts/save', { contract: this.contract, files: this.files })
        this.$stratus.services.notify.success(
          this.$t(this.update ? 'Contract {number} updated.' : 'Contract {number} created.', this.contract)
        )
        this.closeDialog(true)
      } catch (error) {
        if (error.status === 400 && error?.body?.number) {
          console.warn(error)
          this.$stratus.services.notify.error(this.$t('A contract with number {n} already exists!', { n: this.contract.number }))
        } else this.$stratus.services.notify.error(error)
      } finally {
        this.isSaving = false
      }
    }
  },
  mounted () {
    this.loadLists()
  }
}
</script>
