<template>
  <div>
    <sca-modal-dialog :visible="show" max-width="60%" @closeDialog="close" @saveDialog="save" :can-save="canSave" :is-saving="isSaving" :action="action" :external-id="externalId()">
      <template #title>
        {{ subject }} — {{ update ? $t('Event') : $t('Add an event') }}
      </template>

      <div slot="content">
        <v-form ref="followup-form" v-model="valid" lazy-validation>
          <v-row align="center" dense>
            <v-col v-if="companyCode === null" cols="12" md="4">
              <sca-customer-select v-model="customer" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Customer')" clearable @input="onChangeCustomer" show-email show-phone link="emit" @link-click="openCompany(customer)" />
            </v-col>

            <v-col>
              <sca-users-select v-model="organizer" filter-lord show-email show-phone show-company show-card :label="$t('Organizer')" :rules="[$stratus.services.form.rules.required]" class="required" link="emit" @link-click="openUser(organizer)" />
            </v-col>
          </v-row>

          <v-row align="center" no-gutters>
            <v-col cols="12">
              <sca-users-select v-model="guestList" :filter-company="customers" :label="$t('Attendees')" :rules="[$stratus.services.form.rules.required]" class="required" clearable multiple show-company />
            </v-col>
          </v-row>

          <v-row align="baseline" dense>
            <v-col cols="6" md="5">
              <v-select v-model="eventType" :items="eventTypeList" :label="$t('Type')" />
            </v-col>

            <v-col cols="12" md="7">
              <cs-date-time-range :from-date="dateBegin" :to-date="dateEnd" single-date required @changeFrom="onChangeFrom" @changeTo="onChangeTo" />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field v-model="subject" :label="$t('Subject')" class="required" />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-tabs hide-slider v-model="tab" color="transparent">
                <cs-loader-tab href="#tab-content" :header="$t('Content')" icon="icon-opened-email" />
                <cs-loader-tab href="#tab-raw-text" :header="$t('Raw text')" icon="icon-file" />
                <cs-loader-tab v-if="followup.id" href="#tab-attachment" :header="$t('Attachments')" icon="icon-attachment" :loading="loadingAttachments" :count="attachments.length" @load="loadFollowUpAttachmentsMeta(attachments)" />

                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-content">
                    <cs-wysiwyg-textarea v-model="description" :placeholder="$t('Description')" :title="$t('Description')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" />
                  </v-tab-item>

                  <v-tab-item value="tab-raw-text">
                    <pre v-if="description">{{ description }}</pre>
                    <v-row v-else justify="center" class="mt-4">
                      <v-col class="shrink text-no-wrap">
                        <cs-alert-panel type="info" dense :text="$t('No content...')" />
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item v-if="followup.id" value="tab-attachment">
                    <v-expand-transition>
                      <v-row v-if="loadingAttachmentsCount > 0" dense align="center" justify="center">
                        <v-col cols="12" sm="6" md="3">
                          <div text dense class="ma-4">
                            <cs-icon-loading show-text small left />
                            <v-progress-linear :value="percentLoadingAttachmentsCount" />
                          </div>
                        </v-col>
                      </v-row>
                    </v-expand-transition>

                    <v-row dense>
                      <v-col cols="12" class="text-right pa-4">
                        <v-menu offset-y v-model="menuAttachments" transition="scale-transition" :close-on-content-click="false">
                          <template v-slot:activator="{ on }">
                            <v-btn rounded v-on="on">
                              {{ $t('Add attachments') }}
                              <v-icon small right>
                                icon-small-arrow-down
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-card outlined class="px-0">
                            <v-card-title>
                              {{ $t('Add attachments') }}
                            </v-card-title>
                            <v-card-text>
                              <cs-file-upload-input v-model="files" :file-size-limit="ALL_FILE_SIZE_LIMIT" :total-size-limit="ALL_FILE_SIZE_LIMIT" use-isu />
                              <div class="text-caption">
                                {{ $t('You have a space of {size}MB for all attachments.', { size: ALL_FILE_SIZE_LIMIT / 1000 / 1000 }) }}
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer />
                              <v-btn rounded :disabled="!files.length" @click="sendAttachments">
                                {{ $t('Send') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>

                        <v-row v-if="sendingFiles">
                          <v-col v-if="files[fileIndex]" cols="6">
                            {{ $t('Sending file « {name} »', { name: files[fileIndex].name }) }}
                          </v-col>
                          <v-col>
                            <v-progress-linear v-model="sendingFileProgress" />
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols="12" class="pa-4">
                        <cs-attachments-list :id="followup.id" :value="attachments" :can-delete="canDeleteAttachment" @delete="deleteAttachments" />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-col>
          </v-row>
        </v-form>

        <cs-expand-list v-if="opportunity_ids?.length > 0" :items="opportunity_ids" row>
          <template v-slot:expand-list-item="{ item }">
            <sca-opportunity-identity :value="item" :label="item" show-avatar link="emit" @link-click="openOpportunity(item)" />
          </template>
        </cs-expand-list>
      </div>

      <div v-if="!id" slot="buttons-prepend">
        <v-switch v-model="anotherOne" :label="$t('Create another event')" />
      </div>

      <sca-footer-create-update-at-by slot="footer" v-model="followup" :link-user="isLord" />
    </sca-modal-dialog>
    <csm-company-dialog ref="company-dialog" />
    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import getClassNameForExtension from 'font-awesome-filetypes'

let previousId = null

export default {
  name: 'FollowUpEventDialogLite',
  props: {
    companyCode: { type: String, default: null },
    update: { type: Boolean, default: false }
  },
  data () {
    return {
      ALL_FILE_SIZE_LIMIT: 20 * 1000 * 1000,
      DESCRIPTION_MAX_LENGTH: 4095,
      DESCRIPTION_MIN_LENGTH: 1,
      anotherOne: false,
      attachments: [],
      attachmentsMeta: [],
      fileIndex: 0,
      files: [],
      customer: null,
      dateBegin: null,
      dateEnd: null,
      description: '',
      eventType: '',
      followup: {},
      guestList: [],
      id: null,
      idSalesPerson: null,
      isSaving: false,
      loadingAttachments: false,
      loadingAttachmentsCount: 0,
      loadingPdf: false,
      menuAttachments: false,
      opportunity_ids: [],
      organizer: null,
      sendingFiles: false,
      show: false,
      subject: '',
      tab: 'tab-content',
      valid: false
    }
  },
  computed: {
    action () {
      if (this.canUpdate) return this.$t('Update')
      return this.$t('Details')
    },
    canDeleteAttachment () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.FOLLOWUP_EVENT_DELETE_ATTACHMENT)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.UPDATE)
    },
    icon () { return this.$store.getters['followups/ICON_TYPE'](this.$store.getters['followups/ITEM_TYPE_EVENT']) },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.$store.getters['followups/ITEM_TYPE_EVENT']) },
    customers () {
      if (!this.customer) return []
      const list = [this.customer]
      if (this.isLord) list.push(this.$store.getters.appConfig.defaults.corporationCode)
      return list
    },
    eventTypeList () {
      return this.$store.getters['$alto-followups/eventsTypes']
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    maxWidth () {
      if (this.$vuetify.breakpoint.xl) return '50%'
      if (this.$vuetify.breakpoint.lgAndUp) return '70%'
      return '100%'
    },
    percentLoadingAttachmentsCount () {
      return this.attachments?.length ? 100 * (this.attachments.length - this.loadingAttachmentsCount) / this.attachments.length : 0
    },
    sendingFileProgress () { return this.files.length ? 100 * this.fileIndex / this.files.length : 0 }
  },
  watch: {
    companyCode: {
      immediate: true,
      handler (newValue) {
        this.customer = newValue
      }
    }
  },
  async mounted () {
    await this.$store.dispatch('$alto-followups/eventsTypes')
    if (this.$route?.name === 'followup-event' && this.$route.params.id) {
      if (this.$route.params.id !== previousId) {
        previousId = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.openId(this.$route.params.id)
      }
    }
  },
  methods: {
    close (saved = false) {
      this.show = false
      this.resolve(saved)
      if (this.anotherOne) {
        this.$emit('close-reopen', saved)
      } else {
        this.$emit('close', saved)
      }
    },
    customerName (id) { return this.$store.getters['$alto-companies/getName'](id) },
    async deleteAttachments ({ id, attachment }) {
      if (!id || !attachment) return

      try {
        this.isDeleting = true
        this.followup = await this.$store.dispatch('followups/eventAttachmentDel', { id, filename: attachment.name })
        this.attachment = this.followup.attachments
        this.loadFollowUpAttachmentsMeta(this.attachments)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isDeleting = false }, 1000)
      }
    },
    externalId () {
      return this.id ? 'followup-event/' + this.id : ''
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    async onChangeCustomer () {
      // Do not modify if a sales person is already input
      if (!this.customer || this.idSalesPerson) return null
      try {
        const salesPersonId = await this.$store.dispatch('$alto-companies/getSalesPerson', this.customer)
        if (_.isNumber(salesPersonId) && salesPersonId >= 0) {
          this.idSalesPerson = salesPersonId
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    async loadAttachement () {
      if (this.tmpAttachmentList.length === 0) {
        setTimeout(() => {
          this.loadingAttachments = false
          this.loadingAttachmentsCount = 0
        }, 500)
        return
      }

      const attachment = this.tmpAttachmentList.pop()
      try {
        this.loadingAttachmentsCount = this.tmpAttachmentList.length
        const meta = await this.$store.dispatch(
          'documents/getFileMeta',
          attachment.path
        )
        meta.name = attachment.name
        meta.path = attachment.path
        meta.icon = getClassNameForExtension(
          attachment.path.split('.').pop()
        )
        meta.url = this.$stratus.services.api.path(`/documents/${attachment.path}?format=raw`)
        this.attachmentsMeta.push(meta)
      } catch (error) {
        this.attachmentsMeta.push({
          name: attachment.name,
          error: this.$stratus.services.format.sanitizeNotificationMessage(error)
        })
      }
      this.loadAttachement()
    },
    loadFollowUpAttachmentsMeta (attachments) {
      if (!attachments) return
      this.loadingAttachments = true
      this.attachmentsMeta = []
      this.tmpAttachmentList = [...attachments]
      this.loadAttachement()
    },
    onChangeFrom (value) {
      this.dateBegin = value
    },
    onChangeTo (value) {
      this.dateEnd = value
    },
    open (data) {
      this.followup = { ...data }
      this.reset(this.followup)
      this.show = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async openId (id) {
      try {
        this.followup = await this.$store.dispatch('$alto-followups/event', id)
        this.open(this.followup)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reset (data = {}) {
      if (this.$refs['followup-form']) await this.$refs['followup-form'].reset()
      this.id = data.id
      this.subject = data.subject || ''
      this.eventType = data.type
      this.guestList = data.id_guests || []

      if (this.$stratus.dt(data.date_begin).isAfter(this.$stratus.dt(data.date_end))) {
        // date begin after date end
        this.dateBegin = data.date_end || new Date()
        this.dateEnd = data.date_begin || new Date()
      } else {
        this.dateBegin = data.date_begin || new Date()
        this.dateEnd = data.date_end || new Date()
      }

      this.attachments = data.attachments || []
      this.description = this.sanitizeBody(data.description) || ''
      this.idSalesPerson = data.id_lord_salesperson
      this.customer = data.code || this.companyCode
      this.organizer = data.id_organizer || null
      this.anotherOne = data.anotherOne || false
      this.opportunity_ids = data.opportunity_ids || []
      this.tab = 'tab-content'
      this.isSaving = false
      this.valid = false
    },
    sanitizeBody (content) {
      if (!content) return ''
      // Sanitize and replace \n by html tag BR
      // Windows use \r\n, Linux/Unix use \n and MacOS use \r
      return this.$stratus.services.strings
        .stripHtmlTags(content)
        .replace(/\r\n/g, '<br>')
        .replace(/\r/g, '<br>')
        .replace(/\n/g, '<br>')
    },
    save () {
      this.isSaving = true
      if (!this.$refs['followup-form'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      if (this.$stratus.dt(this.dateBegin).isAfter(this.$stratus.dt(this.dateEnd))) {
        [this.dateEnd, this.dateBegin] = [this.dateBegin, this.dateEnd]
      }

      const data = {
        id: this.id,
        subject: this.subject,
        type: this.eventType,
        id_guests: this.guestList,
        date_begin: this.$stratus.dt(this.dateBegin).toISOString(),
        date_end: this.$stratus.dt(this.dateEnd).toISOString(),
        description: this.description,
        id_lord_salesperson: this.idSalesPerson,
        code: this.customer,
        id_organizer: this.organizer
      }

      this.$store.dispatch('followups/saveEvent', data)
        .then(response => {
          this.resolve(response)
          this.close(true)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
          this.isSaving = false
        })
    },
    async sendAttachments () {
      try {
        if (!this.files.length) return
        this.sendingFiles = true
        this.followup = await this.$store.dispatch('followups/eventAttachmentAdd', { id: this.followup.id, files: this.files })
        this.attachments = this.followup.attachments
        this.loadFollowUpAttachmentsMeta(this.attachments)
        this.files = []
        this.menuAttachments = false
      } catch (error) {
        console.error(error)
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.sendingFiles = false }, 500)
      }
    }
  }
}
</script>
