<template>
  <div>
    <v-tabs v-model="tab" class="transparent" hide-slider>
      <cs-validation-tab href="#tab-downsale-analysis" icon="icon-dashboard-csm" :label="$t('Downsale analysis')" />
      <cs-validation-tab href="#tab-company-indicators" icon="icon-company" :label="$t('Company indicators')" />
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-downsale-analysis">
        <v-row align="center" dense>
          <v-col cols="4" md="3" class="pt-4">
            <sca-customer-select v-model="companiesSearch" :placeholder="$t('Company')" @input="refreshDebounced" clearable show-email single-line show-phone link="emit" @link-click="openCompany(companiesSearch)" />
          </v-col>

          <v-col cols="4" md="2" lg="1">
            <cs-month-picker v-model="beginAt" :disabled="loading || loadingHistory" :label="$t('From date')" />
          </v-col>

          <v-col cols="4" md="2" lg="1">
            <cs-month-picker v-model="endAt" :disabled="loading || loadingHistory" :label="$t('To date')" />
          </v-col>

          <v-col>
            <v-btn rounded small class="shortcut-button mr-2" :disabled="loading || loadingHistory" @click="setDateRange(RANGE_LAST_MONTH)">
              <v-icon small left>
                icon-clock
              </v-icon>
              {{ $t('Last month') }}
            </v-btn>
            <v-btn rounded small class="shortcut-button mr-2" :disabled="loading || loadingHistory" @click="setDateRange(RANGE_CURRENT_MONTH)">
              <v-icon small left>
                icon-clock
              </v-icon>
              {{ $t('Current month') }}
            </v-btn>
            <v-btn rounded small class="shortcut-button mr-2" :disabled="loading || loadingHistory" @click="setDateRange(RANGE_CURRENT_QUARTER)">
              <v-icon small left>
                icon-clock
              </v-icon>
              {{ $t('Current quarter') }}
            </v-btn>
            <v-btn rounded small class="shortcut-button mr-2" :disabled="loading || loadingHistory" @click="setDateRange(RANGE_CURRENT_YEAR)">
              <v-icon small left>
                icon-clock
              </v-icon>
              {{ $t('Current year') }}
            </v-btn>
          </v-col>

          <v-col cols="4" md="2">
            <v-select v-model="comparePeriod" :items="comparePeriodList" :label="$t('Compare with history')" :disabled="loading || loadingHistory" @change="refreshHistory" />
          </v-col>

          <v-col class="shrink">
            <cs-refresh-button :loading="loading || loadingHistory" @click="refresh" />
          </v-col>
        </v-row>

        <v-row v-if="loading" no-gutters>
          <v-col cols="12" class="ma-4 text-center">
            <cs-icon-loading show-text small left />
          </v-col>
        </v-row>

        <div v-else class="background-blurred">
          <v-row>
            <v-col v-show="comparePeriod" cols="12" :lg="layout === LAYOUT_DOWNSELL_DISTRIBUTION || layout === LAYOUT_TOP_CUSTOMERS ? 6 : 12">
              <v-sheet class="background-blurred pa-2">
                <v-row no-gutters>
                  <v-col>
                    <span class="text-h5 mb-4">
                      {{ $t('History') }}
                    </span>
                    <cs-icon-loading v-if="loadingHistory" small />
                  </v-col>

                  <!-- FUTURE: allow per_client, one customer at a time.
              <v-col cols="6" md="3" lg="1">
              <v-select v-model="historyField" :label="$t('History per')" :items="historyPerList" :disabled="loading" @change="createGraphHistory" />
            </v-col> -->

                  <v-col cols="12">
                    <cs-chart-bar :chart-data="historyChartData" :options="barChartOptions" ref="opportunity-compare-bar-chart" class="chart-max-height" :styles="chartStyles" />
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>

            <v-col cols="12" v-show="layout !== LAYOUT_TOP_STOPPED_PRODUCTS" :lg="layout === LAYOUT_DOWNSELL_DISTRIBUTION || layout === LAYOUT_TOP_CUSTOMERS ? '6' : 6">
              <v-scale-transition>
                <v-sheet v-show="layout === LAYOUT_DEFAULT || layout === LAYOUT_DOWNSELL_DISTRIBUTION" class="background-blurred pa-2">
                  <div class="text-h5 mb-4 d-flex align-center">
                    {{ $t('Distribution of downsell') }}

                    <v-chip label small class="mx-2">
                      {{ $tc('{count} opportunities', downsellByTypeTotal.nb_opportunities, { count: downsellByTypeTotal.nb_opportunities }) }}
                    </v-chip>

                    {{ $stratus.services.format.asCurrency(downsellByTypeTotal.price) }}
                  </div>

                  <cs-chart-donut v-show="downsellByTypeTotal.price !== 0" class="chart-max-height" :styles="chartStyles" :chart-data="downsellByTypeChartData" :options="optionsDownsellByType" />

                  <v-row v-show="downsellByTypeTotal.price === 0" dense justify="center">
                    <v-col class="shrink text-no-wrap">
                      <cs-alert-panel dense type="info" :text="$t('No data...')" />
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-scale-transition>

              <v-scale-transition>
                <v-sheet v-show="layout === LAYOUT_DEFAULT || layout === LAYOUT_TOP_CUSTOMERS" class="background-blurred mt-4 pa-2">
                  <div class="text-h5 mb-4">
                    {{ $t('Top {count} downsell by customers', { count: TOP_CUSTOMER }) }}
                  </div>

                  <v-row v-if="downsellByCustomer.length === 0" dense justify="center">
                    <v-col class="shrink text-no-wrap">
                      <cs-alert-panel dense type="info" :text="$t('No data...')" />
                    </v-col>
                  </v-row>

                  <div v-else v-for="(downsell, index) in downsellByCustomer" :key="index" class="d-flex align-center pa-3">
                    <div class="shrink mr-2">
                      <v-avatar :style="`background-color: ${COLORS_BY_CUSTOMER[downsell.colorIndex].html}; color: ${COLORS_BY_CUSTOMER[downsell.colorIndex].text}`" size="32">
                        {{ index + 1 }}
                      </v-avatar>
                    </div>

                    <sca-company-identity :value="downsell.company" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(downsell.company)" />

                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" rounded small class="ml-4" @click="computeQuery(LAYOUT_TOP_CUSTOMERS, downsell.query, downsell.company + ' — ' + customerName(downsell.company))">
                          {{ $tc('{count} opportunities', downsell.nb_opportunities, { count: downsell.nb_opportunities }) }}
                        </v-btn>
                      </template>
                      <span>{{ $t('Show opportunities...') }}</span>
                    </v-tooltip>

                    <div class="ml-auto">
                      {{ $stratus.services.format.asCurrency(downsell.price) }}
                    </div>
                  </div>
                </v-sheet>
              </v-scale-transition>
            </v-col>

            <v-col v-show="layout === LAYOUT_DEFAULT || layout === LAYOUT_TOP_STOPPED_PRODUCTS">
              <v-scale-transition>
                <v-sheet class="background-blurred pa-2">
                  <div class="text-h5 mb-4 d-flex align-center">
                    {{ $t('Top {count} stopped products by downsell category', { count: TOP_STOPPED_PRODUCTS }) }}

                    <div class="ml-auto shrink">
                      <v-menu offset-y :close-on-content-click="false" transition="slide-y-transition">
                        <template v-slot:activator="{ on: onMenu }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: onTooltip }">
                              <v-btn small icon rounded v-on="{ ...onMenu, ...onTooltip }">
                                <v-icon small color="menu-icon">
                                  $vuetify.icons.options
                                </v-icon>
                              </v-btn>
                            </template>
                            {{ $t('Options') }}
                          </v-tooltip>
                        </template>

                        <v-card tile class="pa-2">
                          <v-switch v-model="hidePrices" :label="$t('Hide prices')" dense hide-details class="mt-0" />
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <v-row v-if="downsellByTypeTotal.stopped === 0" dense justify="center">
                    <v-col class="shrink text-no-wrap">
                      <cs-alert-panel type="info" dense :text="$t('No product has been stopped.')" />
                    </v-col>
                  </v-row>

                  <div v-else v-for="reason in reasonList" :key="reason.value">
                    <cs-expand-panel v-if="downsellByType[reason.value]?.stopped?.length" block expanded>
                      <v-row slot="header" align="center" dense>
                        <v-col>
                          <span class="font-weight-bold">
                            {{ $t(`downsell-reason-${reason.value}`) }}
                          </span>

                          ({{ $t('{percent}%', { percent: downsellByType[reason.value].total.percentage }) }})
                        </v-col>

                        <v-col v-if="!hidePrices" class="shrink">
                          {{ $stratus.services.format.asCurrency(downsellByType[reason.value].total.price) }}
                        </v-col>
                      </v-row>

                      <div slot="content">
                        <v-row v-for="(product, index) in downsellByType[reason.value].stopped" :key="index" align="center" dense>
                          <v-col>
                            <sca-product-identity :value="product.ref" :label="productName(product.ref)" link="emit" @link-click="openProduct(product.ref)" />

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn v-on="on" small rounded class="ml-4" @click="computeQuery(LAYOUT_TOP_STOPPED_PRODUCTS, product.query, product.ref + ' — ' + productName(product.ref))">
                                  {{ $tc('{count} opportunities', product.nb_opportunities, { count: product.nb_opportunities }) }}
                                </v-btn>
                              </template>
                              <span>{{ $t('Show opportunities...') }}</span>
                            </v-tooltip>
                          </v-col>

                          <v-col v-if="!hidePrices" class="shrink">
                            {{ $stratus.services.format.asCurrency(product.price) }}
                          </v-col>
                        </v-row>
                      </div>
                    </cs-expand-panel>
                  </div>
                </v-sheet>
              </v-scale-transition>
            </v-col>
          </v-row>

          <v-scroll-y-reverse-transition>
            <v-sheet v-show="layout !== LAYOUT_DEFAULT" class="background-blurred mt-4 pa-2">
              <v-row dense justify="center">
                <v-col>
                  <span v-show="label.length" class="text-h5">
                    {{ label }}
                    <v-chip small label>
                      {{ $tc('{count} opportunities', queryResultCount, { count: queryResultCount }) }}
                    </v-chip>
                  </span>
                </v-col>

                <v-col class="shrink text-no-wrap">
                  <v-btn rounded @click="computeQuery(LAYOUT_DEFAULT)">
                    {{ $t('Close') }}
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <csm-opportunity-grid :pause="pauseGridRequests" :query="query" :options="opportunityGridOptions" ref="opportunity-csm-dashboard-grid" @dataLoaded="dataLoaded" />
                </v-col>
              </v-row>
            </v-sheet>
          </v-scroll-y-reverse-transition>
        </div>
      </v-tab-item>

      <v-tab-item value="tab-company-indicators">
        <csm-company-grid :options="companyGridOptions" show-last-contact-filter />
      </v-tab-item>
    </v-tabs-items>

    <csm-company-dialog ref="company-dialog" />
    <csm-product-dialog ref="product-dialog" readonly />
  </div>
</template>

<script>
import _ from 'lodash'

const TOP_CUSTOMER = 5
const TOP_STOPPED_PRODUCTS = 3

const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [{
    backgroundColor: [],
    _backgroundColor: [],
    data: [],
    nbOpportunities: [],
    query: []
  }]
}

const API_DATA_CATEGORY_PER_CUSTOMER = 'per_client'
const API_DATA_CATEGORY_PER_TYPE = 'per_type'

// Panels displayed: top customers, top stopped products, downsell distribution chart
// Panel hidden: opportunity grid
const LAYOUT_DEFAULT = 0
// Panels displayed: Top customers, opportunity grid
// Panel hidden:  Top stopped products, downsell distribution chart
const LAYOUT_DOWNSELL_DISTRIBUTION = 1
// Panels displayed: Top stopped products, opportunity grid
// Panel hidden:  Top customers, downsell distribution chart
const LAYOUT_TOP_STOPPED_PRODUCTS = 2
// Panels displayed: Top customers, opportunity grid
// Panel hidden:  Top stopped products, downsell distribution chart
const LAYOUT_TOP_CUSTOMERS = 3

export default {
  name: 'OpportunitiesCSMDashboard',
  components: {
    'csm-company-grid': () => import(/* webpackChunkName: "components" */ '@/components/Companies/CompanyGrid'),
    'csm-opportunity-grid': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/OpportunitiesGrid')
  },
  data () {
    return {
      COLORS_BY_CUSTOMER: this.$alto.defines.COLORS.CHART_COLOR_PALETTE_REVERSE,
      LAYOUT_DEFAULT,
      LAYOUT_DOWNSELL_DISTRIBUTION,
      LAYOUT_TOP_CUSTOMERS,
      LAYOUT_TOP_STOPPED_PRODUCTS,
      PRICE_DIGITS: 2,
      RANGE_CURRENT_MONTH: this.$alto.defines.DATES.RANGE_CURRENT_MONTH,
      RANGE_CURRENT_QUARTER: this.$alto.defines.DATES.RANGE_CURRENT_QUARTER,
      RANGE_CURRENT_YEAR: this.$alto.defines.DATES.RANGE_CURRENT_YEAR,
      RANGE_LAST_MONTH: this.$alto.defines.DATES.RANGE_LAST_MONTH,
      TOP_CUSTOMER,
      TOP_STOPPED_PRODUCTS,
      beginAt: '',
      companiesSearch: '',
      companyGridOptions: {
        allowAdvancedSearch: false,
        create: false
      },
      comparePeriod: null,
      downsellByCustomer: [],
      downsellByTypeChartData: { ...DEFAULT_CHART_DATA },
      downsellByType: [],
      downsellByTypeTotal: {
        nb_opportunities: 0,
        percentage: 100,
        price: 0
      },
      downsellReasonColors: {}, // Colors indexed by id of downsell reason
      endAt: '',
      hidePrices: false,
      historyChartData: { ...DEFAULT_CHART_DATA },
      historyData: null,
      historyField: API_DATA_CATEGORY_PER_TYPE,
      historyPerList: [],
      label: '',
      layout: LAYOUT_DEFAULT,
      loading: true,
      loadingHistory: false,
      opportunityGridOptions: {
        // disable advanced search to avoid collision with forged query from computeQuery
        allowAdvancedSearch: false
      },
      pauseGridRequests: true,
      query: '',
      queryResultCount: 0,
      refreshDebounced: this.refresh,
      tab: 'tab-downsale-analysis'
    }
  },
  computed: {
    barChartOptions () {
      return {
        // Click on a bar to load it's opportunities
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plugged to.
           */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Create a focus effect by changing the background color of the bar
            // Reset all colors
            _.forEach(this.data.datasets, dataset => {
              dataset.backgroundColor = dataset._backgroundColor
            })
            // Set the focus
            const bc = new Array(this.data.datasets[element[0]._datasetIndex].data.length).fill(this.data.datasets[element[0]._datasetIndex]._backgroundColor) // Restore native color
            bc[element[0]._index] = this.data.datasets[element[0]._datasetIndex].borderColor
            this.data.datasets[element[0]._datasetIndex].backgroundColor = bc
            // Forge a query to load opportunities for this part
            this.data.datasets[element[0]._datasetIndex].computeQuery(
              LAYOUT_DOWNSELL_DISTRIBUTION,
              this.data.datasets[element[0]._datasetIndex].query[element[0]._index],
              this.data.caption + ' > ' + this.data.datasets[element[0]._datasetIndex].label + ' > ' + this.data.labels[element[0]._index]
            )
            this.update()
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{ stacked: true }],
          yAxes: [{
            id: 'price-axis',
            type: 'linear',
            stacked: true,
            ticks: {
              callback: (tick) => {
                return this.$stratus.services.format.asCurrency(tick)
              }
            }
          }]
        },
        tooltips: {
          enabled: true,
          intersect: true,
          callbacks: {
            bodyFontSize: 16,
            label: (tooltipItems, data) => {
              return [
                this.$t('{label}: {value}{unit}.', {
                  label: `${data.datasets[tooltipItems.datasetIndex].label} (${this.$tc('{count} opportunities', data.datasets[tooltipItems.datasetIndex].nbOpportunities[tooltipItems.index], { count: data.datasets[tooltipItems.datasetIndex].nbOpportunities[tooltipItems.index] })})`,
                  value: this.$stratus.services.format.asCurrency(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])
                })
              ]
            }
          }
        }
      }
    },
    chartStyles () {
      return {
        height: '300px',
        width: '100%',
        position: 'relative'
      }
    },
    optionsDownsellByType () {
      const options = {
        // Click on a bar to load it's opportunities
        onClick (e) {
          /* --- IMPORTANT! ---
          * This is a native click event, outside Vue scope!
          * Value of «this» is not vue but the chart instance this event is plugged to.
          */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Create a focus effect by changing the background color of the bar
            // Reset all colors
            _.forEach(this.data.datasets, dataset => {
              dataset.backgroundColor = dataset._backgroundColor
            })
            // Set the focus
            const bc = new Array(this.data.datasets[element[0]._datasetIndex].data.length).fill(this.data.datasets[element[0]._datasetIndex]._backgroundColor) // Restore native color
            bc[element[0]._index] = this.data.datasets[element[0]._datasetIndex].borderColor
            this.data.datasets[element[0]._datasetIndex].backgroundColor = bc
            // Forge a query to load opportunities for this part
            this.data.datasets[element[0]._datasetIndex].computeQuery(
              LAYOUT_DOWNSELL_DISTRIBUTION,
              this.data.datasets[element[0]._datasetIndex].query[element[0]._index],
              this.data.caption + ' > ' + this.data.labels[element[0]._index]
            )
            this.update()
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true,
          animateScale: true
        },
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 20,
            bottom: 20
          }
        },
        legend: {
          display: true,
          position: 'right',
          labels: {
            fontColor: this.isDark() ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.5)'
          }
        },
        circumference: 2 * Math.PI,
        rotation: -Math.PI / 2,
        tooltips: {
          enabled: true,
          intersect: false,
          callbacks: {
            label: (tooltipItems, data) => {
              return ' ' + this.$t('{label}= {value}', {
                label: `${data.labels[tooltipItems.index]} (${this.$tc('{count} opportunities', data.datasets[tooltipItems.datasetIndex].nbOpportunities[tooltipItems.index], { count: data.datasets[tooltipItems.datasetIndex].nbOpportunities[tooltipItems.index] })})`,
                value: this.$stratus.services.format.asCurrency(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index])
              })
            }
          }
        }
      }

      return options
    },
    comparePeriodList () {
      return [{ value: '', text: '' }].concat(this.$store.getters['$alto-opportunities/comparePeriodsList'])
    },
    reasonList () { return this.$store.getters['$alto-opportunities/downsellReasonsList'] }
  },
  methods: {
    addSector (chart, name, value, color, query, nbOpportunities) {
      const currentDataset = chart.datasets[0]
      chart.caption = this.$t('Distribution of downsell')
      chart.labels.push(name)
      currentDataset.data.push(value)
      currentDataset.query.push(query)
      currentDataset.nbOpportunities.push(nbOpportunities)
      currentDataset.backgroundColor.push(color.opacity80)
      currentDataset._backgroundColor.push(color.opacity80)
      currentDataset.borderColor = this.$alto.defines.COLORS.PRIMARY
      currentDataset.borderWidth = 0
      currentDataset.computeQuery = this.computeQuery
    },
    computeQuery (newLayout, query, label) {
      this.layout = newLayout
      if (newLayout === LAYOUT_DEFAULT) {
        // Opportunity grid ih hidden so we can pause its XHR.
        this.pauseGridRequests = true
        return
      }

      this.pauseGridRequests = false
      this.query = query || ''
      this.label = label || ''
    },
    createGraph () {
      this.resetChart()

      if (this.downsellByTypeTotal.nb_opportunities > 0) {
        _.forEach(this.reasonList, reason => {
          const downsell = this.downsellByType[reason.value]
          if (downsell?.total?.percentage) {
            this.addSector(this.downsellByTypeChartData, this.$t(`downsell-reason-${downsell.id}`) + ` : ${downsell.total.percentage} %`, downsell.total.price, this.downsellReasonColors[reason.value], downsell.query, downsell.total.nb_opportunities)
          }
        })
      }
    },
    createGraphHistory () {
      this.resetChartHistory()

      if (this.historyData?.datasets) {
        const dataSets = []
        let labels

        if (this.historyField === API_DATA_CATEGORY_PER_CUSTOMER) {
          let colorIndex = 0
          _.forEach(this.historyData.datasets?.per_client, (data, key) => {
            if (!labels) labels = [...data.begin]
            dataSets.push({
              label: `${key} — ${this.customerName(key)}`,
              data: data.price || [],
              query: data.query,
              backgroundColor: this.$alto.defines.COLORS.PALETTE_FULL[colorIndex % this.$alto.defines.COLORS.PALETTE_FULL.length].opacity80,
              _backgroundColor: this.$alto.defines.COLORS.PALETTE_FULL[colorIndex % this.$alto.defines.COLORS.PALETTE_FULL.length].opacity80,
              borderColor: this.$alto.defines.COLORS.PRIMARY,
              hoverBorderColor: this.$alto.defines.COLORS.PRIMARY,
              borderWidth: 0,
              computeQuery: this.computeQuery,
              nbOpportunities: data.nb_opportunities
            })
            colorIndex++
          })
        } else if (this.historyField === API_DATA_CATEGORY_PER_TYPE) {
          _.forEach(this.historyData.datasets?.per_type, (data, key) => {
            if (this.historyData.total?.per_type[key]?.total?.nb_opportunities) {
              if (!labels) labels = [...data.begin]
              dataSets.push({
                label: this.$t(`downsell-reason-${key}`),
                data: data.price || [],
                query: data.query,
                backgroundColor: this.downsellReasonColors[key].opacity80,
                _backgroundColor: this.downsellReasonColors[key].opacity80,
                borderColor: this.$alto.defines.COLORS.PRIMARY,
                hoverBorderColor: this.$alto.defines.COLORS.PRIMARY,
                borderWidth: 0,
                computeQuery: this.computeQuery,
                nbOpportunities: data.nb_opportunities
              })
            }
          })
        }

        this.historyChartData.labels = labels
        this.historyChartData.datasets = this.$stratus.services.sort.natural(dataSets, { label: 'asc' })
        this.historyChartData.caption = this.$t('History')
        if (this.$refs['opportunity-compare-bar-chart']) this.$refs['opportunity-compare-bar-chart'].update()
      }
    },
    customerName (id) { return this.$store.getters['$alto-companies/getName'](id) },
    dataLoaded ({ count }) {
      this.queryResultCount = count || 0
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    productName (ref) {
      return this.$store.getters['$alto-catalog/get'](ref)?.name || this.$t('Unknown')
    },
    async refresh () {
      try {
        this.loading = true
        const dashboard = await this.$store.dispatch('$alto-opportunities/getCsmDashboard', { dateBegin: this.beginAt, dateEnd: this.endAt, companiesId: this.companiesSearch })

        // Top 5 of customer downsell
        let topDownsell = _.sortBy(dashboard?.[API_DATA_CATEGORY_PER_CUSTOMER] || [], ['price', 'company'])
        topDownsell = _.map(topDownsell, (downsell, index) => {
          // Add color
          return { ...downsell, colorIndex: index % this.COLORS_BY_CUSTOMER.length }
        })
        this.downsellByCustomer = topDownsell.slice(0, TOP_CUSTOMER)

        // By type of downsell
        this.downsellByTypeTotal = {
          nb_opportunities: 0,
          percentage: 100,
          price: 0,
          stopped: 0
        }

        const downsellByType = { ...dashboard?.[API_DATA_CATEGORY_PER_TYPE] || {} }
        _.forEach(this.reasonList, (reason, index) => {
          const downsell = downsellByType[reason.value]
          if (downsell) {
            this.downsellByTypeTotal.nb_opportunities += downsell.total.nb_opportunities
            this.downsellByTypeTotal.price += downsell.total.price
            downsell.id = reason.value
            downsell.stopped = _.chain(downsell.stopped).sortBy('price').slice(0, TOP_STOPPED_PRODUCTS).value()
            this.downsellByTypeTotal.stopped += downsell.stopped?.length || 0
          }
        })
        this.downsellByType = downsellByType

        this.createGraph()
        this.computeQuery(LAYOUT_DEFAULT)
        this.$nextTick(() => { this.refreshHistory() })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loading = false }, 500)
      }
    },
    refreshGrid () {
      if (this.$refs['opportunity-csm-dashboard-grid']) this.$refs['opportunity-csm-dashboard-grid'].refreshGrid()
    },
    async refreshHistory () {
      if (!this.comparePeriod) return
      try {
        this.loadingHistory = true
        this.historyData = await this.$store.dispatch('$alto-opportunities/getCsmDashboard', { dateBegin: this.beginAt, dateEnd: this.endAt, compare: this.comparePeriod, companiesId: this.companiesSearch })
        this.createGraphHistory()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loadingHistory = false }, 500)
      }
    },
    resetChart () {
      this.$set(this.downsellByTypeChartData, 'labels', [])
      this.$set(this.downsellByTypeChartData, 'datasets', [{
        backgroundColor: [],
        _backgroundColor: [],
        data: [],
        nbOpportunities: [],
        query: []
      }])
    },
    resetChartHistory () {
      this.$set(this.historyChartData, 'labels', [])
      this.$set(this.historyChartData, 'datasets', [{
        backgroundColor: [],
        _backgroundColor: [],
        data: [],
        query: []
      }])
    },
    setDateRange (rangeType) {
      switch (rangeType) {
        case this.RANGE_CURRENT_MONTH:
          this.beginAt = this.$stratus.dt().startOf('month').format('YYYY-MM')
          this.endAt = this.$stratus.dt().format('YYYY-MM')
          break
        case this.RANGE_CURRENT_QUARTER:
          this.beginAt = this.$stratus.dt().startOf('quarter').format('YYYY-MM')
          this.endAt = this.$stratus.dt().format('YYYY-MM')
          break
        case this.RANGE_CURRENT_YEAR:
          this.beginAt = this.$stratus.dt().startOf('year').format('YYYY-MM')
          this.endAt = this.$stratus.dt().format('YYYY-MM')
          break
        case this.RANGE_LAST_MONTH:
          this.beginAt = this.$stratus.dt().subtract(1, 'month').startOf('month').format('YYYY-MM')
          this.endAt = this.$stratus.dt().subtract(1, 'month').endOf('month').format('YYYY-MM')
          break
      }
      this.refreshDebounced()
    }
  },
  async created () {
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    this.refreshDebounced = _.debounce(this.refresh, 500)
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('CSM Dashboard'))
  },
  async mounted () {
    await this.$store.dispatch('$alto-users/get', true)
    await this.$store.dispatch('$alto-opportunities/loadDownsellReasons')
    await this.$store.dispatch('$alto-opportunities/loadComparePeriods')
    // value contains the name of the field in the API.
    this.historyPerList = [
      { value: API_DATA_CATEGORY_PER_CUSTOMER, text: this.$t('Customer') },
      { value: API_DATA_CATEGORY_PER_TYPE, text: this.$t('Type') }
    ]
    // Choose colors for each downsell type
    _.forEach(this.reasonList, (reason, index) => {
      this.downsellReasonColors[reason.value] = this.$alto.defines.COLORS.PALETTE_BLUE[index % this.$alto.defines.COLORS.PALETTE_BLUE.length]
    })
    this.refresh()
  }
}
</script>
