<template>
  <sca-modal-dialog :visible="visible" @closeDialog="closeDialog" @saveDialog="saveDialog" :can-save="canSave" :is-saving="isSaving" :action="action" :external-id="externalId" :max-width="$vuetify.breakpoint.lgAndUp ? '80%' : ''">
    <template #title>
      <div class="d-flex align-center">
        <div v-if="order.id">
          {{ $t('#') }} {{ order.id }}
        </div>
        <div v-if="order.name" class="text-h6 mx-2">
          — {{ order.name }}
        </div>
        <cs-icon-loading v-if="loading" />
      </div>
    </template>

    <div slot="subtitle" class="d-flex align-center highlighted-panel">
      {{ $t('Estimated delivery time') }}
      <v-icon small left right>
        $vuetify.icons.for
      </v-icon>
      <div v-if="$stratus.dt(order.estimated_date).isValid()" label small class="font-weight-bold">
        {{ $stratus.dt(order.estimated_date).format('ll') }}
      </div>
      <span v-else>
        {{ $t('None') }}
      </span>
    </div>

    <div slot="content">
      <v-row class="mb-2">
        <v-col cols="12" md="6">
          <div class="d-flex align-center mb-4">
            <sca-company-identity :value="order.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(order.code)" />
          </div>

          <div class="mb-4 d-flex align-center">
            <sca-order-state :value="orderState" class="mr-4" />
            <sca-order-progress v-if="order.state !== 'validating'" v-model="order.accomplishment" :re-pending="isRePending" :show-label="false" label="" />
          </div>

          <v-col class="text-no-wrap d-flex align-center" />

          <v-row v-if="order.id_lord_pre_sale" align="center">
            <v-col class="shrink text-no-wrap">
              {{ $t('Pre-sale') }}
            </v-col>
            <v-col>
              <sca-user-identity :value="order.id_lord_pre_sale" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(order.id_lord_pre_sale)" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" v-if="depositValidated">
              {{ $t('Deposit validate by {mail} on {date}.', { mail: order.deposit_validate_by, date: $stratus.dt(order.deposit_validate_at).format('LL') }) }}
            </v-col>

            <v-col v-if="productNeedTechForm" cols="12">
              <cs-alert-panel type="success" :text="$t('This order needs some technical informations. Click on «Send technical information...» to provide them.')" />
            </v-col>

            <v-col v-if="isLord" cols="12" md="4" class="d-flex align-start">
              <v-icon>
                $vuetify.icons.ticket
              </v-icon>

              <cs-expand-button v-model="showAllTickets" horizontal />

              <div class="d-flex align-start">
                <div v-if="order.ids_intern?.tickets" class="d-flex align-start">
                  <div v-for="(tickets, index) in order.ids_intern?.tickets" :key="index">
                    <sca-ticket-identity v-for="ticket in tickets" :key="`${index}-${ticket}`" :value="ticket" :exclude-states="showAllTickets ? [] : [TICKET_STATE_CANCELED, TICKET_STATE_CLOSED]" show-card no-icon link="emit" @link-click="openTicket" />
                  </div>
                </div>

                <div v-if="order.ids && showAllTickets">
                  <csm-order-tickets :tickets="order.ids" hide-label no-icon no-expand />
                </div>
              </div>
            </v-col>
          </v-row>

          <p v-if="isLord && order.opportunity_id">
            <v-row dense>
              <v-col cols="12">
                <sca-opportunity-identity :value="order.opportunity_id" :label="order.opportunity_id" show-avatar link="emit" @link-click="openOpportunity(order.opportunity_id)" />
              </v-col>
            </v-row>
          </p>
        </v-col>

        <v-col v-if="order.products && canReadPrices">
          <sca-product-prices-grid highlight :prices="order" :title="$t('Total')" :sub-title="$tc('No product|One product|{count} products', order.products.length, { count: order.products.length })" outlined />
        </v-col>
      </v-row>

      <csm-opportunity-dialog ref="opportunity-dialog" />
      <csm-order-pending ref="order-pending" :order="order" :order-id="orderId" @refresh="onRefresh" />
      <csm-company-dialog ref="company-dialog" />
      <csm-ticket-dialog ref="ticket-dialog" />
      <csm-user-dialog ref="user-dialog" />
    </div>

    <div slot="buttons-header">
      <v-btn v-if="isAdmin && isLord && orderId && productNeedTechForm" rounded text color="warning" :loading="isSaving" @click="submitWithoutResponses">
        {{ $t('Ignore questionnaires...') }}
      </v-btn>
      <cs-confirm-dialog ref="confirm-tech-form" />
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  components: {
    'csm-order-pending': () => import(/* webpackChunkName: "components" */ '@/components/Orders/OrderPending'),
    'csm-order-tickets': () => import(/* webpackChunkName: "components" */ '@/components/Orders/OrderTickets')
  },
  name: 'OrderForm',
  props: {
    order: { type: Object, required: true },
    orderId: { type: [Number, String], default: null },
    update: { type: Boolean, default: false }
  },
  data () {
    return {
      TICKET_STATE_CANCELED: this.$alto.defines.TICKETS.TICKET_STATE_CANCELED,
      TICKET_STATE_CLOSED: this.$alto.defines.TICKETS.TICKET_STATE_CLOSED,
      isRePending: false,
      isSaving: false,
      loading: false,
      showAllTickets: false,
      visible: false
    }
  },
  watch: {
    order: {
      deep: true,
      immediate: true,
      handler (newValue) {
        if (newValue && newValue.products && newValue.state === 'completed') {
          let rePending = false
          let i = 0
          while (!rePending && i < newValue.products.length) {
            if (newValue.products[i].state === 're-pending') {
              rePending = true
            }
            i++
          }
          this.isRePending = rePending
        }
      }
    }
  },
  computed: {
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canReadPrices () {
      return !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST_NO_PRICE)
    },
    canSave () {
      return false
    },
    depositValidated () {
      return (this.order.deposit_validate_at && this.order.deposit_validate_by !== '')
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    me () { return this.$store.getters['$stratus-states/me'] },
    orderState () {
      return this.isRePending ? 're-pending' : this.order.state || ''
    },
    quantity () {
      let quantity = 0
      _.forEach(this.order.products, product => {
        quantity += product.quantity
      })
      return quantity
    },
    /**
     * Return true if a product in this order needs to fill in a tech form.
     */
    productNeedTechForm () {
      if (!this.order.products || !this.order.products.length) return false
      let i = 0
      let needTechForm = false
      while (i < this.order.products.length && !needTechForm) {
        needTechForm = this.order.products[i].state === 'tech-awaiting' && this.order.products[i].tech_forms && this.order.products[i].tech_forms.length > 0
        i++
      }
      return needTechForm
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog')
    },
    computeDiscount (total, discount) {
      return parseFloat(total) - (parseFloat(total) * (parseFloat(discount) * 0.01))
    },
    externalId () {
      if (!this.orderId) return
      return [this.orderId, this.orderId ? 'orders/' + this.orderId : '', this.order.name]
    },
    isDark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    onRefresh (order) {
      this.$emit('refreshOrder', order)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reset (company) {
      // Nothing to do yet
    },
    saveDialog () {
      this.isSaving = true
    },
    submitWithoutResponses () {
      if (!this.orderId) return
      this.$refs['confirm-tech-form'].open(this.$t('Ignore questionnaires...'), this.$t('Confirm that you override the questions?'), { color: 'warning' })
        .then(async confirmed => {
          if (confirmed) {
            try {
              await this.$store.dispatch('$alto-orders/skipProductTechForm', this.orderId)
            } catch (error) {
              this.$stratus.services.notify.error(error)
            } finally {
              this.$emit('refreshOrder', this.order)
            }
          }
        })
    },
    async display (show = false) {
      this.visible = show
      if (show) {
        // Load some data in cache
        try {
          await this.$store.dispatch('$alto-opportunities/loadStates')
          await this.$store.dispatch('$alto-catalog/loadAccountingFamilies')
          await this.$store.dispatch('$alto-companies/loadFirstContacts')
          if (this.canReadCatalog && !this.$store.getters['$alto-catalog/list'].length) await this.$store.dispatch('$alto-catalog/list')
          if (this.$refs['order-pending']) this.$refs['order-pending'].countProductsNeedCaption()
        } catch (error) {
          console.error(error)
          this.$stratus.services.notify.error(error)
        }
      }
    }
  }
}
</script>
