<template>
  <span>
    <sca-modal-dialog :visible="visible" @closeDialog="closeDialog" @saveDialog="saveDialog" :can-save="canSave" :is-saving="isSaving" :action="action" :max-width="$vuetify.breakpoint.lgAndUp ? '80%' : ''" :external-id="externalId">
      <span slot="title">
        <span v-if="currentOpportunity.id">{{ $t('Opportunity #{id}', { id: currentOpportunity.id }) }}</span> — {{ title }}
      </span>

      <template slot="buttons-header">
        <cs-action-button v-if="currentOpportunity.link_id && currentOpportunity.type_of_link === OPPORTUNITY_LINK_CART" icon="$vuetify.icons.quotation" help="Show quotation..." @click="showQuotation" />

        <cs-action-button v-if="currentOpportunity.link_id && currentOpportunity.type_of_link === OPPORTUNITY_LINK_ORDER_LINE" icon="$vuetify.icons.order" help="Show order..." @click="showOrder" />

        <cs-action-button v-if="this.update && currentOpportunity.state === OPPORTUNITY_STATE_QUALIFICATION && currentOpportunity.company" icon="$vuetify.icons.quotation" help="New cart" @click="createCart" />

        <cs-action-menu v-if="this.update && currentOpportunity.state === OPPORTUNITY_STATE_QUALIFICATION" icon="$vuetify.icons.setup" help="Update">
          <v-list slot="menu" dense>
            <v-list-item :loading="isSaving" @click="opportunityWon">
              <v-icon left>
                $vuetify.icons.success
              </v-icon>
              {{ $t('Opportunity is won') }}
            </v-list-item>

            <v-list-item :loading="isSaving" @click="opportunityAbandon">
              <v-icon left>
                $vuetify.icons.cancel
              </v-icon>
              {{ $t('Abandon opportunity') }}
            </v-list-item>
          </v-list>
        </cs-action-menu>

        <cs-action-button v-if="allowClone && !create && canClone" icon="$vuetify.icons.clone" help="Clone" @click="clone" />
      </template>

      <template #append-header>
        <v-row>
          <v-col cols="12" md="6">
            <v-row align="start">
              <v-col cols="12" md="5">
                <div class="text-h6">
                  <sca-customer-select v-model="currentOpportunity.company" :label="$t('Company')" :rules="[$stratus.services.form.rules.required]" class="required" :disabled="!canEdit('company') || currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" single-line dense show-email show-phone link="emit" @link-click="openCompany(currentOpportunity.company)" />
                </div>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field v-model="currentOpportunity.name" :label="$t('Name')" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.max(127)]" dense class="required" :disabled="!canEdit('name') || currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" />
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-select v-if="create" v-model="currentOpportunity.state" :items="CREATION_STATE_LIST">
                  <template v-slot:item="{ item }">
                    {{ $t(`opp-state-${item}`) }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ $t(`opp-state-${item}`) }}
                  </template>
                </v-select>
                <div v-else class="d-flex">
                  <v-icon v-if="currentOpportunity.state === OPPORTUNITY_STATE_WON" color="success" small left>
                    $vuetify.icons.success
                  </v-icon>
                  <v-icon v-else-if="currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" color="menu-icon" small left>
                    $vuetify.icons.cart
                  </v-icon>
                  <v-text-field :value="$t(`opp-state-${currentOpportunity.state}`)" disabled dense hide-details :label="$t('State')" />
                </div>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12" md="6">
                <sca-sales-person-select v-model="currentOpportunity.id_lord_salesperson" :label="$t('Sales Person')" :disabled="!canEdit('id_lord_salesperson')" dense hide-details clearable show-email show-phone show-company show-card link="emit" @link-click="openUser(currentOpportunity.id_lord_salesperson)" />
              </v-col>

              <v-col v-if="isLord" cols="12" md="6" class="d-flex align-center">
                <sca-users-select v-model="currentOpportunity.owner" filter-lord show-email item-value="email" :label="$t('Owner')" :disabled="!canEdit('owner')" dense clearable hide-details link="emit" @link-click="openUser(currentOpportunity.owner)" />
                <sca-button-assign-to-me small rounded :disabled="!canSave" :help="$t('Assign to me')" @click="currentOpportunity.owner = me.email" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6" md="4">
                <cs-percent-input v-model="currentOpportunity.probability" :label="$t('Percentage of success')" :rules="[$stratus.services.form.rules.integermin(0), $stratus.services.form.rules.integermax(100)]" :min="0" :max="100" :disabled="!canEdit('probability')" />
              </v-col>

              <v-col cols="6" md="4">
                <cs-date-picker v-model="currentOpportunity.closing_date" :label="$t('Potential closing date')" allow-empty :rules="[$stratus.services.form.rules.required]" class="required" :disabled="!canEdit('closing_date')" tab="tab-info" />
              </v-col>

              <v-col class="d-flex align-center">
                <sca-subscription-identity v-if="currentOpportunity.link_id && currentOpportunity.type_of_link === OPPORTUNITY_LINK_ORDER_LINE" :value="currentOpportunity.link_id" :label="$t('Subscription #{id}', { id: currentOpportunity.link_id })" link="emit" @link-click="showSubscription(currentOpportunity.link_id)" />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <cs-header :title="$t('Rates')" dense>
              <template #content>
                <sca-opportunity-rates v-if="!canSave || currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" :value="currentOpportunity" />
                <div v-else>
                  <v-row class="pa-4">
                    <v-col>
                      <v-row no-gutters align="end" justify="space-between">
                        <v-col />
                        <v-col cols="4" class="text-caption">
                          {{ $t('Price') }}
                        </v-col>
                        <v-col cols="4" class="text-caption">
                          {{ $t('Buying price') }}
                        </v-col>
                        <v-col class="shrink text-no-wrap text-caption text-right">
                          {{ $t('Sales margin') }}
                        </v-col>
                      </v-row>

                      <v-row align="start" justify="space-between">
                        <v-col class="text-caption">
                          {{ $t('Subscription') }}
                        </v-col>
                        <v-col cols="4">
                          <cs-price-input v-model="currentOpportunity.price" :disabled="!canEditPrice('price') || currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" hide-vat />
                        </v-col>
                        <v-col cols="4">
                          <cs-price-input v-model="currentOpportunity.buying" :disabled="!canEditPrice('buying') || currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" hide-vat />
                        </v-col>
                        <v-col class="text-right">
                          {{ $t('{percent}%', { percent: currentOpportunity.margin }) }}
                        </v-col>
                      </v-row>

                      <v-row align="start" justify="space-between">
                        <v-col class="text-caption">
                          {{ $t('periodicity-long-O') }}
                        </v-col>
                        <v-col cols="4">
                          <cs-price-input v-model="currentOpportunity.price_setup" :disabled="!canEditPrice('price_setup') || currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" hide-vat />
                        </v-col>
                        <v-col cols="4">
                          <cs-price-input v-model="currentOpportunity.buying_setup" :disabled="!canEditPrice('buying_setup') || currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL" hide-vat />
                        </v-col>
                        <v-col class="text-right">
                          {{ $t('{percent}%', { percent: currentOpportunity.margin_setup }) }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </cs-header>
          </v-col>
        </v-row>
      </template>

      <v-tabs slot="tabs" color="transparent" v-model="tab" hide-slider show-arrows class="my-2">
        <cs-validation-tab href="#tab-info" icon="icon-contract" :label="$t('Information')" :error="tabErrors['tab-info']" />
        <cs-validation-tab v-if="currentOpportunity.id" href="#tab-history" icon="icon-clock" :label="$t('History')" :loading="isLoadingHistory" @change="loadHistory" />
      </v-tabs>

      <div slot="content" v-if="visible && currentOpportunity">
        <v-form ref="OpportunityForm" v-model="valid" lazy-validation>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-info" eager>
              <v-row v-if="isLord && currentOpportunity.downsell_reason" dense align="center">
                <v-col cols="12" md="3">
                  <sca-downsell-reason-select v-model="currentOpportunity.downsell_reason" :label="$t('Downsell reason')" :disabled="!canUpdateDownsellReason" clearable />
                </v-col>

                <v-col>
                  <v-text-field v-model="currentOpportunity.downsell_comment" :label="$t('Comment')" :rules="[$stratus.services.form.rules.max(255)]" counter="255" />
                </v-col>
              </v-row>

              <v-row v-else align="center">
                <v-col cols="6" md="4">
                  <v-select v-model="currentOpportunity.first_contact" :items="listFirstContacts" :label="$t('Source')" />
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field v-model="currentOpportunity.first_contact_detail" :label="$t('Source details')" :rules="[$stratus.services.form.rules.max(127)]" :counter="127" tab="tab-info" />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6" lg="4">
                  <div>{{ $t('Accounting family') }}</div>
                  <v-select v-model="currentOpportunity.accounting_family" :items="accountingFamilies" :rules="getAccountingFamilyRules" :disabled="!canModifyAccountingFamily" item-value="id" :class="getAccountingFamilyRules.length ? 'required' : ''" clearable dense />

                  <template v-if="isLord">
                    <div>{{ $t('Ticket') }}</div>
                    <sca-tickets-select v-if="isLord" v-model="currentOpportunity.id_ticket_pre_sale" :types="TICKET_TYPE_PRE_SALE" :states="[TICKET_STATE_DONE, TICKET_STATE_NO_GO]" exclude-states clearable hide-details dense show-card show-company :disabled="!canSave" />
                  </template>
                </v-col>

                <v-col cols="12" md="6" lg="8">
                  <div>{{ $t('Notes') }}</div>
                  <v-card tile outlined>
                    <p v-if="!canEdit('notes')" v-html="noteFormatted" class="pa-4" />
                    <cs-wysiwyg-textarea v-else v-model="currentOpportunity.notes" :placeholder="$t('Notes')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="NOTE_MAX_LENGTH" :min-length="0" dense />
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item v-if="currentOpportunity.id" value="tab-history" eager>
              <div v-if="!isLoadingHistory" class="mt-4">
                <v-row v-if="!history || history.length === 0" justify="center">
                  <v-col class="shrink text-no-wrap">
                    <cs-alert-panel type="info" dense :text="$t('No history')" />
                  </v-col>
                </v-row>
                <csm-opportunity-history-timeline v-else v-model="history" @open-user="openUser" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-form>

        <cs-confirm-dialog ref="confirm-opportunity-update" />
      </div>

      <sca-footer-create-update-at-by slot="footer" v-model="currentOpportunity" :link-user="isLord" />
    </sca-modal-dialog>

    <csm-order-dialog ref="order-dialog" />
    <csm-subscription-dialog ref="subscription-dialog" />
    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </span>
</template>

<script>
import _ from 'lodash'

import {
  OPPORTUNITY_ALWAYS_UPDATABLE_FIELDS,
  OPPORTUNITY_STATE_ABANDONED,
  OPPORTUNITY_STATE_PROPOSAL,
  OPPORTUNITY_STATE_QUALIFICATION,
  OPPORTUNITY_STATE_WON,
  OPPORTUNITY_STATES_READ_ONLY_CREATE,
  OPPORTUNITY_STATES_CREATION,
  OPPORTUNITY_LINK_CART,
  OPPORTUNITY_LINK_NONE,
  OPPORTUNITY_LINK_ORDER_LINE,
  OPPORTUNITY_QUALIFICATION_UPDATABLE_FIELDS,
  OPPORTUNITY_PROPOSAL_UPDATABLE_FIELDS
} from '@/services/opportunities'

export default {
  name: 'CsmOpportunityForm',
  components: {
    'csm-opportunity-history-timeline': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/OpportunityHistoryTimeline')
  },
  props: {
    allowClone: { type: Boolean, default: false },
    create: { type: Boolean, default: false },
    opportunity: { type: Object, required: true },
    update: { type: Boolean, default: false },
    show: { type: Boolean, default: true },
    visible: { type: Boolean, default: false }
  },
  data () {
    return {
      CREATION_STATE_LIST: [OPPORTUNITY_STATE_QUALIFICATION, OPPORTUNITY_STATE_WON],
      ACCOUNTING_MODIFIABLE_STATE: [OPPORTUNITY_STATE_QUALIFICATION, OPPORTUNITY_STATE_PROPOSAL],
      OPPORTUNITY_LINK_CART,
      OPPORTUNITY_LINK_NONE,
      OPPORTUNITY_LINK_ORDER_LINE,
      OPPORTUNITY_STATE_ABANDONED,
      OPPORTUNITY_STATE_PROPOSAL,
      OPPORTUNITY_STATE_QUALIFICATION,
      OPPORTUNITY_STATE_WON,
      OPPORTUNITY_STATES_CREATION,
      NOTE_MAX_LENGTH: 1024,
      TICKET_STATE_DONE: this.$alto.defines.TICKETS.TICKET_STATE_DONE,
      TICKET_STATE_NO_GO: this.$alto.defines.TICKETS.TICKET_STATE_NO_GO,
      TICKET_TYPE_PRE_SALE: this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE,
      currentOpportunity: {},
      history: [],
      isLoadingHistory: false,
      isSaving: false,
      listFirstContacts: [],
      listTypes: [],
      menuPotentialClosingDate: false,
      tab: 'tab-info',
      tabErrors: {},
      valid: true
    }
  },
  computed: {
    accountingFamilies () {
      return this.$store.getters['$alto-catalog/accountingFamilies']
    },
    canUpdateDownsellReason () {
      return this.currentOpportunity.state === OPPORTUNITY_STATE_WON && (
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, this.$alto.API_PERMISSIONS.OPPORTUNITIES_UPDATE_DOWNSELL_REASON)
      )
    },
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canClone () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, this.$alto.API_PERMISSIONS.CREATE)
    },
    canModifyAccountingFamily () {
      return this.isNoWon || this.ACCOUNTING_MODIFIABLE_STATE.includes(this.currentOpportunity.state)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, [this.$alto.API_PERMISSIONS.CREATE, this.$alto.API_PERMISSIONS.UPDATE]) && this.currentOpportunity && (this.update || (this.create && !OPPORTUNITY_STATES_READ_ONLY_CREATE.includes(this.currentOpportunity.state)))
    },
    currentCart () { return this.$store.getters['$alto-quotations/current'] || {} },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    getAccountingFamilyRules () {
      return this.isNoWon ? [this.$stratus.services.form.rules.required] : []
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    isNoWon () {
      return this.currentOpportunity.type_of_link === this.OPPORTUNITY_LINK_NONE && this.currentOpportunity.state === this.OPPORTUNITY_STATE_WON
    },
    me () { return this.$store.getters['$stratus-states/me'] },
    noteFormatted () {
      return this.$stratus.services.strings.stripHtmlTags(this.currentOpportunity.notes)
    },
    title () {
      return this.$stratus.services.strings.stripHtmlTags(this.currentOpportunity.name)
    }
  },
  watch: {
    opportunity: {
      immediate: true,
      handler (newOpportunity, oldOpportunity) {
        if (newOpportunity && !_.isEqual(newOpportunity, oldOpportunity)) {
          this.currentOpportunity = { ...newOpportunity }
        }
      }
    }
  },
  methods: {
    canEdit (fieldName) {
      return this.canSave && (
        (this.isLord && this.isAdmin) ||
        this.create ||
        OPPORTUNITY_ALWAYS_UPDATABLE_FIELDS.includes(fieldName) ||
        (OPPORTUNITY_QUALIFICATION_UPDATABLE_FIELDS.includes(fieldName) && this.currentOpportunity.state === OPPORTUNITY_STATE_QUALIFICATION) ||
        (OPPORTUNITY_PROPOSAL_UPDATABLE_FIELDS.includes(fieldName) && this.currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL)
      )
    },
    canEditPrice (fieldName) {
      return this.canSave && (
        (this.isLord && this.isAdmin) ||
        this.create ||
        this.isNoWon ||
        OPPORTUNITY_ALWAYS_UPDATABLE_FIELDS.includes(fieldName) ||
        (OPPORTUNITY_QUALIFICATION_UPDATABLE_FIELDS.includes(fieldName) && this.currentOpportunity.state === OPPORTUNITY_STATE_QUALIFICATION) ||
        (OPPORTUNITY_PROPOSAL_UPDATABLE_FIELDS.includes(fieldName) && this.currentOpportunity.state === OPPORTUNITY_STATE_PROPOSAL)
      )
    },
    clone () {
      this.$set(this.currentOpportunity, 'notes', '')
      this.$set(this.currentOpportunity, 'is_countable', true)
      delete this.currentOpportunity.type_of_link
      delete this.currentOpportunity.set_id
      delete this.currentOpportunity.link_id
      delete this.currentOpportunity.id
      this.$emit('clone', { opportunity: this.currentOpportunity })
      this.$nextTick(() => {
        this.tab = 'tab-info'
        this.$stratus.services.notify.success(this.$t('Opportunity cloned.'))
      })
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    async createCart () {
      try {
        await this.$store.dispatch('$alto-quotations/create', { company: this.currentOpportunity.company, /* opportunityId: this.currentOpportunity.id, */ opportunitySetId: this.currentOpportunity.set_id })
        this.$store.commit('$alto-quotations/setCurrent', this.currentCart) // Force refresh current cart
        this.$router.push('/catalog').catch((error) => { console.warn(error) })
      } catch (error) {
        console.log(error)
        this.$stratus.services.notify.error(error)
      }
    },
    externalId () {
      if (!this.currentOpportunity?.id) return
      return [this.currentOpportunity.id, this.currentOpportunity.id ? 'opportunities/' + this.currentOpportunity.id : '', this.currentOpportunity.name]
    },
    loadHistory () {
      if (!this.currentOpportunity.id) return
      this.isLoadingHistory = true
      this.$store.dispatch('opportunities/getHistory', this.currentOpportunity.id)
        .then(history => {
          this.history = history
        })
        .catch(error => {
          console.log(error)
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          setTimeout(() => { this.isLoadingHistory = false }, 500)
        })
    },
    async loadLists () {
      this.isSaving = true
      try {
        await this.$store.dispatch('$alto-opportunities/loadStates')
        this.listTypes = this.$store.getters['$alto-opportunities/states']

        await this.$store.dispatch('$alto-catalog/loadAccountingFamilies')

        await this.$store.dispatch('$alto-companies/loadFirstContacts')
        this.listFirstContacts = this.$store.getters['$alto-companies/firstContacts']

        this.isSaving = false
      } catch (error) {
        console.log(error)
        this.$stratus.services.notify.error(error)
        this.isSaving = false
      }
    },
    async reset () {
      // Go to first tab
      this.tab = 'tab-info'
      if (this.$refs.OpportunityForm) await this.$refs.OpportunityForm.reset()
      this.tabErrors = {}
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    opportunityAbandon () {
      this.$refs['confirm-opportunity-update'].open(this.$t('Abandon opportunity'), this.$t('Abandoning the opportunity is irreversible. Please confirm?'), { color: 'warning' })
        .then(async confirmed => {
          if (confirmed) {
            this.isSaving = true
            try {
              const result = await this.$store.dispatch('opportunities/update', { id: this.currentOpportunity.id, state: OPPORTUNITY_STATE_ABANDONED })
              this.currentOpportunity.state = result.state
              this.$stratus.services.notify.success(this.$t('Opportunity {name} updated.', { name: this.currentOpportunity.name }))
            } catch (error) {
              console.log(error)
              this.$stratus.services.notify.error(error)
            }
            this.isSaving = false
          }
        })
    },
    opportunityWon () {
      if (_.isEmpty(this.currentOpportunity.accounting_family)) {
        this.$stratus.services.notify.warning(this.$t('You must provide an accounting family!'))
        return
      }

      this.$refs['confirm-opportunity-update'].open(this.$t('Opportunity is won'), this.$t('Winning the opportunity is irreversible. Please confirm?'), { color: 'success', width: 550 })
        .then(async confirmed => {
          if (confirmed) {
            this.isSaving = true
            try {
              const result = await this.$store.dispatch('opportunities/update', { id: this.currentOpportunity.id, state: OPPORTUNITY_STATE_WON, accounting_family: this.currentOpportunity.accounting_family })
              this.currentOpportunity.state = result.state
              this.$stratus.services.notify.success(this.$t('Opportunity {name} updated.', { name: this.currentOpportunity.name }))
            } catch (error) {
              console.log(error)
              this.$stratus.services.notify.error(error)
            }
            this.isSaving = false
          }
        })
    },
    saveDialog () {
      this.isSaving = true
      this.currentOpportunity.closing_date = this.$stratus.dt(this.currentOpportunity.closing_date).format('YYYY-MM-DD')

      if (!this.$refs.OpportunityForm.validate()) {
        this.tabErrors = this.$stratus.services.form.tabsWithError(this.$refs.OpportunityForm)
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      if (this.currentOpportunity.notes && this.currentOpportunity.notes.length > this.NOTE_MAX_LENGTH) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }

      this.$store
        .dispatch('opportunities/' + (this.update ? 'update' : 'create'), this.currentOpportunity)
        .then(() => {
          if (this.update) {
            this.$stratus.services.notify.success(this.$t('Opportunity {name} updated.', { name: this.currentOpportunity.name }))
          } else {
            this.$stratus.services.notify.success(this.$t('Opportunity {name} created.', { name: this.currentOpportunity.name }))
          }
          this.closeDialog()
        })
        .catch(error => {
          console.log(error)
          this.$stratus.services.notify.error(error)
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    async showOrder () {
      try {
        const line = await this.$store.dispatch('$alto-orders/getProduct', this.currentOpportunity.link_id)
        if (!line || !line.cmd_id) throw new Error(this.$t('This opportunity refers to a command line not found!'))
        if (this.$refs['order-dialog']) this.$refs['order-dialog'].open(line.cmd_id)
      } catch (error) {
        console.log(error)
        this.$stratus.services.notify.error(error)
      }
    },
    showSubscription (id) {
      try {
        if (this.$refs['subscription-dialog']) this.$refs['subscription-dialog'].open(id)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    showQuotation () {
      if (this.$root['cart-menu-global-dialog']) this.$root['cart-menu-global-dialog'].open(this.currentOpportunity.link_id)
    }
  },
  async onBeforeMount () {
    // Fill all caches
    await this.$stratus.fetchCaches(false)
    await this.$alto.fetchCaches(false)
  },
  mounted () {
    this.loadLists()
  }
}
</script>
