<template>
  <div>
    <csm-cart-form ref="cart-form" :update="canUpdate" @closeDialog="closeDialog" @refreshOrder="onRefresh" />
    <csm-order-dialog ref="order-dialog" />
  </div>
</template>

<script>
import CartForm from '@/components/Carts/CartForm.vue'

let previousCart = null

export default {
  name: 'CartDialog',
  components: { 'csm-cart-form': CartForm },
  data () {
    return {}
  },
  computed: {
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canReadPrices () {
      return !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST_NO_PRICE)
    },
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog (cart, openOrder) {
      if (this.callbackFn) this.callbackFn(this.cart)
      if (this.$refs['cart-form'].display) this.$refs['cart-form'].display(false)
      previousCart = null
      if (this.$route?.params?.id && this.$route?.params?.id !== 'new') {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', cart)
        if (cart?.id && openOrder) {
          this.$refs['order-dialog'].open(cart.id) // Open order dialog
        }
      }
    },
    create () {
      const cart = this.$store.getters['$alto-quotations/current'] || { products: [] }
      this.$refs['cart-form'].setCart(cart)
      this.$nextTick(() => {
        if (this.$refs['cart-form']) this.$refs['cart-form'].display(true)
      })
    },
    async getCart (id) {
      if (!id || !this.$refs['cart-form']) return

      try {
        const response = await this.$store.dispatch('$alto-quotations/get', id)
        if (response && this.$refs['cart-form']) this.$refs['cart-form'].setCart(response)
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Quotation «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
        this.closeDialog()
      }
    },
    onRefresh (cart) {
      if (!cart.id) {
        this.cart = { ...cart }
        this.$forceUpdate()
        return
      }
      // Reload cart
      this.$store.dispatch('$alto-guotations/get', cart.id)
        .then(response => {
          if (response) {
            if (this.$refs['cart-form']) this.$refs['cart-form'].setCart(response)
          }
        })
        .catch(error => this.$stratus.services.notify.error(error))
    },
    async open (id, callbackFn) {
      if (!id || !this.$refs['cart-form']) return

      this.callbackFn = callbackFn
      await this.getCart(id)
      this.$nextTick(() => {
        if (this.$refs['cart-form']) this.$refs['cart-form'].display(true)
      })
    }
  },
  async mounted () {
    if ((this.$route?.name === 'quotation' || this.$route?.name === 'quotation-new') && this.$route?.params?.id) {
      if (this.$route.params.id !== previousCart) {
        previousCart = this.$route.params.id
        this.open(this.$route.params.id) // Load cart from given id
      }
    }
  }
}
</script>
