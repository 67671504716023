<template>
  <div>
    <sca-advanced-store-grid :options="gridOptions" resource="tickets/credit-transactions" :columns="columns" :filters="filters" :custom-search="customSearch" @createItem="createItem" @deleteItem="deleteItem" @showItem="showItem" @updateItem="updateItem" @resetFilters="resetFilters" ref="ticket-transactions-grid">
      <template slot="search-append">
        <sca-customer-select v-model="companiesSearch" :label="$t('Company')" class="pt-3" @input="companiesSearchChange" clearable show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />
      </template>

      <template v-slot:item-code="{ itemRaw }">
        <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
      </template>

      <template v-slot:item-id_ticket="{ itemRaw }">
        <span v-if="!itemRaw">
          {{ $t('None') }}
        </span>
        <sca-ticket-identity v-else :value="itemRaw" link="emit" @link-click="openTicket(itemRaw)" />
      </template>

      <template v-slot:item-id_oneline="{ itemRaw }">
        <span v-if="!itemRaw">
          {{ $t('None') }}
        </span>
        <sca-subscription-identity v-else :value="itemRaw" :label="itemRaw" link="emit" @link-click="openSubscription(itemRaw)" />
      </template>
    </sca-advanced-store-grid>

    <csm-ticket-transaction-dialog ref="ticket-transaction-dialog" @closeDialog="closeDialog" />
    <csm-ticket-dialog ref="ticket-dialog" />
    <csm-subscription-dialog ref="subscription-dialog" />
    <csm-company-dialog ref="company-dialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TicketsTransactions',
  components: {
    'csm-ticket-transaction-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketTransactionDialog.vue')
  },
  data () {
    return {
      companiesSearch: '',
      filters: ['code', 'comment', 'id_ticket', 'id_oneline'],
      isLogged: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    columns () {
      return [
        {
          text: 'Company',
          value: 'code'
        }, {
          text: 'Comment',
          value: 'comment'
        }, {
          text: 'Amount',
          value: 'amount',
          format: v => v ? this.$n(v, 'currency') : this.$t('None')
        }, {
          text: 'Ticket',
          value: 'id_ticket'
        }, {
          text: 'Product',
          value: 'id_oneline'
        }, {
          text: 'Date',
          value: 'date',
          align: 'right',
          alignValue: 'right',
          format: this.$stratus.services.fieldRenderers.DATE_LONG
        }, {
          text: 'Created at',
          value: 'create_at',
          hidden: true,
          align: 'right',
          alignValue: 'right',
          format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
        }, {
          text: 'Created by',
          hidden: true,
          value: 'create_by'
        }, {
          text: 'Updated at',
          value: 'update_at',
          hidden: true,
          align: 'right',
          alignValue: 'right',
          format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
        }, {
          text: 'Updated by',
          hidden: true,
          value: 'update_by'
        }
      ]
    },
    gridOptions () {
      return {
        allowColumnsVisible: true,
        allowContextMenuOnCell: true,
        create: this.isLogged && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_TRANSACTIONS, this.$alto.API_PERMISSIONS.CREATE),
        customShowItem: true,
        customUpdateItem: true,
        delete: false,
        foreignFields: ['id'],
        key: 'id',
        multiSelect: false,
        notifyErrors: true,
        search: this.isLogged,
        show: false,
        sortBy: 'date',
        sortDescending: true,
        update: false
      }
    }
  },
  methods: {
    companiesSearchChange (value) {
      if (this.$refs['ticket-transactions-grid']) this.$refs['ticket-transactions-grid'].fetchData()
    },
    closeDialog () {
      if (this.$refs['ticket-transactions-grid']) this.$refs['ticket-transactions-grid'].fetchData({ forceReload: true })
    },
    async createItem () {
      this.$refs['ticket-transaction-dialog'].open({})
    },
    customSearch () {
      const cusSearch = []
      if (this.companiesSearch) {
        cusSearch.push({
          column: 'code',
          search: this.companiesSearch
        })
      }
      return cusSearch
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openSubscription (id) {
      if (this.$refs['subscription-dialog']) this.$refs['subscription-dialog'].open(id)
    },
    openTicket (id) {
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(id)
    },
    resetFilters () {
      this.companiesSearch = ''
    },
    async showItem ({ success, error, item }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['ticket-transaction-dialog'].open(item.id)
    },
    async updateItem ({ success, error, item }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['ticket-transaction-dialog'].open(item.id)
    },
    deleteItem ({ success, error, item }) {
      if (success) {
        this.$stratus.services.notify.success(this.$t('Sphere "{name}" deleted.', item))
      } else if (error && error.status === 403) {
        this.$stratus.services.notify.warning(this.$t('You\'re not allowed to delete sphere "{name}".', item))
      } else {
        this.$stratus.services.notify.error(error)
      }
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Tickets transactions'))
  },
  async mounted () {
    this.isLogged = this.$stratus.services.auth.isLogged()
  }
}
</script>
