<template>
  <div v-if="canListCarts">
    <v-menu v-model="showMenu" offset-y transition="scale-transition" :disabled="loading" :close-on-content-click="true">
      <template v-slot:activator="{ on }">
        <v-btn rounded small text v-on="on">
          <cs-icon-loading v-if="loading" small left />
          <v-icon v-else small left>
            $vuetify.icons.cart
          </v-icon>
          <span v-if="current?.id">
            {{ current?.name || `${$t('Quotation')} ${current.id}` }}
          </span>
          <span v-else>
            {{ $tc('Quotations') }}
          </span>
          <v-icon small right :class="showMenu ? 'rotate-once-180' : ''">
            $vuetify.icons.expand
          </v-icon>
        </v-btn>
      </template>

      <v-card flat tile class="pa-2">
        <v-row dense align="center" justify="space-between">
          <v-col>
            <v-btn v-if="canCatalog" to="/productchooser" rounded class="main-button">
              <v-icon left small>
                $vuetify.icons.catalog
              </v-icon>
              {{ $t('Products Catalog') }}
            </v-btn>
          </v-col>

          <v-col class="text-center">
            <v-btn @click="newCart" v-show="currentExists" rounded>
              <v-icon left small>
                $vuetify.icons.add
              </v-icon>
              {{ $t('New cart') }}
            </v-btn>
          </v-col>

          <v-col class="text-right">
            <v-btn to="/quotations" v-show="cartList && cartList.length" rounded>
              <v-icon left small>
                $vuetify.icons.cart
              </v-icon>
              {{ $t('Quotations list') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-show="cartList && cartList.length" dense>
          <v-col cols="12">
            <span class="font-weight-bold">
              {{ $t('Last saved quotations') }}
            </span>
          </v-col>
        </v-row>

        <div v-for="cart in cartList" :key="cart.id" no-gutters class="mt-2">
          <v-hover v-slot="{ hover }">
            <div class="d-flex align-center" :class="hover ? 'focused' : ''">
              <div class="clickable px-1 full-width" @click="gotoQuotation(cart.id)">
                <div>
                  <span :class="cart.id === current.id ? 'font-weight-bold' : ''">
                    {{ cart.name || `${$t('Quotation')} ${cart.id}` }} :
                  </span>
                  <span class="primary--text pl-1">{{ $n((cart.total_discount) ? cart.total_discount : cart.total, 'currency') }}</span>
                  <span class="text-caption primary--text pl-1"> + {{ $n((cart.total_setup_discount) ? cart.total_setup_discount : cart.total_setup, 'currency') }}</span>
                </div>

                <div class="d-flex align-center">
                  <div class="pr-2 text-caption">
                    {{ $stratus.dt(cart.update_at).format('ll') }}
                  </div>
                  <sca-company-identity :value="cart.code" dense no-code show-avatar show-email show-phone show-sales-person show-card />
                  <div v-if="cart.expired" class="pl-2 text-caption danger--text">
                    <v-icon x-small>
                      $vuetify.icons.expired
                    </v-icon>
                    {{ $t('Out-of-date') }}
                  </div>
                </div>
              </div>

              <div v-if="cart.id === current.id && canRead" class="ml-auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon text v-on="on" @click="gotoQuotation(cart.id)">
                      <v-icon small class="menu-icon--text">
                        $vuetify.icons.read
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('This is the current quotations.') }}</span>
                </v-tooltip>
              </div>

              <div v-if="cart.id !== current.id && canDelete" class="ml-auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon text v-on="on" @click.capture.stop="deleteCart(cart)">
                      <v-icon small class="danger--text">
                        $vuetify.icons.delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('Delete') }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-hover>
        </div>
      </v-card>
    </v-menu>
    <cs-confirm-dialog ref="confirmCartDeletion" />

    <csm-cart-dialog ref="cart-menu-global-dialog" @closeDialog="closeDialog" />
  </div>
</template>

<script>
const QUOTATION_HISTORY_SIZE = 8

export default {
  data () {
    return {
      cartList: [],
      current: {},
      loading: false,
      showMenu: false
    }
  },
  computed: {
    canListCarts () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST) },
    canCatalog () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST) },
    canDelete () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_DELETE) },
    canRead () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.READ) },
    currentExists () { return this.current && this.current.products?.length > 0 }
  },
  methods: {
    clearCart () {
      this.$store.commit('$alto-quotations/clear')
      this.create()
    },
    create () {
      this.current = this.$store.getters['$alto-quotations/current'] || { products: [] }
    },
    closeDialog () {
      this.refreshCart()
    },
    deleteCart (cart) {
      this.$refs.confirmCartDeletion
        .open(this.$t('Delete quotation'), this.$t('Confirm deletion of quotation «{name}»?', { name: cart.name }), { color: 'danger' })
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('$alto-quotations/delete', cart.id)
              .then(() => {
                if (cart.id === this.current.id) {
                  // Clear current cart from store as we have just delete it!
                  this.clearCart()
                }
                this.$stratus.services.notify.success(this.$t('Quotation «{name}» deleted!', { name: cart.name }))
                this.refreshList()
              })
              .catch(error => this.$stratus.services.notify.error(error))
          }
        })
    },
    expirationDateHtml (date) {
      if (!date) return ''
      const { color, diff } = this.$store.getters['$alto-quotations/expirationDetails'](date)
      return diff > this.$alto.defines.QUOTATIONS.QUOTATION_EXPIRATION_WARNING_DELAY
        ? this.$stratus.dt(date).format('LL')
        : `<span class="${color}">${this.$stratus.i18n.tc('Expired|Expire tomorrow|Expires in {d} days', Math.abs(diff), { d: Math.abs(diff) })}</span>`
    },
    gotoCatalog () {
      if (this.$router.currentRoute.name !== 'catalog') {
        this.$router.push({ name: 'catalog' }).catch((error) => { console.warn(error) })
      }
    },
    async gotoQuotation (cartId) {
      try {
        this.loading = true
        await this.$refs['cart-menu-global-dialog'].open(cartId)
        this.current = this.$store.getters['$alto-quotations/current'] || {}
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.loading = false
      }
    },
    newCart () {
      this.clearCart()
      this.gotoCatalog()
    },
    async refreshCart () {
      this.current = this.$store.getters['$alto-quotations/current'] || {}
    },
    async refreshList () {
      if (!this.canListCarts) return
      try {
        this.loading = true
        const newList = await this.$store.dispatch('$alto-quotations/list', { limit: QUOTATION_HISTORY_SIZE }) || []
        this.cartList = newList
        this.current = this.$store.getters['$alto-quotations/current'] || {}
        this.register()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.loading = false
      }
    },
    register () {
      if (this.$root['cart-menu-global-dialog']) return
      this.$root['cart-menu-global-dialog'] = this.$refs['cart-menu-global-dialog']
      if (this.$root['cart-menu-global-dialog']) console.log('[quotation] root dialog registered')
    }
  },
  mounted () {
    setTimeout(() => this.refreshList(), 1000)
  }
}
</script>
