<template>
  <csm-marketing-cost-form ref="marketing-cost-form" :event-id="eventId" :visible="dialogVisible" @closeDialog="closeDialog" />
</template>

<script>
export default {
  name: 'MarketingCostDialog',
  props: {
    eventId: { type: String, default: null }
  },
  components: {
    'csm-marketing-cost-form': () => import(/* webpackChunkName: "components" */ './MarketingCostForm')
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES_MARKETING, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog (cost) {
      this.dialogVisible = false
      this.$emit('closeDialog', cost)
    },
    async get (id) {
      try {
        const cost = { ...await this.$store.dispatch('$alto-marketing/getCost', id) }
        this.reset(cost)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    open (id) {
      this.dialogVisible = true
      if (id) this.get(id)
      else this.reset()
    },
    reset (cost = {}) {
      this.$refs['marketing-cost-form'].reset(cost)
    }
  }
}
</script>
