import Vue from 'vue'
import Router from 'vue-router'
import routesServices from '@/services/routes'

Vue.use(Router)

export const router = new Router({
  routes: [
    routesServices.loadViewRoute('/preferences-notifications', 'Main', 'preferences-notifications'),
    routesServices.loadViewRoute('/', 'Main', null, [
      routesServices.loadViewRoute('/', 'Home', 'home'),
      routesServices.loadViewRoute('/secrets/:secretid', 'Secrets', 'secret'),
      routesServices.loadViewRoute('/password-reset/:token', 'ResetPassword', 'resetpassword'),
      routesServices.loadViewRoute('/activities', 'Activities', 'activities'),
      routesServices.loadViewRoute('/subscriptions', 'Subscriptions/SubscriptionsList', 'subscriptions'),
      routesServices.loadComponentRoute('/subscriptions/:id', 'Subscriptions/SubscriptionDialog', 'subscription'),
      routesServices.loadViewRoute('/companies', 'Companies/CompaniesRouter', 'companies'),
      routesServices.loadViewRoute('/companieslist', 'Companies/CompaniesList', 'companies-list'),
      routesServices.loadComponentRoute('/companies/:id', 'Companies/CompanyDialog', 'company'),
      routesServices.loadViewRoute('/contracts', 'Contracts/ContractsList', 'contracts-list'),
      routesServices.loadViewRoute('/contracts/:id', 'Contracts/ContractDialog', 'contract'),
      routesServices.loadViewRoute('/productchooser', 'Products/Chooser', 'productchooser'),
      routesServices.loadViewRoute('/catalog/:domain', 'Products/Catalog', 'catalog-domain'),
      routesServices.loadViewRoute('/catalog', 'Products/Catalog', 'catalog'),
      routesServices.loadViewRoute('/quotations', 'Products/QuotationsList', 'quotations'),
      routesServices.loadViewRoute('/orders', 'Orders/OrdersList', 'orders'),
      routesServices.loadComponentRoute('/orders/:id', 'Orders/OrderDialog', 'order'),
      routesServices.loadViewRoute('/opportunities', 'Opportunities/OpportunitiesRouter', 'opportunities'),
      routesServices.loadViewRoute('/opportunitieslist', 'Opportunities/OpportunitiesList', 'opportunities-list'),
      routesServices.loadComponentRoute('/opportunities/:id', 'Opportunities/OpportunityDialog', 'opportunity'),
      routesServices.loadViewRoute('/sales-dashboard', 'Opportunities/OpportunitiesSalesDashboard', 'sales-dashboard'),
      routesServices.loadViewRoute('/csm-dashboard', 'Opportunities/OpportunitiesCSMDashboard', 'csm-dashboard'),
      routesServices.loadViewRoute('/pre-sale', 'Opportunities/PreSale', 'pre-sale'),
      routesServices.loadViewRoute('/marketing-dashboard', 'Opportunities/OpportunitiesMarketingDashboard', 'marketing-targets'),
      routesServices.loadComponentRoute('/marketing-event/:id', 'Opportunities/MarketingEventDialog', 'marketing-event'),
      routesServices.loadComponentRoute('/marketing-lead/:id', 'Opportunities/MarketingLeadDialog', 'marketing-lead'),
      routesServices.loadViewRoute('/followup-activity', 'FollowUps/FollowUpActivity', 'followup-activity'),
      routesServices.loadComponentRoute('/followup-call/:id', 'FollowUps/FollowUpCallDialogLite', 'followup-call'),
      routesServices.loadComponentRoute('/followup-email/:id', 'FollowUps/FollowUpEmailDialogLite', 'followup-email'),
      routesServices.loadComponentRoute('/followup-event/:id', 'FollowUps/FollowUpEventDialogLite', 'followup-event'),
      routesServices.loadComponentRoute('/followup-task/:id', 'FollowUps/FollowUpTaskDialogLite', 'followup-task'),
      routesServices.loadComponentRoute('/products/:ref', 'Products/ProductDialog', 'product'),
      routesServices.loadComponentRoute('/quotations/new', 'Carts/CartDialog', 'quotation-new'),
      routesServices.loadComponentRoute('/quotations/:id', 'Carts/CartDialog', 'quotation'),
      routesServices.loadViewRoute('/users', 'Users/UsersList', 'users'),
      routesServices.loadComponentRoute('/users/:id', 'Users/UserDialog', 'user'),
      routesServices.loadViewRoute('/spheres', 'Spheres/SpheresList', 'spheres'), // todo
      routesServices.loadComponentRoute('/spheres/:id', 'Spheres/SphereDialog', 'sphere'),
      routesServices.loadViewRoute('/tickets', 'Tickets/TicketsRouter', 'tickets'),
      routesServices.loadViewRoute('/tickets-list', 'Tickets/TicketsList', 'tickets-list'),
      routesServices.loadViewRoute('/tickets/list', 'Tickets/TicketsListUsers', 'tickets-usr'),
      routesServices.loadComponentRoute('/tickets/:id/:comment_id?', 'Tickets/TicketDialog', 'ticket'),
      routesServices.loadViewRoute('/questionnaire-list', 'Tickets/QuestionnaireList', 'questionnaire-list'),
      routesServices.loadComponentRoute('/questionnaires/:id', 'Tickets/TicketQuestionnaireDialog', 'ticket-questionnaire'),
      routesServices.loadViewRoute('/tickets-launcher', 'Tickets/TicketsAutomationList', 'tickets-launcher-list'),
      routesServices.loadComponentRoute('/tickets-launcher/:id', 'Tickets/TicketAutomationDialog', 'ticket-launcher'),
      routesServices.loadViewRoute('/tickets-transactions', 'Tickets/TicketsTransactions', 'tickets-transactions'),
      // FUTURE routesServices.loadComponentRoute('/tickets-transaction/:id', 'Tickets/TicketTransactionDialog', 'ticket-transaction'),
      routesServices.loadViewRoute('/cds-dashboard', 'Tickets/CdsDashboard', 'cds-dashboard'),
      routesServices.loadViewRoute('/sla-dashboard', 'Tickets/SlaDashboard', 'sla-dashboard'),
      routesServices.loadViewRoute('/infra-dashboard', 'Tickets/InfraDashboard', 'infra-dashboard'),
      routesServices.loadViewRoute('/kanban', 'Kanban/Kanban', 'kanban'),
      routesServices.loadViewRoute('/documents', 'Documents', 'documents'),
      routesServices.loadViewRoute('/scheduler', 'Scheduler/Scheduler', 'scheduler'),
      routesServices.loadViewRoute('/about', 'About', 'about'),
      routesServices.pageNotFound()
    ])
  ]
})

export function registerRouteCheck (stratusLib, router) {
  const { name, version, isValid } = stratusLib.services.browser.checkBrowser()
  console.log(`[browser] ${name} ${version}`, isValid ? 'good 🗸' : 'bad 🗴')

  router.beforeEach((to, from, next) => {
    if (isValid) next()
    else {
      console.error('[browser] is not valid!')

      let el = document.getElementById('wrong-browser-version')
      el.innerText = `${name} ${version}`
      el = document.getElementById('wrong-browser')
      if (el) el.classList.remove('hidden')
      el = document.getElementById('loading-message')
      if (el) el.classList.add('hidden')
      el = document.getElementById('app')
      if (el) el.classList.add('hidden')
      next(false)
    }
  })
}

export default {
  registerRouteCheck,
  router
}
