<template>
  <csm-ticket-transaction-form :ref="`ticket-transaction-form-${uuid}`" :value="transaction" :loading="loading" :show="canShow" :update="canUpdate" @closeDialog="closeDialog" @refresh="onRefresh" />
</template>

<script>
import _ from 'lodash'

let previousTicketTransaction = null

export default {
  name: 'TicketTransactionDialog',
  components: {
    'csm-ticket-transaction-form': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketTransactionForm.vue')
  },
  data () {
    return {
      loading: true,
      transaction: {},
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_TRANSACTIONS, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.transaction?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_TRANSACTIONS, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog (saved) {
      if (this.$refs[`ticket-transaction-form-${this.uuid}`]) this.$refs[`ticket-transaction-form-${this.uuid}`].display(false)
      previousTicketTransaction = null
      if (this.$route?.name === 'ticket-transaction' && this.$route?.params?.id) {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', saved)
      }
    },
    async get (data) {
      try {
        this.loading = true
        let transaction
        if (_.isObject(data)) {
          transaction = { ...data } || {}
        } else {
          transaction = { ...await this.$store.dispatch('$alto-ticketing/getTicketTransaction', { id: data, forceReload: true, history: true }) }
        }
        this.transaction = transaction
        this.reset(this.transaction)
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.closeDialog()
      } finally {
        setTimeout(() => { this.loading = false }, 500)
      }
    },
    async onRefresh (data) {
      if (!data.id) {
        this.transaction = { ...data }
        this.$forceUpdate()
        return
      }
      // Reload
      await this.get(data.id)
      this.$forceUpdate()
    },
    async open (id) {
      if (!id) return
      this.transaction = {}
      this.reset()
      await this.get(id)
      if (this.$refs[`ticket-transaction-form-${this.uuid}`]) await this.$refs[`ticket-transaction-form-${this.uuid}`].display(true)
    },
    openNew (data = {}) {
      this.reset()
      this.get(data)
      if (this.$refs[`ticket-transaction-form-${this.uuid}`]) this.$refs[`ticket-transaction-form-${this.uuid}`].display(true)
    },
    async reset () {
      this.restoreTitle = false
      if (this.$refs[`ticket-transaction-form-${this.uuid}`]) await this.$refs[`ticket-transaction-form-${this.uuid}`].reset(this.transaction)
    }
  },
  async mounted () {
    if (this.$route?.name === 'ticket-transaction' && this.$route.params.id) {
      if (this.$route.params.id !== previousTicketTransaction) {
        previousTicketTransaction = this.$route.params.id
        const me = await this.$store.dispatch('$stratus-states/getMe')
        if (this.$alto.services.routes.connectionForbidden.call(this, me)) return
        this.$nextTick(() => { this.open(this.$route.params.id) })
      }
    }
  }
}
</script>
