<template>
  <sca-modal-dialog :visible="visible" @closeDialog="closeDialog" :external-id="externalId" :max-width="$vuetify.breakpoint.lgAndUp ? '80%' : ''">
    <div slot="title" class="d-flex align-center">
      <span class="text-h6">
        {{ product.ref }}
      </span>

      <span class="pl-2">
        — {{ product.name }}
      </span>
    </div>

    <div v-if="!standalone && myCompany !== cartCustomer" slot="subtitle" class="d-flex align-center highlighted-panel">
      <div class="mr-2">
        {{ $t('Quotation for:') }}
      </div>
      <sca-company-identity :value="cartCustomer" />
    </div>

    <div slot="content">
      <v-row class="pa-6" align="start">
        <v-col cols="12" md="6">
          <p v-html="product.description" />
        </v-col>

        <v-col cols="12" md="6">
          <div v-if="!standalone">
            <cs-alert-panel v-if="isLord && product.no_price" type="info" dense>
              <template #content>
                <div class="font-weight-bold pr-2">
                  {{ $t('No price fixed') }}
                </div>
                {{ $t('Please fill the price and buying price fields for this product.') }}
              </template>
            </cs-alert-panel>

            <cs-alert-panel v-if="isLord && product.package" type="info">
              <template #content>
                <div class="font-weight-bold pr-2">
                  {{ $t('Package') }}
                </div>
                {{ $t('Complex products including this reference will not be taken into account when calculating the price with and without discount, and the price including VAT.') }}
              </template>
            </cs-alert-panel>
          </div>

          <v-card outlined>
            <sca-product-prices-grid :prices="computedPrices" :title="$t('Total')" :periodicity="product.periodicity" :show-margin="false" />

            <v-sheet v-if="!standalone && product.periodicity === 'D' && canAddProduct(product) && computedPrices.total_discount_incl_vat !== undefined" class="mt-2 px-2">
              <sca-product-price-simulator ref="product-price-simulator" :customer-id="cartCustomer" :product="productComputed" />
            </v-sheet>
          </v-card>

          <v-form v-if="!standalone" ref="product-form" v-model="valid" lazy-validation>
            <v-row align="start">
              <v-col cols="12" class="mt-4 text-h6">
                <csm-product-quantity-choice v-if="product.quantity_rules" :label="$t('Quantity')" :customer="cartCustomer" :quantity-rules="product.quantity_rules" :quantity="quantity" v-model="quantity" @input="onChangeQuantity" />
                <v-text-field v-if="isLord" v-model="product.caption" :label="$t('Caption')" class="mr-2" :readonly="readonly || standalone" :rules="[$stratus.services.form.rules.max(128)]" counter="128" />
                <p v-else-if="!isLord && product.caption">
                  {{ product.caption }}
                </p>
                <v-text-field v-model="product.customer_caption" :label="$t('Customer caption')" class="mr-2" :readonly="readonly || standalone" :rules="[$stratus.services.form.rules.max(128)]" counter="128" />
              </v-col>

              <v-col cols="12" md="3" v-if="product.no_price && isLord">
                <cs-decimal-input v-model="price" :label="$t('Custom price')" :min="0" />
                <cs-decimal-input v-model="buying_price" :label="$t('Custom buying price')" :min="0" />
              </v-col>
            </v-row>
          </v-form>

          <csm-dyna-products v-if="!product.isSimple" ref="dyna-products" :product="product" @change="onChange" :readonly="readonly || standalone" />
        </v-col>
      </v-row>
    </div>

    <div slot="buttons">
      <sca-sales-manager-message v-if="isNoPriceProduct(product)" page="product" color="primary" :label="$t('Request information')" :text="$t('I am interested in this product « {name} » for...', product)" title-is-label :title-watermark="`(${product.ref})`" button-close />
      <v-btn v-else-if="!standalone && canAddProduct(product) && computedPrices.total_discount_incl_vat !== undefined" rounded class="main-button" :disabled="!valid" @click="addToCart">
        {{ $t('Add to cart') }}
      </v-btn>
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

let previousProductRef = null

export default {
  name: 'ProductDialog',
  components: {
    'csm-dyna-products': () => import(/* webpackChunkName: "components" */ './DynaProducts'),
    'csm-product-quantity-choice': () => import(/* webpackChunkName: "components" */ './ProductQuantityChoice')
  },
  props: {
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      buying_price: NaN,
      computedPrices: {},
      daySimulation: 0,
      daySimulationPrice: null,
      isPricing: false,
      isSimple: false,
      price: NaN,
      productComputed: { main: [] },
      product: {},
      customerDiscounts: {},
      quantity: 1,
      standalone: false,
      valid: false,
      visible: false
    }
  },
  computed: {
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    cartCustomer () { return this.currentCart.code || this.myCompany },
    currentCart () { return this.$store.getters['$alto-quotations/current'] || { products: [] } },
    myCompany () { return this.$store.getters['$stratus-states/me'].company }
  },
  watch: {
    quantity: {
      handler (val, oldVal) {
        this.getPriceProduct()
      }
    },
    price: {
      handler (val, oldVal) {
        this.getPriceProduct()
      }
    },
    buying_price: {
      handler (val, oldVal) {
        this.getPriceProduct()
      }
    }
  },
  methods: {
    addToCart () {
      if (!this.$refs['product-form'].validate()) {
        this.errorMessage = this.$t('One or more fields must be corrected!')
        return
      }
      this.$emit('addToCart', { product: this.product, attributes: this.productComputed.main, quantity: this.quantity })
      this.closeDialog()
    },
    canAddProduct (product) {
      if (this.readonly || this.standalone || product.end_subscription) return false

      if (this.isNoPriceProduct(product)) return false

      return product.available || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.CREATE)
    },
    closeDialog () {
      this.display(false)
      this.resolve(false)
      previousProductRef = null
      if (this.$route.params.ref) this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
    },
    externalId () {
      if (!this.product?.ref) return
      return [this.product.ref, this.product.ref ? 'products/' + this.product.ref : '', this.product.name]
    },
    getQuantityLimit (product, type, def) {
      if (!product.quantity_rules) return def
      return (Object.hasOwnProperty.call(product.quantity_rules, type) ? product.quantity_rules[type] : def)
    },
    getPriceProduct () {
      if (this.isSimple || (this.product.main.length === this.productComputed.main.length && this.product.ref === this.productComputed.ref)) {
        const data = {
          main: this.productComputed.main,
          quantity: this.quantity,
          per_discount: 0,
          per_discount_setup: 0,
          nb_days: this.daySimulation
        }
        if (this.product.no_price) {
          data.price = this.price || 0
          this.product.price = this.price || 0
          data.buying_price = this.buying_price || 0
          this.product.buying_price = this.buying_price || 0
        }

        this.$store.dispatch('$alto-catalog/getPrice', {
          code: this.cartCustomer,
          ref: this.product.ref,
          data
        })
          .then(result => {
            this.computedPrices = result.price
            if (this.$refs['product-price-simulator']) {
              this.$refs['product-price-simulator'].refresh({
                main: this.productComputed.main
              })
            }
            return this.$store.dispatch('$alto-quotations/getDiscount', { ref: this.product.ref, company: this.cartCustomer })
          })
          .then(discounts => {
            this.customerDiscounts = discounts
          })
          .catch(error => {
            console.error(error)
            this.$stratus.services.notify.error(error)
          })
      }
    },
    isNoPriceProduct (product) {
      return product.no_price && !this.isLord
    },
    onChange (payload) {
      this.productComputed = this.isSimple ? { main: [] } : _.cloneDeep(payload)
      if (this.isSimple) {
        this.product.isSimple = true
        this.product.main = []
      }
      this.getPriceProduct()
    },
    onChangeQuantity (payload) {
      this.quantity = payload
      this.getPriceProduct()
    },
    async open (product = {}) {
      await this.reset()
      return new Promise((resolve, reject) => {
        this.product = product
        this.isSimple = !product.main || product.main.length === 0
        this.quantity = product.quantity || 1
        this.resolve = resolve
        this.reject = reject
        this.getPriceProduct()
        this.display(true)
      })
    },
    async openId (ref) {
      if (!this.canReadCatalog) return
      try {
        const product = await this.$store.dispatch('$alto-catalog/getProductByRef', ref)
        if (product) {
          this.standalone = true
          await this.open(product)
        }
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Product {ref} not found!', { ref }))
        else this.$stratus.services.notify.error(error)
      }
    },
    async reset () {
      if (this.$refs['product-form']) await this.$refs['product-form'].reset()
      if (this.$refs['dyna-products']) await this.$refs['dyna-products'].reset()
      this.quantity = NaN
      this.computedPrices = {}
      this.daySimulationPrice = null
      this.daySimulation = 0
      this.price = NaN
      this.buying_price = NaN
      this.product = {}
      this.productComputed = { main: [] }
      this.getPriceProduct()
      this.valid = false
    },
    async display (show = false) {
      this.visible = show
      if (show) {
        // Load some data in cache
        if (this.canReadCatalog && !this.$store.getters['$alto-catalog/list'].length) {
          await this.$store.dispatch('$alto-catalog/list')
        }
      }
    }

  },
  async mounted () {
    this.getPriceProduct = _.debounce(this.getPriceProduct, 1500)

    // Example: https://manager-int-cloudsystem.aws.scalair.fr/#/product/1
    if (this.$route?.name === 'product' && this.$route.params.ref) {
      if (this.$route.params.ref !== previousProductRef) {
        previousProductRef = this.$route.params.ref
        await this.$store.dispatch('$stratus-states/getMe')
        this.openId(this.$route.params.ref)
      }
    }
  }
}
</script>
