<template>
  <div v-if="internalCart?.products">
    <v-tabs v-if="!orderingLines" hide-slider v-model="quotationTabs" class="transparent pb-2">
      <cs-validation-tab href="#tab-product-list" :label="$t('Products')" icon="$vuetify.icons.product" :count="internalCart.products.length" />
      <cs-validation-tab href="#tab-details" :label="internalCart.description?.length > 0 ? `${$t('Detail')} (${$t('...')})` : $t('Detail')" icon="$vuetify.icons.details" />
      <cs-validation-tab v-if="!company?.blocked_payment" href="#tab-contacts" :label="$t('Contacts')" icon="$vuetify.icons.contact" />
    </v-tabs>

    <v-tabs-items v-show="!orderingLines" v-model="quotationTabs">
      <v-tab-item value="tab-product-list" eager>
        <v-row v-for="(item, index) in cartLines" :key="index" dense>
          <v-divider v-if="index > 0" class="mx-1" />

          <v-scroll-y-reverse-transition>
            <v-hover v-show="!orderingLines" v-slot="{ hover }">
              <v-sheet class="full-width py-4" :class="hover ? 'focused' : ''">
                <v-row align="start" class="px-2">
                  <v-col class="text-h5 text-center shrink">
                    <div class="my-2">
                      {{ item.index_order + 1 }}
                    </div>
                  </v-col>

                  <v-col cols="11" md="6">
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold shrink text-no-wrap">
                        <sca-product-identity :value="item.ref" :label="item.name" :company="customer" show-avatar link="emit" @link-click="openProduct(item.ref)" />
                      </div>

                      <div v-if="isLord && item.ref">
                        <cs-clipboard-button :text="item.ref" link icon dense />
                      </div>

                      <div class="ml-auto d-flex align-center">
                        <v-tooltip v-if="isLord && item?.main?.length" top>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="expandedToggle(index)">
                              <v-icon :class="expanded[index] ? 'primary--text' : ''" small>
                                $vuetify.icons.discount
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('Sub-product discount') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="canSave && (item.main?.length || productHasNoPrice(item.ref))" top>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="modifyProduct(index, item)">
                              <v-icon small>
                                $vuetify.icons.edit
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('Update') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="canSave" top>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="duplicateProduct(item)" :loading="isAdding">
                              <v-icon small>
                                $vuetify.icons.clone
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('Add same product to this quotation') }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="canSave" top>
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon @click="deleteProduct(index)" :loading="isDeleting">
                              <v-icon class="danger--text" small>
                                $vuetify.icons.delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t('Delete') }}</span>
                        </v-tooltip>
                      </div>
                    </div>

                    <div>
                      <span v-if="item.date_begin">
                        {{ $t('Begin: {date}', { date: $stratus.dt(item.date_begin).format('LL') || $t('Undefined') }) }}
                      </span>
                      <span v-if="item.date_end">
                        <v-icon small class="px-4">
                          icon-long-arrow-right
                        </v-icon>
                        {{ $t('End: {date}', { date: $stratus.dt(item.date_end).format('LL') || $t('Undefined') }) }}
                      </span>

                      <div>
                        <v-text-field v-model="item.caption" :label="$t('Caption')" :rules="captionRules(item)" counter="128" @input="captionChange(item)" :disabled="!isLord" />
                      </div>

                      <div v-if="canSave">
                        <v-text-field v-model="item.customer_caption" :label="$t('Customer caption')" :rules="[$stratus.services.form.rules.max(128)]" counter="128" @input="applyCustomerCaption(item)" />
                      </div>

                      <cs-alert-panel v-if="isLord && productHasNoPrice(item.ref)" type="info" dense class="mb-1">
                        <template #content>
                          <div class="font-weight-bold pr-2 text-no-wrap">
                            {{ $t('No price fixed') }}
                          </div>
                          {{ $t('To update price and buying price fields you need to update this product.') }}
                        </template>
                      </cs-alert-panel>

                      <cs-alert-panel v-if="isLord && productIsPackage(item.ref)" type="info" dense class="mb-1">
                        <template #content>
                          <div class="font-weight-bold pr-2 text-no-wrap">
                            {{ $t('Package') }}
                          </div>
                          {{ $t('Complex products including this reference will not be taken into account when calculating the price with and without discount, and the price including VAT.') }}
                        </template>
                      </cs-alert-panel>

                      <sca-product-details :product="item" :company="item.code" :has-caption="!isLord" :show="true" />
                    </div>
                  </v-col>

                  <v-col>
                    <v-row align="start" justify="end">
                      <v-col cols="4">
                        <div class="text-caption">
                          {{ $t('Sphere') }}
                        </div>
                        <cs-helper>
                          <sca-sphere-select slot="content" v-model="item.id_sphere" :customer="internalCart.code" dense hide-details :readonly="!canSave" @blur="applySphere(item)" />
                          <div slot="help">
                            {{ $t('Spheres allow you to generate multiple invoices. To create and manage spheres, go to his space, in the administration tab!') }}
                          </div>
                        </cs-helper>
                      </v-col>

                      <v-col v-if="isLord" cols="4">
                        <div class="text-caption">
                          {{ $t('Commitment\'s days') }}
                        </div>
                        <cs-integer-input v-model="item.nb_days_commitment" dense hide-details x-small :min="0" :suffix="$t('days')" @input="applyCommitment(item)" />
                        <div v-if="item.commitment_update_by" class="ml-1 text-caption">
                          {{ $t('Updated by') }} {{ item.commitment_update_by }}
                        </div>
                      </v-col>

                      <v-col v-else>
                        <span :class="item.nb_days_commitment ? 'font-weight-bold' : ''">
                          {{ $tc('Committed for {days} days', item.nb_days_commitment, { days: item.nb_days_commitment }) }}
                        </span>
                        <div v-if="item.nb_days_commitment">
                          ({{ $t('Until {date}', { date: $stratus.dt().add(item.nb_days_commitment, 'days').format('LL') }) }})
                        </div>
                      </v-col>

                      <v-col>
                        <csm-product-quantity-choice v-if="getProductByRef(item.ref).quantity_rules && typeof item.quantity === 'number'" :label="$t('Quantity')" dense hide-details :customer="customer" :quantity-rules="getProductByRef(item.ref).quantity_rules" :quantity="item.quantity" v-model="item.quantity" :readonly="!canSave || !canChangeQuantity(item)" @input="applyQuantity(item)" label-top />
                      </v-col>

                      <v-col v-if="isLord && item.delivery_at" cols="12" class="d-flex align-center text-right text-caption">
                        <div class="ml-auto mr-1">
                          {{ $t('Delivered at {date} by', { date: $stratus.dt(item.delivery_at).format('LLL') }) }}
                        </div>
                        <sca-user-identity :value="item.delivery_by" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.delivery_by)" />
                      </v-col>

                      <v-col v-if="canReadPrices" cols="12">
                        <sca-product-prices-grid :prices="item" :show-margin="isLord" :periodicity="item.periodicity" :working="working" dense />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row v-if="isLord || item.per_discount > 0 || item.per_discount_setup > 0" align="center" class="ml-4" no-gutters>
                  <v-col cols="12" class="d-flex align-center">
                    <v-checkbox v-show="isLord" :label="$t('Apply customer discounts')" v-model="item.custom_discounts" :false-value="true" :true-value="false" :disabled="!canUpdateDiscounts" @change="onChangeCustomDiscounts(item, $event)" />

                    <div class="ml-4">
                      <cs-percent-input v-show="isLord || item.per_discount > 0" v-model="item.per_discount" :label="item.periodicity !== 'O' ? $t('Subscription') : $t('Once')" :min="0" :max="100" :step="1" :disabled="!canUpdateDiscounts || !item.custom_discounts" :readonly="!canSave" @input="applyCustomDiscount(item)" />
                    </div>

                    <div v-if="item.periodicity !== 'O'" class="ml-4">
                      <cs-percent-input v-show="isLord || item.per_discount_setup > 0" v-model="item.per_discount_setup" :label="$t('Setup')" :min="0" :max="100" :step="1" :disabled="!canUpdateDiscounts || !item.custom_discounts" :readonly="!canSave" @input="applyCustomDiscount(item)" />
                    </div>
                  </v-col>
                </v-row>

                <v-row v-if="isLord && item?.main?.length" no-gutters class="ml-4">
                  <v-col cols="12">
                    <v-expand-transition>
                      <div v-if="expanded[index]">
                        <csm-product-sub-discount :ref="`sub-product-discount-${item.id}`" dense :product="item" :customer="item.code" :readonly="!canUpdateDiscounts" @change="changeSubDiscount" hide-checkbox />
                      </div>
                    </v-expand-transition>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-hover>
          </v-scroll-y-reverse-transition>
        </v-row>
      </v-tab-item>

      <v-tab-item value="tab-details" eager>
        <v-row>
          <v-col cols="12" :md="isLord ? 6 : 12">
            <cs-wysiwyg-textarea v-model="internalCart.description" :placeholder="$t('Details')" :title="$t('Add some important informations about your quotation...')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" @input="refreshPartial('description')" />
          </v-col>

          <v-col v-if="isLord" cols="12" md="6">
            <cs-wysiwyg-textarea v-model="internalCart.private_description" :placeholder="$t('Details')" :title="$t('Details (Scalair eye only)')" :actions="['bold', 'italic', 'underline', 'olist', 'ulist']" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" @input="refreshPartial('private_description')" />
          </v-col>
        </v-row>

        <v-sheet outlined class="pa-0">
          <div v-if="canCartAddAttachment">
            <cs-file-uploader v-model="attachments" use-isu @change="onChangeAttachments" />
          </div>

          <cs-attachments-list v-if="internalCart?.id" :id="internalCart.id" :value="internalCart.attachments" can-download :can-delete="canCartDelAttachment" @delete="delAttachments" />
        </v-sheet>
      </v-tab-item>

      <v-tab-item value="tab-contacts" eager>
        <v-row v-if="!company.blocked_payment">
          <v-col class="text-subtitle-1" cols="12">
            <v-icon class="mr-1">
              $vuetify.icon.email
            </v-icon>
            {{ $t('List of people to be notified by email about the progress of the order') }}
          </v-col>

          <v-col cols="12">
            <sca-users-select v-model="cart.users_alerts" clearable multiple show-company show-email show-phone show-card item-value="email" :filter-company="companiesForEmails" hide-details dense :placeholder="$t('Contacts')" />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-product-dialog ref="product-dialog" readonly />
    <csm-user-dialog ref="user-dialog" />
    <csm-product-duplicate-dialog ref="cart-product-duplicate-dialog" :code="customer" :in-cart="true" />
    <cs-confirm-dialog ref="confirm-cart-line-action" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'CartTabs',
  components: {
    'csm-product-duplicate-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductDuplicateDialog'),
    'csm-product-sub-discount': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductSubDiscount'),
    'csm-product-quantity-choice': () => import(/* webpackChunkName: "components" */ '@/components/Products/ProductQuantityChoice')
  },
  props: {
    cart: { type: Object, required: true },
    company: { type: Object, default: () => {} },
    emails: { type: Array, default: () => [] },
    enabled: { type: Boolean, default: false },
    orderingLines: { type: Boolean, default: false }
  },
  data () {
    return {
      DESCRIPTION_MAX_LENGTH: 4096 * 4,
      DESCRIPTION_MIN_LENGTH: 0,
      applyingCaption: false,
      applyingCustomerCaption: false,
      attachments: [],
      expanded: {},
      internalCart: null,
      isAdding: false,
      isDeleting: false,
      isStateChangingProductId: null,
      loading: false,
      quotationTabs: 'tab-product-list',
      working: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    customer () { return this.internalCart?.code || this.me.company },
    canCartAddAttachment () {
      return this.isLord && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE)
    },
    canCartDelAttachment () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE)
    },
    canSave () {
      return (
        !this.internalCart.expired ||
         this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
      ) && (
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CREATE) ||
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE)
      )
    },
    canReadPrices () {
      return !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST_NO_PRICE)
    },
    canUpdateDiscounts () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE_DISCOUNT_PERCENT)
    },
    cartLines () { return _.sortBy(this.internalCart?.products || [], 'index_order') },
    companiesForEmails () {
      const companies = [this.customer, this.me.company]
      if (this.me?.customers) companies.concat(this.me?.customers)
      return companies
    }
  },
  watch: {
    cart: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue)) {
          this.setCart(newValue)
        }
      }
    }
  },
  methods: {
    async addProductToCart ({ product, main, quantity }) {
      // Override default product from catalog with user inputs, if any
      product.main = main || null
      this.isAdding = true
      try {
        let cart

        if (!this.internalCart.id) {
          // New cart must be saved first
          const { cart: newCart } = await this.$store.dispatch('$alto-quotations/save', { cart: this.internalCart })
          cart = newCart
        } else cart = this.internalCart

        // add line
        const newProduct = { ...product, quantity }
        this.internalCart = { ...await this.$store.dispatch('$alto-quotations/addProduct', { id: cart.id, product: newProduct }) }
        this.$emit('refresh', this.internalCart)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isAdding = false }, 250)
      }
    },
    applyCaption: _.debounce(function (product) {
      this.applyingCaption = true
      this.$store.dispatch('$alto-orders/updateSubscription', { id: product.id, caption: product.caption || '' })
        .then(response => {
          this.$stratus.services.notify.success(this.$t('Quotation updated.'))
        })
        .catch(error => this.$stratus.services.notify.error(error))
        .finally(() => {
          setTimeout(() => { this.applyingCaption = false }, 250)
        })
    }, 500),
    applyCustomDiscount: _.debounce(function (product) {
      product.custom_discounts = true
      this.updateProduct(product, true) // refresh line of the product
    }, 500),
    applyCustomerCaption: _.debounce(function (product) {
      this.applyingCustomerCaption = true
      this.$store.dispatch('$alto-orders/updateSubscription', { id: product.id, customer_caption: product.customer_caption || '' })
        .then(response => {
          this.$stratus.services.notify.success(this.$t('Order updated.'))
        })
        .catch(error => this.$stratus.services.notify.error(error))
        .finally(() => {
          setTimeout(() => { this.applyingCustomerCaption = false }, 250)
        })
    }, 500),
    applyQuantity: _.debounce(function (product) {
      this.updateProduct(product)
    }, 500),
    applySphere: _.debounce(function (product) {
      this.updateProduct(product)
    }, 500),
    applyCommitment: _.debounce(function (product) {
      this.updateProduct(product)
    }, 500),
    canChangeQuantity (item) {
      return this.isLord || !this.productHasNoPrice(item.ref)
    },
    captionChange (item) {
      this.countProductsNeedCaption()
      if (this.applyCaption) this.applyCaption(item)
    },
    captionRules (item) {
      const rules = [this.$stratus.services.form.rules.max(128)]
      if (this.refNeedCaption(item.ref)) {
        rules.push(this.$stratus.services.form.rules.min(1))
      }
      return rules
    },
    changeCartFile (files) {
      const currentCart = this.$store.getters['$alto-quotations/current']
      try {
        this.$store.commit('$alto-quotations/setCurrent', { ...currentCart, attachments: files }) // Modify files only
      } catch (error) {
        console.error(error)
      }
    },
    changeSubDiscount (product) {
      const index = _.findIndex(this.internalCart.products, { id: product.id })
      if (index < 0) {
        console.error('[discount] sub-product not found in cart:', product.id, product.name)
        return
      }

      this.internalCart.products[index] = { ...product } // Force UI update
      this.updateProduct(this.internalCart.products[index])
    },
    countProductsNeedCaption () {
      if (!this.internalCart.products || !this.internalCart.products.length) {
        this.productsNeedCaption = 0
        return
      }
      let count = 0
      let p
      this.internalCart.products.forEach(product => {
        p = this.$store.getters['$alto-catalog/get'](product.ref)
        if (p && p.need_caption && !product.caption) count++
      })
      this.productsNeedCaption = count
    },
    async delAttachments ({ id, attachment }) {
      if (!id || !attachment) return

      this.isDeleting = true
      try {
        const newCart = await this.$store.dispatch('$alto-quotations/delAttachment', { id, file: attachment })
        this.$store.commit('$alto-quotations/setCurrent', newCart)
        this.setCart(newCart)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isDeleting = false }, 250)
      }
    },
    async deleteProduct (index) {
      try {
        const product = this.internalCart.products[index]
        if (product?.id) {
          this.internalCart = { ...await this.$store.dispatch('$alto-quotations/delLine', product.id) }
          this.$emit('refresh', this.internalCart)
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    duplicateProduct (product) {
      this.$refs['cart-product-duplicate-dialog'].open(_.cloneDeep(product), { update: false, customer: this.customer })
        .then(({ product, main, quantity }) => {
          if (product) this.addProductToCart({ product, main, quantity })
        })
    },
    expandedToggle (line) {
      this.$set(this.expanded, line, !this.expanded[line])
    },
    getCart () {
      this.$emit('refresh', this.internalCart)
    },
    getProductByRef (ref) {
      return this.$store.getters['$alto-catalog/get'](ref) || {}
    },
    async modifyProduct (index, product) {
      const newProduct = _.cloneDeep(product)
      try {
        const { product, main, quantity } = await this.$refs['cart-product-duplicate-dialog'].open(newProduct, { update: true, customer: this.customer })
        if (product) {
          newProduct.caption = product.caption
          newProduct.customer_caption = product.customer_caption
          newProduct.main = _.cloneDeep(main)
          newProduct.quantity = quantity
          if (this.productHasNoPrice(product.ref)) {
            newProduct.price = product.price
            newProduct.buying_price = product.buying_price
          }
          await this.updateProduct(newProduct)
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    onChangeAttachments (files) {
      this.$emit('change-attachments', files)
    },
    async onChangeCustomDiscounts (item, value) {
      if (!value) {
        // User want to apply customer discounts, this will reset them.
        const confirmed = await this.$refs['confirm-cart-line-action'].open(this.$t('Confirm'), this.$t('Can you confirm the reset of the discounts for ‘{name}’?', { name: item.name }))
        if (!confirmed) {
          item.custom_discounts = true // Revert state of checkbox
          return
        }

        try {
          this.setWorking(true)
          const newCart = await this.$store.dispatch('$alto-quotations/resetLineDiscounts', item.id)
          this.$store.commit('$alto-quotations/setCurrent', newCart)
          this.setCart(newCart)
        } catch (error) {
          this.$stratus.services.notify.error(error)
        } finally {
          this.setWorking(false)
        }
      }
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    productHasNoPrice (ref) {
      return this.getProductByRef(ref)?.no_price
    },
    productIsPackage (ref) {
      return this.getProductByRef(ref)?.package
    },
    refNeedCaption (ref) {
      const p = this.$store.getters['$alto-catalog/get'](ref)
      return p && p.need_caption
    },
    refreshPartial: _.debounce(function (fields) {
      this.$emit('refresh-partial', { cart: this.internalCart, fields })
    }, 500),
    refreshLine (product) {
      const index = _.findIndex(this.internalCart.products, { id: product.id })
      if (index >= 0) {
        this.internalCart.products.splice(index, 1, _.cloneDeep(product))
      }
    },
    setCart (cart) {
      this.internalCart = { ...cart } // Get a copy
      this.countProductsNeedCaption()
    },
    async updateProduct (product, refreshLine) {
      if (!this.enabled) return

      try {
        this.setWorking(true)
        const cart = { ...await this.$store.dispatch('$alto-quotations/updateLine', { id: product.id, product }) }
        // Update product in cart
        if (cart && refreshLine) {
          const updatedProduct = _.find(cart.products, { id: product.id })
          if (updatedProduct) this.refreshLine(updatedProduct)
        }
        // Carefully update the fields that were changed by this update of the product
        this.$emit('refresh-partial', { cart, fields: ['total', 'total_discount', 'total_discount_incl_vat', 'total_setup', 'total_setup_discount', 'total_setup_discount_incl_vat', 'margin', 'margin_setup', 'per_margin', 'per_margin_setup'] })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.setWorking(false)
      }
    },
    setWorking (working) {
      this.working = working
      this.$emit('working', working)
    }
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    this.countProductsNeedCaption()
  }
}
</script>
