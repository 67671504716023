<template>
  <sca-modal-dialog :visible="visible" @closeDialog="close" :is-saving="isSaving" max-width="800px">
    <span slot="title">
      <span class="text-h6">
        {{ title }}
      </span>
    </span>

    <span slot="buttons-header">
      <span class="text-caption pl-2">
        {{ product.name }}
      </span>
    </span>

    <div slot="content">
      <v-form ref="product-tech-form" v-model="valid" lazy-validation>
        <v-row v-if="techForms && techForms.length > 0" align="start" justify="start" no-gutters>
          <v-col v-for="form in techForms" :key="form.index" cols="12">
            <v-card outlined class="ma-2">
              <cs-expand-panel block class="pa-2" expanded>
                <v-row slot="header" align="center" no-gutters>
                  <v-col class="text-h5 text-shrink text-truncate">
                    {{ form.title }}
                  </v-col>

                  <v-col v-if="form.questions" class="shrink">
                    <v-chip x-small label :class="form.questions.length === 0 ? 'danger white--text' : ''">
                      {{ $tc('{count} questions', form.questions.length, { count: form.questions.length }) }}
                    </v-chip>
                  </v-col>

                  <v-col cols="12">
                    <div v-html="$stratus.services.strings.stripHtmlTags(form.description)" />
                  </v-col>
                </v-row>

                <div slot="content">
                  <sca-dyna-inputs v-if="form.questions && form.questions.length" show-hover show-index title="" :fields="form.questions" :data="getData(form.questions)" ipv4-null-for-zeroes @change="onChange(form, $event)" />
                </div>
              </cs-expand-panel>
            </v-card>
          </v-col>
        </v-row>
      </v-form>

      <cs-confirm-dialog ref="confirm-dialog" />
    </div>

    <template slot="buttons-prepend">
      <v-btn rounded :loading="isSaving" @click="askHelp(orderItem.id)">
        <v-icon left>
          $vuetify.icons.help
        </v-icon>
        {{ $t('Ask for help...') }}
      </v-btn>
    </template>

    <template slot="buttons">
      <v-btn rounded :loading="isSaving" class="mr-1 main-button" @click="submitResponses(orderItem.id, true)">
        {{ $t('Send technical information') }}
      </v-btn>

      <v-btn rounded :loading="isSaving" @click="submitResponses(orderItem.id, false)">
        {{ $t('Save') }}
      </v-btn>
    </template>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

// Errors that can have more details
const TECH_FORM_ERRORS = ['ORD-013', 'ORD-015']

export default {
  name: 'TechFormDialog',
  data () {
    return {
      isSaving: false,
      orderId: null,
      orderItem: {},
      originalValues: {},
      product: {},
      serialized: {},
      techForms: {},
      update: false,
      valid: false,
      visible: false
    }
  },
  computed: {
    title () {
      let title = ''
      if (this.orderItem.caption) {
        title = this.orderItem.caption
      }
      if (this.orderItem.customer_caption) {
        title = this.orderItem.customer_caption + ' ' + title
      }
      if (title) title += ' — '
      title += this.$t('Tech form')
      return title
    }
  },
  methods: {
    askHelp (lineId) {
      this.$refs['confirm-dialog'].open(this.$t('Ask for help...'), this.$t('This will send a ticket to SCALAIR support. Do you confirm?'))
        .then(async confirmed => {
          if (confirmed) {
            try {
              const result = await this.$store.dispatch('$alto-orders/submitProductTechForm', { lineId, needHelp: true })
              this.$stratus.services.notify.success(result)
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
            this.isSaving = false
            this.visible = false
            this.$emit('closeDialog')
          }
        })
    },
    close (form) {
      this.isSaving = false
      this.visible = false
      this.$emit('closeDialog')
    },
    getDefaultFromType (type) {
      return this.$alto.defines.FIELDS.DEFAULTS[type]
    },
    getData (questions) {
      const data = {}
      _.forEach(questions, (question, index) => {
        if (question.type === this.$alto.defines.FIELDS.FIELD_TYPE_ENUM) {
          data[question.name] = {
            value: question.value !== undefined ? question.value : question.default,
            enumList: question.enum_values
          }
        } else {
          data[question.name] = question.value !== undefined ? question.value : question.default
        }
      })
      return data
    },
    onChange (form, { field, value, serialized } = {}) {
      this.serialized[form.index] = serialized
    },
    open ({ create = false, update = false, orderId, orderItem, product } = {}) {
      this.orderId = orderId
      this.orderItem = { ...orderItem }
      this.product = { ...product }
      // Keep original values for all forms
      this.originalValues = {}
      this.techForms = this.orderItem.tech_forms || this.product.tech_forms
      _.forEach(this.techForms, form => {
        this.originalValues[form.index] = this.getData(form.questions)
      })
      this.update = update
      this.visible = true
    },
    async submitResponses (lineId, validate) {
      this.isSaving = true
      const techForms = []
      try {
        _.forEach(this.techForms, form => {
          const questions = []
          _.forEach(form.questions, question => {
            const value = this.serialized?.[form.index]?.[question.name] || question.value
            if (question.required && value === undefined) {
              throw new Error(this.$t('Question « {name} » requires a response!', { name: question.name }))
            }
            questions.push({
              index: question.index,
              value: value || this.getDefaultFromType(question.type)
            })
          })
          techForms.push({ index: form.index, questions })
        })
        await this.$store.dispatch('$alto-orders/submitProductTechForm', { lineId, needHelp: false, validate, techForms })
        this.close(true)
      } catch (response) {
        if (response.status === 400 && TECH_FORM_ERRORS.includes(response.body.code)) {
          let errorMessage = ''
          _.forEach(response.body.error?.errors, err => {
            _.forEach(err.details, detail => {
              if (this.techForms[detail.id_info]) {
                errorMessage += this.$t('Error for the response to « {question} »', { question: this.techForms[detail.id_info].questions[detail.id_question].name }) + '<br>'
              }
            })
          })
          this.$stratus.services.notify.error(errorMessage || response)
        } else this.$stratus.services.notify.error(response)
      } finally {
        this.isSaving = false
      }
    }
  }
}
</script>
