<template>
  <div v-if="ticket" class="full-width">
    <div v-for="link in otherLinks" :key="link" class="d-flex" :class="inline ? 'flex-column' : 'flex-row'">
      <div v-if="!hideLabel" class="text-subtitle-1 w-150px">
        {{ $t(`ticket-link-${link}`) }}
        <v-chip small>
          {{ ticket.ids_intern[link]?.length || $t('None') }}
        </v-chip>
      </div>

      <div v-if="link === 'carts'">
        <sca-quotation-identity v-for="(quotation, index) in ticket.ids_intern[link]" :key="`quotation-${index}`" class="pr-4" :value="quotation" show-avatar link="emit" @link-click="openQuotation(quotation)" />
      </div>

      <div v-if="link === 'opportunities'">
        <sca-opportunity-identity v-for="(opportunity, index) in ticket.ids_intern[link]" :key="`opportunity-${index}`" class="pr-4" :value="opportunity" :label="opportunity" show-avatar link="emit" @link-click="openOpportunity(opportunity)" />
      </div>

      <div v-if="link === 'orders'">
        <sca-order-identity v-for="(order, index) in ticket.ids_intern[link]" :key="`order-${index}`" class="pr-4" :value="order" :label="order" show-avatar link="emit" @link-click="openOrder(order)" />
      </div>

      <div v-if="link === 'onelines'" class="d-flex align-center">
        <sca-subscription-identity v-for="(subscription, index) in ticket.ids_intern[link]" :key="`subscription-${index}`" class="pr-4" :value="subscription" :label="subscription" link="emit" @link-click="openSubscription(subscription)" />
      </div>

      <div v-if="link === 'products'">
        <sca-product-identity v-for="(product, index) in ticket.ids_intern[link]" :key="`product-${index}`" class="pr-4" :value="product" :company="ticket.code" :label="product" link="emit" @link-click="openProduct(product)" />
      </div>
    </div>

    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-order-dialog ref="order-dialog" />
    <csm-product-dialog ref="product-dialog" readonly />
    <csm-cart-dialog ref="cart-dialog" />
    <csm-subscription-dialog ref="subscription-dialog" />
  </div>
</template>

<script>
export default {
  name: 'TicketLinks',
  props: {
    hideLabel: { type: Boolean, default: false },
    inline: { type: Boolean, default: false },
    ticket: { type: Object, required: true, default: null }
  },
  data () {
    return {}
  },
  computed: {
    otherLinks () {
      return this.ticket?.ids_intern ? Object.keys(this.ticket.ids_intern) || [] : []
    }
  },
  methods: {
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openOrder (id) {
      if (this.$refs['order-dialog']) this.$refs['order-dialog'].open(id)
    },
    openQuotation (id) {
      if (this.$refs['cart-dialog']) this.$refs['cart-dialog'].open(id)
    },
    openProduct (id) {
      if (this.$refs['product-dialog']) this.$refs['product-dialog'].openId(id)
    },
    openSubscription (id) {
      if (this.$refs['subscription-dialog']) this.$refs['subscription-dialog'].open(id)
    }
  }
}
</script>
