<template>
  <div>
    <v-tabs v-model="tab" class="transparent" hide-slider>
      <v-tab v-show="canShowDashboard" href="#tab-dashboard">
        <v-icon left small>
          $vuetify.icons.dashboardMarketing
        </v-icon>
        {{ $t('Marketing Dashboard') }}
      </v-tab>

      <v-tab href="#tab-events">
        <v-icon left small>
          $vuetify.icons.marketingEvent
        </v-icon>
        {{ $t('Campaigns') }}
      </v-tab>

      <v-tab href="#tab-leads">
        <v-icon left small>
          $vuetify.icons.leads
        </v-icon>
        {{ $t('Leads') }}
      </v-tab>

      <v-tab href="#tab-contacts">
        <v-icon left small>
          $vuetify.icons.contact
        </v-icon>
        {{ $t('Contacts') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-if="canShowDashboard" value="tab-dashboard">
        <csm-marketing-dashboard ref="marketing-dashboard" />
      </v-tab-item>

      <v-tab-item value="tab-events">
        <sca-advanced-store-grid :options="optionsEvent" resource="opportunities/marketing/events" :columns="columnsEvent" @createItem="createEvent" @deleteItem="refresh" @updateItem="updateEvent" ref="marketing-event-grid">
          <template v-slot:item-id_parent="{ itemRaw, itemInternal }">
            <sca-marketing-event-identity v-if="itemRaw" :value="itemInternal || itemRaw" show-avatar link="emit" @link-click="openEvent(itemRaw)" />
          </template>
        </sca-advanced-store-grid>
      </v-tab-item>

      <v-tab-item value="tab-leads">
        <csm-marketing-lead-grid ref="marketing-lead-grid" />
      </v-tab-item>

      <v-tab-item value="tab-contacts">
        <csm-marketing-lead-grid ref="marketing-contact-grid" contact />
      </v-tab-item>
    </v-tabs-items>

    <csm-marketing-event-dialog ref="marketing-event-dialog" @closeDialog="closeEventDialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'OpportunitiesMarketingDashboard',
  components: {
    'csm-marketing-lead-grid': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingLeadGrid'),
    'csm-marketing-dashboard': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingDashboard'),
    'csm-marketing-event-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingEventDialog')
  },
  data () {
    return {
      eventTypeSearch: '',
      loading: false,
      tab: 'tab-dashboard'
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      eventTypesList: '$alto-marketing/eventTypesList'
    }),
    canShowDashboard () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_PRE_SALE_DASHBOARD)
    },
    columnsEvent () {
      return [{
        value: 'id',
        text: 'Id'
      }, {
        value: 'name',
        text: 'Name'
      }, {
        value: 'type',
        text: 'Type',
        format: v => this.$t(`marketing-event-type-${v}`)
      }, {
        value: 'date_begin',
        text: 'Begin date',
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : this.$t('None')
      }, {
        value: 'date_end',
        text: 'End date',
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : this.$t('None')
      }, {
        value: 'turnover_target',
        text: 'Turnover target',
        align: 'right',
        alignValue: 'right',
        format: v => this.$n(v, 'currency')
      }, {
        value: 'turnover_generated',
        text: 'Turnover generated',
        align: 'right',
        alignValue: 'right',
        format: v => this.$n(v, 'currency')
      }, {
        value: 'id_parent',
        text: 'Parent',
        internalKey: 'id' // id_parent is to be found in same data as column internalKey
      }, {
        value: 'create_at',
        text: 'Created at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
      }, {
        value: 'update_at',
        text: 'Updated at',
        format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
      }, {
        value: 'create_by',
        text: 'Created by',
        hidden: true
      }, {
        value: 'update_by',
        text: 'Updated by',
        hidden: true
      }]
    },
    eventTypeList () {
      return this.$store.getters['$alto-marketing/eventTypesList']
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    optionsEvent () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/opportunities',
        advancedSearchFieldsSchemaName: 'MarketingEvent',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: true,
        createInFab: false,
        customShowItem: true,
        customUpdateItem: true,
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: true,
        key: 'id',
        multiSelect: false,
        notifyErrors: false,
        show: true,
        sortBy: 'update_at',
        sortDescending: true,
        update: true
      }
    }
  },
  methods: {
    closeEventDialog () {
      this.refresh()
    },
    createEvent () {
      this.$refs['marketing-event-dialog'].open()
    },
    openEvent (id) {
      this.updateEvent({ item: { id }, success: true })
    },
    refresh () {
      if (this.$refs['marketing-event-grid']) this.$refs['marketing-event-grid'].fetchData()
      if (this.$refs['marketing-dashboard']) this.$refs['marketing-dashboard'].refresh()
      this.refreshLeads()
    },
    refreshLeads: _.debounce(function () {
      if (this.$refs['marketing-lead-grid']) this.$refs['marketing-lead-grid'].refresh()
      if (this.$refs['marketing-contact-grid']) this.$refs['marketing-contact-grid'].refresh()
    }, 500),
    updateEvent ({ item, success, error }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['marketing-event-dialog'].open(item.id)
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Marketing Dashboard'))
  },
  mounted () {
    setTimeout(() => {
      if (this.canShowDashboard) this.tab = 'tab-dashboard'
      else this.tab = 'tab-events'
    }, 500)
  }
}
</script>
