<template>
  <sca-modal-dialog v-if="internalCart" :visible="visible" @closeDialog="closeDialog" @saveDialog="saveDialog(true, $event)" :can-save="canSave && !isOrderingLines" :is-saving="saving" :action="action" :dirty="isDirty()" :hide-buttons-header="isOrderingLines" :loading="!enabled || isLoading || sendingOrder" :external-id="externalId" :max-width="$vuetify.breakpoint.lgAndUp ? '90%' : ''">
    <template #title>
      <div class="d-flex align-center">
        <div v-if="internalCart.id">
          {{ $t('#') }} {{ internalCart.id }}
        </div>
        <div v-if="internalCart.name" class="text-h6 mx-2">
          — {{ internalCart.name }}
        </div>
        <cs-icon-isLoading v-if="isLoading" />
      </div>
    </template>

    <div slot="subtitle" class="d-flex align-center">
      <div v-if="!canCartUpdateEstimatedDate" class="d-flex align-center highlighted-panel mx-2">
        {{ $t('Estimated delivery time') }}
        <v-icon small left right>
          $vuetify.icons.for
        </v-icon>

        <cs-helper>
          <div slot="content" label small class="font-weight-bold">
            {{ $stratus.dt(internalCart.estimated_date).format('ll') }}
          </div>
          <div slot="help">
            {{ $t('The estimated delivery date is calculated from the day the quotation is signed. The sooner the quotation is signed, the sooner the project can be delivered.') }}

            <div class="mt-2">
              <span>
                {{ $t('Start-up time') }}
                <v-icon small left right>
                  $vuetify.icons.for
                </v-icon>
                {{ $t('{count}{unit}', { count: internalCart.nb_days_begin_delay, unit: $t('days')}) }}
              </span>
              <span class="text-caption pl-4">
                {{ $t('The build load reflects the current load of the team that will be in charge of your project, the start of your project should take place after this deadline.') }}
              </span>
            </div>

            <div class="mt-2">
              <span>
                {{ $t('Time to production') }}
                <v-icon small left right>
                  $vuetify.icons.for
                </v-icon>
                {{ $t('{count}{unit}', { count: internalCart.nb_days_building_time, unit: $t('days')}) }}
              </span>
              <span class="text-caption pl-4">
                {{ $t('Estimate the duration of your project, the time between the start date and the delivery date.') }}
              </span>
            </div>
          </div>
        </cs-helper>
      </div>

      <div v-if="$stratus.dt(internalCart.expiration_date).isValid()" class="mx-2 d-flex align-center" :class="isExpired ? 'danger-panel' : 'highlighted-panel'">
        <div class="px-1">
          {{ isExpired ?
            $t('This quotation expires on {date}.', { date: $stratus.dt(internalCart.expiration_date).format('LL') }) :
            $t('Expires on {date}', { date: $stratus.dt(internalCart.expiration_date).format('LL') })
          }}
        </div>
        <v-btn v-if="isExpired && canCartUpdateExpirationDate" class="text-caption background--text ml-2" x-small rounded outlined @click="reactivateCart">
          {{ $t('Reactivate') }}
        </v-btn>
      </div>
    </div>

    <div slot="buttons-header" class="d-flex align-center">
      <cs-action-button v-if="canCartDelete" icon="$vuetify.icons.delete" help="Delete" color="danger" @click="deleteCart" />

      <div class="action-button-separator" />

      <cs-action-button v-if="internalCart.id && canSave && canChangeLineOrder && cartLines?.length > 1" icon="$vuetify.icons.reorder_row" help="Change order of lines" @click="onOrderingLines" />

      <csm-cart-invoice-download v-show="internalCart.id" action-button :cart="internalCart" :name="internalCart.name" format="pdf" />

      <cs-action-menu v-if="internalCart.id" icon="$vuetify.icons.sphere" help="Modify spheres">
        <v-card slot="menu" tile class="pa-2" width="400">
          <div class="font-weight-bold">
            {{ $t('Modify the sphere of all lines') }}
          </div>

          <div class="d-flex">
            <cs-helper>
              <sca-sphere-select slot="content" v-model="globalSphere" :placeholder="$t('Sphere')" :customer="internalCart.code" dense :readonly="!canSave" allow-empty />
              <div slot="help">
                {{ $t('The sphere of the quotation will be applied to all its lines.') }}
              </div>
            </cs-helper>

            <div class="ml-auto">
              <v-btn small rounded :loading="saving" @click="applyGlobalSphere">
                {{ $t('Apply') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </cs-action-menu>

      <cs-action-button v-if="canCartUpdateDiscounts" icon="$vuetify.icons.discount" help="Apply customer discounts" @click="applyCustomerDiscounts" />

      <div class="action-button-separator" />

      <cs-action-button v-if="canSave" icon="$vuetify.icons.clone" help="Clone" @click="cloneCart" />
    </div>

    <div slot="content-fixed" class="pb-2">
      <cs-alert-panel v-if="isOrderingLines">
        <template #content>
          <div>
            <v-icon class="background--text" left>
              $vuetify.icons.info
            </v-icon>
            <span class="text-body-1">{{ $t('Move lines according to your needs. Then click on « Save order of lines » to return to full view.') }}</span>
          </div>
          <div class="ml-auto">
            <v-btn rounded small outlined class="background--text" @click="saveOrderingLines">
              <v-icon class="background--text" left>
                $vuetify.icons.reorder_row
              </v-icon>
              {{ $t('Save order of lines') }}
            </v-btn>
          </div>
        </template>
      </cs-alert-panel>

      <v-row v-else justify="center">
        <v-col v-if="!canCompanyOrder || (isLord && isForeignCompany) || hasDeposit" class="shrink text-no-wrap">
          <cs-alert-panel type="info" dense>
            <template #content>
              <v-icon small class="background--text" left>
                $vuetify.icons.warning
              </v-icon>

              <div>
                <div v-if="!canCompanyOrder" class="pb-1">
                  {{ isLord ? $t('Impossible to place an order for a non-customer company.') : $t('Unable to order for this company, please contact Scalair.') }}
                  <v-btn v-show="isLord && companyProps.code" x-small rounded outlined class="text-caption background--text ml-2" @click="companyIsVisible = true">
                    {{ $t('Modify company {name} — {code}', companyProps) }}
                  </v-btn>
                </div>

                <div v-if="isLord && isForeignCompany" class="pb-1">
                  {{ $t('This is a foreign customer. Contact the accounting department for invoicing.') }}
                </div>

                <div v-if="hasDeposit" class="pb-1">
                  {{ $t('A deposit will be required before delivering your order.') }}
                </div>
              </div>
            </template>
          </cs-alert-panel>
        </v-col>
      </v-row>
    </div>

    <div slot="content">
      <v-expand-transition>
        <v-row v-show="!isOrderingLines" no-gutters>
          <v-col cols="12" md="6" lg="8">
            <v-row align="start" dense>
              <v-col cols="12" md="">
                <v-text-field v-model="internalCart.name" :placeholder="$t('Name')" counter="64" :rules="[$stratus.services.form.rules.required, $stratus.services.form.rules.min(4), $stratus.services.form.rules.max(64)]" class="required" dense append-outer-icon="$vuetify.icons.refresh" @click:append-outer="createCartName" :readonly="!canSave" />
              </v-col>

              <v-col cols="12" md="4">
                <sca-customer-select v-model="internalCart.code" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" @change="onChangeCompany" :readonly="!canSave" dense link="emit" @link-click="openCompany(internalCart.code)" />
              </v-col>
            </v-row>

            <v-row align="start" dense>
              <v-col cols="12" md="6" lg="4">
                <div v-if="internalCart.id_lord_pre_sale || isLord" class="d-flex align-center">
                  <sca-users-select v-if="isLord" v-model="internalCart.id_lord_pre_sale" allow-empty clearable filter-lord show-card single-line dense hide-details link="emit" @link-click="openUser(internalCart.id_lord_pre_sale)" :placeholder="$t('Pre-sale')" />

                  <sca-user-identity v-else-if="internalCart.id_lord_pre_sale" :value="internalCart.id_lord_pre_sale" :label="$t('Pre-sale')" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(internalCart.id_lord_pre_sale)" />
                </div>

                <sca-tickets-select v-if="isLord" v-model="preSaleTicket" :label="$t('Ticket')" :types="TICKET_TYPE_PRE_SALE" :states="[TICKET_STATE_CLOSED, TICKET_STATE_DONE, TICKET_STATE_NO_GO]" exclude-states :company="internalCart.code" class="mt-4 pt-1 mr-2 full-width" :include="[preSaleTicket]" disable-include clearable hide-details dense show-card show-company :disabled="!canSave" />
              </v-col>

              <v-col v-if="canCartUpdateEstimatedDate" cols="12" md="6" lg="8">
                <v-row dense>
                  <v-col cols="12" lg="7" class="pl-4">
                    <v-row no-gutters align="center">
                      <v-col cols="12" md="7">
                        <cs-helper prepend-help>
                          <template #content>
                            {{ $t('Start-up time') }}
                          </template>
                          <div slot="help">
                            {{ $t('The build load reflects the current load of the team that will be in charge of your project, the start of your project should take place after this deadline.') }}
                          </div>
                        </cs-helper>
                      </v-col>
                      <v-col>
                        <cs-decimal-input v-model="internalCart.nb_days_begin_delay" :suffix="$t('days')" :min="0" x-small :disabled="!canCartUpdateEstimatedDate" class="ml-2" @input="reload" />
                      </v-col>
                    </v-row>

                    <v-row no-gutters align="center">
                      <v-col cols="12" md="7">
                        <cs-helper prepend-help>
                          <template #content>
                            {{ $t('Time to production') }}
                          </template>
                          <div slot="help">
                            {{ $t('Estimate the duration of your project, the time between the start date and the delivery date.') }}
                          </div>
                        </cs-helper>
                      </v-col>
                      <v-col>
                        <cs-decimal-input v-model="internalCart.nb_days_building_time" :suffix="$t('days')" :min="0" allow-empty x-small :disabled="!canCartUpdateEstimatedDate" class="ml-2" @input="reload" />
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" lg="5" class="text-no-wrap d-flex align-center">
                    <v-icon left right>
                      $vuetify.icons.for
                    </v-icon>
                    <div class="text-center">
                      <div>
                        <cs-helper prepend-help>
                          <template #content>
                            {{ $t('Estimated delivery time') }}
                          </template>
                          <div slot="help">
                            {{ $t('The estimated delivery date is calculated from the day the quotation is signed. The sooner the quotation is signed, the sooner the project can be delivered.') }}
                          </div>
                        </cs-helper>
                      </div>
                      <span v-if="internalCart.nb_days_begin_delay + internalCart.nb_days_building_time > 0" class="text-h5 font-weight-bold text-no-wrap">
                        {{ computedEstimatedDate.format('ll') }}
                      </span>
                      <span v-else>
                        {{ $t('None') }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row align="start" dense>
              <v-col cols="12" md="4" lg="2" class="align-self-start ml-2">
                <cs-percent-input v-if="isLord" v-model="internalCart.per_partial_billing" :label="$t('Deposit percentage')" :max="100" :min="0" small :disabled="!canSave || !isLord" @input="applyPartialBilling" />
                <div v-else-if="internalCart.per_partial_billing > 0" class="d-flex align-center">
                  <div class="font-weight-bold">
                    {{ $t('Deposit percentage') }}
                  </div>
                  <v-icon small left right>
                    $vuetify.icons.for
                  </v-icon>
                  {{ $t('{percent}%', { percent: internalCart.per_partial_billing }) }}
                </div>
              </v-col>

              <v-col v-if="isLord" cols="12" md="4" lg="3">
                <cs-date-picker v-model="internalCart.potential_closing_date" allow-empty :label="$t('Potential closing date')" :disabled="!canCartUpdateClosing" />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6" lg="4" class="pl-8">
            <sca-product-prices-grid :prices="internalCart" dense :title="$t('Total')" :sub-title="$tc('No product|One product|{count} products', quantity, { count: quantity })" :company="customer" :show-margin="isLord" :working="working" outlined />

            <div v-if="isLord && opportunityId" class="mt-4 ">
              <sca-opportunity-identity :value="opportunityId" :label="opportunityId" show-avatar link="emit" @link-click="openOpportunity(opportunityId)" />
            </div>
          </v-col>

          <v-col v-if="needDelayDays || needDetailInput" cols="12">
            <v-row no-gutters class="mb-4">
              <v-col class="shrink text-no-wrap">
                <cs-alert-panel type="warning">
                  <div slot="content" class="d-flex align-center justify-center">
                    <v-icon left color="color0">
                      $vuetify.icons.warning
                    </v-icon>

                    <div class="pl-1 pr-8">
                      {{ $t('The quotation cannot be validated because certain information is missing concerning:') }}
                      <div v-if="needDetailInput" class="font-weight-bold">
                        - {{ $t('Order details') }}
                      </div>
                      <div v-if="needDelayDays" class="font-weight-bold">
                        - {{ $t('Information on the delivery date of the order') }}
                      </div>
                    </div>

                    <sca-sales-manager-message v-if="canSave" page="quotation" :light="isDark" outlined small button-close button-cls="text-ink background--text" />
                  </div>
                </cs-alert-panel>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="countProductReachEndOfLife > 0" cols="12">
            <v-row justify="center" no-gutters>
              <v-col class="shrink text-no-wrap">
                <cs-alert-panel type="warning" :text="$tc('This cart contains no product that reach end of life.|This cart contains one product that reach end of life.|This cart contains {count} products that reach end of life.', countProductReachEndOfLife, { count: countProductReachEndOfLife }) + ' — ' + $t('Please contact your sales manager at Scalair.')" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expand-transition>

      <csm-cart-lines-mover v-if="isOrderingLines" ref="cart-lines-mover" :cart="internalCart" />
      <csm-order-cart v-else ref="order-cart" :cart="internalCart" :company="companyProps" :emails="emailsCache" :enabled="enabled" :ordering-lines="isOrderingLines" @refresh="refresh" @refresh-partial="refreshPartial" @working="setWorking" @change-attachments="onChangeAttachments" />

      <csm-opportunity-dialog ref="opportunity-dialog" />
      <csm-company-dialog ref="company-dialog" />
      <csm-user-dialog ref="user-dialog" />
      <cs-confirm-dialog ref="confirm-cart-dialog" />
      <cs-generic-dialog ref="alerts-ordering-items-dialog">
        <div slot="content">
          <div v-for="(product, index) in alertsOrderingItems" :key="product.id">
            <v-divider v-if="index > 0" class="my-4" />

            <div class="d-flex align-center">
              <div class="font-weight-bold">
                {{ product.name }}
              </div>

              <div class="ml-4">
                <sca-product-identity :value="product.ref" :label="product.ref" :company="customer" show-avatar link="emit" @link-click="openProduct(product.ref)" />
              </div>
            </div>

            <div class="ml-6" v-html="product.warning_message_ordering" />
          </div>
        </div>
      </cs-generic-dialog>
    </div>

    <div v-if="!isOrderingLines" slot="buttons-prepend">
      <v-btn v-if="canCartOrder" rounded @click.native="sendOrder" class="primary-button" :loading="isLoading || sendingOrder" :disabled="saving || sendingOrder || needDelayDays || needDetailInput">
        {{ $t('Send order') }}
      </v-btn>
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  components: {
    'csm-order-cart': () => import(/* webpackChunkName: "components" */ '@/components/Carts/CartTabs'),
    'csm-cart-lines-mover': () => import(/* webpackChunkName: "components" */ '@/components/Carts/CartLinesMover'),
    'csm-cart-invoice-download': () => import(/* webpackChunkName: "components" */ '@/components/Carts/CartInvoiceDownload')
  },
  name: 'CartForm',
  props: {
    loading: { type: Boolean, default: false },
    update: { type: Boolean, default: false }
  },
  data () {
    return {
      QUOTATION_STATES_FULL: this.$alto.defines.QUOTATIONS.QUOTATION_STATES_FULL,
      QUOTATION_STATE_EXPIRED: this.$alto.defines.QUOTATIONS.QUOTATION_STATE_EXPIRED,
      TICKET_STATE_CANCELED: this.$alto.defines.TICKETS.TICKET_STATE_CANCELED,
      TICKET_STATE_CLOSED: this.$alto.defines.TICKETS.TICKET_STATE_CLOSED,
      TICKET_STATE_DONE: this.$alto.defines.TICKETS.TICKET_STATE_DONE,
      TICKET_STATE_NO_GO: this.$alto.defines.TICKETS.TICKET_STATE_NO_GO,
      TICKET_TYPE_PRE_SALE: this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE,
      alertsOrderingItems: [],
      attachments: [],
      companyProps: {
        mail_accounting: [],
        nic: '',
        siren: '',
        type: ''
      },
      emailsCache: [],
      enabled: false,
      globalSphere: null,
      internalCart: null,
      isForeignCompany: false,
      isLoading: false,
      isOrderingLines: false,
      sendingOrder: false,
      saving: false,
      visible: false,
      working: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canCartDelete () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_DELETE)
    },
    canCartOrder () {
      return this.canSave &&
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_ORDER) &&
        this.canCompanyOrder &&
        this.countProductReachEndOfLife === 0
    },
    canCartUpdateClosing () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE_CLOSING)
    },
    canCartUpdateEstimatedDate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.UPDATE_ESTIMATED)
    },
    canCartUpdateExpirationDate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
    },
    canChangeLineOrder () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_LINE_REORDER)
    },
    canCompanyOrder () {
      return this.companyProps?.type && this.$alto.defines.COMPANIES.TYPE_THAT_CAN_ORDER.includes(this.companyProps.type) && !this.companyProps?.blocked_payment
    },
    canCartUpdateDiscounts () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE_DISCOUNT_PERCENT)
    },
    cartLines () { return this.internalCart?.products || [] },
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE) && (
        this.isLord ||
        !this.internalCart.expired ||
        this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE_EXPIRED)
      )
    },
    computedEstimatedDate () {
      return this.$stratus.dt().add(this.internalCart.nb_days_begin_delay + this.internalCart.nb_days_building_time, 'days')
    },
    countProductReachEndOfLife () {
      let count = 0
      _.forEach(this.internalCart?.products, product => {
        const p = this.getProductByRef(product.ref)
        // Ensure simple product do not have attribut « main » in quotation
        count += Number(p?.end_subscription || false)
      })
      return count
    },
    customer () { return this.internalCart?.code || this.me.company },
    hasDeposit () {
      return (this.internalCart.per_partial_billing > 0 && this.internalCart.total_deposit && this.internalCart.total_deposit_incl_vat)
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isExpired () { return this.internalCart?.state === this.QUOTATION_STATE_EXPIRED },
    minExpirationDate () { return this.$stratus.dt().add(1, 'days').toDate() },
    needDelayDays () {
      return this.internalCart.has_service && (_.isNil(this.internalCart.nb_days_begin_delay) || _.isNil(this.internalCart.nb_days_building_time))
    },
    needDetailInput () {
      return !this.internalCart?.description || this.$stratus.services.strings.stripHtmlTags(this.internalCart.description, { ignoreTags: [] })?.length < 1
    },
    opportunityId: {
      get () {
        return this.internalCart?.ids_intern?.opportunities?.carts?.[0] || null
      },
      set (value) {
        if (this.internalCart.ids_intern?.opportunities) {
          this.internalCart.ids_intern.opportunities[0] = value
        }
      }
    },
    preSaleTicket: {
      get () {
        return this.internalCart?.ids_intern?.tickets?.['pre-sale']?.[0] || null
      },
      set (value) {
        if (this.internalCart.ids_intern?.tickets) {
          this.internalCart.ids_intern.tickets['pre-sale'][0] = value
        }
      }
    },
    prices () { return { ...this.internalCart } },
    quantity () {
      let quantity = 0
      _.forEach(this.internalCart.products, product => {
        quantity += product.quantity
      })
      return quantity
    }
  },
  methods: {
    async applyCustomerDiscounts () {
      const confirmed = await this.$refs['confirm-cart-dialog'].open(this.$t('Confirm'), this.$t('Can you confirm the reset of the discounts for all the lines in the quote?'))
      if (!confirmed) return

      try {
        this.saving = true
        const newCart = await this.$store.dispatch('$alto-quotations/resetDiscounts', this.internalCart.id)
        this.setCart(newCart)
        // this.resetDiscounts(this.internalCart.products)
        // this.$refs['order-cart'].
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.saving = false
      }
    },
    async applyGlobalSphere () {
      const sphere = await this.$store.dispatch('$alto-spheres/get', { id: this.globalSphere })
      const confirmed = await this.$refs['confirm-cart-dialog']
        .open(this.$t('Confirm'),
          this.globalSphere
            ? this.$t('Can you confirm that the sphere « {name} » applies to all the lines in this quote?', sphere)
            : this.$t('Can you confirm the removal of the sphere from all the lines in this quote?'),
          { color: 'danger' })
      if (!confirmed) return

      this.savePartialCart({ id_sphere: this.globalSphere || '' })
    },
    applyPartialBilling: _.debounce(function () {
      this.working = true
      this.savePartialCart({ per_partial_billing: this.internalCart.per_partial_billing })
      this.working = false
    }, 250),
    async cloneCart () {
      if (!this.internalCart.id) return

      try {
        if (this.isDirty()) {
          this.saveDialog(false, { notifySuccess: false, setCart: false })
        }

        this.saving = true
        const { cart } = await this.$store.dispatch('$alto-quotations/clone', { id: this.internalCart.id })
        this.setCart(cart)
        this.$stratus.services.notify.success(this.$t('Cart cloned.'))
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.saving = false
      }
    },
    async closeDialog (force, openOrder) {
      const confirmed = force || !this.isDirty() || await this.$refs['confirm-cart-dialog']
        .open(this.$t('Confirm'), this.$t('Do you confirm the loss of your modifications?'), { color: 'danger' })
      if (confirmed) { this.$emit('closeDialog', this.internalCart, openOrder) }
    },
    computeDiscount (total, discount) {
      return parseFloat(total) - (parseFloat(total) * (parseFloat(discount) * 0.01))
    },
    async createCartName () {
      if (this.canSave) {
        this.$set(this.internalCart, 'name', await this.$store.dispatch('$alto-quotations/createName'))

        if (!this.internalCart.id) return

        this.savePartialCart({ name: this.internalCart.name })
      }
    },
    deleteCart () {
      this.$refs['confirm-cart-dialog']
        .open(this.$t('Delete quotation'), this.$t('Confirm deletion of quotation «{name}»?', { name: this.internalCart.name }), { color: 'danger' })
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('$alto-quotations/delete', this.internalCart.id)
              .then(() => {
                this.$stratus.services.notify.success(this.$t('Quotation «{name}» deleted!', { name: this.internalCart.name }))
                // Cart is deleted. There's no more current one.
                if (this.$root['global-cart-menu']) {
                  this.$root['global-cart-menu'].clearCart()
                  this.$root['global-cart-menu'].refreshList()
                }
              })
              .then(() => {
                this.closeDialog(true)
              })
              .catch(error => this.$stratus.services.notify.error(error))
          }
        })
    },
    externalId () {
      return this.internalCart?.id ? [this.internalCart.id, 'quotations/' + this.internalCart.id, this.internalCart.name] : undefined
    },
    async getEmails () {
      let list = []
      try {
        const companies = [this.customer, this.me.company]
        if (this.me?.customers) companies.concat(this.me?.customers)

        list = await this.$store.dispatch('$alto-quotations/getUserEmails', _.uniq(companies))
      } catch (error) {
        console.error(error)
      }
      const emails = []
      _.forEach(list, user => {
        emails.push({
          text: user.lastname + ' ' + user.firstname + ' (' + user.email + ')',
          value: user.email
        })
      })

      this.emailsCache = this.$stratus.services.sort.natural(emails, { text: 'asc' })
    },
    getProductByRef (ref) {
      return this.$store.getters['$alto-catalog/get'](ref) || {}
    },
    isDirty () {
      return this.$store.getters['$alto-quotations/isDirty'](this.internalCart, { ignore: ['products'] })?.length > 0
    },
    isDirtyCount () {
      return this.$store.getters['$alto-quotations/isDirty'](this.internalCart, { ignore: ['products'] })?.length || 0
    },
    onChangeAttachments (files) {
      this.attachments = files
    },
    async onChangeCompany (customer) {
      if (!customer) return

      try {
        if (this.isDirtyCount() > 1) {
          this.saveDialog(false, { refreshList: false, setCart: false })
        }

        this.saving = true
        if (this.internalCart.id) {
          const newCart = await this.$store.dispatch('$alto-quotations/setCompany', { id: this.internalCart.id, code: customer })
          this.setCart(newCart)
        }
        this.resetDiscounts(this.internalCart.products)
        this.$nextTick(() => {
          if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList() // Company appears in cart menu
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.saving = false }, 250)
      }
    },
    onOrderingLines () {
      this.isOrderingLines = true
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async reactivateCart () {
      if (!this.internalCart?.id) return

      try {
        const cart = await this.$store.dispatch('$alto-quotations/reactivate', this.internalCart.id)
        this.setCart(cart)
        this.$nextTick(() => {
          if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    refresh (cart) {
      this.setCart(cart)
    },
    refreshPartial ({ cart, fields }) {
      // Update only given fields in cart
      const fieldList = Array.isArray(fields) ? fields : [fields]
      _.forEach(fieldList, field => {
        this.$set(this.internalCart, field, cart[field])
      })
    },
    reset (cart) {
      this.alertsOrderingItems = []
      this.attachments = []
      if (cart?.code !== this.internalCart?.code) {
        // This will be reloaded
        this.companyProps = {
          mail_accounting: [],
          nic: '',
          siren: '',
          type: ''
        }
        this.isForeignCompany = false
        this.emailsCache = []
      }
      this.globalSphere = null
      this.isLoading = false
      this.sendingOrder = false
      this.saving = false
      this.working = false
      this.isOrderingLines = false
      this.internalCart = cart ? { ...cart } : null
      // Create properties that are missing
      if (!this.internalCart.ids_intern?.tickets?.['pre-sale']) {
        _.set(this.internalCart, 'ids_intern.tickets.pre-sale', [])
      }
      if (!this.internalCart.ids_intern?.opportunities?.carts) {
        _.set(this.internalCart, 'ids_intern.opportunities.carts', [])
      }

      this.sanitize()
    },
    resetDiscounts (productTree) {
      _.each(productTree, (value, key, product) => {
        if (key === 'per_discount') { product.per_discount = 0 }
        if (key === 'per_discount_setup') { product.per_discount_setup = 0 }
        if (key === 'custom_discounts') { product.custom_discounts = false }
        if (_.isObject(value)) this.resetDiscounts(value)
      })
    },
    reload () {
      this.$nextTick(() => {
        try { this.$forceUpdate() } catch (error) {}
      })
    },
    sanitize () {
      if (!this.internalCart) return
      this.internalCart.nb_days_begin_delay = this.internalCart.nb_days_begin_delay || 0
      // Do not sanitize nb_days_building_time, empty (null) value is allowed.
    },
    async saveDialog (closeDialog = true, { keys, refreshList = true, notifySuccess = true, setCart = true } = {}) {
      if (!this.enabled) return

      this.saving = true
      try {
        const { cart } = await this.$store.dispatch('$alto-quotations/save', { cart: this.internalCart, files: this.attachments })

        if (setCart) this.setCart(cart)

        if (notifySuccess) { this.$stratus.services.notify.success(this.$t('Quotation «{name}» saved.', cart)) }

        if (refreshList && this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()

        if (closeDialog && !keys?.ctrl && !keys?.meta) this.closeDialog(true)
        else {
          this.resetDiscounts(this.internalCart.products)
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.saving = false
      }
    },
    async savePartialCart (data) {
      if (!this.enabled) return

      try {
        // We must save all fields, because some of them can have been modified by user
        this.saving = true
        const { cart } = await this.$store.dispatch('$alto-quotations/save', { cart: { ...this.internalCart, ...data } })
        this.setCart(cart)

        if (this.$root['global-cart-menu']) {
          this.$root['global-cart-menu'].refreshList()
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.saving = false
      }
    },
    async sendOrder () {
      if (!this.canCartOrder) return

      const confirmed = await this.$refs['confirm-cart-dialog']
        .open(this.$t('Send order'), this.$t('Confirm the order of quotation {name}?', { name: this.internalCart.name }))
      if (!confirmed) return

      if (this.internalCart.description && this.internalCart.description.length > this.DESCRIPTION_MAX_LENGTH) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.saving = false
        return
      }

      // First we remove double refs and we add `showAlertMessage` property
      const productsUniq = [...new Map(this.internalCart?.products.map((product) => [product.ref, { ...product, showAlertMessage: false }])).values()]
      // Then we filter only those who have a `warning_message_ordering`
      this.alertsOrderingItems = productsUniq.filter(x => x.warning_message_ordering)
      if (this.alertsOrderingItems.length > 0) {
        const { confirmed } = await this.$refs['alerts-ordering-items-dialog']
          .open(this.$tc('Before ordering, one product needs your attention.|Before ordering, {count} products need your attention.', this.alertsOrderingItems.length, { count: this.alertsOrderingItems.length }), null, { color: 'accent', width: '60%' })
        if (!confirmed) {
          // User cancel dialog
          return
        }
      }

      this.saving = true
      this.sendingOrder = true

      try {
        await this.$store.dispatch('$alto-quotations/sendOrder', this.internalCart)
        // Cart is completed. There's no more current one.
        if (this.$root['global-cart-menu']) {
          this.$root['global-cart-menu'].clearCart()
          this.$root['global-cart-menu'].refreshList()
        }
        // this.$router.push(`/orders/${order.id}`)
        this.closeDialog(true, true)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.sendingOrder = false; this.saving = true }, 250)
      }
    },
    setCart (cart) {
      if (!cart) {
        this.reset()
        return
      }

      this.reset(cart)
      this.$nextTick(async () => {
        this.$store.commit('$alto-quotations/setCurrent', this.internalCart)
        if (this.internalCart?.code) {
          if (this.internalCart.code !== this.companyProps.code) {
            const company = await this.$store.dispatch('$alto-companies/getById', this.internalCart.code)
            this.companyProps = { ...company }
            this.isForeignCompany = await this.$store.dispatch('$alto-companies/isForeign', this.internalCart.code)
            this.getEmails()
          }
        } else {
          this.companyProps = {
            mail_accounting: [],
            nic: '',
            siren: '',
            type: ''
          }
          this.isForeignCompany = false
          this.emailsCache = []
        }
      })
    },
    setWorking (working) {
      this.working = working
    },
    async saveOrderingLines () {
      if (!this.internalCart?.id || !this.canSave || !this.canChangeLineOrder) return

      try {
        this.saving = true
        const products = this.$refs['cart-lines-mover'].getProducts()
        if (products?.length > 1) {
          // Check if we need to change line index
          let productsMoved = false
          const newIndexes = {}
          for (const product of products) {
            if (product?.origin !== undefined && product.origin !== product.index_order) {
              // product has moved
              newIndexes[`${product.origin}`] = product.index_order
              productsMoved = true
            } else {
              // not moved: API is a bit lazy...
              newIndexes[`${product.index_order}`] = product.index_order
            }
          }

          if (productsMoved) {
            const cart = await this.$store.dispatch('$alto-quotations/moveProducts', { id: this.internalCart.id, reIndex: newIndexes })
            this.setCart(cart)
          }
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.isOrderingLines = false
        this.saving = false
      }
    },
    async display (show = false) {
      this.enabled = false
      this.visible = show
      if (show) {
        // Load some data in cache
        try {
          await this.$store.dispatch('$alto-opportunities/loadStates')
          await this.$store.dispatch('$alto-catalog/loadAccountingFamilies')
          await this.$store.dispatch('$alto-companies/loadFirstContacts')
          if (this.canReadCatalog && !this.$store.getters['$alto-catalog/list'].length) await this.$store.dispatch('$alto-catalog/list')
          setTimeout(() => {
            this.enabled = true
          }, 2000)
        } catch (error) {
          this.$stratus.services.notify.error(error)
        }
      }
    }
  }
}
</script>
