<template>
  <div class="my-1">
    <cs-expand-panel :icon="iconInOut" block>
      <v-row slot="header" align="center" no-gutters>
        <v-col cols="1" class="grow text-no-wrap">
          <div class="text-caption">
            {{ sortDate }}
          </div>

          <v-chip small label :class="`${color} color0--text clickable`">
            {{ $t('follow-up-type-call-1') | uppercase }}
          </v-chip>
        </v-col>

        <v-col>
          <div class="d-flex align-center">
            <sca-user-identity v-if="value.id_caller" :value="value.id_caller" class="mr-2" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(value.id_caller)" />

            <v-icon small left right>
              $vuetify.icons.for
            </v-icon>

            <sca-user-identity v-if="value.id_user" :value="value.id_user" class="pa-0 ma-0" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(value.id_user)" />
            <span v-else>{{ $t('Unknown user') }}</span>
          </div>

          <div v-if="value.subject">
            {{ value.subject }}
          </div>
        </v-col>

        <v-col class="shrink text-no-wrap text-right text-caption">
          <div v-if="showCompany && value.code" class="my-1 mr-1">
            <sca-company-identity :value="value.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(value.code)" />
          </div>
        </v-col>

        <v-divider vertical class="ml-2 mr-4" />

        <v-col class="shrink text-no-wrap">
          <v-btn v-if="canUpdateCall" small icon rounded class="mr-2" @click.stop="updateCall(value)">
            <v-icon small color="primary">
              $vuetify.icons.edit
            </v-icon>
          </v-btn>

          <v-btn v-if="canDeleteCall" small icon rounded class="mr-2" @click.stop="deleteCall(value)">
            <v-icon small color="danger">
              $vuetify.icons.delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row slot="content" dense class="mt-2">
        <v-col cols="12">
          <cs-phone :value="value.number_phone_user" />
        </v-col>

        <v-col cols="12">
          <v-row justify="center">
            <v-col v-if="value.details_call" cols="12">
              <v-card tile flat>
                <v-tabs v-model="tab" color="transparent" hide-slider>
                  <cs-loader-tab href="#tab-content" :header="$t('Content')" icon="icon-opened-email" />
                  <cs-loader-tab href="#tab-raw-text" :header="$t('Raw text')" icon="icon-file" />

                  <v-tabs-items v-model="tab">
                    <v-tab-item value="tab-content">
                      <div class="pa-2" v-html="sanitizeBody(value.details_call)" />
                    </v-tab-item>

                    <v-tab-item value="tab-raw-text">
                      <pre>{{ value.details_call }}</pre>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-card>
            </v-col>
            <v-col v-else class="shrink text-no-wrap">
              <cs-alert-panel type="info" dense :text="$t('No content...')" />
            </v-col>
          </v-row>
        </v-col>

        <v-col class="d-flex">
          <!-- <sca-user-identity :value="value.id_lord_salesperson" show-card dense show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(value.id_lord_salesperson)" /> -->

          <cs-expand-list v-if="isArray(value.opportunity_ids) && value.opportunity_ids.length > 0" :items="value.opportunity_ids" row>
            <template v-slot:expand-list-item="{ item }">
              <sca-opportunity-identity :value="item" :label="item" show-avatar link="emit" @link-click="openOpportunity(item)" />
            </template>
          </cs-expand-list>
          <span v-else class="ml-8">{{ $t('Undefined opportunity') }}</span>
        </v-col>

        <v-col cols="12">
          <sca-footer-create-update-at-by :value="value" :link-user="isLord" />
        </v-col>
      </v-row>
    </cs-expand-panel>

    <cs-confirm-dialog ref="confirm-event-delete" />
    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-followup-call-dialog ref="followup-call-dialog" :company-code="companyCode" update @close="onCloseDialog" />
    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'FollowUpCall',
  components: {
    'csm-followup-call-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpCallDialogLite')
  },
  props: {
    companyCode: { type: String, default: null },
    showCompany: { type: Boolean, default: false },
    showSalesPerson: { type: Boolean, default: false },
    value: { type: Object, required: true }
  },
  data () {
    return {
      isArray: _.isArray,
      tab: 'tab-content'
    }
  },
  computed: {
    canAddCall () {
      return this.$store.getters['$alto-roles/canI'](
        this.$alto.API_CONTEXTS.FOLLOWUP_CALL,
        this.$alto.API_PERMISSIONS.CREATE
      )
    },
    canDeleteCall () {
      return this.$store.getters['$alto-roles/canI'](
        this.$alto.API_CONTEXTS.FOLLOWUP_CALL,
        this.$alto.API_PERMISSIONS.DELETE
      )
    },
    canListCalls () {
      return this.$store.getters['$alto-roles/canI'](
        this.$alto.API_CONTEXTS.FOLLOWUP_CALL,
        this.$alto.API_PERMISSIONS.LIST
      )
    },
    canUpdateCall () {
      return this.$store.getters['$alto-roles/canI'](
        this.$alto.API_CONTEXTS.FOLLOWUP_CALL,
        this.$alto.API_PERMISSIONS.UPDATE
      )
    },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.value.$type) },
    customerList () { return this.$store.getters['$alto-companies/list'] },
    customerListSorted () {
      return this.$stratus.services.sort.natural(_.compact(this.customerList), { searchText: 'asc' })
    },
    icon () { return this.$store.getters['followups/ICON_TYPE'](this.value.$type) },
    iconInOut () { return this.$store.getters['$alto-followups/ICON_IN_OUT'](this.value.type_call) },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    ITEM_TYPE_CALL () {
      return this.$store.getters['followups/ITEM_TYPE_CALL']
    },
    ITEM_TYPE_EMAIL () {
      return this.$store.getters['followups/ITEM_TYPE_EMAIL']
    },
    ITEM_TYPE_CALL_IN () {
      return this.$store.getters['followups/ITEM_TYPE_CALL_IN']
    },
    ITEM_TYPE_CALL_OUT () {
      return this.$store.getters['followups/ITEM_TYPE_CALL_OUT']
    },
    sortDate () {
      const d = this.value.sortDate || this.value.date_call
      if (!d) return this.$t('Undefined')
      if (this.$stratus.dt(d).isAfter(this.$stratus.dt().startOf('year'))) return this.$stratus.dt(d).format('DD MMM HH:mm')
      return this.$stratus.dt(d).format('L HH:mm')
    }
  },
  methods: {
    addCall () {
      if (this.canAddCall && this.$refs['followup-call-dialog']) {
        this.$refs['followup-call-dialog'].open()
      }
    },
    deleteCall (item) {
      if (!item) return

      this.$refs['confirm-event-delete']
        .open(
          this.$t('Delete call'),
          this.$t('Do you want to delete call of {date}?', {
            date: this.$stratus.dt(item.sortDate).format('LLLL')
          }),
          { color: 'danger' }
        )
        .then(async (confirm) => {
          if (confirm) {
            try {
              await this.$store.dispatch('followups/deleteCall', item.id)
              this.$emit('updated', true)
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
          }
        })
    },
    onCloseDialog (saved) {
      this.$emit('updated', saved)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    sanitizeBody (content) {
      if (!content) return ''
      // sanitize and replace \n by html tag BR
      // Windows use \r\n, Linux/Unix use \n and MacOS use \r
      return this.$stratus.services.strings
        .stripHtmlTags(content)
        .replace(/\r\n/g, '<br>')
        .replace(/\r/g, '<br>')
        .replace(/\n/g, '<br>')
    },
    updateCall (item) {
      if (this.canAddCall && this.$refs['followup-call-dialog']) {
        this.$refs['followup-call-dialog'].open(item)
      }
    }
  }
}
</script>
