<template>
  <div>
    <v-row align="center">
      <v-col cols="6">
        <sca-users-select ref="user-select" v-model="userId" :label="$t('User')" :filter-team="TEAM_DESK" allow-all multiple clearable explode-value @selectionChange="refresh" @input="refresh" />
      </v-col>

      <v-col class="shrink text-no-wrap text-right">
        <cs-refresh-button :loading="loading" @click="load()" />

        <div v-show="lastRefreshDate" class="text-caption">
          ({{ $t('Last refresh: {date}', { date: $stratus.dt(lastRefreshDate).format('LT') }) }})
        </div>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" md="4">
        <v-row dense>
          <v-col cols="12" md="6" class="text-center">
            <v-sheet class="pa-1 full-height" :class="ticketNotAssigned.length ? 'clickable' : ''" @click="showTickets(PANEL_TICKET_NOT_ASSIGNED)">
              <div class="text-h6 text-center">
                {{ $t('Ticket(s) not assigned') }}
              </div>

              <cs-gauge :value="ticketNotAssigned?.length || 0" :min="0" :max="maxTicketNoAssigned" :labels-on-arc="true" :min-threshold="2" :min-threshold-fill="COLOR_THRESHOLD_MIN" :med-threshold="5" :med-threshold-fill="COLOR_THRESHOLD_MED" :max-threshold="maxTicketNoAssigned" :max-threshold-fill="COLOR_THRESHOLD_MAX" :value-to-exceed-limits="true" :active-fill="COLOR_THRESHOLD_MED" :inactive-fill="COLOR_THRESHOLD_MAX" :pointer-gap="3" :thickness="30" :radius="100" :pointer-stroke-width="4" :pivot-radius="6" :pivot-stroke-width="3" pivot-fill="none" :heading-level="6" font-size="0.8em" thresholds-always-visible />
            </v-sheet>
          </v-col>

          <v-col>
            <v-sheet class="pa-1 full-height" :class="ticketTotalFailure.length ? 'clickable' : ''" @click="showTickets(PANEL_TICKET_FAILURE)">
              <div class="text-h6 text-center">
                {{ $t('Total failures') }}
              </div>

              <cs-gauge :value="ticketTotalFailure?.length || 0" :min="0" :max="maxTotalFailure" :labels-on-arc="true" :min-threshold="2" :min-threshold-fill="COLOR_THRESHOLD_MIN" :med-threshold="5" :med-threshold-fill="COLOR_THRESHOLD_MED" :max-threshold="maxTotalFailure" :max-threshold-fill="COLOR_THRESHOLD_MAX" :value-to-exceed-limits="true" :active-fill="COLOR_THRESHOLD_MED" :inactive-fill="COLOR_THRESHOLD_MAX" :pointer-gap="3" :thickness="30" :radius="100" :pointer-stroke-width="4" :pivot-radius="6" :pivot-stroke-width="3" pivot-fill="none" :heading-level="6" font-size="0.8em" thresholds-always-visible />
            </v-sheet>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <v-sheet class="pa-1 full-height">
              <div v-if="showTicketPanel">
                <div class="d-flex align-center text-h6 mb-2">
                  {{ $t(ticketsPanelTitle) }} <v-chip small>
                    {{ ticketsPanel?.length || '-' }}
                  </v-chip>
                  <v-spacer />
                  <v-btn icon @click="showTicketPanel = false">
                    <v-icon>
                      $vuetify.icons.close
                    </v-icon>
                  </v-btn>
                </div>

                <sca-ticket-list-panel :value="ticketsPanel" can-open fixed-height link="emit" @link-click="openTicket" show-severity />
              </div>

              <div v-else class="d-flex flex-column">
                <div class="text-h6 text-center">
                  {{ $t('Distribution of pending tickets') }}
                  <v-chip small>
                    {{ ticketByUser }}
                  </v-chip>
                </div>

                <div class="mt-auto mb-auto">
                  <cs-chart-donut v-show="ticketByUser" ref="chart-ticket-by-user" class="chart-max-height" :styles="chartStyles" :options="chartOptions" :chart-data="ticketByUserChartData" />
                  <span v-show="!ticketByUser">
                    {{ $t('No data...') }}
                  </span>
                </div>

                <div v-if="ticketIdsByUser?.length">
                  <div class="d-flex align-center text-h6 mb-2">
                    {{ ticketByUserGridLabel }}
                    <v-spacer />
                    <v-btn icon @click="computeQuery()">
                      <v-icon>
                        $vuetify.icons.close
                      </v-icon>
                    </v-btn>
                  </div>

                  <sca-ticket-list-panel :value="ticketIdsByUser" can-open fixed-height link="emit" @link-click="openTicket" show-severity />
                </div>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8">
        <div class="pa-1 full-height">
          <csm-sla-grid v-if="usersSelected?.length" ref="cds-sla-grid" :title="$t('Latest SLA')" :query="querySLA" :columns="SLAColumns" :options="slaGridOptions" hide-filters />

          <div v-else class="full-height d-flex flex-column">
            <div class="mt-auto mb-auto ml-auto mr-auto">
              <cs-alert-panel type="info" dense class="ml-auto mr-auto mt-auto mb-auto" :text="$t('Choose a user...')" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <csm-ticket-grid v-if="usersSelected?.length" ref="customer-feedback-ticket-grid" :title="$t('Customer feedback')" :query="queryFeedbackTickets" :options="ticketGridOptions" :save-preferences="false" hide-toggles hide-filter hide-global-incident />
      </v-col>

      <v-col cols="12">
        <csm-ticket-grid v-if="usersSelected?.length" ref="my-ticket-grid" :title="$t('Tickets')" :query="queryCDSTickets" :options="ticketGridOptions" :save-preferences="false" hide-toggles hide-global-incident inline-filter :filter-ticket-state="[TICKET_STATE_TODO]" filter-companies filter-project />
      </v-col>
    </v-row>

    <csm-ticket-dialog @closeDialog="closeDialog" ref="ticket-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'

import { mapGetters } from 'vuex'

const DEFAULT_REFRESH_DELAY = 3 // Minutes

const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [{
    backgroundColor: [],
    data: []
  }]
}

export default {
  name: 'CdsDashboard',
  components: {
    'csm-sla-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/SlaGrid'),
    'csm-ticket-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGrid')
  },
  data () {
    return {
      COLOR_THRESHOLD_MAX: '#EB5128',
      COLOR_THRESHOLD_MED: '#FFC133',
      COLOR_THRESHOLD_MIN: '#6BDE81',
      PANEL_TICKET_NOT_ASSIGNED: 1,
      PANEL_TICKET_FAILURE: 2,
      TEAM_DESK: this.$alto.defines.TEAMS.TEAM_DESK,
      TICKET_SEVERITY_CRITICAL: this.$alto.defines.TICKETS.TICKET_SEVERITY_CRITICAL,
      TICKET_STATE_CANCELED: this.$alto.defines.TICKETS.TICKET_STATE_CANCELED,
      TICKET_STATE_CLOSED: this.$alto.defines.TICKETS.TICKET_STATE_CLOSED,
      TICKET_STATE_DOING: this.$alto.defines.TICKETS.TICKET_STATE_DOING,
      TICKET_STATE_DONE: this.$alto.defines.TICKETS.TICKET_STATE_DONE,
      TICKET_STATE_REVIEW: this.$alto.defines.TICKETS.TICKET_STATE_REVIEW,
      TICKET_STATE_TODO: this.$alto.defines.TICKETS.TICKET_STATE_TODO,
      TICKET_STATE_WAITING: this.$alto.defines.TICKETS.TICKET_STATE_WAITING,
      TICKET_TYPE_INCIDENT: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT,
      TICKET_BY_USER_PALETTE: this.$alto.defines.COLORS.PALETTE_SCALAIR_10,
      TICKETS_STATES_TEAM_DESK: [
        this.$alto.defines.TICKETS.TICKET_STATE_DOING,
        this.$alto.defines.TICKETS.TICKET_STATE_TODO
      ],
      lastRefreshDate: new Date(),
      latestSLA: [],
      loading: true,
      refreshHandler: null,
      showTicketPanel: null,
      slaGridOptions: {
        allowAdvancedSearch: false,
        itemsPerPage: 15,
        queryAdd: { onList: { state: 'ongoing' } } // Only a part of SLA
      },
      ticketGridOptions: {
        allowAdvancedSearch: false,
        create: false
      },
      ticketByUser: 0,
      ticketByUserChartData: { ...DEFAULT_CHART_DATA },
      ticketByUserGridLabel: '',
      ticketByUserQuery: null,
      ticketIdsByUser: [],
      ticketNotAssigned: [],
      ticketsOfDesk: [],
      ticketsPanel: [],
      ticketsPanelTitle: '',
      ticketTotalFailure: [],
      userId: [],
      usersSelected: []
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      preferences: '$alto-preferences/preferences'
    }),
    chartOptions () {
      return {
        // Click on a part to load it's data
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plugged to.
           */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Forge a query to load data for this part
            this.data.datasets[element[0]._datasetIndex].computeQuery(
              this.data.datasets[element[0]._datasetIndex].query[element[0]._index],
              this.data.caption + ' > ' + this.data.labels[element[0]._index],
              this.data.datasets[element[0]._datasetIndex].ticketIds[element[0]._index]
            )
            this.update()
          }
        },
        responsive: true,
        animation: {
          animateRotate: true,
          animateScale: true
        },
        maintainAspectRatio: false,
        // FIXME: if exception « Cannot read property 'transition' of null » still occurs,
        // uncomment the following:
        // animation: {
        //     duration: 0
        // },
        // hover: {
        //     animationDuration: 0
        // },
        responsiveAnimationDuration: 0,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          enabled: true,
          intersect: false,
          callbacks: {
            label: (tooltipItems, data) => {
              return data.labels[tooltipItems.index]
            }
          }
        },
        circumference: 2 * Math.PI,
        rotation: -Math.PI / 2
      }
    },
    chartStyles () {
      return {
        height: '350px',
        width: '100%',
        position: 'relative'
      }
    },
    maxTicketNoAssigned () {
      return _.max([this.ticketNotAssigned?.length || 0, 10])
    },
    maxTotalFailure () {
      return _.max([this.ticketTotalFailure?.length || 0, 10])
    },
    queryFeedbackTickets () {
      return `id_owner[in]=${this.usersSelected.join(',')}!!last_comment_by[ne]=""!!last_comment_by[nrg]=@scalair.fr`
    },
    queryCDSTickets () {
      return `(id_owner[in]=${this.usersSelected.join(',')}||id_referring[in]=${this.usersSelected.join(',')})`
    },
    querySLA () {
      return `ticket_id_lord_team[in]=${this.TEAM_DESK}!!(ticket_id_owner[in]=${this.usersSelected.join(',')}||ticket_id_referring[in]=${this.usersSelected.join(',')})`
    },
    SLAColumns () {
      // Columns for SLA grid. {String} value: {Boolean} hidden (if true)
      return {
        name: false,
        ticket_code: false,
        ticket_name: false,
        per_passed_time: false,
        due_date: false,
        ticket_id_referring: false,
        ticket_id_lord_team: false,
        id_ticket: true,
        state: true,
        start_at: true,
        passed_time: true,
        ticket_id_owner: true,
        ticket_create_by: true
      }
    }
  },
  methods: {
    addSector (chart, name, value, color, query, ticketIds) {
      const currentDataset = chart.datasets[0]
      chart.caption = this.$t('Distribution of tickets')
      chart.labels.push(name)
      currentDataset.data.push(value)
      currentDataset.query.push(query)
      currentDataset.ticketIds.push(ticketIds)
      // currentDataset.backgroundColor.push(color.html)
      currentDataset.backgroundColor.push(color.opacity80)
      currentDataset._backgroundColor.push(color.opacity80)
      currentDataset.borderColor = this.$alto.defines.COLORS.WARNING
      currentDataset.borderWidth = 0
      currentDataset.computeQuery = this.computeQuery
    },
    closeDialog () {
      this.refresh()
    },
    computeQuery (query, label, ticketIds) {
      this.ticketByUserQuery = query || []
      this.ticketByUserGridLabel = label || ''
      this.ticketIdsByUser = ticketIds || []
    },
    createGraph () {
      this.resetChart()

      const ticketByUser = _.groupBy(this.ticketsOfDesk, 'id_owner')
      if (ticketByUser) {
        this.ticketByUser = this.ticketsOfDesk.length
        let i = 0
        _.forEach(ticketByUser, async (list, ticketOwner) => {
          let userName
          let query = ''

          if (ticketOwner) {
            const user = { ...await this.$store.dispatch('$alto-users/getById', ticketOwner) }
            if (user) {
              userName = `${user.firstname} ${user.lastname}`
              query = `id_owner[eq]=${ticketOwner}!!`
            }
          } else userName = this.$t('Unallocated')

          query += `state[in]=${this.TICKETS_STATES_TEAM_DESK}`

          this.addSector(this.ticketByUserChartData, `${userName} : ${list.length}`, list.length, this.TICKET_BY_USER_PALETTE[i % this.TICKET_BY_USER_PALETTE.length], query, _.map(list, 'id'))
          i++
        })
      } else this.ticketByUser = 0

      this.$nextTick(() => {
        if (this.$refs['chart-ticket-by-user']) this.$refs['chart-ticket-by-user'].update()
      })
    },
    async load () {
      try {
        clearTimeout(this.refreshHandler)

        this.loading = true

        const { explodedValue } = this.$refs['user-select'].explode(this.userId)
        this.usersSelected = explodedValue

        // Get all tickets for desk team
        const ticketsDesk = await this.$store.dispatch('$alto-ticketing/getTickets', { teamIds: [this.TEAM_DESK], states: this.TICKETS_STATES_TEAM_DESK })
        if (ticketsDesk?.results) {
          this.ticketsOfDesk = [...ticketsDesk.results]
          this.ticketNotAssigned = _.filter(ticketsDesk.results, ticket => {
            return ticket.id_owner === '' && ticket.id_lord_team === this.TEAM_DESK && ![this.TICKET_STATE_CANCELED, this.TICKET_STATE_CLOSED, this.TICKET_STATE_DONE].includes(ticket.state)
          })
        } else {
          this.ticketNotAssigned = []
          this.ticketsOfDesk = []
        }

        // Failure tickets
        const ticketsFailure = await this.$store.dispatch('$alto-ticketing/getTickets', { types: this.TICKET_TYPE_INCIDENT, severities: this.TICKET_SEVERITY_CRITICAL, states: [this.TICKET_STATE_DOING, this.TICKET_STATE_TODO, this.TICKET_STATE_WAITING] })
        this.ticketTotalFailure = ticketsFailure?.results || []

        if (this.refreshHandler) {
          if (this.$refs['my-ticket-grid']) this.$refs['my-ticket-grid'].refresh()
          if (this.$refs['cds-sla-grid']) this.$refs['cds-sla-grid'].refresh()
          if (this.$refs['customer-feedback-ticket-grid']) this.$refs['customer-feedback-ticket-grid'].refresh()
          this.lastRefreshDate = new Date()
        }

        // Propagate data modifications
        if (this.showTicketPanel) {
          this.showTickets()
        }

        // It's time to render the graph
        this.createGraph()
      } catch (error) {
        console.error('[CDS] dashboard error:', error)
      } finally {
        this.refreshHandler = setTimeout(() => { this.refresh() }, DEFAULT_REFRESH_DELAY * 60 * 1000)
        setTimeout(() => {
          this.loading = false
          if (this.$refs['chart-ticket-by-user']) this.$refs['chart-ticket-by-user'].update()
        }, 1000)
      }
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    refresh () {
      if (!this.$refs['user-select']) return
      this.load()
    },
    resetChart () {
      this.$set(this.ticketByUserChartData, 'labels', [])
      this.$set(this.ticketByUserChartData, 'datasets', [{
        backgroundColor: [],
        _backgroundColor: [],
        data: [],
        nbOpportunities: [],
        query: [],
        ticketIds: []
      }])
    },
    showTickets (panelId) {
      // Behave like a switch
      if (panelId === this.showTicketPanel) panelId = null // Hide panel

      if (panelId === undefined) panelId = this.showTicketPanel // Refresh current visible panel

      switch (panelId) {
        case this.PANEL_TICKET_FAILURE:
          this.ticketsPanel = this.ticketTotalFailure
          this.ticketsPanelTitle = 'Total failures'
          break
        case this.PANEL_TICKET_NOT_ASSIGNED:
          this.ticketsPanel = this.ticketNotAssigned
          this.ticketsPanelTitle = 'Ticket(s) not assigned'
          break
        default:
          this.ticketsPanel = []
          this.ticketsPanelTitle = ''
      }
      this.showTicketPanel = this.ticketsPanel?.length > 0 ? panelId : null
    }
  },
  beforeDestroy () {
    clearTimeout(this.refreshHandler)
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('CDS Dashboard'))
    this.refresh = _.debounce(this.refresh, 1000)
    this.resetChart()
  },
  mounted () {
    // this. = _.debounce(this.load, LOADING_MIN_DURATION)
    this.$nextTick(() => {
      if (this.me.lord_team_id === this.TEAM_DESK) this.userId = [this.me?.id]
      else this.userId = ['ALL']
      setTimeout(() => {
        this.refresh()
      }, 1000)
    })
  }
}
</script>
