<template>
  <div>
    <div class="text-right">
      <sca-sales-manager-message page="catalog" button-close />
    </div>

    <div class="mx-16 mt-8">
      <v-row align="center" justify="space-around">
        <v-col v-for="(domain, index) in domainList" cols="12" md="6" lg="4" :key="index" class="text-center">
          <v-hover v-slot="{ hover }">
            <router-link :to="domain.link">
              <v-card outlined v-ripple="{ center: true, class: 'primary--text' }" :color="domain.color" class="domain-card background-blurred" :class="margins + (hover ? ' focused' : '')">
                <v-img :src="domain.img + (dark ? '_dark' : '_light') + '.svg?1'" contain :height="imageSize">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey" />
                    </v-row>
                  </template>
                </v-img>
                <div class="product-domain font-weight-bold primary--text">
                  {{ $t(`product-domain-${domain.value.toLowerCase()}`) }}
                </div>
                <v-divider vertical />
                <div class="text-caption text-left" :class="dark ? '' : 'secondary--text'">
                  {{ $t(`product-domain-help-${domain.value.toLowerCase()}`) }}
                </div>
              </v-card>
            </router-link>
          </v-hover>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  data () {
    return {
      domainList: []
    }
  },
  computed: {
    imageSize () {
      if (this.$vuetify.breakpoint.lgAndUp) return 180
      if (this.$vuetify.breakpoint.mdAndUp) return 140
      if (this.$vuetify.breakpoint.smAndUp) return 100
      return 100
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    domains () {
      return this.$store.getters['$alto-catalog/domains']
    },
    paddingContainer () {
      if (this.$vuetify.breakpoint.lgAndUp) return 'pa-8'
      if (this.$vuetify.breakpoint.mdAndUp) return 'pa-4'
      if (this.$vuetify.breakpoint.smAndUp) return 'pa-2'
      return 'pa-1'
    },
    margins () {
      if (this.$vuetify.breakpoint.lgAndUp) return 'mx-4 mb-8 pa-4'
      if (this.$vuetify.breakpoint.mdAndUp) return 'mx-4 mb-4 pa-3'
      if (this.$vuetify.breakpoint.smAndUp) return 'mx-2 mb-2 pa-2'
      return 'mx-1 mb-1 pa-1'
    },
    tags () { return this.$store.getters.appConfig.defaults.productTags || [] }
  },
  methods: {
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Products Catalog'))
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    this.$alto.services.routes.connectionForbidden.call(this, me)

    await this.$store.dispatch('$alto-catalog/listDomains')
    let list = _.map(this.domains, domain => {
      return {
        ...domain,
        img: '/img/products/' + domain.value.toLowerCase(),
        link: '/catalog/' + domain.value
      }
    })
    // Add all product card
    list = list.concat({
      img: '/img/products/all',
      link: '/catalog',
      value: 'all'
    })
    this.domainList = this.$stratus.services.sort.natural(list, { text: 'asc' })
  }
}
</script>

<style scoped>
.domain-card {
  min-height: 350px;
  max-height: 350px;
}

.product-domain {
  font-size: 1.6rem;
  letter-spacing: +.03em;
}
</style>
