<template>
  <sca-modal-dialog :visible="visible" :loading="isLoading || isSaving" @closeDialog="closeDialog" @saveDialog="saveDialog" :action="action" :can-save="canSave && !isLoading" :is-saving="isSaving" max-width="50%">
    <div slot="title" dense>
      {{ this.$t('Ticket transaction') }} — {{ transaction.id }}
      <span v-show="isDirty()" class="ml-2">*</span>
    </div>

    <div slot="content">
      <v-form ref="ticket-transaction-form" v-model="valid" lazy-validation :disabled="!canSave">
        <v-row>
          <v-col cols="12" md="6" lg="4">
            <sca-customer-select v-model="transaction.code" :label="$t('Customer')" :rules="[$stratus.services.form.rules.required]" class="required" link="emit" @link-click="openCompany(transaction.code)" />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <cs-price-input v-model="transaction.amount" :label="$t('Amount')" />
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <cs-date-picker v-model="transaction.date" :label="$t('Date')" />
          </v-col>

          <v-col cols="12">
            <v-text-field v-model="transaction.comment" :label="$t('Comment')" :rules="[$stratus.services.form.rules.required,$stratus.services.form.rules.max(254)]" class="required" counter="254" />
          </v-col>
        </v-row>
      </v-form>

      <csm-company-dialog ref="company-dialog" />
      <csm-ticket-dialog ref="ticket-dialog" />
      <csm-user-dialog ref="user-dialog" />
      <cs-confirm-dialog ref="confirm-modification-dialog" />
    </div>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'TicketTransactionForm',
  props: {
    show: { type: Boolean, default: true },
    update: { type: Boolean, default: false },
    value: { type: Object, required: true, default: () => { return {} } }
  },
  data () {
    return {
      isSaving: false,
      isLoading: false,
      transaction: {},
      valid: false,
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_TRANSACTIONS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_TRANSACTIONS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    companies () {
      const companies = [this.me.company]
      if (this.transaction?.code) companies.push(this.transaction?.code)
      if (this.me?.customers) companies.concat(this.me?.customers)
      return companies
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newValue, oldValue) {
        if (!_.isEqual(newValue, oldValue) && (!_.isEmpty(newValue) || oldValue !== undefined)) {
          this.transaction = { ...newValue }
        }
      }
    }
  },
  methods: {
    closeDialog (saved) {
      if (!this.canSave || !this.isDirty()) {
        this.$emit('closeDialog', saved)
        return
      }

      this.$refs['confirm-modification-dialog'].open(this.$t('Modifications detected'), this.$t('Do you confirm the loss of your modifications?'))
        .then(confirmed => {
          if (confirmed) {
            this.$emit('closeDialog', saved)
          }
        })
    },
    // FUTURE
    // externalId () {
    //   if (!this.transaction?.id) return
    //   return [this.transaction.id, this.transaction.id ? 'tickets-transaction/' + this.transaction.id : '', this.transaction.comment || '']
    // },
    display (show = false) {
      this.visible = show
    },
    isDirty () {
      if (!this.originalRecord) return false
      const currentRecord = this.sanitizeRecord(this.transaction)
      return !_.isEqual(currentRecord, this.originalRecord)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    sanitizeRecord (data) {
      const result = {
        ...data,
        comment: this.$stratus.services.strings.stripHtmlTags(data.comment),
        date: data.date || null
      }
      return result
    },
    async saveDialog () {
      if (!this.canSave) return false

      if (!this.$refs['ticket-transaction-form']?.validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        return false
      }

      this.isSaving = true
      try {
        const record = this.sanitizeRecord(this.transaction)
        await this.$store.dispatch('$alto-ticketing/saveTicketTransaction', record)
        if (this.update) {
          this.$stratus.services.notify.success(this.$t('Ticket transaction updated.', record))
        } else {
          this.$stratus.services.notify.success(this.$t('Ticket transaction created.', record))
        }
        this.resetOriginal()
        this.closeDialog(true)
      } catch (error) {
        this.$stratus.services.notify.error(error)
        return false
      } finally {
        this.isSaving = false
      }
    },
    async reset (data) {
      this.isLoading = true
      if (this.$refs['ticket-transaction-form']) await this.$refs['ticket-transaction-form'].reset()
      this.transaction = { ...data }
      setTimeout(() => {
        this.resetOriginal()
        this.isLoading = false
      }, 500)
    },
    resetOriginal () {
      this.originalRecord = { ...this.sanitizeRecord(this.transaction) }
    }
  }
}
</script>
