<template>
  <sca-modal-dialog :visible="visible" @closeDialog="closeDialog(false)" @saveDialog="saveDialog" :action="action" :can-save="canSave" :is-saving="isSaving" :max-width="$vuetify.breakpoint.lgAndUp ? '75%' : ''" :external-id="externalId">
    <template #title>
      {{ title || $t('Company') }}
    </template>

    <template v-if="company.name_history?.length" #subtitle>
      <cs-options-menu icon="$vuetify.icons.expand" rotate-icon help="Name history">
        <template #menu>
          <div v-for="(name, index) in company.name_history" :key="index" class="text-body-1">
            {{ name }}
          </div>
        </template>
      </cs-options-menu>
    </template>

    <div v-if="isLord && (update || show) && company.blocked_payment" slot="subtitle" class="d-flex align-center">
      <cs-alert-panel type="warning" :text="$t('Be careful: Payment is blocked by this company! Do not issue any order!')" />
    </div>

    <template #append-header>
      <v-form ref="company-form-header" v-model="validHeaders" lazy-validation :disabled="!canSave">
        <v-row v-if="isLord && (update || show) && isForeignCompany" justify="center" class="mb-2">
          <v-col class="shrink text-no-wrap">
            <cs-alert-panel type="info" :text="$t('This is a foreign customer. Contact the accounting department for invoicing.')" />
          </v-col>
        </v-row>

        <v-row align="center" no-gutters>
          <v-col>
            <v-text-field v-model="company.name" :label="$t('Name')" :counter="127" :rules="[$stratus.services.form.rules.max(127)]" :error="getErrorExist('name')" :error-count="getErrorCount('name')" :error-messages="getErrors('name')" @blur="fixCase(company)" />
          </v-col>

          <v-col cols="12" md="3" class="pl-2">
            <sca-sales-person-select v-model="company.id_lord_salesperson" clearable :label="$t('Sales Manager')" :disabled="!canModifySalesPerson" :error="getErrorExist('id_lord_salesperson')" :error-count="getErrorCount('id_lord_salesperson')" :error-messages="getErrors('id_lord_salesperson')" show-email show-phone show-company show-card link="emit" @link-click="openUser(company.id_lord_salesperson)" />
          </v-col>

          <v-col cols="12" md="3" class="pl-2">
            <sca-customer-manager-select v-model="company.id_lord_manager" :label="$t('Service Manager')" :disabled="!isLord" clearable show-email show-phone show-company show-card link="emit" @link-click="openUser(company.id_lord_manager)" />
          </v-col>
        </v-row>

        <v-row v-if="isLord" align="center" no-gutters>
          <v-col class="shrink text-no-wrap mr-4">
            {{ $t('Tags') }}
          </v-col>

          <v-col>
            <v-btn-toggle v-model="company.tags" class="transparent" :disabled="!canSave" multiple dense>
              <v-btn v-for="tag in listTags" :key="tag.value" :value="tag.value" :class="company?.tags?.includes(tag.value) ? 'primary' : 'transparent'" class="ml-1">
                <sca-company-tag :value="tag.value" :dark="isDark" />
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col v-if="isLord" class="shrink text-no-wrap">
            <v-switch v-model="company.one_of_the_top" :label="$t('Top 70')" :disabled="!canSave" />
          </v-col>

          <v-col v-if="isLord && company.one_of_the_top" cols="12" md="3" class="d-flex align-center text-no-wrap">
            <sca-customer-manager-select v-model="company.id_lord_one_of_the_top" class="ml-2" :label="$t('Scalair Manager (top)')" :disabled="!canSave" clearable show-email show-phone show-company show-card link="emit" @link-click="openUser(company.id_lord_one_of_the_top)" />
          </v-col>
        </v-row>
      </v-form>

      <template v-if="company?.type">
        <v-stepper :value="company.type" flat non-linear>
          <v-stepper-header>
            <template v-if="company.type === TYPE_EX_CUSTOMER">
              <v-stepper-step step="" :edit-icon="company.type === TYPE_EX_CUSTOMER ? '$vuetify.icons.isOK' : ''" complete :color="company.type === TYPE_EX_CUSTOMER ? 'primary' : 'grey lighten-2'" :complete-icon="company.type === TYPE_EX_CUSTOMER ? '$vuetify.icons.customerOld' : ''">
                <span :class="company.type === TYPE_EX_CUSTOMER ? 'font-weight-bold' : ''">{{ $t(`company-type-${TYPE_EX_CUSTOMER}`) }}</span>
              </v-stepper-step>
            </template>

            <template v-else>
              <v-stepper-step step="" :editable="canEditForType(TYPE_SUSPECT)" :class="canEditForType(TYPE_SUSPECT) ? 'clickable' : ''" :edit-icon="company.type === TYPE_SUSPECT ? '$vuetify.icons.isOK' : ''" complete :color="canHighlightType(TYPE_SUSPECT) ? 'primary' : 'grey lighten-2'" :complete-icon="company.type === TYPE_SUSPECT ? '$vuetify.icons.customerSuspect' : ''" @click="changeType(TYPE_SUSPECT)">
                <span :class="company.type === TYPE_SUSPECT ? 'font-weight-bold' : ''">{{ $t(`company-type-${TYPE_SUSPECT}`) }}</span>
              </v-stepper-step>

              <v-divider :class="styleForTypeLine(TYPE_PROSPECT)" />

              <v-stepper-step step="" :editable="canEditForType(TYPE_PROSPECT)" :class="canEditForType(TYPE_PROSPECT) ? 'clickable' : ''" :edit-icon="company.type === TYPE_PROSPECT ? '$vuetify.icons.isOK' : ''" complete :color="canHighlightType(TYPE_PROSPECT) ? 'primary' : 'grey lighten-2'" :complete-icon="company.type === TYPE_PROSPECT ? '$vuetify.icons.customerProspect' : ''" @click="changeType(TYPE_PROSPECT)">
                <span :class="company.type === TYPE_PROSPECT ? 'font-weight-bold' : ''">{{ $t(`company-type-${TYPE_PROSPECT}`) }}</span>
              </v-stepper-step>
            </template>

            <template v-if="company?.code">
              <v-divider :class="styleForTypeLine(TYPE_CUSTOMER)" />

              <v-stepper-step step="" :editable="canEditForType(TYPE_CUSTOMER)" :class="canEditForType(TYPE_CUSTOMER) ? 'clickable' : ''" :edit-icon="company.type === TYPE_CUSTOMER ? '$vuetify.icons.isOK' : ''" complete :color="canHighlightType(TYPE_CUSTOMER) ? 'primary' : 'grey lighten-2'" :complete-icon="company.type === TYPE_CUSTOMER ? '$vuetify.icons.customer' : ''" @click="changeType(TYPE_CUSTOMER)">
                <span :class="company.type === TYPE_CUSTOMER ? 'font-weight-bold' : ''">{{ $t(`company-type-${TYPE_CUSTOMER}`) }}</span>
              </v-stepper-step>
            </template>
          </v-stepper-header>
        </v-stepper>
      </template>
    </template>

    <v-tabs hide-slider slot="tabs" color="transparent" v-model="tab" show-arrows>
      <v-tab v-show="isLord && CUSTOMER_COMPANY_TYPES.includes(company.type) && company.code" href="#tab-overview">
        <v-icon small left>
          {{ loadingOverview ? '$vuetify.icons.loading' : '$vuetify.icons.overview' }}
        </v-icon>
        {{ $t('Overview') }}
      </v-tab>
      <cs-validation-tab href="#tab-info" icon="icon-company" :label="$t('Information')" :error="tabErrors['tab-info']" />
      <cs-loader-tab v-if="company?.code" href="#tab-users" :header="$t('Users')" icon="$vuetify.icons.users" :count="usersForCompany ? usersForCompany.length : 0" :loading="loadingUsers" @load="loadUsersForCompany" />
      <cs-loader-tab v-if="isLord && canReadOpportunities && company?.code" href="#tab-products" :header="$t('Subscriptions')" icon="$vuetify.icons.product" :count="productsForCompany ? productsForCompany.length : 0" :loading="loadingProducts" @load="loadProductsForCompany" />
      <cs-loader-tab v-if="canListFollowUp && company?.code" href="#tab-follow-up" :header="$t('Follow-up')" icon="$vuetify.icons.message" :loading="loadingFollowUp" :count="followUpCount" />
      <cs-loader-tab v-if="isLord && company?.code" href="#tab-tickets" :header="$t('Tickets')" :loading="loadingTickets" icon="$vuetify.icons.ticket" />
      <cs-loader-tab v-if="canListTransactions && ticketsTransactions" href="#tab-transactions" :header="$t('Tickets transactions')" :loading="loadingTicketsTransactions" icon="$vuetify.icons.ticketCredits" />
    </v-tabs>

    <div v-if="errors" slot="content-fixed">
      <sca-field-error-panel :value="errors" prefix="company-field" />
    </div>

    <div slot="content" v-if="visible">
      <v-form ref="company-form-tabs" v-model="valid" lazy-validation :disabled="!canSave">
        <v-tabs-items v-model="tab">
          <v-tab-item v-if="isLord" value="tab-overview" eager class="pa-2">
            <v-row>
              <v-col v-if="ticketSurveyAnalysis" cols="6" md="4" lg="3">
                <div class="text-h5 primary--text">
                  {{ $t('Satisfaction') }}
                </div>

                <v-chip v-if="ticketSurveyAnalysis.ids_questionnaires_analysed?.length" x-large class="color3 text-h3" @click="showSatisfactions =! showSatisfactions">
                  {{ $t('{percent}%', { percent: ticketSurveyAnalysis.per_yes }) }}
                </v-chip>
                <div v-else class="text-h3">
                  {{ $t('n/a') }}
                </div>

                <div v-if="showSatisfactions" class="pt-4">
                  <v-btn v-for="id in ticketSurveyAnalysis.ids_questionnaires_analysed" :key="`survey-${id}`" text rounded @click="openTicketSurvey(id)">
                    <v-icon small left>
                      $vuetify.icons.satisfactionSurvey
                    </v-icon>
                    {{ getSurveyText(id) }}
                    <v-icon v-if="ticketSurveyAnalysis.ids_questionnaires_not_good.includes(id)" small right color="warning">
                      $vuetify.icons.warning
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>

              <v-col cols="6" md="4" lg="4">
                <div class="d-flex align-center">
                  <div class="text-h5 primary--text">
                    {{ $t('Current topics') }}
                  </div>
                  <div class="ml-auto d-flex align-center">
                    <sca-generic-history v-if="company.current_topic_history && company.current_topic_history.length > 0" :value="company.current_topic_history" :company="company.code" content-field="current_topic" content-field-name="Current topics" content-field-diff="current_topic_diff" date-field="date_begin" user-field="user" exclude="date_end" />

                    <v-btn icon @click.stop="canEditCurrentTopics = !canEditCurrentTopics">
                      <v-icon small>
                        $vuetify.icons.edit
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
                <div v-if="!company.current_topic && !canEditCurrentTopics">
                  {{ $t('No topics.') }}
                </div>
                <sca-wysiwyg-editor ref="company-current-topics-editor" v-model="company.current_topic" :placeholder="$t('Current topics')" :customers="company.code" :disabled="!canSave || !canEditCurrentTopics" :max-length="DESCRIPTION_MAX_LENGTH" :min-length="DESCRIPTION_MIN_LENGTH" small no-tool-align no-tool-header no-tool-block-text no-tool-indent no-tool-link />
              </v-col>

              <v-col cols="12" md="4" lg="5">
                <div class="text-h5 primary--text">
                  {{ $t('Scope') }}
                </div>

                <cs-expand-panel v-for="domain in subscriptionsByDomainList" :key="domain" class="mb-2" block>
                  <template v-slot:header="{ expanded }">
                    <div class="d-flex align-center font-weight-bold" :class="expanded ? 'primary--text' : ''">
                      {{ $t(`product-domain-${domain}`) }} ({{ subscriptionsByDomainQuantities?.[domain] }})

                      <v-divider class="mx-2" />

                      <v-chip small class="ml-auto">
                        {{ $n(subscriptionsByDomainPrices?.[domain], 'currency') }}
                      </v-chip>
                    </div>
                  </template>

                  <template #content>
                    <div v-for="product in subscriptionsByDomain[domain]" :key="`${domain}-${product.name}`" class="ml-1 d-flex align-center">
                      <div class="text-truncate">
                        {{ product.name }} ({{ product.quantity }})
                      </div>
                      <div class="ml-auto">
                        {{ $n(product.price, 'currency') }}
                      </div>
                    </div>
                  </template>
                </cs-expand-panel>
              </v-col>
            </v-row>

            <v-row v-show="ticketsByMonth.length">
              <v-col cols="12">
                <div class="text-h5 primary--text d-flex align-center">
                  {{ $t('Tickets') }}
                  <cs-icon-loading v-if="loadingTickets" small class="ml-2" />
                  <v-chip v-else small class="ml-2">
                    {{ ticketsByMonth.length }}
                  </v-chip>
                  <div class="ml-auto">
                    <cs-options-menu>
                      <template #menu>
                        <v-radio-group v-model="ticketRange" dense hide-details :label="$t('History')" class="ma-0 pa-0" @change="loadTicketHistory()">
                          <v-radio :value="12" :label="$t('{months} months', { months: 12 })" />
                          <v-radio :value="3" :label="$t('{months} months', { months: 3 })" />
                        </v-radio-group>
                      </template>
                    </cs-options-menu>
                  </div>
                </div>

                <v-row class="d-flex" dense>
                  <v-col cols="12" :md="barChartTickets?.length ? 6 : ''">
                    <cs-chart-bar :chart-data="barChartData" :options="barChartOptions" :styles="chartStyles" ref="company-tickets-bar-chart" />
                  </v-col>

                  <v-col v-if="barChartTickets?.length" cols="12" :md="barChartTickets?.length ? 6 : ''">
                    <div class="d-flex align-center">
                      <span class="font-weight-bold">
                        {{ barChartTicketsLabel }}
                        <v-chip small>
                          {{ barChartTickets?.length }}
                        </v-chip>
                      </span>
                      <v-btn icon @click="computeTickets()" class="ml-auto">
                        <v-icon small>
                          $vuetify.icons.close
                        </v-icon>
                      </v-btn>
                    </div>

                    <sca-ticket-list-panel :value="barChartTickets" dense block can-open show-icon show-state show-severity link="emit" @link-click="openTicket" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="tab-info" eager class="pa-2">
            <cs-expand-panel v-if="isLord" :header="$t('General')" icon="icon-company" :expanded="expandAdditional">
              <v-row no-gutters slot="content" align="center">
                <v-col class="pr-2" cols="6" md="3">
                  <v-select v-model="company.business_sector" :items="businessSectors" :label="$t('Business sector')" tab="tab-info" :error="getErrorExist('business_sector')" :error-count="getErrorCount('business_sector')" :error-messages="getErrors('business_sector')" />
                </v-col>

                <v-col class="pr-2" cols="12" md="4" lg="5">
                  <v-text-field v-model="company.business_description" :label="$t('Business')" :rules="[$stratus.services.form.rules.max(127)]" :counter="127" tab="tab-info" :error="getErrorExist('business_description')" :error-count="getErrorCount('business_description')" :error-messages="getErrors('business_description')" />
                </v-col>

                <v-col class="pr-2" cols="6" md="3" lg="2">
                  <cs-date-picker v-model="company.business_creation_date" :label="$t('Business creation')" allow-empty tab="tab-info" :disabled="!canSave" :error="getErrorExist('business_creation_date')" :error-count="getErrorCount('business_creation_date')" :error-messages="getErrors('business_creation_date')" />
                </v-col>

                <v-col cols="6" md="3" lg="2">
                  <v-select v-model="company.employee_number" item-value="id" :items="listEmployeeNumbers" :label="$t('Employees')" tab="tab-info" :error="getErrorExist('employee_number')" :error-count="getErrorCount('employee_number')" :error-messages="getErrors('employee_number')" />
                </v-col>
              </v-row>

              <v-row no-gutters slot="content" align="end">
                <v-col v-if="isHealthCare" cols="12" md="4" lg="3" class="pr-2">
                  <sca-users-select v-model="company.health_contact_point" show-email show-phone strict-users show-card prepend-icon="icon-health-contact" item-value="email" :filter-company="company.code" :label="$t('Health contact point')" :rules="[isClient ? $stratus.services.form.rules.required : true]" tab="tab-info" :class="isClient ? 'required' : ''" :error="getErrorExist('health_contact_point')" :error-count="getErrorCount('health_contact_point')" :error-messages="getErrors('health_contact_point')" link="emit" @link-click="openUser(company.health_contact_point)" />
                </v-col>

                <v-col v-if="isLord" cols="12" md="3" class="pr-2">
                  <sca-customer-select v-model="company.parent" :label="$t('Managing company')" :disabled="!canSave" tab="tab-info" customers-only :error="getErrorExist('parent')" :error-count="getErrorCount('parent')" :error-messages="getErrors('parent')" />
                </v-col>

                <v-col cols="6" md="3" lg="2" class="pr-2">
                  <v-select v-model="company.first_contact" :items="listFirstContacts" :label="$t('First contact')" :error="getErrorExist('first_contact')" :error-count="getErrorCount('first_contact')" :error-messages="getErrors('first_contact')" />
                </v-col>

                <v-col cols="12" md="" class="pr-2">
                  <v-text-field v-model="company.first_contact_detail" :label="$t('First contact details')" :rules="[$stratus.services.form.rules.max(127)]" :counter="127" tab="tab-info" :error="getErrorExist('first_contact_detail')" :error-count="getErrorCount('first_contact_detail')" :error-messages="getErrors('first_contact_detail')" />
                </v-col>

                <v-col v-if="company?.type && subtypes(company.type).length" cols="12" md="3" lg="2">
                  <v-select v-model="company.subtype" :label="$t('Subtype')" clearable :items="subtypes(company.type)" :error="getErrorExist('subtype')" :error-count="getErrorCount('subtype')" :error-messages="getErrors('subtype')">
                    <template #selection="{ item }">
                      {{ $t(`company-subtype-${item}`) }}
                    </template>
                    <template #item="{ item }">
                      {{ $t(`company-subtype-${item}`) }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </cs-expand-panel>

            <cs-expand-panel :header="$t('Domicile of the company')" icon="icon-company" :expanded="expandDomicile">
              <v-row slot="content" no-gutters>
                <v-col cols="12" sm="6" class="pr-2">
                  <v-text-field v-model="company.h_street" :label="$t('Address')" :counter="255" :rules="[$stratus.services.form.rules.max(255)]" tab="tab-info" :error="getErrorExist('h_street')" :error-count="getErrorCount('h_street')" :error-messages="getErrors('h_street')" @blur="fixCase(company)" />
                </v-col>

                <v-col cols="12" sm="6" class="pr-2">
                  <v-text-field v-model="company.h_street2" :label="$t('Complement')" :counter="255" :rules="[$stratus.services.form.rules.max(255)]" :error="getErrorExist('h_street2')" :error-count="getErrorCount('h_street2')" :error-messages="getErrors('h_street2')" @blur="fixCase(company)" />
                </v-col>

                <v-col cols="12" sm="2" class="pr-2">
                  <v-text-field v-model="company.h_zipcode" :label="$t('Zip code')" :rules="[$stratus.services.form.rules.alphaNumericSpace]" tab="tab-info" :error="getErrorExist('h_zipcode')" :error-count="getErrorCount('h_zipcode')" :error-messages="getErrors('h_zipcode')" />
                </v-col>

                <v-col cols="12" sm="6" class="pr-2">
                  <v-text-field v-model="company.h_city" :label="$t('Town')" :counter="127" :rules="[$stratus.services.form.rules.cityName, $stratus.services.form.rules.max(127)]" tab="tab-info" :error="getErrorExist('h_city')" :error-count="getErrorCount('h_city')" :error-messages="getErrors('h_city')" @blur="fixCase(company)" />
                </v-col>

                <v-col cols="12" sm="4" class="pr-2">
                  <cs-country-select v-model="company.h_country" tab="tab-info" format="cca3" :error="getErrorExist('h_country')" :error-count="getErrorCount('h_country')" :error-messages="getErrors('h_country')" />
                </v-col>

                <v-col cols="12" sm="4">
                  <v-text-field v-model="company.phone" :label="$t('Phone')" :rules="[$stratus.services.form.rules.phoneOrEmpty]" tab="tab-info" :error="getErrorExist('phone')" :error-count="getErrorCount('phone')" :error-messages="getErrors('phone')" />
                </v-col>
              </v-row>
            </cs-expand-panel>

            <cs-expand-panel v-if="canShowBusinessFields" :header="$t('Invoicing')" icon="$vuetify.icons.invoicing" :expanded="expandInvoicing">
              <div slot="content">
                <v-row align="center" no-gutters>
                  <v-col v-if="isLord" cols="12" md="" class="pr-2">
                    <v-switch dense inset flat v-model="company.blocked_payment" :label="$t('Payment blocked')" />
                  </v-col>

                  <v-col cols="12" md="4" lg="2" class="pr-2">
                    <v-select :disabled="!isLord" v-model="company.payment_method" :label="$t('Payment method')" :items="listPaymentMethods" :error="getErrorExist('payment_method')" :error-count="getErrorCount('payment_method')" :error-messages="getErrors('payment_method')" />
                  </v-col>

                  <v-col cols="12" md="4" lg="2" class="pr-2">
                    <v-select v-model="company.payment_terms" :label="$t('Payment term')" :items="listPaymentTerms" :disabled="!isLord" :error="getErrorExist('payment_terms')" :error-count="getErrorCount('payment_terms')" :error-messages="getErrors('payment_terms')" />
                  </v-col>

                  <v-col cols="12" md="4" class="pr-2">
                    <v-text-field v-model="company.iban" :rules="[IbanRule]" tab="tab-info" :label="$t('IBAN')" :class="companyNeedsIbanBic() ? 'required' : ''" :error="getErrorExist('iban')" :error-count="getErrorCount('iban')" :error-messages="getErrors('iban')" />
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-text-field v-model="company.bic" :rules="[BicRule]" :label="$t('BIC')" tab="tab-info" :class="companyNeedsIbanBic() ? 'required' : ''" :error="getErrorExist('bic')" :error-count="getErrorCount('bic')" :error-messages="getErrors('bic')" />
                  </v-col>
                </v-row>

                <v-row align="center" no-gutters>
                  <v-col cols="12" md="8" lg="6" class="pr-2">
                    <sca-company-siren :siren="company.siren" :nic="company.nic" :check-company-siren-used="company.code" :required="companyNeedSiret" can-search :disabled="!isLord" :error="getErrorExist('siren')" :error-count="getErrorCount('siren')" :error-messages="getErrors('siren')" @input="siretInput" @siren-search="sirenSearch" />
                  </v-col>

                  <v-col cols="12" md="4" lg="3">
                    <cs-helper>
                      <v-text-field slot="content" v-model="company.vat" :label="$t('VAT')" tab="tab-info" :class="companyNeedVat() ? 'required' : ''" :error="getErrorExist('vat')" :error-count="getErrorCount('vat')" :error-messages="getErrors('vat')" />
                      <div slot="help">
                        {{ $t('VAT is mandatory for customers of type "{mandatory}". Excluding "{excluding}" business sectors.', { mandatory: vatMandatoryList, excluding: vatExcludingList }) }}
                      </div>
                    </cs-helper>
                  </v-col>

                  <v-col v-if="company.vat_warning" cols="12" md="4" lg="3">
                    <cs-alert-panel type="warning" dense :text="$t(company.vat_warning)" />
                  </v-col>
                </v-row>

                <v-row align="center" no-gutters>
                  <v-col cols="12" md="2" class="pr-2">
                    <cs-helper>
                      <sca-sphere-select slot="content" v-model="company.ticketing_sphere_default" :customer="company.code" :label="$t('Default sphere')" clearable />
                      <div slot="help">
                        {{ $t('This is a default sphere when you create a ticket for this company.') }}
                      </div>
                    </cs-helper>
                  </v-col>

                  <v-col cols="12" md="5" class="pr-2">
                    <v-text-field v-model="company.name_billing" :label="$t('Name on invoices')" :counter="127" :rules="[$stratus.services.form.rules.max(127)]" tab="tab-info" :error="getErrorExist('name_billing')" :error-count="getErrorCount('name_billing')" :error-messages="getErrors('name_billing')" @blur="fixCase(company)" :disabled="!isLord" />
                  </v-col>

                  <v-col cols="12" md="5" class="pb-2">
                    <sca-mail-accounts v-model="company.mail_accounting" :disabled="!canSave" :min="companyNeedMailAccount() ? 1 : 0" :class="companyNeedMailAccount() ? 'required' : ''" tab="tab-info" :max="10" :label="$t('E-Mail accounting')" :error="getErrorExist('mail_accounting')" :error-count="getErrorCount('mail_accounting')" :error-messages="getErrors('mail_accounting')" @change="onChangeMails" />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col class="ml-1">
                    <v-switch dense inset flat v-model="useHQAddress" :label="$t('Use head-quarter address for invoicing.')" />
                  </v-col>
                  <v-col>
                    <sca-company-billing-type-select v-model="company.billing_type" />
                  </v-col>
                </v-row>

                <v-row v-if="!useHQAddress" no-gutters>
                  <v-col cols="12" sm="6" class="pr-2">
                    <v-text-field v-model="company.b_street" :label="$t('Billing address')" :disabled="!canSave || useHQAddress" @blur="fixCase(company)" tab="tab-info" :error="getErrorExist('b_street')" :error-count="getErrorCount('b_street')" :error-messages="getErrors('b_street')" />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field v-model="company.b_street2" :label="$t('Complement')" :disabled="!canSave || useHQAddress" :error="getErrorExist('b_street2')" :error-count="getErrorCount('b_street2')" :error-messages="getErrors('b_street2')" @blur="fixCase(company)" />
                  </v-col>
                </v-row>

                <v-row v-else no-gutters>
                  <v-col cols="12" sm="6" class="pr-2">
                    <v-text-field v-model="company.h_street" :label="$t('Billing address')" disabled />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field v-model="company.h_street2" :label="$t('Complement')" disabled />
                  </v-col>
                </v-row>

                <v-row v-if="!useHQAddress" no-gutters>
                  <v-col cols="12" sm="2" class="pr-2">
                    <v-text-field v-if="zipCodeWithLetters(company.b_country)" v-model="company.b_zipcode" :label="$t('Zip code')" :rules="[$stratus.services.form.rules.alphaNumericSpace]" tab="tab-info" :error="getErrorExist('b_zipcode')" :error-count="getErrorCount('b_zipcode')" :error-messages="getErrors('b_zipcode')" />

                    <cs-zipcode-input v-else v-model="company.b_zipcode" :label="$t('Zip code')" :disabled="!canSave" tab="tab-info" :error="getErrorExist('b_zipcode')" :error-count="getErrorCount('b_zipcode')" :error-messages="getErrors('b_zipcode')" />
                  </v-col>

                  <v-col cols="12" sm="6" class="pr-2">
                    <v-text-field v-model="company.b_city" :disabled="!canSave" :label="$t('Town')" tab="tab-info" :error="getErrorExist('b_city')" :error-count="getErrorCount('b_city')" :error-messages="getErrors('b_city')" @blur="fixCase(company)" />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <cs-country-select v-model="company.b_country" format="cca3" :disabled="!canSave" tab="tab-info" :error="getErrorExist('b_country')" :error-count="getErrorCount('b_country')" :error-messages="getErrors('b_country')" />
                  </v-col>
                </v-row>

                <v-row v-else no-gutters>
                  <v-col cols="12" sm="2" class="pr-2">
                    <v-text-field v-model="company.h_zipcode" disabled :label="$t('Zip code')" />
                  </v-col>

                  <v-col cols="12" sm="6" class="pr-2">
                    <v-text-field v-model="company.h_city" disabled :label="$t('Town')" />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <cs-country-select v-model="company.h_country" format="cca3" disabled />
                  </v-col>
                </v-row>
              </div>
            </cs-expand-panel>

            <cs-expand-panel v-if="isLord && canShowBusinessFields && canReadOpportunities && company.turnover" :header="$t('Turnover')" icon="$vuetify.icons.price" :expanded="expandTurnover">
              <div slot="content">
                <sca-turnover-graph :data="company.turnover" />
              </div>
            </cs-expand-panel>
          </v-tab-item>

          <v-tab-item value="tab-users" eager>
            <v-row v-if="usersForCompany && usersForCompany.length">
              <v-col cols="12">
                <v-row dense>
                  <v-col cols="8" md="6">
                    <cs-search-input :label="$t('Search...')" @search="onSearchUser" :disabled="false" />
                  </v-col>

                  <v-col v-if="canCreateUser" class="text-right">
                    <v-btn small class="primary" fab right @click="createUser">
                      <v-icon>
                        $vuetify.icons.add
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row align="start" justify="start" no-gutters>
                  <v-col v-for="(item, index) in usersForCompanyFiltered" :key="index" cols="12" md="6" lg="4" xl="3">
                    <sca-contact-card :value="item.id" dense show-avatar show-border show-email show-phone show-role show-last-auth class="ma-2" height="120" link="emit" @link-click="openUser(item.id)" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-else-if="loadingUsers" justify="center">
              <v-col class="shrink text-no-wrap">
                <cs-alert-panel type="loading" />
              </v-col>
            </v-row>
            <v-row v-else justify="center">
              <v-col class="shrink text-no-wrap">
                <cs-alert-panel type="info" :text="$t('No user for this company.')" />
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item v-if="isLord && (update || show)" value="tab-products" eager>
            <cs-expand-panel v-if="isLord && canReadOpportunities && (update || show)" :header="$t('Price trends')" icon="$vuetify.icons.opportunity" :expanded="expandTrends" :loading="loadingOpportunity" @expand="onExpandTrends">
              <sca-price-trends ref="company-price-trend" slot="content" :data="opportunityData" :disabled="loadingOpportunity" :loading="loadingOpportunity" input-period @refresh="loadPriceTrends">
                <div v-if="isLord" slot="button-prepend">
                  <v-switch v-model="opportunityProposal" :label="$t('Include proposal (¹)')" dense hide-details class="ma-0" @change="loadPriceTrends" />
                  <v-switch v-model="opportunityAccounting" :label="$t('Accounting')" dense hide-details class="ma-0" @change="loadPriceTrends" />
                </div>
                <div v-if="isLord && opportunityProposal" slot="caption" class="text-tiny font-weight-bold">
                  {{ $t('(¹) Taking into account opportunities that are not yet on order (in qualification status or proposal). Each calculated price is multiplied by the probability field.') }}
                </div>
              </sca-price-trends>
            </cs-expand-panel>

            <cs-expand-panel :header="$t('Current subscriptions')" icon="$vuetify.icons.product" :expanded="expandSubscriptions" :loading="loadingProducts" @expand="onExpandTrends">
              <template #content>
                <v-row v-if="productsForCompany && productsForCompany.length">
                  <v-col cols="12">
                    <v-row dense align="center" class="mt-2">
                      <v-col class="shrink text-no-wrap">
                        {{ $t('Filter') }}
                      </v-col>
                      <v-col>
                        <v-chip v-for="(family, id) in productFamilies" :key="id" small class="mx-2" :color="familyFilter === id ? 'primary' : 'gray lighten-2'" @click="onFilterFamily(id)">
                          {{ $t(family.text) }}
                          <v-icon>
                            $vuetify.icons.next
                          </v-icon>
                          {{ $t('{count}{unit}', { count: family.count, unit: $tc('product|product|products', family.count) }) }}
                        </v-chip>
                      </v-col>
                    </v-row>

                    <v-row dense align="center">
                      <v-col md="6">
                        <cs-search-input :label="$t('Search...')" @search="onSearchProduct" :disabled="false" />
                      </v-col>
                      <v-col md="6" class="text-caption">
                        {{ $t('Tickets and cancelled or stopped subscriptions are not in this list.') }}
                      </v-col>
                    </v-row>

                    <v-data-table :headers="headers" :items="productsForCompanyFiltered" :options.sync="options" :footer-props="footerProps" :page.sync="pageProduct" :loading="loadingProducts">
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.name="{ item }">
                        {{ item.name }}
                        <div>{{ item.caption || '' }}</div>
                        <div>{{ item.customer_caption || '' }}</div>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.state="{ item }">
                        {{ $t('order-state-' + item.state) }}
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.date_begin="{ item }">
                        <span v-if="item.date_begin">
                          {{ $stratus.dt(item.date_begin).format('LL') }}
                        </span>
                        <span v-if="item.date_end">
                          <v-icon small class="px-4">
                            icon-long-arrow-right
                          </v-icon>
                          {{ $t('End: {date}', { date: $stratus.dt(item.date_end).format('LL') }) }}
                        </span>
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.date_end="{ item }">
                        {{ item.date_end ? $stratus.dt(item.date_end).format('LL') : '-' }}
                      </template>
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item._price_="{ item }">
                        <sca-product-prices-grid :prices="item" :show-margin="isLord" dense />
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row v-else-if="loadingProducts" justify="center">
                  <v-col class="shrink text-no-wrap">
                    <cs-alert-panel type="loading" />
                  </v-col>
                </v-row>
                <v-row v-else justify="center">
                  <v-col class="shrink text-no-wrap">
                    <cs-alert-panel type="info" :text="$t('No product.')" />
                  </v-col>
                </v-row>
              </template>
            </cs-expand-panel>
          </v-tab-item>

          <v-tab-item v-if="canListFollowUp" value="tab-follow-up" eager>
            <v-row align="center" no-gutters>
              <v-col cols="6" md="3" lg="2" class="pr-2">
                <cs-date-picker v-model="beginAt" format-short :disabled="loadingFollowUp" :label="$t('From date')" />
              </v-col>

              <v-col cols="6" md="3" lg="2" class="pr-2">
                <cs-date-picker v-model="endAt" format-short :disabled="loadingFollowUp" :label="$t('To date')" />
              </v-col>

              <v-col>
                <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_LAST_WEEK)">
                  <v-icon small left color="menu-icon">
                    icon-clock
                  </v-icon>
                  {{ $t('Last week') }}
                </v-btn>
                <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_WEEK)">
                  <v-icon small left color="menu-icon">
                    icon-clock
                  </v-icon>
                  {{ $t('Current week') }}
                </v-btn>
                <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_MONTH)">
                  <v-icon small left color="menu-icon">
                    icon-clock
                  </v-icon>
                  {{ $t('Current month') }}
                </v-btn>
                <v-btn rounded small class="shortcut-button mr-2" @click="setDateRange(RANGE_CURRENT_YEAR)">
                  <v-icon small left color="menu-icon">
                    icon-clock
                  </v-icon>
                  {{ $t('Current year') }}
                </v-btn>
              </v-col>

              <v-col class="shrink">
                <cs-refresh-button :loading="loadingFollowUp" @click="refreshFollowUp" />
              </v-col>
            </v-row>

            <csm-company-follow-up ref="company-follow-up" :company-code="company.code" :begin-at="beginAt" :end-at="endAt" allow-add @loaded="onFollowupLoaded" />
          </v-tab-item>

          <v-tab-item v-if="isLord && company.code" value="tab-tickets" class="pa-2">
            <csm-ticket-grid ref="company-ticket-grid" :query="queryCompanyTickets" :options="ticketGridOptions" :columns="ticketColumns" :save-preferences="false" hide-toggles hide-global-incident inline-filter filter-project />
          </v-tab-item>

          <v-tab-item v-if="canListTransactions && ticketsTransactions" value="tab-transactions" class="pa-2">
            <v-row justify="space-between">
              <v-col class="text-center">
                <div class="text-h5">
                  {{ $t('Credit remaining') }}
                </div>

                <div>
                  <v-chip large class="text-h4 primary">
                    {{ $n(ticketsTransactions.current_credits || 0, 'currency') }}
                  </v-chip>
                </div>
              </v-col>

              <v-col class="text-center">
                <cs-helper>
                  <div slot="content" class="text-h5">
                    {{ $t('Expiring credit') }}
                  </div>
                  <div slot="help">
                    {{ $t('Amount of credit that will expire at the end of the year.') }}
                  </div>
                </cs-helper>

                <div>
                  <v-chip large class="text-h4">
                    {{ $n(ticketsTransactions.current_credits_expire_end_year || 0, 'currency') }}
                  </v-chip>
                </div>
              </v-col>

              <v-col class="text-center">
                <cs-helper>
                  <div slot="content" class="text-h5">
                    {{ $t('Credit after expiration') }}
                  </div>
                  <div slot="help">
                    {{ $t('The amount of credit that will remain after expiry.') }}
                  </div>
                </cs-helper>

                <div>
                  <v-chip large class="text-h4">
                    {{ $n(ticketsTransactions.rest_credits_next_year || 0, 'currency') }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="ticketsTransactionsYears?.length" no-gutters class="align-start mt-4">
              <v-col cols="12">
                <v-row align="center" dense>
                  <v-col cols="4" md="2" lg="1" class="text-h6">
                    {{ $t('Year') }}
                  </v-col>

                  <v-col cols="2" class="text-center">
                    {{ $t('End of year') }}
                  </v-col>

                  <v-col cols="2" class="text-center">
                    {{ $t('Start of year') }}
                  </v-col>

                  <v-col cols="2" class="text-center">
                    {{ $t('Positive') }}
                  </v-col>

                  <v-col cols="2" class="text-center">
                    {{ $t('Negative') }}
                  </v-col>
                </v-row>

                <div v-for="year in ticketsTransactionsYears" :key="year">
                  <v-divider class="my-2" />

                  <cs-expand-panel block>
                    <template v-slot:header="{ expanded }">
                      <v-row align="center" dense :class="expanded ? 'font-weight-bold' : ''">
                        <v-col cols="4" md="2" lg="1" class="text-h6">
                          {{ year }}
                        </v-col>

                        <v-col cols="2" class="text-right">
                          {{ $stratus.services.format.asSignedCurrency(ticketsTransactions.credits_by_year[year].amount) }}
                        </v-col>

                        <v-col cols="2" class="text-right">
                          {{ $stratus.services.format.asSignedCurrency(ticketsTransactions.credits_by_year[year].rest_last_year) }}
                        </v-col>

                        <v-col cols="2" class="text-right">
                          {{ $stratus.services.format.asSignedCurrency(ticketsTransactions.credits_by_year[year].positive) }}
                        </v-col>

                        <v-col cols="2" class="text-right">
                          {{ $stratus.services.format.asSignedCurrency(ticketsTransactions.credits_by_year[year].negative) }}
                        </v-col>
                      </v-row>
                    </template>

                    <v-simple-table slot="content" dense>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('Date') }}
                          </th>
                          <th class="text-left">
                            {{ $t('Amount') }}
                          </th>
                          <th class="text-left">
                            {{ $t('Comment') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="ticketsTransactions.credits_by_year[year]?.credits?.length">
                        <tr v-for="item in ticketsTransactions.credits_by_year[year].credits" :key="item._id" dense>
                          <td class="text-no-wrap">
                            {{ $stratus.dt(item.date).format('ll') }}
                          </td>

                          <td class="text-no-wrap">
                            {{ $stratus.services.format.asSignedCurrency(item.amount) }}
                          </td>

                          <td>
                            {{ item.comment }}
                            <div class="d-flex align-center">
                              <sca-ticket-identity v-if="item.id_ticket" :value="item.id_ticket" class="mr-1" link="emit" @link-click="openTicket(item.id_ticket)" />
                              <sca-subscription-identity v-if="item.id_oneline" :value="item.id_oneline" class="mr-1" :label="item.id_oneline" link="emit" @link-click="openSubscription(item.id_oneline)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="4" class="text-center">
                            {{ $t('No data...') }}
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </cs-expand-panel>
                </div>
                <v-divider class="my-2" />
              </v-col>
            </v-row>
            <div v-else class="py-8 text-center">
              {{ $t('No history') }}
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-form>

      <csm-user-dialog ref="user-dialog" />
      <csm-ticket-questionnaire-dialog ref="ticket-questionnaire-dialog" />
      <csm-ticket-dialog ref="ticket-dialog" />
      <csm-subscription-dialog ref="subscription-dialog" />
      <csm-company-resume-dialog ref="company-resume-dialog" @apply="applyCompany" />
      <cs-confirm-dialog ref="company-confirm-dialog" />
    </div>

    <sca-footer-create-update-at-by v-model="company" slot="footer" :link-user="isLord" />
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const COUNTRIES_ZIPCODE_WITH_LETTERS = ['CAN']
const COUNTRIES_VAT_MANDATORY = ['FRA']
// const COUNTRIES_VAT_VALIDATION = ['FRA'] // Validate VAT for these countries only

const NUMBER_OF_MONTH = 12

export default {
  name: 'CsmCompanyForm',
  components: {
    'csm-company-resume-dialog': () => import(/* webpackChunkName: "components" */ './CompanyResumeDialog.vue'),
    'csm-company-follow-up': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/CompanyFollowUp'),
    'csm-ticket-questionnaire-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketQuestionnaireDialog'),
    'csm-ticket-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGrid')
  },
  props: {
    update: { type: Boolean, default: false },
    show: { type: Boolean, default: true }
  },
  data () {
    return {
      COMPANY_BUSINESS_FIELDS: this.$alto.defines.COMPANIES.COMPANY_BUSINESS_FIELDS,
      CUSTOMER_COMPANY_TYPES: this.$alto.defines.COMPANIES.CUSTOMER_COMPANY_TYPES,
      TYPE_SUSPECT: this.$alto.defines.COMPANIES.TYPE_SUSPECT,
      TYPE_PROSPECT: this.$alto.defines.COMPANIES.TYPE_PROSPECT,
      TYPE_CUSTOMER: this.$alto.defines.COMPANIES.TYPE_CUSTOMER,
      TYPE_EX_CUSTOMER: this.$alto.defines.COMPANIES.TYPE_EX_CUSTOMER,
      DESCRIPTION_MAX_LENGTH: 8000,
      DESCRIPTION_MIN_LENGTH: 0,
      RANGE_CURRENT_YEAR: this.$alto.defines.DATES.RANGE_CURRENT_YEAR,
      RANGE_CURRENT_MONTH: this.$alto.defines.DATES.RANGE_CURRENT_MONTH,
      RANGE_CURRENT_WEEK: this.$alto.defines.DATES.RANGE_CURRENT_WEEK,
      RANGE_LAST_WEEK: this.$alto.defines.DATES.RANGE_LAST_WEEK,
      TICKET_TYPES_FOR_SELECT: [this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT, this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST],
      allExpanded: false,
      barChartData: {
        labels: [],
        datasets: [{ data: [] }]
      },
      barChartTickets: [],
      barChartTicketsLabel: '',
      beginAt: null,
      canEditCurrentTopics: false,
      company: {},
      endAt: null,
      errors: null,
      expandTurnover: false,
      expandInvoicing: false,
      expandContacts: false,
      expandAdditional: false,
      expandDomicile: false,
      expandSubscriptions: false,
      expandTrends: false,
      familyFilter: null,
      filteredUsers: [],
      followUpCount: 0,
      footerProps: { 'items-per-page-options': [10, 25, 50, -1] },
      headers: [{
        text: this.$t('Ref'),
        value: 'ref'
      }, {
        text: this.$t('Name'),
        value: 'name'
      }, {
        text: this.$t('Quantity'),
        value: 'quantity',
        align: 'right'
      }, {
        text: this.$t('State'),
        value: 'state'
      }, {
        text: this.$t('Begin date'),
        value: 'date_begin'
      }, {
        text: this.$t('Prices'),
        value: '_price_',
        align: 'center'
      }],
      isSaving: false,
      isSearching: false,
      listEmployeeNumbers: [],
      listFirstContacts: [],
      listPaymentMethods: [],
      listPaymentTerms: [],
      listSalesPersons: [],
      listTags: [],
      listTypes: [],
      loadingFollowUp: false,
      loadingOpportunity: false,
      loadingOverview: false,
      loadingProducts: false,
      loadingTickets: false,
      loadingTicketsTransactions: false,
      loadingUsers: false,
      notes: [],
      opportunityAccounting: false,
      opportunityData: {},
      opportunityProposal: false,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['date_begin'],
        sortDesc: [true],
        multiSort: true,
        mustSort: false
      },
      pageProduct: 1,
      productFamilies: [],
      productsForCompany: null,
      roles: [],
      rowsPerPageProduct: 4,
      ticketSurveyAnalysis: null,
      searchProduct: '',
      searchUsers: '',
      showErrors: false,
      showSatisfactions: false,
      subscriptionsByDomain: [],
      subscriptionsByDomainList: [],
      subscriptionsByDomainPrices: {},
      subscriptionsByDomainQuantities: {},
      tab: 'tab-info',
      tabErrors: {},
      ticketsByMonth: [],
      ticketGridOptions: {
        allowAdvancedSearch: false,
        create: false,
        sortBy: 'create_at'
      },
      ticketRange: NUMBER_OF_MONTH,
      ticketsTransactions: {},
      ticketsTransactionsYears: [],
      useHQAddress: false,
      user: null,
      userDialog: false,
      usersForCompany: null,
      uuid: this.$stratus.uuid(),
      valid: true,
      validHeaders: true,
      visible: false,
      // VATRule: v => {
      //   if (!COUNTRIES_VAT_VALIDATION.includes(this.company?.b_country)) return true
      //   if (v && v !== '') {
      //     return (this.$alto.services.vat.validate({ vatNumber: v }) || this.$t('VAT number must be valid!'))
      //   } else if (this.companyNeedVat()) {
      //     return this.$t('VAT is mandatory!')
      //   }
      //   return true
      // },
      IbanRule: v => {
        if (this.companyNeedsIbanBic()) {
          // Test IBAN if BIC is not filled-in
          if (this.$alto.defines.COMPANIES.DIRECT_DEBIT_PAYMENT_METHODS.includes(this.company.payment_method) && (!this.company.bic || this.company.bic.length === 0)) {
            return (v && v !== '') || this.$t('BIC or IBAN is mandatory!')
          }
        }
        return true
      },
      BicRule: v => {
        if (this.companyNeedsIbanBic()) {
          // Test BIC if IBAN is not filled-in
          if (this.$alto.defines.COMPANIES.DIRECT_DEBIT_PAYMENT_METHODS.includes(this.company.payment_method) && (!this.company.iban || !this.company.iban.length === 0)) {
            return (v && v !== '') || this.$t('BIC or IBAN is mandatory!')
          }
        }
        return true
      }
    }
  },
  watch: {
    company (newValue, oldValue) {
      if (newValue !== oldValue && this.$refs['company-price-trend']) {
        if (this.$refs['company-price-trend']) this.$refs['company-price-trend'].empty() // Empty graph as we've changed the company
      }
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      subtypes: '$alto-companies/subtypes'
    }),
    action () {
      if (this.update) return this.$t('Update')
      return this.show ? this.$t('Details') : this.$t('Create')
    },
    barChartOptions () {
      return {
        // Click on a bar to load it's opportunities
        onClick (e) {
          /* --- IMPORTANT! ---
           * This is a native click event, outside Vue scope!
           * Value of «this» is not vue but the chart instance this event is plug to.
           */
          const element = this.getElementAtEvent(e)
          if (element.length > 0) {
            // Create a focus effect by changing the background color of the bar
            // Reset all colors
            _.forEach(this.data.datasets, dataset => {
              dataset.backgroundColor = dataset._backgroundColor
            })
            // Set the focus
            const bc = new Array(this.data.datasets[element[0]._datasetIndex].data.length).fill(this.data.datasets[element[0]._datasetIndex]._backgroundColor) // Restore native color
            bc[element[0]._index] = this.data.datasets[element[0]._datasetIndex].borderColor
            this.data.datasets[element[0]._datasetIndex].backgroundColor = bc
            // Forge a query to load opportunities for this bar
            this.data.datasets[element[0]._datasetIndex].computeTickets(
              this.data.datasets[element[0]._datasetIndex].tickets[element[0]._index],
              this.data.datasets[element[0]._datasetIndex].label,
              this.data.labels[element[0]._index]
            )
            this.update()
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            stacked: false
          }],
          yAxes: [{
            type: 'linear',
            ticks: {
              beginAtZero: true,
              min: 0,
              callback: function (value) {
                // Display only ticks for integer value
                if (value % 1 === 0) { return value }
              }
            }
          }]
        }
      }
    },
    businessSectors () {
      return this.$store.getters['$alto-companies/businessSectors']
    },
    canCreateUser () {
      return this.me?.company && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.CREATE)
    },
    canListFollowUp () {
      return this.company?.code && (this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.LIST) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.LIST))
    },
    canListTransactions () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_TRANSACTIONS, this.$alto.API_PERMISSIONS.LIST)
    },
    canModifySalesPerson () {
      return this.isLord && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.COMPANIES_UPDATE_SALES_PERSON)
    },
    canReadOpportunities () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, this.$alto.API_PERMISSIONS.OPPORTUNITIES_COMPANY)
    },
    canSave () {
      return (this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.UPDATE))
    },
    canShowBusinessFields () {
      return (this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.COMPANIES_BUSINESS_FIELDS))
    },
    canUpdateUser () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    chartStyles () {
      return {
        height: '240px',
        width: '100%',
        position: 'relative'
      }
    },
    companyNeedSiret () {
      return this.company && ((this.useHQAddress && this.company.h_country === 'FRA') || (!this.useHQAddress && this.company.b_country === 'FRA'))
    },
    // dark () { return this.$store.getters['$stratus-states/isDark'] },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isClient () { return this.company.type === this.$alto.defines.COMPANIES.TYPE_CUSTOMER },
    isForeignCompany () {
      const country = this.company.b_country || this.company.h_country // Country of billing then headquarter
      return country && country !== this.$store.getters.appConfig.defaults.oemCountry
    },
    isHealthCare () {
      return this.company.business_sector === this.$alto.defines.COMPANIES.BUSINESS_SECTOR_HEALTHCARE
    },
    productsForCompanyFiltered () {
      const tmpProducts = this.familyFilter ? _.filter(this.productsForCompany, { familyId: this.familyFilter }) : this.productsForCompany
      return !this.searchProduct
        ? tmpProducts
        : _.filter(tmpProducts, item => {
          return (item.ref || '').toLowerCase().includes(this.searchProduct.toLowerCase()) ||
          (item.caption || '').toLowerCase().includes(this.searchProduct.toLowerCase()) ||
          (item.customer_caption || '').toLowerCase().includes(this.searchProduct.toLowerCase()) ||
          (item.name || '').toLowerCase().includes(this.searchProduct.toLowerCase())
        })
    },
    queryCompanyTickets () {
      // Tickets created last 3 months
      return `code[in]=${this.company.code}!!create_at[gte]=${this.$stratus.dt().subtract(3, 'month').startOf('month').format('YYYY-MM-DD')}!!create_at[lte]=${this.$stratus.dt().endOf('day').format('YYYY-MM-DD')}`
    },
    ticketColumns () {
      return [{
        text: 'Type',
        value: 'type'
      }, {
        text: 'Created at',
        value: 'create_at',
        hidden: false,
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : ''
      }, {
        text: 'State',
        value: 'state'
      }, {
        text: 'Closure date',
        value: 'date_closure',
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : ''
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Referrer',
        value: 'id_referring'
      }]
    },
    title () {
      let title = this.company.code || ''
      if (this.company.code_no_customer && this.company.code_no_customer !== this.company.code) title += ' / ' + this.company.code_no_customer
      if (this.company.name) title += ' — ' + this.company.name
      return this.$stratus.services.strings.stripHtmlTags(title)
    },
    usersForCompanyFiltered () {
      return !this.searchUsers
        ? this.usersForCompany
        : _.filter(this.usersForCompany, item => {
          return (item.lastname || '').toLowerCase().includes(this.searchUsers.toLowerCase()) || (item.firstname || '').toLowerCase().includes(this.searchUsers.toLowerCase()) || (item.email || '').toLowerCase().includes(this.searchUsers.toLowerCase()) || (item.phone || '').toLowerCase().includes(this.searchUsers.toLowerCase())
        })
    },
    vatExcludingList () {
      return this.$alto.defines.COMPANIES.VAT_INTRACOM_EXCLUDE_BUSINESS_SECTORS.join(', ')
    },
    vatMandatoryList () {
      return this.$alto.defines.COMPANIES.VAT_INTRACOM_TYPES.join(', ')
    }
  },
  methods: {
    applyCompany (company) {
      if (company) {
        if (company.siren !== undefined) this.$set(this.company, 'siren', company.siren)
        if (company.nic !== undefined) this.$set(this.company, 'nic', company.nic)
        if (company.business_creation_date !== undefined) this.$set(this.company, 'business_creation_date', company.business_creation_date)
        if (company.name !== undefined) this.$set(this.company, 'name', company.name)
        if (company.name_billing !== undefined) this.$set(this.company, 'name_billing', company.name_billing)
        if (company.employee_number !== undefined) this.$set(this.company, 'employee_number', company.employee_number)
        if (company.b_street !== undefined) {
          this.useHQAddress = false
          this.$set(this.company, 'b_street', company.b_street)
          this.$set(this.company, 'h_street', company.b_street)
        }
        if (company.b_street2 !== undefined) {
          this.useHQAddress = false
          this.$set(this.company, 'b_street2', company.b_street2)
          this.$set(this.company, 'h_street2', company.b_street2)
        }
        if (company.b_city !== undefined) {
          this.useHQAddress = false
          this.$set(this.company, 'b_city', company.b_city)
          this.$set(this.company, 'h_city', company.b_city)
          this.$set(this.company, 'b_country', 'FRA')
          this.$set(this.company, 'h_country', 'FRA')
        }
        if (company.b_zipcode !== undefined) {
          this.useHQAddress = false
          this.$set(this.company, 'b_zipcode', company.b_zipcode)
          this.$set(this.company, 'h_zipcode', company.b_zipcode)
        }
      }
    },
    canEditForType (type) {
      if (!this.company.code) return true // Can choose any type
      if (this.company.type === this.TYPE_CUSTOMER) return false
      if (this.company.type === this.TYPE_PROSPECT) return type === this.TYPE_CUSTOMER
      if (this.company.type === this.TYPE_SUSPECT) return type === this.TYPE_PROSPECT
    },
    canHighlightType (type) {
      switch (this.company.type) {
        case this.TYPE_CUSTOMER:
          return true
        case this.TYPE_PROSPECT:
          return [this.TYPE_SUSPECT, this.TYPE_PROSPECT].includes(type)
        case this.TYPE_SUSPECT:
          return type === this.TYPE_SUSPECT
        default:
          return false
      }
    },
    changeType (newType) {
      if (this.company.type === this.TYPE_CUSTOMER) return
      if (this.company.type === this.TYPE_SUSPECT && newType !== this.TYPE_PROSPECT) return
      if (this.company.type === this.TYPE_PROSPECT && newType !== this.TYPE_CUSTOMER) return
      this.$set(this.company, 'type', newType)
      this.tab = 'tab-info'
    },
    closeDialog (saved) {
      this.$emit('closeDialog', saved)
    },
    closeUserDialog () {
      this.userDialog = false
      if (this.canUpdateUser) this.loadUsersForCompany()
    },
    companyNeedsIbanBic () {
      return !this.$alto.defines.COMPANIES.NO_IBAN_BIC_TYPES.includes(this.company.type)
    },
    companyNeedMailAccount () {
      return this.$alto.defines.COMPANIES.MAIL_ACCOUNTING_TYPES.includes((this.company.type))
    },
    companyNeedVat () {
      if (!COUNTRIES_VAT_MANDATORY.includes(this.company?.h_country)) return false
      return this.$alto.defines.COMPANIES.VAT_INTRACOM_TYPES.includes(this.company?.type?.toLowerCase()) &&
       !this.$alto.defines.COMPANIES.VAT_INTRACOM_EXCLUDE_BUSINESS_SECTORS.includes(this.company?.business_sector?.toLowerCase())
    },
    computeTickets (tickets, label, month) {
      this.loadingTickets = true
      setTimeout(() => {
        this.$nextTick(() => {
          this.barChartTickets = tickets || []
          this.barChartTicketsLabel = `${label} ${month}`
          setTimeout(() => { this.loadingTickets = false }, 2000)
        })
      }, 500)
    },
    createGraphData () {
      const dataSets = []
      const series = {} // Array of values by type of ticket
      const labels = []
      const tickets = {} // Array of tickets by type

      // Initialize all data needed for graph
      for (let m = 0; m < this.ticketRange; m++) {
        const month = this.$stratus.dt().subtract(m, 'month').format('MM/YYYY')
        labels.unshift(month)
      }
      _.forEach(this.TICKET_TYPES_FOR_SELECT, type => {
        series[type] = _.fill(Array(this.ticketRange), 0)
        tickets[type] = _.fill(Array(this.ticketRange), null)
      })

      if (this.ticketsByMonth?.length > 0) {
        // Graph is per month
        _.forEach(this.ticketsByMonth, ticket => {
          const indexOfMonth = _.indexOf(labels, this.$stratus.dt(ticket.create_at).format('MM/YYYY'))
          series[ticket.type][indexOfMonth] += 1 // One ticket of this type for its month of creation
          if (!tickets[ticket.type][indexOfMonth]) tickets[ticket.type][indexOfMonth] = []
          tickets[ticket.type][indexOfMonth].push(ticket)
        })

        _.forEach(this.TICKET_TYPES_FOR_SELECT, type => {
          dataSets.push({
            label: this.$t(`ticket-type-${type}`),
            backgroundColor: this.$alto.defines.TICKETS.COLORS_TICKET_TYPE[type].opacity80,
            _backgroundColor: this.$alto.defines.TICKETS.COLORS_TICKET_TYPE[type].opacity80,
            borderColor: this.$alto.defines.COLORS.PRIMARY,
            hoverBorderColor: this.$alto.defines.COLORS.PRIMARY,
            borderWidth: 0,
            data: _.map(series[type], item => item),
            tickets: tickets[type], // Internal use
            computeTickets: this.computeTickets
          })
        })
      }
      this.barChartData.labels = labels
      this.barChartData.datasets = dataSets
      if (this.$refs['company-tickets-bar-chart']) this.$refs['company-tickets-bar-chart'].update()
    },
    createUser () {
      if (!this.me?.company) return
      this.user = {
        type: 'user',
        role: 'bus',
        company: this.me?.company
      }
      this.openUser(this.user)
    },
    externalId () {
      if (!this.company?.code) return
      return [this.company.code, this.company.code ? 'companies/' + this.company.code : '', this.company.name || '']
    },
    fixCase (company) {
      if (!company) return
      company.name = this.$stratus.services.strings.toUpperCase(company.name)
      if (company.name_billing) company.name_billing = this.$stratus.services.strings.toUpperCase(company.name_billing)
      if (company.h_city) company.h_city = this.$stratus.services.strings.inflector.titleize(company.h_city)
      if (company.h_street) company.h_street = this.$stratus.services.strings.inflector.titleize(company.h_street)
      if (company.h_street2) company.h_street2 = this.$stratus.services.strings.inflector.titleize(company.h_street2)
      if (company.b_city) company.b_city = this.$stratus.services.strings.inflector.titleize(company.b_city)
      if (company.b_street) company.b_street = this.$stratus.services.strings.inflector.titleize(company.b_street)
      if (company.b_street2) company.b_street2 = this.$stratus.services.strings.inflector.titleize(company.b_street2)
      return company
    },
    getErrorCount (fieldName) {
      return this.errors?.[fieldName]?.length || 0
    },
    getErrorExist (fieldName) {
      return this.errors?.[fieldName]?.length > 0
    },
    getErrors (fieldName) {
      if (!this.errors?.[fieldName]) return []
      return _.map(this.errors?.[fieldName], error => this.$t(error))
    },
    getFamily (id) { return this.$store.getters['$alto-catalog/getFamily'](id) },
    getProductByRef (ref) { return this.$store.getters['$alto-catalog/get'](ref) },
    getRole (id) {
      const found = _.find(this.roles, { value: id })
      return found ? found.text : id
    },
    getSurveyText (id) {
      const survey = _.find(this.ticketSurveyAnalysis.questionnaires_analysed, { id })
      return survey ? `${survey.id_ticket} (${this.$stratus.dt(survey.answered_at).format('LL')})` : this.$t('Questionnaire {id}', { id })
    },
    async loadLists () {
      this.isSaving = true
      try {
        await this.$store.dispatch('$alto-companies/loadTypes')
        this.listTypes = this.$store.getters['$alto-companies/types']

        await this.$store.dispatch('$alto-companies/loadSubTypes')

        await this.$store.dispatch('$alto-companies/loadBusinessSectors')

        if (this.isLord) {
          await this.$store.dispatch('$alto-companies/loadEmployeeNumbers')
          this.listEmployeeNumbers = this.$store.getters['$alto-companies/employeeNumbers']

          await this.$store.dispatch('$alto-companies/loadFirstContacts')
          this.listFirstContacts = this.$store.getters['$alto-companies/firstContacts']

          await this.$store.dispatch('$alto-users/loadSalesPersons')
          this.listSalesPersons = this.$store.getters['$alto-users/salesPersons']

          await this.$store.dispatch('$alto-companies/loadTags')
          this.listTags = this.$store.getters['$alto-companies/tags']()
        }

        await this.$store.dispatch('$alto-users/loadRoles')
        this.roles = this.$store.getters['$alto-users/roles']

        await this.$store.dispatch('$alto-catalog/loadFamilies')

        await this.$store.dispatch('$alto-companies/loadPaymentTerms')
        this.listPaymentTerms = this.$store.getters['$alto-companies/paymentTerms']

        await this.$store.dispatch('$alto-companies/loadPaymentMethods')
        this.listPaymentMethods = this.$store.getters['$alto-companies/paymentMethods']

        this.loadTicketsTransactions()

        this.isSaving = false
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.isSaving = false
      } finally {
        this.setDateRange(this.RANGE_CURRENT_WEEK)
      }
    },
    async loadOverview () {
      if (!this.isLord || !this.CUSTOMER_COMPANY_TYPES.includes(this.company.type) || !this.company.code) return
      this.loadingOverview = true
      this.tab = 'tab-overview'

      try {
        this.ticketSurveyAnalysis = await this.$store.dispatch('$alto-ticketing/getQuestionnairesAnalysis', { company: this.company.code })

        this.subscriptionsByDomain = await this.$store.dispatch('$alto-subscriptions/getSubscriptionsByDomain', { company: this.company.code })
        this.subscriptionsByDomainList = Object.keys(this.subscriptionsByDomain)
        const quantities = {}
        const prices = {}
        _.forEach(this.subscriptionsByDomainList, domain => {
          quantities[domain] = this.subscriptionsByDomain[domain].quantity
          prices[domain] = this.subscriptionsByDomain[domain].price
          delete this.subscriptionsByDomain[domain].quantity
          delete this.subscriptionsByDomain[domain].price
        })
        this.subscriptionsByDomainQuantities = quantities
        this.subscriptionsByDomainPrices = prices

        this.loadTicketHistory()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loadingOverview = false }, 1000)
      }
    },
    async loadPriceTrends ({ dateBegin, dateEnd } = {}) {
      this.loadingOpportunity = true
      try {
        this.opportunityData = await this.$store.dispatch('opportunities/getCompaniesHistory', {
          ids: [this.company.code],
          dateBegin: dateBegin || this.$stratus.dt().subtract(6, 'month').startOf('month'),
          dateEnd: dateEnd || this.$stratus.dt(),
          countable: this.opportunityAccounting,
          proposal: this.opportunityProposal,
          proposalSum: this.opportunityProposal
        })
        if (this.$refs['company-price-trend']) this.$refs['company-price-trend'].reset()
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.opportunityData = {}
      }
      setTimeout(() => { this.loadingOpportunity = false }, 1000)
    },
    async loadProductsForCompany () {
      if (!this.company) return

      this.loadingProducts = true
      this.productsForCompany = []

      try {
        this.productsForCompany = await this.$store.dispatch('$alto-orders/getActiveProductsForCompany', this.company.code)
        const prodFamilies = {}
        _.forEach(this.productsForCompany, prod => {
          const p = this.getProductByRef(prod.ref)
          if (p?.family) {
            const family = this.getFamily(p.family)
            prod.family = family
            prod.familyId = family.id
            if (!prodFamilies[family.id]) prodFamilies[family.id] = { text: family.text, count: 0 }
            prodFamilies[family.id].count += prod.quantity
          }
        })
        this.productFamilies = prodFamilies
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }

      setTimeout(() => { this.loadingProducts = false }, 500)
    },
    async loadTicketHistory () {
      if (!this.isLord || !this.CUSTOMER_COMPANY_TYPES.includes(this.company.type) || !this.company.code) return
      this.loadingTickets = true

      try {
        const ticketsByMonth = await this.$store.dispatch('$alto-ticketing/getTickets', {
          company: this.company.code,
          types: this.TICKET_TYPES_FOR_SELECT,
          sortBy: 'create_at',
          createdAfter: this.$stratus.dt().subtract(this.ticketRange, 'month').startOf('month'),
          createdBefore: this.$stratus.dt().endOf('day')
        })
        this.ticketsByMonth = ticketsByMonth?.results || []
        this.createGraphData()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loadingTickets = false }, 1000)
      }
    },
    async loadTicketsTransactions () {
      if (!this.canListTransactions) return

      this.loadingTicketsTransactions = true
      try {
        this.ticketsTransactions = await this.$store.dispatch('$alto-ticketing/getTicketTransactionForCompany', { code: this.company.code })
        this.ticketsTransactionsYears = Object.keys(this.ticketsTransactions?.credits_by_year || {})
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.loadingTicketsTransactions = false }, 1000)
      }
    },
    async loadUsersForCompany () {
      if (!this.company) return

      this.loadingUsers = true
      this.usersForCompany = []

      try {
        this.usersForCompany = await this.$store.dispatch('$alto-users/list', { filterCompanies: this.company.code, includeNotActive: this.isLord })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }

      setTimeout(() => { this.loadingUsers = false }, 500)
    },
    onChangeMails (value) {
      this.company.mail_accounting = value
    },
    async onExpandTrends (expanded) {
      if (expanded) await this.loadPriceTrends()
    },
    onFilterFamily (id) {
      if (this.familyFilter === id) this.familyFilter = null
      else this.familyFilter = id
    },
    onFollowupLoaded (count) {
      this.followUpCount = count
    },
    onSearchProduct (value) {
      this.searchProduct = value
    },
    onSearchUser (value) {
      this.searchUsers = value
    },
    openSubscription (id) {
      if (this.$refs['subscription-dialog']) this.$refs['subscription-dialog'].open(id)
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    openTicketSurvey (id) {
      if (this.$refs['ticket-questionnaire-dialog']) this.$refs['ticket-questionnaire-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refreshFollowUp () {
      if (this.$refs['company-follow-up']) this.$refs['company-follow-up'].refresh()
    },
    async reset (company) {
      this.tab = 'tab-info'
      this.tabErrors = {}
      if (this.$refs['company-form-header']) await this.$refs['company-form-header'].reset()
      if (this.$refs['company-form-tabs']) await this.$refs['company-form-tabs'].reset()

      this.allExpanded = false
      this.barChartTickets = []
      this.errors = null
      this.showErrors = false
      this.expandTurnover = false
      this.expandInvoicing = false
      this.expandContacts = false
      this.expandAdditional = false
      this.expandDomicile = false
      this.expandSubscriptions = false
      this.expandTrends = false
      this.familyFilter = null
      this.userDialog = false
      this.loadingOpportunity = false
      this.loadingProducts = false
      this.loadingUsers = false
      this.productsForCompany = null
      this.searchProduct = ''
      this.searchUsers = ''
      this.ticketSurveyAnalysis = undefined
      this.showSatisfactions = false
      this.usersForCompany = null
      this.pageProduct = 1
      this.rowsPerPageProduct = 4
      this.followUpCount = 0
      this.canEditCurrentTopics = false

      await this.resetCompany(company)

      await this.loadOverview()
    },
    async resetCompany (company) {
      this.company = this.fixCase({ ...company })
      if (!this.company || (this.company.b_city === this.company.h_city && this.company.b_zipcode === this.company.h_zipcode && this.company.b_country === this.company.h_country && this.company.b_street === this.company.h_street && this.company.b_street2 === this.company.h_street2)) {
        this.useHQAddress = true
      }

      if (!this.isLord && this.company) {
        if (_.isNumber(this.company.id_lord_salesperson)) {
          await this.$store.dispatch('$alto-users/getSalesPersonById', this.company.id_lord_salesperson)
        }
        this.listSalesPersons = this.$store.getters['$alto-users/salesPersons']
        if (_.isNumber(this.company.id_lord_manager)) await this.$store.dispatch('$alto-users/loadManagers', { id: this.company.id_lord_manager })
      }
    },
    async saveDialog () {
      this.isSaving = true

      // Sanitize
      this.company.vat_warning = null
      this.company = this.$stratus.services.strings.stripHtmlTags(this.company)
      if (this.$refs['company-current-topics-editor']) {
        this.company.current_topic = this.$refs['company-current-topics-editor'].getContent()
      }

      // Validate?
      if (!this.$refs['company-form-header'].validate()) {
        this.$stratus.services.notify.warning(this.$t('One or more fields must be corrected!'))
        this.isSaving = false
        return
      }
      // Fix case
      this.company = this.fixCase(this.company)

      // Validation is OK
      if (this.useHQAddress) {
        this.company.b_city = this.company.h_city
        this.company.b_zipcode = this.company.h_zipcode
        this.company.b_country = this.company.h_country
        this.company.b_street = this.company.h_street
        this.company.b_street2 = this.company.h_street2
      }
      if (!this.company.business_creation_date) {
        this.company.business_creation_date = null // Backend requires null for empty date
      }

      try {
        this.errors = null
        // First a dry run to detect errors
        const response = await this.$store.dispatch('$alto-companies/save', { company: this.company, dryRun: true })
        if (response?.company) {
          await this.resetCompany({ ...this.company, ...response.company })
        }

        const errList = Object.keys(response?.errors || {})
        if (errList.length) {
          this.errors = response.errors // Houston, we've got a problem!
          this.expandInvoicing = true
          this.expandAdditional = true
          this.expandDomicile = true
        } else {
          if (this.company.vat_warning) {
            const confirmed = await this.$refs['company-confirm-dialog']
              .open(this.$t(this.company.vat_warning), this.$t('The VAT number is invalid. Are you sure you want to save without changing it?'))
            if (!confirmed) return false // User cancel
          }

          await this.$store.dispatch('$alto-companies/save', { company: this.company })
          if (this.update) {
            this.$stratus.services.notify.success(
              this.$t('Company {code} — {name} updated.', this.company)
            )
          } else {
            this.$stratus.services.notify.success(
              this.$t('Company {code} — {name} created.', this.company)
            )
          }
          await this.$store.dispatch('$alto-companies/fillCache', true)
          this.closeDialog(true)
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.isSaving = false
      }
    },
    setDateRange (rangeType) {
      switch (rangeType) {
        case this.RANGE_CURRENT_YEAR:
          this.beginAt = this.$stratus.dt().startOf('year').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('year').format('YYYY-MM-DD')
          break
        case this.RANGE_CURRENT_MONTH:
          this.beginAt = this.$stratus.dt().startOf('month').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('month').format('YYYY-MM-DD')
          break
        case this.RANGE_CURRENT_WEEK:
          this.beginAt = this.$stratus.dt().startOf('week').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('week').format('YYYY-MM-DD')
          break
        case this.RANGE_LAST_WEEK:
          this.beginAt = this.$stratus.dt().startOf('week').subtract(1, 'week').format('YYYY-MM-DD')
          this.endAt = this.$stratus.dt().endOf('week').subtract(1, 'week').format('YYYY-MM-DD')
          break
      }
      this.refreshFollowUp()
    },
    sirenSearch ({ company, errors }) {
      if (!company?.name) {
        this.$stratus.services.notify.warning(this.$t('No company found for SIREN "{siren}"!', { siren: this.company.siren }))
        return
      }
      if (this.$refs['company-resume-dialog']) this.$refs['company-resume-dialog'].open(company)
    },
    siretInput ({ siren, nic }) {
      if (siren) this.$set(this.company, 'siren', siren)
      if (nic) this.$set(this.company, 'nic', nic)
    },
    styleForTypeLine (type) {
      switch (this.company.type) {
        case this.TYPE_CUSTOMER:
          return ''
        // case this.TYPE_BUSINESS_INTRODUCER:
        //   return type === this.TYPE_CUSTOMER ? 'border-style-dash' : ''
        case this.TYPE_PROSPECT:
          return type === this.TYPE_CUSTOMER ? 'border-style-dash' : ''
          // return [this.TYPE_CUSTOMER, this.TYPE_BUSINESS_INTRODUCER].includes(type) ? 'border-style-dash' : ''
        default:
          return 'border-style-dash'
      }
    },
    zipCodeWithLetters (country) {
      return COUNTRIES_ZIPCODE_WITH_LETTERS.includes(country)
    },
    async display (show = false) {
      this.visible = show
      if (show) {
        // Load some data in cache
        await this.loadLists()
      }
    }
  },
  async onBeforeMount () {
    // Fill all caches
    this.$stratus.fetchCaches(false)
    this.$alto.fetchCaches(false)
  }
}
</script>
