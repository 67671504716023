<template>
  <csm-company-form :ref="`company-form-${uuid}`" :show="canShow" :update="canUpdate" @closeDialog="closeDialog" />
</template>

<script>
import _ from 'lodash'

let previousCompany = null

export default {
  name: 'CompanyDialog',
  components: { 'csm-company-form': () => import(/* webpackChunkName: "components" */ './CompanyForm') },
  data () {
    return {
      company: {},
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.company?.code && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.COMPANIES, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog (saved) {
      if (this.$refs[`company-form-${this.uuid}`]) this.$refs[`company-form-${this.uuid}`].display(false)
      previousCompany = null
      if (this.$route?.name === 'company' && this.$route?.params?.id) {
        this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
      } else {
        this.$emit('closeDialog', { company: this.company, saved })
      }
    },
    async get (id) {
      try {
        await this.$refs[`company-form-${this.uuid}`].reset({})
        if (_.isString(id)) {
          const company = await this.$store.dispatch('$alto-companies/getById', { id, noCache: true, noHistory: false })
          this.company = { ...company }
        } else {
          this.company = id || {}
        }
        if (this.$refs[`company-form-${this.uuid}`]) {
          this.$refs[`company-form-${this.uuid}`].display(true)
          await this.$refs[`company-form-${this.uuid}`].reset(this.company)
        }
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Company «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
        this.closeDialog()
      }
    },
    open (id) {
      if (!id) return
      this.get(id)
    }
  },
  async mounted () {
    if (this.$route?.name === 'company' && this.$route.params.id) {
      if (this.$route.params.id !== previousCompany) {
        previousCompany = this.$route.params.id
        await this.$store.dispatch('$stratus-states/getMe')
        this.open(this.$route.params.id)
      }
    }
  }
}
</script>
