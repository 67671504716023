<template>
  <sca-modal-dialog :visible="show" @closeDialog="close" :loading="isWorking" :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : ''">
    <template #title>
      {{ product.customer_caption || product.caption || product.name }}
      — {{ product.ref }}
    </template>

    <template v-if="isLord && (product.code || customer)" #subtitle>
      <sca-company-identity :value="product.code || customer" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(product.code)" />
    </template>

    <div slot="content">
      <v-expand-transition>
        <div v-if="productOperation === STEP_CHOICE" class="py-4">
          <div class="text-h5">
            {{ $t('What do you want to do?') }}
          </div>

          <v-row justify="center" class="py-8">
            <div class="d-flex flex-column flex-shrink-1">
              <v-btn v-if="productByRef.can_modify" class="main-button text-h5 my-4" rounded large @click="productOperation = OPERATION_MODIFY_SUB_PRODUCTS">
                {{ $t('Modify product\'s properties') }}
              </v-btn>
              <v-btn v-if="productByRef.others_periodicity && Object.keys(productByRef.others_periodicity).length > 0" class="main-button text-h5 my-4" rounded large @click="productOperation = OPERATION_MODIFY_PERIODICITY">
                {{ $t('Modify products\' periodicity') }}
              </v-btn>
              <v-btn v-if="canStop() && canStopSomeProduct" class="main-button text-h5 my-4" rounded large @click="productOperation = OPERATION_STOP_SUB_PRODUCTS">
                {{ $t('Permanently stop a part of all products') }}
              </v-btn>
              <v-btn v-if="canStop()" class="main-button text-h5 my-4" rounded large @click="productOperation = OPERATION_STOP_ALL">
                {{ $t('Permanently stop all products') }}
              </v-btn>
              <v-btn v-if="isLord && canStop()" class="main-button text-h5 my-4" rounded large @click="productOperation = OPERATION_MODIFY_COMMITMENT">
                {{ $t('Modify the commitment') }}
              </v-btn>
            </div>
          </v-row>

          <div v-if="product.description">
            <p class="text-subtitle-1">
              {{ $t('Description') }}
            </p>
            <p full-width readonly v-html="product.description" />
          </div>
        </div>
      </v-expand-transition>

      <v-expand-transition>
        <div v-if="productOperation === OPERATION_MODIFY_SUB_PRODUCTS">
          <div class="d-flex align-center">
            <div class="text-h5">
              {{ $t('Modify product\'s properties') }}
            </div>
          </div>

          <v-row justify="space-between">
            <v-col v-if="product.main && product.main.length" cols="12" md="8">
              <p>{{ $t('Please choose your new properties of your product:') }}</p>
              <csm-dyna-products-modify ref="dyna-products" :product="product" :customer="product.code || customer" :product-ref="productByRef" @change="onChange" />
            </v-col>

            <v-col cols="12" md="4">
              <v-form ref="product-form" v-model="valid" lazy-validation>
                <v-row align="center" justify="center" no-gutters>
                  <v-col v-if="isLord" cols="12">
                    <div class="mb-4">
                      <v-switch v-model="noTicket" dense class="ma-0" :label="$t('The state of the related ticket will be set to done.')" />
                      <v-text-field v-model="noTicketComment" :disabled="!noTicket" :label="$t('Comment')" :rules="[$stratus.services.form.rules.max(255)]" counter="255" />
                    </div>

                    <p>{{ $t('Select the date to apply the modifications:') }}</p>
                    <div class="text-h6 text-center button-main button-main-ink--text">
                      {{ $stratus.dt(dateUpdate).format('LL') }}
                    </div>
                    <cs-date-picker-panel v-model="dateUpdate" :max="maxDateEnd()" flat no-title full-width />
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </div>

        <div v-if="productOperation === OPERATION_MODIFY_PERIODICITY">
          <div class="d-flex align-center">
            <div class="text-h5">
              {{ $t('Modify products\' periodicity') }}
            </div>
          </div>

          <v-row justify="space-between">
            <v-col md="8">
              <p>{{ $t('Please choose the new periodicity of your product:') }}</p>
              <v-select v-model="periodicityChoice" :items="periodicityList" :label="$t('Periodicity')" :rules="[$stratus.services.form.rules.required]" class="required" />
              <p>{{ $t('For now your periodicity is {periodicity}', { periodicity: $t(`periodicity-short-${productByRef.periodicity.toUpperCase()}`) }) }}</p>

              <v-card outlined v-if="periodicityChoice === 'D' && product.code && computedPrices.total_discount_incl_vat !== undefined" class="px-2">
                <sca-product-price-simulator ref="product-price-simulator" :customer-id="product.code || customer" :product="product" />
              </v-card>
            </v-col>

            <v-col class="text-center" md="4" v-if="isLord">
              <p>{{ $t('Select the date to apply the modifications:') }}</p>
              <div class="text-h6 text-center button-main button-main-ink--text">
                {{ $stratus.dt(dateUpdatePeriod).format('LL') }}
              </div>
              <cs-date-picker-panel v-model="dateUpdatePeriod" flat no-title full-width :max="maxDateEnd()" />
            </v-col>
          </v-row>
        </div>

        <div v-if="productOperation === OPERATION_STOP_SUB_PRODUCTS">
          <div class="d-flex align-center">
            <div class="text-h5">
              {{ $t('Permanently stop a part of all products') }}
            </div>
          </div>

          <cs-alert-panel v-if="productByRef.can_stop_alone === false && !this.isLord" type="warning" dense :text="$t('To stop this product, please contact Scalair customer support.')" />

          <cs-alert-panel v-else-if="!canStopSomeProduct" type="warning" dense>
            <template #content>
              {{ $t('You cannot stop some quantity of this product. To stop all quantities of this product, please go to \"permanently stop all products\".') }}
            </template>
          </cs-alert-panel>

          <cs-alert-panel v-else-if="intoCommitmentDate" type="warning" dense>
            <template #content>
              {{ $t('You are engaged on this product until {date}.', { date: commitmentDate.format('LL') }) }}
              <span>
                {{ intoCommitmentDate ? $t('You will be able to stop it definitively after this deadline.') : $t('This deadline has passed, so you can definitively stop this product if you wish.') }}
              </span>
            </template>
          </cs-alert-panel>

          <div v-else>
            <v-row justify="space-between">
              <v-col md="8">
                <p>{{ $t('Please choose the new quantity wanted:') }}</p>
                <csm-product-quantity-choice :customer="product.code || customer" :quantity-rules="productByRef.quantity_rules" :quantity="maxQuantity" v-model="quantity" :stop-product="true" :greater-zero="true" :take-by-default-max="true" @input="onChange" />

                <p>
                  {{ $tc('No product stopped|One product stopped|{count} products stopped', maxQuantity - quantity, { count: maxQuantity - quantity } ) }}
                </p>

                <div class="text-subtitle-1 mt-8">
                  {{ $t('If you have a moment, tell us why you\'re stopping this product?') }}
                </div>

                <v-row>
                  <v-col cols="12">
                    <sca-order-stop-reason-select v-model="stopReason" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Reason for final stop')" />
                  </v-col>

                  <v-col cols="12">
                    <v-text-field v-model="stopReasonComment" :label="$t('Comment')" :rules="[$stratus.services.form.rules.max(255)]" counter="255" />
                  </v-col>

                  <p class="text-caption">
                    {{ $t('* Indicates required field') }}
                  </p>
                </v-row>
              </v-col>

              <v-col class="text-center" md="4" v-if="canStopThisMonth() || canStopFuture() || canStopFullPast()">
                <p>{{ $t('Select the date to stop the product:') }}</p>
                <div class="text-h6 text-center button-main button-main-ink--text">
                  {{ $stratus.dt(dateEnd).format('LL') }}
                </div>
                <cs-date-picker-panel v-model="dateEnd" flat no-title full-width :show-current="dateEnd" :min="minDateEnd().format()" :max="maxDateEnd()" :locale="this.$store.getters['$stratus-states/locale']" />
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="productOperation === OPERATION_STOP_ALL">
          <div class="d-flex align-center">
            <div class="text-h5">
              {{ $t('Permanently stop all products') }}
            </div>
          </div>

          <cs-alert-panel v-if="productByRef.can_stop_alone === false && !this.isLord" type="warning" dense :text="$t('To stop this product, please contact Scalair customer support.')" />

          <div v-else>
            <div v-if="noticeDate && commitmentDate" class="my-4">
              <cs-alert-panel v-if="noticeDate > commitmentDate" type="warning" dense :text="$t('This complete stop of this product takes {days} notice days. You can program the stop of this product from {date}.', { days: productByRef.nb_days_notice, date: noticeDate.format('LL') })" />
              <cs-alert-panel v-else type="warning" dense>
                <template #content>
                  {{ $t('You are engaged on this product until {date}.', { date: commitmentDate.format('LL') }) }}
                  <span>
                    {{ intoCommitmentDate ? $t('You will be able to stop it definitively after this deadline.') : $t('This deadline has passed, so you can definitively stop this product if you wish.') }}
                  </span>
                </template>
              </cs-alert-panel>
            </div>

            <div v-else class="my-4">
              <cs-alert-panel v-if="noticeDate" type="warning" dense :text="$t('This complete stop of this product takes {days} notice days. You can stop this product from {date}.', { days: productByRef.nb_days_notice, date: noticeDate.format('LL') })" />
              <cs-alert-panel v-if="commitmentDate" type="warning" dense>
                <template #content>
                  {{ $t('You are engaged on this product until {date}.', { date: commitmentDate.format('LL') }) }}
                  <span>
                    {{ intoCommitmentDate ? $t('You will be able to stop it definitively after this deadline.') : $t('This deadline has passed, so you can definitively stop this product if you wish.') }}
                  </span>
                </template>
              </cs-alert-panel>
            </div>

            <v-row justify="space-between">
              <v-col cols="12" md="8">
                <cs-integer-input v-model="quantity" :label="$t('Quantity')" :min="0" :max="0" disabled />
                <p>
                  {{ $t('All product stopped') }}
                </p>

                <div class="text-subtitle-1 font-weight-bold mt-8">
                  {{ $t('Do you have a specific request to pass on to our teams concerning the shutdown (precise time of intervention, need or other)?') }}
                </div>

                <v-textarea v-model="stopInstructions" :rules="[$stratus.services.form.rules.max(2048)]" outlined auto-grow counter="2048" />

                <div class="text-subtitle-1 font-weight-bold mt-4">
                  {{ $t('If you have a moment, tell us why you\'re stopping this product?') }}
                </div>

                <div>
                  <sca-order-stop-reason-select v-model="stopReason" :rules="[$stratus.services.form.rules.required]" class="required" :label="$t('Reason for final stop')" />
                </div>

                <div>
                  <v-text-field v-model="stopReasonComment" :label="$t('Comment')" :rules="[$stratus.services.form.rules.max(255)]" counter="255" />
                </div>

                <p class="text-caption">
                  {{ $t('* Indicates required field') }}
                </p>
              </v-col>

              <v-col cols="12" md="4">
                <div v-if="isLord" class="mb-4">
                  <v-switch v-model="noTicket" dense class="ma-0" :label="$t('The state of the related ticket will be set to done.')" />
                  <v-text-field v-model="noTicketComment" :disabled="!noTicket" :label="$t('Comment')" :rules="[$stratus.services.form.rules.max(255)]" counter="255" />
                </div>

                <div v-if="canStopWhateverEngagment() || canStopThisMonth() || canStopFuture() || canStopFullPast()" cols="12" md="4">
                  <p>{{ $t('Select the date to stop the product:') }}</p>
                  <div class="text-h6 text-center button-main button-main-ink--text">
                    {{ $stratus.dt(dateEnd).format('LL') }}
                  </div>
                  <cs-date-picker-panel v-model="dateEnd" flat no-title full-width :show-current="dateEnd" :min="minDateEnd().format()" :max="maxDateEnd()" :locale="this.$store.getters['$stratus-states/locale']" />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div v-if="productOperation === OPERATION_MODIFY_COMMITMENT">
          <div class="d-flex align-center">
            <div class="text-h5">
              {{ $t('Modify the commitment') }}
            </div>
          </div>

          <v-row dense align="start">
            <v-col cols="12" md="5" offset-md="1">
              <div class="my-8">
                {{ $tc('Initial product commitment: {days} days', productByRef.nb_days_commitment, { days: productByRef.nb_days_commitment }) }}
              </div>

              <v-row justify="center" dense>
                <v-col cols="12" md="8">
                  <cs-integer-input v-model="product.nb_days_commitment" :label="$t('Commitment\'s days')" dense :min="0" />
                </v-col>
              </v-row>

              {{ $t('Order date') }}
              <v-icon small>
                $vuetify.icons.for
              </v-icon>

              {{ $stratus.dt(product.date_order).format('LL') }}
              <div v-if="product.date_commitment_end">
                {{ $t('Committed until {date}', { date: $stratus.dt(product.date_commitment_end).format('LL') }) }}
                <span v-if="product.commitment_update_by" class="text-caption">
                  ({{ $t('Updated by') }} {{ product.commitment_update_by }})
                </span>
              </div>
            </v-col>
            <v-col cols="12" md="4" offset-md="1">
              <div class="text-h6 text-center button-main button-main-ink--text">
                {{ $stratus.dt(dateEndCommitment).format('LL') }}
              </div>
              <cs-date-picker-panel v-model="dateEndCommitment" :max="maxDateEnd()" flat no-title full-width />
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>

      <cs-confirm-dialog ref="confirm-product-modification" />
      <csm-company-dialog ref="company-dialog" />
    </div>

    <!-- Sub product modifications display a cost diff bar -->
    <v-row v-show="productOperation === OPERATION_MODIFY_SUB_PRODUCTS && productByRef.can_modify" slot="footer" justify="space-between" no-gutters>
      <v-col cols="12" md="4" v-if="previousPrices !== null && previousPrices" class="pa-2">
        <sca-product-prices-grid v-if="previousPrices !== null" :prices="previousPrices" :title="$t('Total before changes:')" :periodicity="product.periodicity" dense hide-setup :show-margin="isLord" />
      </v-col>

      <v-col cols="12" md="4" class="pa-2">
        <sca-product-prices-grid :prices="computedPrices" :title="$t('New total:')" :periodicity="product.periodicity" dense hide-setup :show-margin="isLord" />
      </v-col>

      <v-col cols="12" md="4" class="pa-2">
        <sca-product-prices-grid v-if="previousPrices !== null" :prices="getPricesDiff(previousPrices, computedPrices)" :title="$t('Prices diff:')" :periodicity="product.periodicity" show-trend-sign dense hide-setup :show-margin="isLord" />
      </v-col>
    </v-row>

    <template #buttons-prepend>
      <v-btn v-if="productOperation !== STEP_CHOICE" rounded class="main-button" @click="productOperation = STEP_CHOICE">
        {{ $t('Return') }}
      </v-btn>
    </template>

    <template #buttons>
      <v-btn v-if="canStop() && (productOperation === OPERATION_STOP_SUB_PRODUCTS || productOperation === OPERATION_STOP_ALL)" rounded class="main-button" :disabled="isWorking || quantity < 0 || quantity >= product.quantity" :loading="isWorking" @click="stopProduct">
        {{ $t('Save') }}
      </v-btn>
      <v-btn v-else-if="productOperation === OPERATION_MODIFY_SUB_PRODUCTS && canUpdateProduct(product) && computedPrices.total_discount_incl_vat !== undefined" rounded class="main-button" :disabled="isWorking || quantity <= 0" :loading="isWorking" @click="updateProduct">
        {{ $t('Save') }}
      </v-btn>
      <v-btn v-else-if="productOperation === OPERATION_MODIFY_PERIODICITY && canUpdateProduct(product)" rounded class="main-button" :disabled="isWorking || quantity <= 0 || periodicityChoice === null" :loading="isWorking" @click="updatePeriodProduct">
        {{ $t('Save') }}
      </v-btn>
      <v-btn v-else-if="isLord && productOperation === OPERATION_MODIFY_COMMITMENT" rounded class="main-button" :disabled="isWorking" :loading="isWorking" @click="updateCommitment">
        {{ $t('Save') }}
      </v-btn>
    </template>
  </sca-modal-dialog>
</template>

<script>
import _ from 'lodash'

const YEAR_SPAN = 10

const OPERATION_MODIFY_SUB_PRODUCTS = 'modify-sub'
const OPERATION_MODIFY_PERIODICITY = 'modify-periodicity'
const OPERATION_MODIFY_COMMITMENT = 'modify-commitment'
const OPERATION_STOP_ALL = 'stop-all'
const OPERATION_STOP_SUB_PRODUCTS = 'stop-sub'

const STEP_CHOICE = 'choice'

export default {
  name: 'ProductModifyForm',
  components: {
    'csm-dyna-products-modify': () => import(/* webpackChunkName: "components" */ './DynaProductsModify'),
    'csm-product-quantity-choice': () => import(/* webpackChunkName: "components" */ './ProductQuantityChoice')
  },
  data () {
    return {
      OPERATION_MODIFY_SUB_PRODUCTS,
      OPERATION_MODIFY_PERIODICITY,
      OPERATION_MODIFY_COMMITMENT,
      OPERATION_STOP_ALL,
      OPERATION_STOP_SUB_PRODUCTS,
      STEP_CHOICE,
      computedPrices: {},
      customer: null,
      previousPrices: null,
      dateEnd: this.$stratus.dt().toISOString().substr(0, 10),
      dateUpdate: this.$stratus.dt().toISOString().substr(0, 10),
      dateUpdatePeriod: this.$stratus.dt().toISOString().substr(0, 10),
      isWorking: false,
      maxQuantity: 0,
      noTicket: false,
      noTicketComment: null,
      periodicityChoice: null,
      permissions: {},
      price: NaN,
      product: {},
      productByRef: {},
      productComputed: [],
      productOperation: STEP_CHOICE,
      quantity: 1,
      show: false,
      stopInstructions: '',
      stopReason: null,
      stopReasonComment: '',
      valid: false
    }
  },
  computed: {
    canStopSomeProduct () {
      if (!this.productByRef.quantity_rules) {
        return false
      }
      // Check if there is new quantity possible that is less to new but not 0
      if (Object.hasOwnProperty.call(this.productByRef.quantity_rules, 'list')) {
        const nbLess = this.productByRef.quantity_rules.list.filter(x => (x < this.maxQuantity && x > 0))
        return nbLess.length > 0
      } else {
        let delta = 0
        if (this.productByRef.quantity_rules.min === 0) {
          if (this.productByRef.quantity_rules.rules.indexOf('float') >= 0) {
            delta = 0.125
          } else {
            delta = 1
          }
        }
        return (this.maxQuantity > this.productByRef.quantity_rules.min + delta)
      }
    },
    commitmentDate () {
      if (this.product.date_commitment_end) return this.$stratus.dt(this.product.date_commitment_end)
      return this.product.date_begin && this.productByRef.nb_days_commitment ? this.$stratus.dt(this.product.date_begin).add(this.productByRef.nb_days_commitment, 'days') : null
    },
    dateEndCommitment: {
      get () {
        return this.$stratus.dt(this.product.date_begin).add(this.product.nb_days_commitment || 0, 'days').format('YYYY-MM-DD')
      },
      set (value) {
        if (!value || !this.$stratus.dt(value).isValid() || !this.$stratus.dt(this.product.date_begin)) return
        const nbDays = this.$stratus.dt(value).startOf('day').diff(this.$stratus.dt(this.product.date_begin).startOf('day'), 'days')
        this.$set(this.product, 'nb_days_commitment', nbDays)
      }
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    intoCommitmentDate () {
      return this.commitmentDate && this.commitmentDate.isAfter(this.$stratus.dt())
    },
    isAdminLord () { return this.$store.getters['$stratus-states/isLord'] && this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    noticeDate () {
      return this.product.date_begin && this.productByRef.nb_days_notice ? this.$stratus.dt().add(this.productByRef.nb_days_notice, 'days') : null
    },
    periodicityList () {
      return Object.keys(this.productByRef.others_periodicity).map(p => ({ text: this.$t(`periodicity-long-${p.toUpperCase()}`), value: p.toUpperCase() }))
    },
    me () { return this.$store.getters['$stratus-states/me'] || {} },
    minDateEndFormatted () {
      return `(${this.minDateEnd().format('LL')})`
    }
  },
  watch: {
    quantity: {
      handler (val, oldVal) {
        if (!isNaN(val) && !isNaN(oldVal)) {
          this.getPriceProduct()
        }
      }
    }
  },
  methods: {
    canStop () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP) },
    canStopThisMonth () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST) },
    canStopFullPast () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST_FULL) },
    canStopFuture () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_FUTURE) },
    canStopWhateverEngagment () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_WITHOUT_ENGAGEMENT) },
    canUpdateProduct (product) {
      return !product.end_subscription && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_MODIFY)
    },
    close () {
      this.show = false
      this.resolve(false)
    },
    getPricesDiff (price1, price2) {
      const result = {}
      _.forEach(price2, (value, attr) => {
        if (!price1[attr]) result[attr] = value
        else result[attr] = value - price1[attr]
      })
      _.forEach(price1, (value, attr) => {
        if (!price2[attr]) result[attr] = -value
      })
      return result
    },
    getPriceProduct (callbackFn) {
      if (this.productComputed.length) {
        this.$store.dispatch('$alto-orders/computeNewPrices', {
          id: this.product.id,
          main: this.productComputed
        })
          .then(result => {
            if (result?.prices) {
              this.computedPrices = result.prices
              if (this.$refs['product-price-simulator']) {
                this.$refs['product-price-simulator'].refresh({
                  main: this.productComputed
                })
              }
              if (callbackFn) callbackFn(result.prices)
            }
          })
          .catch(error => {
            console.error(error)
            this.$stratus.services.notify.error(error)
          })
      }
    },
    async getProductByRef (ref) {
      return new Promise(resolve => {
        this.$store.dispatch('$alto-catalog/getProductByRef', ref)
          .then(result => {
            resolve(result)
          })
      })
    },
    minDateEnd () {
      let minDate = this.$stratus.dt()
      const stopAll = this.productOperation === OPERATION_STOP_ALL

      // Commitment date and notices days must be used first!
      if (this.commitmentDate && this.productByRef && !this.isAdminLord) {
        const noticeDate = this.$stratus.dt().add(this.productByRef.nb_days_notice, 'days')
        const commitmentDate = this.$stratus.dt(this.commitmentDate)

        // Notice day is only used if stopping all
        if (commitmentDate > noticeDate || !stopAll) {
          minDate = commitmentDate
        } else {
          minDate = noticeDate
        }
      } else if (this.canStopFullPast()) {
        minDate = this.$stratus.dt().subtract(YEAR_SPAN, 'years').startOf('day')
      } else if (this.productByRef && this.productByRef.nb_days_notice && !this.isAdminLord) {
        // We have some days to wait before stopping product
        minDate = this.$stratus.dt().add(this.productByRef.nb_days_notice, 'days')
      } else if (this.canStopThisMonth()) {
        minDate = this.$stratus.dt().startOf('month')
      } else if (minDate.isBefore(this.$stratus.dt()) && !this.isLord) {
        // minDate is expired. Simple users cannot stop in past
        minDate = this.$stratus.dt()
      }

      if (!this.canStopFuture() && minDate.isAfter(this.$stratus.dt())) {
        // If user cannot stop in the future, then stay at today
        minDate = this.$stratus.dt()
      }

      // If minDate is after the current date in date pickers, then set current date
      if (minDate.isAfter(this.$stratus.dt(this.dateEnd))) {
        this.dateEnd = minDate.clone().toISOString().substr(0, 10)
      }

      return minDate
    },
    maxDateEnd () {
      let maxDate = this.$stratus.dt().add(1, 'days').startOf('day') // Tomorrow

      if (this.canStopFuture()) {
        // Stop in future only if stopping all
        if (this.productOperation !== OPERATION_STOP_SUB_PRODUCTS || this.quantity === 0) {
          maxDate = this.$stratus.dt().add(YEAR_SPAN, 'years').startOf('day')
        }
      }

      return maxDate.toISOString()
    },
    onChange (payload) {
      this.productComputed = payload
      this.getPriceProduct(price => {
        if (this.previousPrices === null) this.previousPrices = { ...price }
      })
    },
    async open (id, operation, customer) {
      await this.reset()
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        this.customer = customer
        this.product = { ...await this.$store.dispatch('$alto-subscriptions/get', id) }
        this.productByRef = await this.getProductByRef(this.product.ref)
        this.dateEnd = this.product.date_end || this.$stratus.dt().toISOString().substr(0, 10)
        this.quantity = this.product.quantity || 1
        this.maxQuantity = this.quantity
        this.resolve = resolve
        this.reject = reject
        // Prices will be updated by event onChange from product tree
        this.show = true
        this.productOperation = operation || STEP_CHOICE
      })
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    async reset () {
      if (this.$refs['product-form']) await this.$refs['product-form'].reset()
      if (this.$refs['dyna-products']) await this.$refs['dyna-products'].reset()
      this.quantity = NaN
      this.dateEnd = this.$stratus.dt().toISOString().substr(0, 10)
      this.computedPrices = {}
      this.previousPrices = null
      this.price = NaN
      this.product = {}
      this.productComputed = []
      // Prices will be update by event onChange from product tree
      this.valid = false
      this.productOperation = STEP_CHOICE
    },
    stopProduct () {
      if (this.quantity >= 0 && this.quantity < this.product.quantity) {
        this.$refs['confirm-product-modification'].open(this.$t('Permanent product stop'), this.$t('Confirm the permanent stop?'))
          .then(confirmed => {
            if (confirmed) {
              this.isWorking = true
              this.$store.dispatch('$alto-orders/stopProduct', {
                id: this.product.id,
                quantity: (this.product.quantity - this.quantity),
                date_end: this.dateEnd || this.$stratus.dt().toISOString().substr(0, 10),
                delete_request: this.stopInstructions,
                delete_reason: this.stopReason,
                delete_comment: this.stopReasonComment,
                no_ticket: this.noTicket,
                comment_no_ticket: this.noTicketComment
              })
                .then(response => {
                  this.$stratus.services.notify.success(this.$t('Your product has been updated.'))
                  this.$emit('productUpdated')
                })
                .catch(error => {
                  this.$stratus.services.notify.error(error)
                })
                .finally(() => {
                  this.isWorking = false
                  this.close()
                })
            }
          })
      }
    },
    updatePeriodProduct (product) {
      this.$refs['confirm-product-modification'].open(this.$t('Product update'), this.$t('Confirm the modifications?'))
        .then(confirmed => {
          if (confirmed) {
            this.isWorking = true
            this.$store.dispatch('$alto-orders/updatePeriodicity', { id: this.product.id, new_periodicity: this.periodicityChoice.charAt(this.periodicityChoice.length - 1), date_end: this.isLord ? this.dateUpdatePeriod : this.$stratus.dt().toISOString().substr(0, 10) })
              .then(response => {
                this.$stratus.services.notify.success(this.$t('Your product has been updated.'))
                this.$emit('productUpdated')
              })
              .catch(error => {
                this.$stratus.services.notify.error(error)
              })
              .finally(() => {
                this.isWorking = false
                this.close()
              })
          }
        })
    },
    updateCommitment () {
      this.$refs['confirm-product-modification'].open(this.$t('Product update'), this.$t('Confirm the modifications?'))
        .then(async confirmed => {
          if (confirmed) {
            this.isWorking = true
            try {
              await this.$store.dispatch('$alto-orders/updateSubscription', { id: this.product.id, nb_days_commitment: this.product.nb_days_commitment })
              this.$stratus.services.notify.success(this.$t('Your product has been updated.'))
              this.$emit('productUpdated')
              this.close()
            } catch (error) {
              if (error.status === 406 && error.body?.code === 'ORD-013') {
                this.$stratus.services.notify.warning(this.$t('An adjustment must be made for the change to be taken into account.'))
              } else this.$stratus.services.notify.error(error)
            } finally {
              this.isWorking = false
            }
          }
        })
    },
    updateProduct () {
      if (!this.$refs['product-form'].validate()) {
        this.errorMessage = this.$t('One or more fields must be corrected!')
        return
      }
      this.$refs['confirm-product-modification'].open(this.$t('Product update'), this.$t('Confirm the modifications?'))
        .then(async confirmed => {
          if (confirmed) {
            this.isWorking = true
            try {
              await this.$store.dispatch('$alto-orders/updateProductMain', {
                id: this.product.id,
                main: this.productComputed,
                date_update: this.isLord ? this.dateUpdate : this.$stratus.dt().toISOString().substr(0, 10),
                no_ticket: this.noTicket,
                comment_no_ticket: this.noTicketComment
              })
              this.$stratus.services.notify.success(this.$t('Your product has been updated.'))
              this.$emit('productUpdated')
              this.close()
            } catch (error) {
              if (error.status === 406 && error.body?.code === 'ORD-013') {
                this.$stratus.services.notify.warning(this.$t('An adjustment must be made for the change to be taken into account.'))
              } else this.$stratus.services.notify.error(error)
            } finally {
              this.isWorking = false
            }
          }
        })
    }
  },
  async mounted () {
    this.getPriceProduct = _.debounce(this.getPriceProduct, 1500)

    this.permissions.cancel = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_CANCEL)

    this.permissions.complete = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE)

    this.permissions.modify = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.UPDATE)
    this.permissions.modifyMain = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_MODIFY)

    this.permissions.stop = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_FUTURE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_STOP_PAST_FULL)

    this.permissions.update = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.UPDATE)
  }
}
</script>
