<template>
  <div class="my-1">
    <cs-expand-panel :icon="icon" block>
      <v-row slot="header" align="center" no-gutters>
        <v-col cols="1" class="grow text-no-wrap">
          <div class="text-caption">
            {{ sortDate }}
          </div>

          <v-chip small label :class="`${color} color0--text clickable`">
            {{ $t('follow-up-type-email-1') | uppercase }}
          </v-chip>
        </v-col>

        <v-col>
          <div class="d-flex">
            <sca-user-identity v-if="value.from_email" :value="value.from_email" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(value.from_email)" />
            <div v-if="value.id_user" class="d-flex align-center">
              <v-icon right left small>
                $vuetify.icons.for
              </v-icon>
              <sca-user-identity :value="value.id_user" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(value.id_user)" />
            </div>
          </div>

          <div v-if="value.subject_email">
            {{ value.subject_email }}
          </div>
        </v-col>

        <v-col class="shrink text-no-wrap text-right text-caption">
          <div v-if="showCompany && value.code" class="my-1 mr-1">
            <sca-company-identity :value="value.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(value.code)" />
          </div>

          <!-- <div v-if="showSalesPerson">
            <sca-user-identity :value="value.id_lord_salesperson" show-card dense show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(value.id_lord_salesperson)" />
          </div> -->
        </v-col>

        <v-divider vertical class="ml-2 mr-4" />

        <v-col class="shrink text-no-wrap">
          <v-btn v-if="canUpdate" small icon rounded class="mr-2" @click.stop="updateItem(value)">
            <v-icon small color="primary">
              $vuetify.icons.edit
            </v-icon>
          </v-btn>

          <v-btn v-if="canDelete" small icon rounded class="mr-2" @click.stop="deleteItem(value)">
            <v-icon small color="danger">
              $vuetify.icons.delete
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row slot="content" dense class="mt-2">
        <v-col cols="12">
          <div>
            {{ $t('From: ') }}
            <sca-email :value="value.from_email" />
          </div>
          <div v-if="value.recipient_ids?.length" class="d-flex align-center">
            {{ $t('To: ') }}
            <sca-user-identity v-for="(user, index) in value.recipient_ids" :key="`${user}-${index}`" :value="user" class="ml-2" show-card dense show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(user)" />
          </div>
        </v-col>

        <v-col cols="12">
          <div>
            <v-tabs v-model="tab" color="transparent" hide-slider>
              <cs-loader-tab href="#tab-content" :header="$t('Content')" icon="icon-opened-email" />
              <cs-loader-tab href="#tab-raw-text" :header="$t('Raw text')" icon="icon-file" />
              <cs-loader-tab href="#tab-attachment" :header="$t('Attachments')" icon="icon-attachment" :loading="loadingAttachments" :count="value.attachments.length" @load="loadFollowUpAttachmentsMeta(value.attachments)" />

              <v-tabs-items v-model="tab">
                <v-tab-item value="tab-content">
                  <div class="pa-2" v-html="sanitizeBody(value.body_email)" />
                </v-tab-item>

                <v-tab-item value="tab-raw-text">
                  <pre :class="dark ? 'black' : ''">{{ value.body_email }}</pre>
                </v-tab-item>

                <v-tab-item value="tab-attachment">
                  <v-expand-transition>
                    <v-row v-if="loadingAttachmentsCount > 0" dense align="center" justify="center">
                      <v-col cols="12" sm="6" md="3">
                        <div text dense class="ma-4">
                          <cs-icon-loading small left show-text />
                          <v-progress-linear :value="percentLoadingAttachmentsCount" />
                        </div>
                      </v-col>
                    </v-row>
                  </v-expand-transition>

                  <v-row dense>
                    <v-col cols="12" class="pa-4">
                      <cs-attachments-list :id="value.id" :value="value.attachments" />
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </div>
        </v-col>

        <v-col class="d-flex">
          <cs-expand-list v-if="isArray(value.opportunity_ids) && value.opportunity_ids.length > 0" :items="value.opportunity_ids" row>
            <template v-slot:expand-list-item="{ item }">
              <sca-opportunity-identity :value="item" :label="item" show-avatar link="emit" @link-click="openOpportunity(item)" />
            </template>
          </cs-expand-list>
          <span v-else class="ml-8">{{ $t('Undefined opportunity') }}</span>
        </v-col>

        <v-col cols="12">
          <sca-footer-create-update-at-by :value="value" :link-user="isLord" />
        </v-col>
      </v-row>
    </cs-expand-panel>

    <sca-pdf-viewer-dialog ref="pdf-document-file-viewer" />
    <cs-confirm-dialog ref="confirm-email-delete" />
    <csm-opportunity-dialog ref="opportunity-dialog" />
    <csm-followup-email-dialog ref="followup-email-dialog" :company-code="companyCode" update @close="onCloseDialog" />
    <csm-company-dialog ref="company-dialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
// import getClassNameForExtension from 'font-awesome-filetypes'

export default {
  name: 'FollowUpEmail',
  components: {
    'csm-followup-email-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEmailDialogLite')
  },
  props: {
    companyCode: { type: String, default: null },
    showCompany: { type: Boolean, default: false },
    showSalesPerson: { type: Boolean, default: false },
    value: { type: Object, required: true }
  },
  data () {
    return {
      isArray: _.isArray,
      tmpAttachmentList: [],
      attachmentsMeta: [],
      loadingAttachments: false,
      loadingAttachmentsCount: 0,
      loadingPdf: false,
      tab: 'tab-content'
    }
  },
  computed: {
    canDelete () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.DELETE)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.UPDATE
      )
    },
    color () { return this.$store.getters['followups/ITEM_COLOR'](this.value.$type) },
    customerList () { return this.$store.getters['$alto-companies/list'] },
    customerListSorted () {
      return this.$stratus.services.sort.natural(_.compact(this.customerList), { searchText: 'asc' })
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    icon () { return this.$store.getters['followups/ICON_TYPE'](this.value.$type) },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    ITEM_TYPE_EMAIL () {
      return this.$store.getters['followups/ITEM_TYPE_EMAIL']
    },
    percentLoadingAttachmentsCount () {
      return this.value && this.value.attachments && this.value.attachments.length ? 100 * (this.value.attachments.length - this.loadingAttachmentsCount) / this.value.attachments.length : 0
    },
    sortDate () {
      if (!this.value.sortDate) return this.$t('Undefined')

      if (this.$stratus.dt(this.value.sortDate).isAfter(this.$stratus.dt().startOf('year'))) return this.$stratus.dt(this.value.sortDate).format('DD MMM HH:mm')
      return this.$stratus.dt(this.value.sortDate).format('L HH:mm')
    },
    sortedMeta () {
      return _.sortBy(this.attachmentsMeta, ['name'])
    }
  },
  methods: {
    deleteItem (item) {
      if (!item) return

      this.$refs['confirm-email-delete']
        .open(
          this.$t('Delete'),
          this.$t('Do you want to delete {subject}?', { subject: item.subject_email }),
          { color: 'danger' }
        )
        .then(async (confirm) => {
          if (confirm) {
            try {
              await this.$store.dispatch('followups/deleteEmail', item.id)
              this.$emit('updated', true)
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
          }
        })
    },
    mimeIsImage (meta) {
      return meta && meta.mime && meta.mime.includes('image/')
    },
    mimeIsPdf (meta) {
      return meta && meta.mime && meta.mime.includes('application/pdf')
    },
    loadFollowUpAttachmentsMeta (attachments) {
      if (!attachments || attachments.length === 0) return
      this.loadingAttachments = true
      this.attachmentsMeta = []
      this.tmpAttachmentList = [...attachments]
    },
    onCloseDialog (saved) {
      this.$emit('updated', saved)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    sanitizeBody (content) {
      if (!content) return ''
      // Sanitize and replace \n by html tag BR
      // Windows use \r\n, Linux/Unix use \n and MacOS use \r
      return this.$stratus.services.strings
        .stripHtmlTags(content)
        .replace(/\r\n/g, '<br>')
        .replace(/\r/g, '<br>')
        .replace(/\n/g, '<br>')
    },
    updateItem (item) {
      if (this.canUpdate && this.$refs['followup-email-dialog']) {
        this.$refs['followup-email-dialog'].open(item)
      }
    }
  }
}
</script>
