<template>
  <div>
    <sca-advanced-store-grid :ref="`marketing-lead-grid-${uuid}`" :options="optionsLead" resource="opportunities/leads" :columns="columnsLead" :custom-search="customSearchLeads" @createItem="createLead" @deleteItem="refresh" @updateItem="updateLead">
      <template v-if="!contact" #search-append>
        <v-btn-toggle v-model="filterByState" mandatory dense class="transparent" @change="refresh">
          <v-btn small text :value="FILTER_HANDLED" :class="filterByState === FILTER_HANDLED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
            {{ $t('handled') }}
          </v-btn>
          <v-btn small text :value="FILTER_NOT_HANDLED" :class="filterByState === FILTER_NOT_HANDLED ? 'button-main button-main-ink--text' : ''" :disabled="loading">
            {{ $t('unhandled') }}
          </v-btn>
          <v-btn small text :value="FILTER_ALL" :class="filterByState === FILTER_ALL ? 'button-main button-main-ink--text' : ''" :disabled="loading">
            {{ $t('All') }}
          </v-btn>
        </v-btn-toggle>
      </template>

      <template v-slot:item-score="{ itemRaw }">
        <v-avatar size="36">
          {{ itemRaw || 0 }}
        </v-avatar>
      </template>

      <template v-slot:item-marketing_events_ids="{ itemRaw }">
        <template v-if="itemRaw?.length">
          <sca-marketing-event-identity v-for="id in itemRaw" :key="id" :value="id" show-avatar link="emit" @link-click="openEvent(itemRaw)" />
        </template>
        <span v-else class="font-italic">
          {{ $t('None') }}
        </span>
      </template>

      <template v-slot:item-id_lord_salesperson="{ itemRaw }">
        <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
      </template>

      <template v-slot:item-id_user="{ itemRaw }">
        <sca-user-identity :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
      </template>
    </sca-advanced-store-grid>

    <csm-marketing-lead-dialog ref="marketing-lead-dialog" @closeDialog="closeLeadDialog" />
    <csm-user-dialog ref="user-dialog" />
  </div>
</template>

<script>
const FILTER_ALL = 0
const FILTER_HANDLED = 1
const FILTER_NOT_HANDLED = 2

export default {
  name: 'MarketingLeadGrid',
  components: {
    'csm-marketing-lead-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingLeadDialog')
  },
  props: {
    contact: { type: Boolean, default: false }
  },
  data () {
    return {
      FILTER_ALL,
      FILTER_HANDLED,
      FILTER_NOT_HANDLED,
      filterByState: FILTER_ALL,
      loading: false,
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    columnsLead () {
      return [{
        value: 'email',
        text: 'Email'
      }, {
        value: 'state',
        text: 'State',
        format: v => this.$t(v) || ''
      }, {
        value: 'firstname',
        text: 'Firstname',
        format: v => v || ''
      }, {
        value: 'lastname',
        text: 'Lastname',
        format: v => v || ''
      }, {
        value: 'phone',
        format: v => v || '',
        text: 'Phone'
      }, {
        value: 'company_name',
        text: 'Company',
        format: v => v || ''
      }, {
        value: 'function',
        text: 'Function',
        format: v => v || ''
      }, {
        value: 'score',
        text: 'Score'
      }, {
        value: 'id_user',
        text: 'User',
        hidden: true
      }, {
        value: 'id_lord_salesperson',
        text: 'Sales Person'
      }, {
        value: 'comment',
        text: 'Comment',
        format: v => this.$stratus.services.fieldRenderers.BOOLEAN_CHECK_IF_TRUE(v)
      }, {
        value: 'marketing_events_ids',
        text: 'Campaigns'
      }, {
        value: 'create_at',
        text: 'Created at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATETIME_SHORT
      }, {
        value: 'create_by',
        text: 'Created by',
        hidden: true
      }]
    },
    optionsLead () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/opportunities',
        advancedSearchFieldsSchemaName: 'Lead',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: true,
        createInFab: false,
        customShowItem: true,
        customUpdateItem: true,
        debounce: {
          onList: 500 // Request rate is one every 500ms
        },
        delete: true,
        foreignFields: ['id', 'history'],
        key: 'id',
        multiSelect: false,
        notifyErrors: false,
        show: true,
        sortBy: 'email',
        sortDescending: false,
        update: true
      }
    }
  },
  watch: {},
  methods: {
    closeLeadDialog () {
      this.refresh()
    },
    createLead () {
      this.$refs['marketing-lead-dialog'].openNew({ state: this.contact ? 'contact' : '' })
    },
    customSearchLeads () {
      const cusSearch = []
      if (this.contact) {
        cusSearch.push({ column: 'state', search: 'contact', operator: 'eq' })
      } else {
        switch (this.filterByState) {
          case FILTER_HANDLED:
            cusSearch.push({ column: 'state', search: 'handled' })
            break
          case FILTER_NOT_HANDLED:
            cusSearch.push({ column: 'state', search: 'unhandled' })
            break
          default:
            cusSearch.push({ column: 'state', search: 'contact', operator: 'ne' })
        }
      }

      return cusSearch
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh () {
      if (this.$refs[`marketing-lead-grid-${this.uuid}`]) this.$refs[`marketing-lead-grid-${this.uuid}`].fetchData()
    },
    updateLead ({ item, success, error }) {
      if (!success) {
        if (error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(error)
        return
      }
      this.$refs['marketing-lead-dialog'].open(item.id)
    }
  },
  created () {},
  mounted () {}
}
</script>
