<template>
  <csm-ticket-questionnaire-form ref="ticket-questionnaire-form" :visible="dialogVisible" :value="questionnaire" :readonly="readonly" :loading="loading" :show="canShow" :update="canUpdate" @closeDialog="closeDialog" @refresh="onRefresh" />
</template>

<script>
import _ from 'lodash'

let previousQuestionnaire = null

export default {
  name: 'TicketQuestionnaireDialog',
  components: {
    'csm-ticket-questionnaire-form': () => import(/* webpackChunkName: "components" */ './TicketQuestionnaireForm')
  },
  data () {
    return {
      dialogVisible: false,
      loading: true,
      questionnaire: {},
      readonly: true
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.questionnaire?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog ({ reload } = {}) {
      if (!reload) {
        this.dialogVisible = false
        previousQuestionnaire = null
        if (this.$route?.name === 'ticket-questionnaire' && this.$route?.params?.id) {
          this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
        } else {
          this.$emit('closeDialog', this.questionnaire)
        }
      }

      this.open(reload)
    },
    async get (id) {
      try {
        this.loading = true
        if (_.isObject(id)) {
          this.questionnaire = { ...id }
        } else {
          this.questionnaire = { ...await this.$store.dispatch('$alto-ticketing/getQuestionnaire', { id }) }
        }
        this.$nextTick(() => { this.dialogVisible = true })
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Questionnaire «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
        this.closeDialog()
      } finally {
        setTimeout(() => { this.loading = false }, 500)
      }
    },
    async onRefresh (data) {
      if (!data.id) {
        this.questionnaire = { ...data }
        this.$forceUpdate()
        return
      }
      // Reload
      await this.get(data.id)
      this.$forceUpdate()
    },
    open (id) {
      if (!id) return
      this.questionnaire = {}
      this.reset()
      this.readonly = false
      this.get(id)
    },
    openReadonly (id) {
      if (!id) return
      this.questionnaire = {}
      this.reset()
      this.readonly = true
      this.get(id)
    },
    async reset () {
      if (this.$refs['ticket-questionnaire-form']) await this.$refs['ticket-questionnaire-form'].reset(this.questionnaire)
    }
  },
  async mounted () {
    if (this.$route?.name === 'ticket-questionnaire' && this.$route.params.id) {
      if (this.$route.params.id !== previousQuestionnaire) {
        previousQuestionnaire = this.$route.params.id
        const me = await this.$store.dispatch('$stratus-states/getMe')
        if (this.$alto.services.routes.connectionForbidden.call(this, me)) return
        this.$nextTick(() => { this.open(this.$route.params.id) })
      }
    }
  }
}
</script>
