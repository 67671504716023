<template>
  <v-chip v-if="lookChips" class="ma-1" small label outlined>
    <a :href="`https://app.brevo.com/marketing-campaign/edit/${value}`" target="_ext" class="hover-underline-from-center pt-1">
      <span v-if="prependText">
        {{ $t('Open campaign in Brevo') }}
      </span>
      <v-icon v-if="showArrow" small>
        $vuetify.icons.for
      </v-icon>
      <span v-if="appendText">
        {{ $t('Open campaign in Brevo') }}
      </span>
      <span :class="boldLabel ? 'font-weight-bold' : ''">{{ label }}</span>
      <v-chip v-if="count > 1" x-small class="ml-1">
        {{ count }}
      </v-chip>
    </a>
  </v-chip>

  <a v-else :href="`https://app.brevo.com/marketing-campaign/edit/${value}`" target="_ext" class="hover-underline-from-center pt-1">
    <span v-if="prependText">
      {{ $t('Open campaign in Brevo') }}
    </span>
    <v-icon v-if="showArrow" small>
      $vuetify.icons.for
    </v-icon>
    <span v-if="appendText">
      {{ $t('Open campaign in Brevo') }}
    </span>
    <span :class="boldLabel ? 'font-weight-bold' : ''">{{ label }}</span>
    <v-chip v-if="count > 1" small class="ml-1">
      {{ count }}
    </v-chip>
  </a>
</template>

<script>
export default {
  name: 'MarketingCampaignLink',
  props: {
    appendText: { type: Boolean, default: false },
    boldLabel: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    label: { type: String, default: '' },
    lookChips: { type: Boolean, default: false },
    showArrow: { type: Boolean, default: false },
    prependText: { type: Boolean, default: false },
    value: { type: [Number, String], default: null }
  },
  data () {
    return {}
  }
}
</script>
