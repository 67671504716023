<template>
  <v-row no-gutters>
    <v-col cols="12" class="mb-4">
      <v-row no-gutters>
        <v-col>
          <sca-product-domain-slider :mandatory="!showFavorites" show-arrows allow-all @change="filterDomain" ref="catalog-domain-slider" />
        </v-col>

        <v-col class="shrink">
          <v-spacer />
          <sca-sales-manager-message page="catalog" button-close />
        </v-col>
      </v-row>
    </v-col>

    <v-row align="center" dense class="mr-2 background-blurred">
      <cs-search-input @search="onSearch" prepend-inner-icon="icon-magnifier" :placeholder="$t('Search...')" color="primary" :loading="loading" :disabled="loading" clearable class="mt-4 mx-2" />
      <v-menu offset-y transition="scale-transition" :close-on-content-click="false" :disabled="loading">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon small>
              $vuetify.icons.menu
            </v-icon>
          </v-btn>
        </template>
        <v-card outlined class="pa-4">
          <v-switch class="my-0" dense inset flat v-model="hideAcademic" :label="$t('Hide academic products')" hide-details @change="filterProducts" />
          <v-switch v-if="isLord" class="my-0" dense inset flat v-model="hideNotAvailable" :label="$t('Hide products that are not available')" hide-details @change="filterProducts" />
          <v-switch class="my-0" dense inset flat v-model="hideDescription" :label="$t('Hide products description')" hide-details />
          <v-switch class="my-0" dense inset flat v-model="hideLinked" :label="$t('Hide linked products')" hide-details />
        </v-card>
      </v-menu>
      <v-btn icon :disabled="loading" @click="fetchCatalog()">
        <v-icon color="menu-icon">
          $vuetify.icons.refresh
        </v-icon>
      </v-btn>

      <v-chip label outlined>
        {{ $tc('No product|One product|{count} products', filteredProducts.length, { count: filteredProducts.length }) }}
      </v-chip>

      <v-spacer />

      <v-btn v-if="preferences.favoriteProducts.length" rounded :outlined="!showFavorites" :disabled="loading" class="mr-2" color="pink white--text" @click="filterFavorites">
        <v-icon left small>
          $vuetify.icons.favorite
        </v-icon>
        {{ $t('Favorites') }}
        ({{ preferences.favoriteProducts.length }})
      </v-btn>

      <v-btn-toggle v-model="displayType" class="transparent" mandatory borderless dense tile>
        <v-btn icon :class="displayType === 0 ? 'button-main button-main-ink--text' : ''" :disabled="loading" @click="toggleView(0)">
          <v-icon :class="displayType === 0 ? 'button-main-ink--text' : ''">
            icon-dashboard
          </v-icon>
        </v-btn>
        <v-btn icon :class="displayType === 1 ? 'button-main button-main-ink--text' : ''" :disabled="loading" @click="toggleView(1)">
          <v-icon :class="displayType === 1 ? 'button-main-ink--text' : ''">
            $vuetify.icons.list
          </v-icon>
        </v-btn>
      </v-btn-toggle>

      <v-col cols="shrink text-truncate">
        <sca-customer-select v-model="current.code" :label="current.code ? $t('Rates') : $t('{name} prices', { name: cartCustomer })" @change="onChangeCompany" dense hide-details :disabled="loading" show-email show-phone link="emit" @link-click="openCompany(current.code)" />
      </v-col>

      <v-badge v-show="canSaveCart && (current?.products?.length || current?.name)" :value="current.products.length" class="ml-2" overlap>
        <template v-slot:badge>
          {{ cartQuantity }}
        </template>
        <v-btn class="main-button" rounded :disabled="loading" @click="showShoppingCart">
          <v-icon left small>
            $vuetify.icons.cart
          </v-icon>
          <span>{{ current.name }}</span>
          <cs-icon-loading v-if="saving" right small />
        </v-btn>
      </v-badge>
    </v-row>

    <v-col cols="12" class="mt-4">
      <v-data-iterator :items="filteredProducts" item-key="code" :loading="loading" must-sort :custom-sort="sortNatural" :search="search" :page="page" :items-per-page.sync="rowsPerPage" :sort-by.sync="sortBy" :sort-desc.sync="descending" :footer-props="footerProps">
        <template v-if="displayType === 0" v-slot:default="props">
          <v-row align="stretch" no-gutters>
            <v-col v-for="(item, itemIndex) in props.items" :key="itemIndex" cols="12" sm="6" md="4" lg="4" xl="3">
              <v-hover v-slot="{ hover }">
                <v-card flat class="catalog-card overflow-hidden background-blurred pa-1 mb-4 mx-1" :class="{ focused: hover }" :max-height="hideDescription ? 180 : 404" :min-height="hideDescription ? 180 : 404" @click="canAddProduct(item) ? showProduct(item) : null">
                  <v-row no-gutters align="center" class="product-title-fixed-height px-1" :class="hover ? 'hover font-weight-bold': ''">
                    <v-col class="shrink">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" @click.stop="toggleFavorite(item.ref)" :class="isFavorite(item.ref) ? 'pink--text' : ''" left>
                            $vuetify.icons.favorite
                          </v-icon>
                        </template>
                        <span>{{ isFavorite(item.ref) ? $t('Remove from your favorites') : $t('Add to your favorites') }}</span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="text-h6 font-weight-bold overflow-hidden text-no-wrap">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <span v-on="on">
                            {{ item.name }}
                          </span>
                        </template>
                        <span>{{ item.name }}</span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="shrink text-no-wrap">
                      <cs-clipboard-button v-if="item.ref" icon link :text="productURL(item.ref)" :help="$t('Copy URL to clipboard')" />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col class="text-subtitle-2 px-4 pb-1">
                      <v-icon v-if="item.academic" left>
                        icon-academic
                      </v-icon>
                      <v-chip label outlined color="primary">
                        {{ $t(`product-domain-${item.domain.toLowerCase()}`) }}
                      </v-chip>
                      <v-chip v-if="!item.available" small label outlined color="error" class="ml-1">
                        {{ $t('Not available') }}
                      </v-chip>
                      <v-chip v-if="item.end_subscription" small label outlined color="warning" class="ml-1">
                        {{ $t('This product reach end of life.') }}
                      </v-chip>
                    </v-col>

                    <v-col v-if="item.periodicity" class="text-right mr-2">
                      <span class="text-caption">{{ $t('Billing: ') }}
                        <span class="font-weight-bold">{{ $t('billing-' + item.periodicity) }}
                        </span>
                      </span>
                    </v-col>
                  </v-row>

                  <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                  <v-card-text v-if="!hideDescription" class="scrollable-card-text" v-html="item.description" />

                  <div class="full-width product-footer-fixed-height bottom">
                    <v-divider class="mx-3" />

                    <div v-if="priceCache[item.ref]" class="mx-2">
                      <sca-product-price :price="priceCache[item.ref].price" :discount="priceCache[item.ref].price_discount" :discount-percent="priceCache[item.ref].discount || 0" :setup-price="priceCache[item.ref].price_setup" :setup-discount="priceCache[item.ref].price_setup_discount || 0" :setup-discount-percent="priceCache[item.ref].discount_setup" :periodicity="item.periodicity" dense ignore-vat />
                    </div>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>

        <template v-else-if="displayType === 1" v-slot:default="props">
          <v-card flat v-for="(item, itemIndex) in props.items" :key="itemIndex" class="mt-2 background-blurred">
            <v-hover v-slot="{ hover }">
              <v-row align="center" class="pa-2" :class="{ focused: hover }" dense>
                <v-col>
                  <v-row align="center" no-gutters>
                    <v-col>
                      <v-row align="start" justify="start" no-gutters>
                        <v-col v-if="item.academic" class="shrink">
                          <v-icon left>
                            icon-academic
                          </v-icon>
                        </v-col>

                        <v-col>
                          <span class="font-weight-bold text-subtitle-1">{{ item.name }}</span>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" @click.stop="toggleFavorite(item.ref)" :class="isFavorite(item.ref) ? 'pink--text' : ''" right>
                                $vuetify.icons.favorite
                              </v-icon>
                            </template>
                            <span>{{ isFavorite(item.ref) ? $t('Remove from your favorites') : $t('Add to your favorites') }}</span>
                          </v-tooltip>
                        </v-col>

                        <v-col class="shrink">
                          <v-chip small label outlined color="primary">
                            {{ $t(`product-domain-${item.domain.toLowerCase()}`) }}
                          </v-chip>
                        </v-col>

                        <v-col cols="12">
                          <sca-product-price v-if="priceCache[item.ref]" class="mx-4" :price="priceCache[item.ref].price" :discount="priceCache[item.ref].price_discount" :discount-percent="priceCache[item.ref].discount || 0" :setup-price="priceCache[item.ref].price_setup" :setup-discount="priceCache[item.ref].price_setup_discount || 0" :setup-discount-percent="priceCache[item.ref].discount_setup" :periodicity="item.periodicity" />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col class="mx-4">
                      <div v-if="item.periodicity">
                        {{ $t('Billing: ') }}
                        <span class="font-weight-bold">
                          {{ $t('billing-' + item.periodicity) }}
                        </span>
                      </div>

                      <div v-if="!hideLinked" cols="12">
                        {{ $t('Associated products:') }}

                        <ul v-if="item.main && item.main.length">
                          <li v-for="(prod, index) in item.main" :key="index">
                            <template v-if="prod.ref">
                              <span class="text-caption accent--text">{{ getProductNameByRef(prod.ref) || prod.ref }} </span> × {{ explainQuantity(prod) }}
                            </template>
                            <template v-if="prod.type">
                              <span class="text-caption accent--text">{{ prod.type.join(', ') }} </span> : {{ explainQuantity(prod) }}
                            </template>
                          </li>
                        </ul>
                      </div>
                    </v-col>

                    <v-col cols="12" class="mt-2">
                      <cs-expand-panel v-show="!hideDescription" :header="$t('Description')">
                        <div slot="content" v-html="item.description" />
                      </cs-expand-panel>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="shrink text-no-wrap">
                  <v-tooltip top v-if="canAddProduct(item)">
                    <template v-slot:activator="{ on }">
                      <v-btn icon slot="activator" class="main-button" @click="showProduct(item)" v-on="on">
                        <v-icon>$vuetify.icons.add</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('Add to your shopping cart') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-hover>
          </v-card>
        </template>

        <template v-slot:no-data>
          <div class="text-center">
            {{ $t('No product.') }}
          </div>
        </template>

        <template v-slot:pageText="props">
          {{ props.pageStart }}
          <v-icon small>
            $vuetify.icons.next
          </v-icon>
          {{ props.pageStop }} / {{ props.itemsLength }}
        </template>
      </v-data-iterator>
    </v-col>

    <csm-product-dialog ref="product-dialog" @addToCart="addProductToCart" />
    <csm-company-dialog ref="company-dialog" />
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Catalog',
  data () {
    return {
      darkMode: false,
      addingProduct: false,
      checkbox: null,
      displayFilter: true,
      currentDomain: null,
      previousDomainFilter: null,
      domainFilter: [],
      displayType: 0, // 0 = lines, 1 = cards
      expanded: [],
      menuChangeCustomer: false,
      filteredProducts: [],
      headers: [
        { text: '', value: 'data-table-expand' },
        { text: this.$t('Name'), value: 'name', sorted: true },
        { text: this.$t('Details'), value: 'details' }, // custom column
        { text: '', value: 'actions', width: 64, sortable: false }
      ],
      hideAcademic: false,
      hideDescription: false,
      hideLinked: false,
      hideNotAvailable: true,
      hideEndSubscription: true,
      loading: false,
      navigator,
      page: 1,
      priceCache: {},
      rowsPerPage: 20,
      sortBy: 'name',
      descending: false,
      totalItems: 0,
      saving: false,
      search: null,
      showFavorites: false,
      tagAll: false,
      tagFilter: []
    }
  },
  computed: {
    canSaveCart () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.UPDATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE)
    },
    cartQuantity () { return this.$store.getters['$alto-quotations/currentQuantity'] },
    current () { return this.$store.getters['$alto-quotations/current'] || { products: [] } },
    cartCustomer () { return this.current.customer || this.myCompany },
    domains () { return this.$store.getters['$alto-catalog/domains'] },
    footerProps () {
      return { 'items-per-page-options': [10, 20, 40, -1] }
    },
    myCompany () { return this.$store.getters['$stratus-states/me'].company },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    preferences () { return this.$store.getters['$alto-preferences/preferences'] },
    products () { return this.$store.getters['$alto-catalog/list'] || [] },
    tags () { return this.$store.getters['$alto-catalog/tags'] || [] }
  },
  methods: {
    async addProductToCart ({ product, attributes, quantity = 1 }) {
      this.addingProduct = true
      try {
        let newCart
        product.main = attributes || null
        const newProduct = Object.assign(product, { quantity })

        if (!this.current.code) this.$set(this.current, 'code', this.cartCustomer)

        if (!this.current.id) {
          // New cart
          this.current.products = [newProduct]
          const { cart } = await this.$store.dispatch('$alto-quotations/save', { cart: this.current })
          newCart = cart
        } else {
          newCart = await this.$store.dispatch('$alto-quotations/addProduct', { id: this.current.id, product: newProduct })
        }

        this.$store.commit('$alto-quotations/setCurrent', newCart)
        this.$stratus.services.notify.success(this.$t('{quantity} × {product} added to cart.', { quantity, product: product.name || product.ref }))
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.addingProduct = false }, 200)
      }
    },
    async applyPreferences () {
      await this.$store.dispatch('$alto-preferences/load')
      this.displayType = this.preferences.catalogDisplayType || 0
      if (this.$refs['catalog-domain-slider']) this.$refs['catalog-domain-slider'].setDomain(this.currentDomain)
    },
    canAddProduct (product) {
      if (product.end_subscription) return false
      return (this.isLord || product.available) && (this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.UPDATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CART_UPDATE))
    },
    explainQuantity (product) {
      if (!product.quantity) {
        return ''
      }

      if (Object().hasOwnProperty.call(product.quantity, 'list')) {
        if (product.quantity.list.length > 5) {
          return this.$t('{count}{unit}', { count: `${product.quantity.list[0]}, ${product.quantity.list[1]}, ${product.quantity.list[2]} ... ${product.quantity.list[product.quantity.list.length - 2]}, ${product.quantity.list[product.quantity.list.length - 1]}`, unit: product.unit })
        }
        return this.$t('{count}{unit}', { count: product.quantity.list.join(', '), unit: product.unit })
      }

      if (product.quantity.min !== undefined && product.quantity.max !== undefined) {
        return this.$t('from {min}{unit} to {max}{unit}', { min: product.quantity.min, max: product.quantity.max, unit: product.unit })
      }

      return product.quantity
    },
    fetchCatalog () {
      this.loading = true
      this.$store.dispatch('$alto-catalog/list')
        .then(() => {
          // To change AND by OR:
          // OR: if (!this.paramTag) this.tagFilter = this.tags.join(',').split(',') // Allow all tags to display all
          // AND:
          this.tagFilter = [] // Allow all tags to display all
          // if (!this.paramTag) this.tagFilter = [] // Allow all tags to display all
          // else this.tagFilter = [this.paramTag]
          this.domainFilter = this.currentDomain || [] // Allow all domain to display all
          return this.products
        })
        .then(() => {
          return this.$store.dispatch('$alto-catalog/getPrices', this.cartCustomer)
        })
        .then(response => {
          this.priceCache = response
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
        .finally(async () => {
          this.filterProducts()
          setTimeout(() => { this.loading = false }, 1000)
        })
    },
    filterDomain (domain) {
      this.showFavorites = false
      this.domainFilter = domain ? [domain] : []
      this.filterProducts()
    },
    filterFavorites () {
      if (this.showFavorites) {
        this.showFavorites = false
        this.domainFilter = this.previousDomainFilter
        this.filterProducts()
        return
      }

      this.previousDomainFilter = this.domainFilter
      this.currentDomain = null
      this.showFavorites = true
      this.domainFilter = [] // We allow all product to be displayed
      this.filterProducts()
    },
    filterProducts () {
      if (!this.preferences || !this.preferences.favoriteProducts || this.preferences.favoriteProducts.length === 0) this.showFavorites = false
      this.filteredProducts = _.filter(this.getFilteredProductsByFavorites(this.getFilteredProductsByAvailability(this.getFilteredProductsByDomainAndTags())), product => {
        return !this.search ||
          product.name.toLowerCase().includes(this.search.toLowerCase()) ||
          product.ref.toLowerCase().includes(this.search.toLowerCase()) ||
          product.description.toLowerCase().includes(this.search.toLowerCase()) ? product : null
      })
      this.totalItems = this.filteredProducts.length
    },
    getFilteredProductsByAvailability (products) {
      return _.filter(products || this.products, product => {
        return (!this.hideNotAvailable || product.available) && (!this.hideEndSubscription || !product.end_subscription) && (!this.hideAcademic || !product.academic) ? product : null
      })
    },
    getFilteredProductsByFavorites (products) {
      return _.filter(products || this.products, product => {
        return !this.showFavorites || this.preferences.favoriteProducts.length === 0 || this.isFavorite(product.ref) ? product : null
      })
    },
    getFilteredProductsByDomainAndTags (products) {
      const list = products || this.products
      // First filter the domain. Product domain must be one that is filtered
      let result = this.domainFilter.length ? _.filter(list, product => {
        return this.domainFilter.includes(product.domain)
      }) : list
      // Finally filter with tags
      if (this.tagFilter.length) {
        result = _.filter(result, product => {
          // To change AND by OR:
          // OR: One of the chosen tags must match for a product to be selected
          // return (product.tags.length === 0 || _.intersection(product.tags, this.tagFilter).length > 0) ? product : null
          // AND : all chosen tags must match for a product to be selected
          return (product.tags.length === 0 || _.intersection(product.tags, this.tagFilter).length === this.tagFilter.length) ? product : null
        })
      }
      return result
    },
    getProductByRef (ref) {
      return this.$store.getters['$alto-catalog/get'](ref)
    },
    getProductNameByRef (ref) {
      return (this.$store.getters['$alto-catalog/get'](ref) || {}).name || ''
    },
    isDark () { return this.$store.getters['$stratus-states/isDark'] },
    isFavorite (id) {
      return this.preferences.favoriteProducts && this.preferences.favoriteProducts.indexOf(id) >= 0
    },
    async onChangeCompany (customer) {
      if (!customer || !this.current?.id) return

      try {
        this.saving = true
        const newCart = await this.$store.dispatch('$alto-quotations/setCompany', { id: this.current.id, code: customer })
        this.$store.commit('$alto-quotations/setCurrent', newCart)
        if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.saving = false }, 250)
      }
    },
    onSearch (value) {
      this.search = value
      this.filterProducts()
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    productURL (ref) {
      return window.location.origin + '/#/products/' + ref
    },
    async loadLists () {
      await this.$store.dispatch('$alto-catalog/loadTags')
    },
    async saveCurrentCart () {
      if (!this.current || !this.current.products || !this.current.products.length) return

      this.saving = true
      try {
        const { cart } = await this.$store.dispatch('$alto-quotations/save', { cart: this.current })
        this.$store.commit('$alto-quotations/setCurrent', cart)
        if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()
      } catch (error) {
        console.error(error)
        this.$stratus.services.notify.error(error)
      } finally {
        this.saving = false
      }
    },
    setTag (value) {
      this.tagFilter = value ? [value] : [] // all
      this.domainFilter = value ? [value] : [] // all
      this.filterProducts()
    },
    showProduct (product) {
      this.$refs['product-dialog'].open(_.cloneDeep(product))
    },
    showShoppingCart () {
      if (!this.$root['cart-menu-global-dialog']) return

      if (this.current?.id) this.$root['cart-menu-global-dialog'].open(this.current.id)
      else this.$root['cart-menu-global-dialog'].create()
    },
    sortNatural (items, index, isDescending) {
      const sort = {}
      sort[index] = isDescending ? 'desc' : 'asc'
      return this.$stratus.services.sort.natural(items, sort)
    },
    toggleFavorite (id) {
      if (!this.preferences.favoriteProducts) return
      const i = this.preferences.favoriteProducts.indexOf(id)
      if (i >= 0) this.preferences.favoriteProducts.splice(i, 1)
      else this.preferences.favoriteProducts.push(id)
      this.$store.dispatch('$alto-preferences/save', { favoriteProducts: this.preferences.favoriteProducts })
    },
    toggleView (display) {
      this.loading = true
      this.displayType = display
      setTimeout(() => { this.loading = false }, 1000)
      this.preferences.catalogDisplayType = display
      this.$store.dispatch('$alto-preferences/save', { catalogDisplayType: this.preferences.catalogDisplayType })
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Products Catalog'))
    this.saveCurrentCart = _.debounce(this.saveCurrentCart, 1000)
  },
  async onBeforeMount () {
    // Fill all caches
    await this.$stratus.fetchCaches(false)
    await this.$alto.fetchCaches(false)
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    const me = this.$store.getters['$stratus-states/me']
    if (this.$alto.services.routes.connectionForbidden.call(this, me)) return

    this.currentDomain = this.$route.params ? (this.$route.params.domain || '') : null
    this.loadLists()
    this.fetchCatalog()
    this.applyPreferences()
  }
}
</script>

<style scoped>
.active-domain {
  border-width: 0 0 2px 0;
  border-bottom-style: solid;
  border-color: #17E1CB;
}

.product-title-fixed-height {
  height: 40px;
}

.product-footer-fixed-height {
  height: 50px;
}

.scrollable-card-text {
  overflow-y: auto;
  max-height: 268px;
}
</style>
