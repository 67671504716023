<template>
  <cs-action-button v-if="actionButton" :disabled="disabled || isLoading" :loading="loading || isLoading" :icon="format === FORMAT_PDF ? '$vuetify.icons.pdf' : '$vuetify.icons.csv'" :help="format === FORMAT_PDF ? 'Download as PDF' : 'Download as CSV'" @click="downloadInvoice" />
  <v-btn v-else rounded text class="py-2 mx-2" @click.native="downloadInvoice" :disabled="disabled || isLoading" :loading="loading || isLoading">
    {{ format === FORMAT_PDF ? $t('Download as PDF') : $t('Download as CSV') }}
  </v-btn>
</template>

<script>
const FORMAT_CSV = 'csv'
const FORMAT_PDF = 'pdf'

export default {
  name: 'CartInvoiceDownload',
  props: {
    actionButton: { type: Boolean, default: false },
    cart: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    format: { type: String, default: FORMAT_PDF },
    loading: { type: Boolean, default: false },
    name: { type: String, default: 'Scalair_Cart' }
  },
  data () {
    return {
      FORMAT_CSV,
      FORMAT_PDF,
      isLoading: false
    }
  },
  methods: {
    async downloadInvoice (...args) {
      this.isLoading = true
      try {
        if (this.format === FORMAT_PDF) {
          await this.$stratus.services.api.downloadProgress(`/orders/${this.cart.id}/${this.format}`, `${this.name}.${this.format}`)
        } else {
          await this.$stratus.services.api.downloadProgress(`/orders/${this.cart.id}?format=${this.format}`, `${this.name}.${this.format}`)
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.isLoading = false }, 2000)
      }
    }
  }
}
</script>
