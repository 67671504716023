<template>
  <v-form :ref="ref" v-model="valid" lazy-validation>
    <cs-alert-panel v-if="errorMessage" dense type="error" :text="errorMessage" />
    <div v-show="product.main && canShowSubProducts">
      <v-row dense align="center" justify="start" v-for="(input, index) in productsByIndex" :key="index" class="pb-4">
        <v-col v-if="input.ref">
          <csm-dyna-product-recursive v-model="input.ref" :detail="input" :indent="true" @change="changeProduct" :choices-discount="false" :choices-product="true" :readonly="readonly" />
        </v-col>
        <v-col v-else-if="input.type">
          <csm-dyna-product-recursive v-model="input.type[0]" :detail="input" :indent="true" @change="changeProduct" :choices-discount="false" :choices-product="true" :readonly="readonly" />
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'DynaProduct',
  props: {
    product: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  components: {
    'csm-dyna-product-recursive': () => import(/* webpackChunkName: "components" */ './DynaProductRecursive')
  },
  data () {
    return {
      errorMessage: '',
      mainProductCustom: [],
      ref: this.$stratus.uuid(),
      valid: true
    }
  },
  computed: {
    canShowSubProducts () { return this.isLord || !this.product.hide_subproduct },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    productsByIndex () {
      return _.sortBy(this.product.main, 'index')
    }
  },
  methods: {
    changeProduct (data) {
      const test = this.hasThisRef(this.mainProductCustom, data.index)
      if (test !== null) {
        this.mainProductCustom[test] = data
      } else {
        this.mainProductCustom.push(data)
      }
      this.$emit('change', { ref: this.product.ref, main: this.mainProductCustom })
    },
    hasThisRef (main, index) {
      let i = 0
      while (i < main.length) {
        if (main[i].index === index) return i
        i++
      }
      return null
    },
    reset (product = {}) {
      // Reset when product changed
      this.errorMessage = ''
      this.mainProductCustom = []
    }
  }
}
</script>
