<template>
  <v-row v-if="isLogged && me" justify="center" dense class="mx-0 px-0">
    <v-col cols="12">
      <div class="mb-4">
        <cs-quick-access :menu="menu" hide-caption />
      </div>

      <v-tabs v-if="isLord" v-model="tab" class="transparent mb-4" hide-slider>
        <v-tab href="#tab-activities">
          <v-icon left small>
            $vuetify.icons.scalair
          </v-icon>
          {{ $t('Activities') }}
        </v-tab>

        <v-tab href="#tab-calendar">
          <v-icon left small>
            $vuetify.icons.calendar
          </v-icon>
          {{ $t('Calendar') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-activities">
          <v-row>
            <v-col cols="12">
              <v-card flat class="background-blurred">
                <v-row align="center" no-gutters>
                  <v-col v-if="myCompany?.id_lord_salesperson" cols="6" md="4" lg="3">
                    <sca-contact-card :value="myCompany.id_lord_salesperson" :height="CARD_HEIGHT" compact show-border show-avatar show-card show-phone show-email :title="$t('Sales person')" />
                  </v-col>

                  <v-col cols="6" md="4" lg="3" :class="$vuetify.breakpoint.lgAndUp ? 'px-2' : ''">
                    <sca-contact-card v-if="!myCompany?.id_lord_manager" value="contact@scalair.fr" :height="CARD_HEIGHT" class="mx-2" compact show-border show-avatar show-card show-phone show-email :title="$t('The Scalair teams')" />

                    <sca-contact-card v-else :value="myCompany.id_lord_manager" :height="CARD_HEIGHT" class="mx-2" compact show-border show-avatar show-card show-phone show-email :title="$t('Service Manager')" />
                  </v-col>

                  <v-col cols="6" md="4" lg="3">
                    <sca-customer-new-request-incident large x-large-icon no-label :height="CARD_HEIGHT" :width="CARD_HEIGHT" @create-ticket="createTicket" />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" lg="4">
              <v-card outlined class="full-height background-blurred overflow-hidden pa-2">
                <div class="d-flex align-center">
                  <span class="text-h5 primary--text">
                    {{ $t('To do') }}
                  </span>
                  <cs-icon-loading v-if="loadingCardsData" class="ml-auto" />
                </div>

                <div v-if="pendingTickets.length" class="px-2 mb-4">
                  <div class="text-h6">
                    {{ $t('For your feedback') }}
                  </div>
                  <sca-ticket-list-panel :value="pendingTickets" dense can-open hide-id show-icon show-severity link="emit" @link-click="openTicket" />
                </div>

                <div v-if="pendingOrders.length" class="px-2 mb-4">
                  <div class="text-h6">
                    {{ $t('For your deposit') }}
                  </div>

                  <div v-for="order in pendingOrders" :key="order.id" class="mb-2 ml-3">
                    <sca-order-identity :value="order.id" :label="order.name" show-avatar link="emit" @link-click="openOrder" />

                    <div class="text-right text-caption color2--text">
                      {{ $stratus.dt(order.update_date).format('LL') }}
                    </div>
                  </div>
                </div>

                <div v-if="pendingSubscriptions.length" class="px-2 mb-4">
                  <div class="text-h6">
                    {{ $t('For your technical information') }}
                  </div>

                  <div v-for="subscription in pendingSubscriptions" :key="subscription.id" class="mb-2 ml-3 text-truncate">
                    <sca-subscription-identity :value="subscription.id" :label="subscription.caption || subscription.ref" show-avatar link="emit" @link-click="openSubscription" />

                    <div class="text-right text-caption color2--text">
                      {{ $stratus.dt(subscription.date_order).format('LL') }}
                    </div>
                  </div>
                </div>

                <div v-if="!pendingTickets.length && !pendingOrders.length && !pendingSubscriptions.length">
                  {{ $t('No action pending on your part.') }}
                </div>
              </v-card>
            </v-col>

            <v-col v-if="canListCarts" cols="12" lg="4">
              <v-card outlined class="full-height background-blurred overflow-hidden pa-2">
                <div class="d-flex align-center">
                  <v-btn text rounded to="/quotations" class="text-h5 primary--text pl-0">
                    {{ $t('Last quotations') }}
                  </v-btn>
                  <cs-icon-loading v-if="loadingCardsData" class="ml-auto" />
                </div>

                <div v-if="!carts?.length" class="mt-2 text-center">
                  <p class="text-left">
                    {{ $t('You don\'t have any quotation yet.') }}
                  </p>

                  <div v-if="canListCatalog" class="mt-4 text-center">
                    <v-btn to="/productchooser" rounded class="main-button">
                      <v-icon left small>
                        $vuetify.icons.catalog
                      </v-icon>
                      {{ $t('Products Catalog') }}
                    </v-btn>
                  </div>
                </div>

                <div v-else class="mt-2 px-2">
                  <template v-for="(cart, index) in carts">
                    <v-hover v-slot="{ hover }" :key="`cart-${index}`">
                      <div :class="hover ? 'focused' : ''" class="clickable mb-2" @click="openQuotation(cart?.id)">
                        <div class="d-flex align-center">
                          <v-icon small>
                            $vuetify.icons.cart
                          </v-icon>
                          <div class="ml-1">
                            {{ cart.name }}
                          </div>
                        </div>

                        <div class="text-right text-caption color2--text">
                          {{ $t('Expires on {date}', { date: $stratus.dt(cart.expiration_date).format('LL') }) }}
                        </div>
                      </div>
                    </v-hover>
                  </template>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" lg="4">
              <v-card outlined class="full-height background-blurred overflow-hidden pa-2">
                <div class="d-flex align-center">
                  <span class="text-h5 primary--text">
                    {{ $t('Quick order') }}
                  </span>
                  <cs-icon-loading v-if="loadingCardsData || addingProduct" class="ml-auto" />
                </div>

                <div v-if="!preferences.favoriteProducts?.length" class="mt-2 text-center">
                  <p class="text-left">
                    {{ $t('Add favorite products to find them here.') }}
                  </p>
                </div>

                <div v-else class="px-2">
                  <div v-for="product in favoriteProducts" :key="product.ref" class="my-1">
                    <v-hover v-slot="{ hover }" :key="product.ref">
                      <div class="py-1 clickable d-flex align-center" :class="hover ? 'focused' : ''" @click="openProduct(product.ref)">
                        <v-icon small>
                          $vuetify.icons.product
                        </v-icon>
                        <div class="ml-1 text-truncate">
                          {{ product.name }}
                        </div>
                        <cs-icon-loading v-if="loadingCardsData || addingProduct" class="ml-auto" small />
                        <v-icon v-else small class="ml-auto">
                          $vuetify.icons.cart
                        </v-icon>
                      </div>
                    </v-hover>
                  </div>
                </div>

                <div v-if="canListCatalog" class="mt-4 text-center">
                  <v-btn to="/productchooser" rounded class="main-button">
                    <v-icon left small>
                      $vuetify.icons.catalog
                    </v-icon>
                    {{ $t('Products Catalog') }}
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="canReadOpportunities" cols="12" lg="8">
              <v-card outlined class="full-height background-blurred overflow-hidden pa-2">
                <div class="text-h5 primary--text d-flex align-center">
                  {{ $t('Evolution of subscriptions') }}

                  <div v-if="isLord && priceTrendCustomer" class="ml-4">
                    <sca-customer-select v-model="priceTrendCustomer" dense hide-details @input="loadPriceTrends" show-card link="emit" @link-click="openCompany(priceTrendCustomer)" />
                  </div>

                  <cs-icon-loading v-if="loadingCardsData" class="ml-auto" />
                </div>

                <div class="pt-2 px-2">
                  <sca-price-trends ref="global-price-trend" hide-legend hide-warning :height="430" :data="opportunityData" :disabled="loadingOpportunity" :loading="loadingOpportunity" @refresh="loadPriceTrends" />
                </div>
              </v-card>
            </v-col>

            <v-col v-if="orders.length" cols="12" lg="4">
              <v-card outlined class="full-height background-blurred overflow-hidden pa-2">
                <div class="d-flex align-center">
                  <span class="text-h5 primary--text">
                    {{ $t('Orders in progress or recently delivered') }}
                  </span>

                  <cs-icon-loading v-if="loadingCardsData" class="ml-auto" />
                </div>

                <div class="mt-2 px-2">
                  <v-row v-for="order in orders" :key="order.id" align="center" class="pb-2" no-gutters>
                    <v-col cols="12" class="text-truncate">
                      <sca-order-identity :value="order.id" :label="order.name" show-avatar link="emit" @link-click="openOrder" />
                    </v-col>
                    <v-col>&nbsp;</v-col>
                    <v-col class="shrink">
                      <div v-if="order.state === $alto.defines.ORDERS.ORDER_STATE_COMPLETED" class="ml-auto text-caption color2--text">
                        {{ $stratus.dt(order.update_at).format('LL') }}
                      </div>
                      <sca-order-state :value="order.state" dense hide-text />
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item v-if="isLord" value="tab-calendar">
          <v-row class="background-blurred">
            <v-col cols="12">
              <sca-calendar ref="activity-calendar" @link-click="openCalendarEvent" @create-call="editCall" @create-event="editEvent" @create-task="editTask" />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <cs-confirm-dialog ref="confirm-order-same-product" />
    <csm-company-dialog ref="company-dialog" />
    <csm-order-dialog ref="order-dialog" />
    <csm-product-dialog ref="product-dialog" @addToCart="addProductToCart" />
    <csm-subscription-dialog ref="subscription-dialog" />
    <csm-ticket-dialog @closeDialog="loadTickets" ref="ticket-dialog-secondary" />
    <csm-ticket-new-incident ref="form-ticket-new-incident" :descriptor="descriptor" :visible="showIncidentDialog" @closeDialog="closeTicketDialog" />
    <csm-ticket-new-request ref="form-ticket-new-request" :descriptor="descriptor" :visible="showRequestDialog" @closeDialog="closeTicketDialog" />
    <csm-marketing-event-dialog ref="marketing-event-dialog" @closeDialog="closeMarketingDialog" />
    <csm-calendar-call-dialog ref="calendar-call-dialog" @close="onCalendarDialogClose" @close-reopen="onCalendarDialogCloseReopen($event, 'call')" />
    <csm-calendar-event-dialog ref="calendar-event-dialog" @close="onCalendarDialogClose" @close-reopen="onCalendarDialogCloseReopen($event, 'event')" />
    <csm-calendar-task-dialog ref="calendar-task-dialog" @close="onCalendarDialogClose" @close-reopen="onCalendarDialogCloseReopen($event, 'task')" />
  </v-row>
</template>

<script>
import _ from 'lodash'

const WIDGETS_ITEMS_NUMBER = 5

export default {
  name: 'Activites',
  components: {
    'csm-ticket-new-incident': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewIncident'),
    'csm-ticket-new-request': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewRequest'),
    'csm-marketing-event-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/MarketingEventDialog'),
    'csm-calendar-call-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpCallDialogLite'),
    'csm-calendar-event-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEventDialogLite'),
    'csm-calendar-task-dialog': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpTaskDialogLite')
  },
  data () {
    return {
      CARD_HEIGHT: 110,
      addingProduct: false,
      carts: [],
      companyCode: '',
      dateBegin: '',
      dateEnd: '',
      descriptor: [],
      favoriteProducts: [],
      loadingCardsData: true,
      loadingOpportunity: false,
      me: null,
      myCompany: null,
      opportunityData: {},
      orders: [],
      pendingOrders: [],
      pendingTickets: [],
      pendingSubscriptions: [],
      priceTrendCustomer: null,
      showIncidentDialog: false,
      showRequestDialog: false,
      tab: 'tab-activities'
    }
  },
  computed: {
    canAddCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.CREATE)
    },
    canAddEvent () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.CREATE)
    },
    canAddTask () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.CREATE)
    },
    canListCarts () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST)
    },
    canListCatalog () { return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST) },
    canListOrders () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.LIST)
    },
    canListRegister () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.DOCUMENTS_REGISTERS, this.$alto.API_PERMISSIONS.LIST)
    },
    canListSubscriptions () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.LIST) // Same as orders above
    },
    canListTickets () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.LIST)
    },
    canReadOpportunities () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, this.$alto.API_PERMISSIONS.OPPORTUNITIES_COMPANY)
    },
    currentCart () { return this.$store.getters['$alto-quotations/current'] || {} },
    currentCartCustomer () { return this.currentCart.code || this.subscription.code || this.$store.getters['$stratus-states/me'].company },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    docHistoryDateBegin () {
      return this.$stratus.dt().subtract(7, 'days').startOf('day')
    },
    isLogged () { return this.$stratus.services.auth.isLogged() },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    menu () {
      const _menu = []

      const extractMenuForHome = (items) => {
        if (!items) return
        items.forEach(item => {
          if (item.favorite) {
            if (!item.role || this.$store.getters['$alto-roles/canIMenu'](item.role)) {
              _menu.push(item)
            }
          }
          if (item.items) {
            extractMenuForHome(item.items)
          }
        })
      }

      extractMenuForHome(this.isLogged ? this.$store.getters['menu/app'].CONNECTED : this.$store.getters['menu/app'].DISCONNECTED)

      return _menu.sort((a, b) => {
        return a.favorite - b.favorite
      })
    },
    preferences () { return this.$store.getters['$alto-preferences/preferences'] }
  },
  methods: {
    async addProductToCart ({ product, attributes, quantity = 1 }) {
      this.addingProduct = true
      let newCart
      // Override default product from catalog with user inputs, if any
      product.main = attributes || null

      try {
        if (!this.currentCarts?.name) {
          // No cart exist, create a brand new one
          this.$store.dispatch('$alto-quotations/create', { company: this.me.company })
          // Add product to cart
          await this.$store.dispatch('$alto-quotations/addToCurrent', { products: { ...product, quantity }, company: this.myCompany })
          // Save the new cart
          const { cart, isNew } = await this.$store.dispatch('$alto-quotations/save', { cart: this.currentCart })
          newCart = cart
          if (isNew) {
            this.$stratus.services.notify.success(this.$t('New cart {name} created.', { name: newCart.name }))
          }
        } else {
          // Add product to existing cart
          newCart = await this.$store.dispatch('$alto-quotations/addProduct', { id: this.currentCart.id, product: { ...product, quantity } })
        }

        this.$store.dispatch('$alto-quotations/list') // Refresh cart menu
        this.$store.commit('$alto-quotations/setCurrent', newCart) // Force refresh current cart
        if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()
        this.$stratus.services.notify.success(this.$t('{quantity} × {product} added to cart.', { quantity, product: product.name || product.ref }))
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => { this.addingProduct = false }, 1000)
      }
    },
    editCall (call) {
      if (this.canAddCall && this.$refs['calendar-call-dialog']) {
        this.$refs['calendar-call-dialog'].open(call)
      }
    },
    editEvent (event) {
      if (this.canAddEvent && this.$refs['calendar-event-dialog']) {
        this.$refs['calendar-event-dialog'].open(event)
      }
    },
    editTask (task) {
      if (this.canAddTask && this.$refs['calendar-task-dialog']) {
        this.$refs['calendar-task-dialog'].open(task)
      }
    },
    async createTicket ({ ticketType, descriptor }) {
      if (this.isLord) {
        if (this.$refs['ticket-dialog-secondary']) {
          this.$refs['ticket-dialog-secondary'].openNew({
            type: ticketType,
            code: this.me.company,
            state: this.$alto.defines.TICKETS.TICKET_STATE_TODO,
            severity: this.$alto.defines.TICKETS.TICKET_SEVERITY_NONE
          })
        }
        return
      }

      this.descriptor = descriptor
      if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT) {
        this.$refs['form-ticket-new-incident'].reset()
        this.showIncidentDialog = true
        this.$refs['form-ticket-new-incident'].resetPage()
      } else if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST) {
        this.$refs['form-ticket-new-request'].reset()
        this.showRequestDialog = true
      }
    },
    closeMarketingDialog () {
      if (this.$refs['activity-calendar']) this.$refs['activity-calendar'].refresh()
    },
    closeTicketDialog () {
      this.showIncidentDialog = false
      this.showRequestDialog = false
      if (this.$refs['activity-calendar']) this.$refs['activity-calendar'].refresh()
    },
    async loadFavoriteProducts () {
      if (this.preferences?.favoriteProducts?.length) {
        try {
          const response = await this.$store.dispatch('$alto-catalog/getProductsByRefs', {
            refs: this.preferences.favoriteProducts,
            fields: 'ref,name'
          })
          this.favoriteProducts = response || []
        } catch (error) {
          this.$stratus.services.notify.error(error)
        }
      }
    },
    async loadLists () {
      this.loadingCardsData = true
      try {
        this.myCompany = await this.$store.dispatch('$alto-companies/getById', this.me.company)

        this.loadFavoriteProducts()
        this.loadOrders()
        this.loadSubscriptions()
        this.loadTickets()
        this.orders = await this.$store.dispatch('$alto-orders/listTopPending', WIDGETS_ITEMS_NUMBER)
        this.carts = await this.$store.dispatch('$alto-quotations/listTop', WIDGETS_ITEMS_NUMBER)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.loadingCardsData = false
        this.$forceUpdate()
      }
    },
    async loadOrders () {
      if (this.canListOrders) {
        try {
          const response = await this.$store.dispatch('$alto-orders/getOrders', {
            company: this.me.company,
            states: [this.$alto.defines.ORDERS.ORDER_STATE_DEPOSIT_AWAITING],
            sortBy: '-update_at',
            limit: WIDGETS_ITEMS_NUMBER
          })
          this.pendingOrders = response?.results || []
        } catch (error) {
          this.$stratus.services.notify.error(error)
        }
      }
    },
    async loadPriceTrends () {
      if (!this.canReadOpportunities) return
      this.loadingOpportunity = true
      try {
        const ids = this.priceTrendCustomer ? [this.priceTrendCustomer] : [this.companyCode]
        const oppData = await this.$store.dispatch('opportunities/getCompaniesHistoryPrices', {
          ids,
          dateBegin: this.dateBegin,
          dateEnd: this.dateEnd
        })
        if (this.$refs['global-price-trend']) this.$refs['global-price-trend'].reset()
        this.opportunityData = { ...oppData }
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.opportunityData = {}
      } finally {
        this.priceTrendCustomer = this.priceTrendCustomer || this.companyCode
        setTimeout(() => { this.loadingOpportunity = false }, 1000)
      }
    },
    async loadSubscriptions () {
      if (this.canListOrders) {
        try {
          const response = await this.$store.dispatch('$alto-subscriptions/getSubscriptions', {
            // company: this.me.company,
            states: [this.$alto.defines.ORDERS.ORDER_STATE_TECH_AWAITING],
            sortBy: '-update_at',
            limit: WIDGETS_ITEMS_NUMBER
          })
          this.pendingSubscriptions = response?.results || []
        } catch (error) {
          this.$stratus.services.notify.error(error)
        }
      }
    },
    async loadTickets () {
      if (this.canListTickets) {
        try {
          const response = await this.$store.dispatch('$alto-ticketing/getTickets', {
            ownerId: this.me.id,
            states: [this.$alto.defines.TICKETS.TICKET_STATE_WAITING],
            waitingReasons: this.$alto.defines.TICKETS.TICKET_WAITING_REASON_CLI_RETURN,
            sortBy: '-update_at',
            limit: WIDGETS_ITEMS_NUMBER
          })
          this.pendingTickets = response?.results || []
        } catch (error) {
          this.$stratus.services.notify.error(error)
        }
        if (this.$refs['activity-calendar']) this.$refs['activity-calendar'].refresh()
      }
    },
    onCalendarDialogClose (saved) {
      if (saved) {
        if (this.$refs['activity-calendar']) this.$refs['activity-calendar'].refresh()
      }
    },
    async onCalendarDialogCloseReopen (saved, dialog) {
      if (saved) {
        if (this.$refs['activity-calendar']) this.$refs['activity-calendar'].refresh()
        this.$nextTick(() => {
          if (dialog === 'call') this.editCall({ anotherOne: true })
          if (dialog === 'event') this.editEvent({ anotherOne: true })
          if (dialog === 'task') this.creaseTask({ anotherOne: true })
        })
      }
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openCalendarEvent (event) {
      if (event.$type === this.$alto.defines.CALENDAR.EVENT_TYPE_TICKET) {
        this.openTicket(event.data)
      }
      if (event.$type === this.$alto.defines.CALENDAR.EVENT_TYPE_MARKETING) {
        this.openMarketingEvent(event.data.id)
      }
    },
    openMarketingEvent (id) {
      this.$refs['marketing-event-dialog'].open(id)
    },
    openOrder (id) {
      if (this.$refs['order-dialog']) this.$refs['order-dialog'].open(id)
    },
    async openProduct (ref) {
      if (this.addingProduct) return
      const product = await this.$store.dispatch('$alto-catalog/getProductByRef', ref)
      if (this.$refs['product-dialog'] && product) this.$refs['product-dialog'].open(_.cloneDeep(product))
    },
    openQuotation (id) {
      if (this.$root['cart-menu-global-dialog']) this.$root['cart-menu-global-dialog'].open(id)
    },
    openSubscription (id) {
      if (this.$refs['subscription-dialog']) this.$refs['subscription-dialog'].open(id)
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog-secondary']) this.$refs['ticket-dialog-secondary'].open(ticket.id)
    }
  },
  async created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Activities'))
    if (this.$stratus.services.auth.isLogged()) {
      try {
        this.me = await this.$store.dispatch('$stratus-states/getMe')
      } catch (error) {
        console.error(error)
        this.$nextTick(() => {
          this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
        })
      }
    }
  },
  async mounted () {
    if (!this.$stratus.services.auth.isLogged()) {
      this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
    } else {
      const me = this.$store.getters['$stratus-states/me']
      await this.$store.dispatch('$alto-preferences/load')
      if (!me?.email) {
        this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
      } else if (this.me?.role === this.$alto.defines.USERS.USER_ROLE_PURE_TECH) {
        this.$router.push({ name: 'subscriptions' }).catch((error) => { console.warn(error) })
      } else {
        if (this.$alto.services.routes.connectionForbidden.call(this, me)) return
        this.companyCode = me.company
        this.loadLists()
        this.dateBegin = this.$stratus.dt().subtract(6, 'month').startOf('month')
        this.dateEnd = this.$stratus.dt()
        this.loadPriceTrends()
      }
    }
  }
}
</script>
