import * as Sentry from '@sentry/vue'
import App from './App.vue'
import { registerRouteCheck, router } from './router/'

import config from '@/config'

import csServices from '@/services'
import http from '@/services/http'
import VueResource from 'vue-resource'

// These components are used everywhere.
import CartDialog from '@/components/Carts/CartDialog'
import CompanyDialog from '@/components/Companies/CompanyDialog'
import OpportunityDialog from '@/components/Opportunities/OpportunityDialog'
import OrderDialog from '@/components/Orders/OrderDialog'
import ProductDialog from '@/components/Products/ProductDialog'
import SphereDialog from '@/components/Spheres/SphereDialog'
import SubscriptionDialog from '@/components/Subscriptions/SubscriptionDialog'
import TicketDialog from '@/components/Tickets/TicketDialog'
import UserDialog from '@/components/Users/UserDialog'

function init (Vue, vuetify, store, Stratus, Alto) {
  const { name, version, isValid } = Stratus.services.browser.checkBrowser()

  if (isValid && config.sentryDSN?.length) {
    Sentry.init({
      Vue,
      dsn: config.sentryDSN,
      environment: config.env,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new Sentry.Replay()
      ],

      trackComponents: true,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // replaysSessionSampleRate: 0.1,
      // replaysOnErrorSampleRate: 1.0,

      ignoreErrors: [
        'Non-Error exception captured with keys: body, bodyText, headers, ok, status',
        'Object captured as promise rejection with keys: body, bodyText, headers, ok, status',
        'Object captured as exception with keys: body, bodyText, headers, ok, status',
        'Cannot convert a Symbol value to a string',
        'Cannot read properties of undefined (reading \'ok\')',
        'Cannot read properties of undefined (reading \'catch\')',
        'Cannot read properties of undefined (reading \'_transitionClasses\')',
        't.getContent() is undefined',
        'Cannot set properties of undefined (setting \'scrollTop\')',
        // Read https://stackoverflow.com/a/50387233/1660152
        'ResizeObserver loop limit exceeded',
        // Quill component
        'undefined is not an object (evaluating \'this.quill.getHTML\')',
        'undefined is not an object (evaluating \'this.emitter.emit\')',
        'Cannot read properties of undefined (reading \'emit\')',
        'this.emitter is undefined',
        /.\.hasAttribute is not a function/i,
        'Index or size is negative or greater than the allowed amount',
        // Occurs after session expired
        'undefined is not an object (evaluating \'e.ok\')',
        /.\.queue is undefined/i,
        'Non-Error promise rejection captured',
        'NavigationDuplicated',
        'requestAnimationFrame',
        'Hub.prototype.captureException',
        // PDF renderer
        'Rendering cancelled, page',
        'TypeError this.renderPage(vue-pdf/src/pdfjsWrapper)',
        /undefined is not an object \(evaluating '.\.cancel\(\)\.catch'\)/i,
        'Cannot read properties of null (reading \'sendWithPromise\')',
        '0:'
      ],
      beforeSend (event, hint) {
        if (hint.originalException === 'Timeout') {
          // Ignore "UnhandledRejection: Non-Error promise rejection captured with value: Timeout"
          // This issue comes from [recaptcha bug](https://github.com/getsentry/sentry-javascript/issues/2514)
          console.log('[Sentry] ignoring "UnhandledRejection: Non-Error promise rejection captured with value: Timeout"')
          return null
        }
        return event
      }
    })
    Stratus.services.debug.log('%c Sentry %c activated! %c')
    Stratus.services.debug.info(`Sentry DSN: %c ${config.sentryDSN} %c`)
  } else {
    Stratus.services.debug.warn('Sentry %c disabled! %c')
    if (!isValid) Stratus.services.debug.warn(`This browser (${name} ${version}) is too old or %c not supported %c. This application will not work properly!`)
  }

  Vue.use(VueResource)
  Vue.use(csServices)

  // Register global components
  Vue.component('csm-company-dialog', CompanyDialog)
  Vue.component('csm-opportunity-dialog', OpportunityDialog)
  Vue.component('csm-order-dialog', OrderDialog)
  Vue.component('csm-product-dialog', ProductDialog)
  Vue.component('csm-sphere-dialog', SphereDialog)
  Vue.component('csm-cart-dialog', CartDialog)
  Vue.component('csm-subscription-dialog', SubscriptionDialog)
  Vue.component('csm-ticket-dialog', TicketDialog)
  Vue.component('csm-user-dialog', UserDialog)

  new Vue({
    i18n: Alto.i18n,
    router,
    store,
    vuetify,
    csServices,
    created () {
      // Update framework modules with ready to all-inclusive translations
      this.$stratus.services.array.register(Alto.i18n, this.$stratus.dt)
      // Allow frameworks access from store
      this.$store.commit('loadStratus')
      this.$store.commit('loadAlto')
      this.$stratus.services.debug.info(`Stratus version: %c ${Stratus.version} %c`)
      this.$stratus.services.debug.info(`Alto version: %c ${Alto.version} %c`)
      registerRouteCheck(this.$stratus, router)
      // Register HTTP interceptors
      this.$stratus.services.http = Vue.use(this.$stratus.services.http, this.$stratus.services.auth)
      Vue.use(http, this.$stratus.services.auth)
    },
    render: h => h(App)
  }).$mount('#app')
}

export default { init }
