<template>
  <csm-ticket-form :ref="`ticket-form-${uuid}`" :value="ticket" :comment-id="commentId" :loading="loading" :show="canShow" :update="canUpdate" @closeDialog="closeDialog" @refresh="onRefresh" />
</template>

<script>
import _ from 'lodash'

let previousTicket = null

export default {
  name: 'TicketDialog',
  components: { 'csm-ticket-form': () => import(/* webpackChunkName: "components" */ './TicketForm') },
  data () {
    return {
      commentId: null,
      loading: true,
      restoreTitle: false,
      ticket: {},
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.ticket?.id && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.UPDATE)
    }
  },
  methods: {
    closeDialog ({ reloadTicket } = {}) {
      if (!reloadTicket) {
        if (this.$refs[`ticket-form-${this.uuid}`]) this.$refs[`ticket-form-${this.uuid}`].display(false)
        previousTicket = null
        if (this.restoreTitle) this.$store.commit('$stratus-states/popBrowserTitle')
        if (this.$route?.name === 'ticket' && this.$route?.params?.id) {
          this.$router.push({ name: 'activities' }).catch((error) => { console.warn(error) })
        } else {
          this.$emit('closeDialog', this.ticket)
        }
      }

      this.open(reloadTicket)
    },
    async get (id, commentId) {
      try {
        this.loading = true
        let ticket
        if (_.isObject(id)) {
          ticket = { ...id }
        } else {
          ticket = { ...await this.$store.dispatch('$alto-ticketing/getTicket', { id, forceReload: true, history: true }) }
        }
        ticket.description = this.$stratus.services.format.sanitizeHTML(ticket.description || '')
        this.ticket = ticket
        await this.reset(this.ticket)
        if (commentId) this.scrollToComment(commentId)
      } catch (error) {
        if (error.status === 404) this.$stratus.services.notify.error(this.$t('Ticket «{name}» not found!', { name: id }))
        else this.$stratus.services.notify.error(error)
        this.closeDialog()
      } finally {
        setTimeout(() => { this.loading = false }, 500)
      }
    },
    async onRefresh (data) {
      if (!data.id) {
        this.ticket = { ...data }
        this.$forceUpdate()
        return
      }
      // Reload
      await this.get(data.id)
      this.$forceUpdate()
    },
    async open (id, commentId) {
      if (!id) return
      this.ticket = {}
      this.reset()
      await this.get(id, commentId)
      if (this.$refs[`ticket-form-${this.uuid}`]) await this.$refs[`ticket-form-${this.uuid}`].display(true)

      this.$store.commit('$stratus-states/pushBrowserTitle', this.ticket.id)
      this.restoreTitle = true
    },
    openNew (ticket = {}) {
      this.reset()
      this.get(ticket)
      if (this.$refs[`ticket-form-${this.uuid}`]) this.$refs[`ticket-form-${this.uuid}`].display(true)
    },
    async reset () {
      this.restoreTitle = false
      if (this.$refs[`ticket-form-${this.uuid}`]) await this.$refs[`ticket-form-${this.uuid}`].reset(this.ticket)
    },
    scrollToComment (commentId) {
      this.commentId = commentId
    }
  },
  async mounted () {
    if (this.$route?.name === 'ticket' && this.$route.params.id) {
      if (this.$route.params.id !== previousTicket) {
        previousTicket = this.$route.params.id
        const me = await this.$store.dispatch('$stratus-states/getMe')
        if (this.$alto.services.routes.connectionForbidden.call(this, me)) return
        this.$nextTick(() => { this.open(this.$route.params.id, this.$route.params.comment_id) })
      }
    }
  }
}
</script>
