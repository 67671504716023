<template>
  <div>
    <v-row>
      <v-col>
        <div class="d-flex align-baseline">
          <div>
            {{ $t('Filters') }}
          </div>

          <div v-if="!hideFilterTeam" class="ml-2">
            <sca-teams-select v-model="teamIds" :label="$t('Teams')" :disabled="loading" dense multiple @selectionChange="loadDebounce()" />
          </div>

          <div class="ml-2">
            <sca-customer-select v-model="customerIds" :label="$t('Customers')" :disabled="loading" dense multiple allow-all clearable @input="loadDebounce()" />
          </div>

          <div class="ml-2">
            <sca-sphere-select v-model="projectCodes" :label="$t('Spheres')" :customer="customerIds" :loading="loading" dense clearable multiple @input="loadDebounce()" />
          </div>

          <div class="shrink">
            <v-menu offset-y :close-on-content-click="false" transition="slide-y-transition">
              <template v-slot:activator="{ on: onMenu }">
                <v-tooltip top>
                  <template v-slot:activator="{ on: onTooltip }">
                    <v-btn small icon rounded v-on="{ ...onMenu, ...onTooltip }">
                      <v-icon small color="menu-icon">
                        $vuetify.icons.options
                      </v-icon>
                    </v-btn>
                  </template>
                  {{ $t('Options') }}
                </v-tooltip>
              </template>

              <v-card tile class="pa-2">
                <v-switch v-model="displaySphere" :label="$t('Display sphere')" @change="savePrefs" dense hide-details class="ma-0 pb-2" />
                <v-switch v-model="hideSubTasks" :label="$t('Hide subs-tasks')" @change="savePrefs" dense hide-details class="ma-0 pa-0" />

                <v-row align="center" class="mt-2" dense>
                  <v-col cols="12" class="text-caption d-flex align-center">
                    {{ $t('Save current filter') }}
                    <v-divider class="ml-3" />
                  </v-col>

                  <v-col>
                    <v-text-field v-model="prefSlotName" :label="$t('Filter name')" dense />
                  </v-col>

                  <v-col class="shrink">
                    <v-btn icon rounded :disabled="!prefSlotName?.length" @click="addSlot">
                      <v-icon small>
                        $vuetify.icons.add
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <template v-if="prefSlotsCount">
                  <div cols="12" class="text-caption d-flex align-center">
                    {{ $t('Saved filters') }}
                    <v-divider class="ml-3" />
                  </div>

                  <div v-for="(slot, index) in prefSlots" :key="index" class="d-flex align-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" rounded text @click="applySlot(index)">
                          <v-icon small left>
                            $vuetify.icons.filter
                          </v-icon>
                          {{ slot.name }}
                        </v-btn>
                      </template>
                      {{ $t('Apply this filter') }}
                    </v-tooltip>

                    <div class="ml-auto">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" small icon rounded @click="savePrefs(index, slot.name)">
                            <v-icon small color="primary">
                              $vuetify.icons.fileUpload
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ $t('Save in this filter') }}
                      </v-tooltip>

                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" small icon rounded @click="deleteSlot(index)">
                            <v-icon small color="danger">
                              $vuetify.icons.delete
                            </v-icon>
                          </v-btn>
                        </template>
                        {{ $t('Delete') }}
                      </v-tooltip>
                    </div>
                  </div>
                </template>
              </v-card>
            </v-menu>
          </div>
        </div>

        <v-row align="center" dense>
          <v-col v-if="!hideFilterGroup" class="text-no-wrap shrink">
            <span class="mr-4">
              {{ $t('Group by') }}
            </span>
            <v-btn-toggle v-model="groupBy" class="transparent" dense @change="loadDebounce()">
              <v-btn small text :value="GROUP_BY_NONE" :class="groupBy === GROUP_BY_NONE ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('None') }}
              </v-btn>
              <v-btn small text :value="GROUP_BY_TEAM" :class="groupBy === GROUP_BY_TEAM ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('By team') }}
              </v-btn>
              <v-btn small text :value="GROUP_BY_USER" :class="groupBy === GROUP_BY_USER ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('By user') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col class="d-flex align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <cs-avatar-empty-none v-if="hideFilterGroup" class="mr-1 clickable" :size="userSelectedId === UNALLOCATED ? 32 : 24" :color="(userSelectedId === UNALLOCATED ? 'avatar-selected' : '')" v-on="on" @click="selectUser({ id: UNALLOCATED })" />
              </template>
              {{ $t('Unallocated') }}
            </v-tooltip>

            <template v-for="userId in userIds">
              <sca-user-identity v-if="userId !== me.id" :key="userId" :value="userId" dense can-select show-avatar show-card show-company show-role show-phone show-email no-label :selected="userId === userSelectedId" :large="userId === userSelectedId" :disabled="loading" @select="selectUser" />
            </template>
            <v-btn v-if="me?.id" rounded class="pl-1 pr-2" :class="userSelectedId === me.id ? 'primary button-main-ink--text' : ''" @click="selectUser(me)">
              <sca-user-avatar :user-id="me.id" class="mr-1" :size="userSelectedId === me.id ? 32 : 24" />
              {{ $t('My tickets') }}
            </v-btn>

            <v-btn v-show="[GROUP_BY_TEAM, GROUP_BY_USER].includes(groupBy)" class="ml-2" rounded @click="expandAll">
              {{ isAllExpanded ? $t('Collapse all') : $t('Expand all') }}
              <v-icon right>
                {{ isAllExpanded ? '$vuetify.icons.collapse' : '$vuetify.icons.expand' }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="shrink text-no-wrap text-center">
        <cs-refresh-button :loading="loading" @click="loadDebounce()" />

        <div v-show="lastRefreshDate" class="text-caption">
          ({{ $t('Last refresh: {date}', { date: $stratus.dt(lastRefreshDate).format('LT') }) }})
        </div>
      </v-col>

      <v-col class="shrink">
        <v-btn v-if="canAdd" :disabled="loading" small fab right class="primary" @click="createTicket">
          <v-icon>
            $vuetify.icons.add
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="!hideFilterTeam" justify="center">
      <v-col class="shrink text-no-wrap">
        <cs-alert-panel v-if="!teamIds?.length" dense :text="$t('Choose a team to see its tickets...')" />
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="groupBy === GROUP_BY_TEAM" cols="12">
        <div v-for="ticketsTeam in ticketsByTeam" :key="ticketsTeam.id" class="pb-4">
          <cs-expand-panel :ref="`kanban-team-${ticketsTeam.id}`" block :expanded="isTeamExpanded(ticketsTeam.id)" @expand="onExpandTeam(ticketsTeam.id, $event)">
            <v-row slot="header" class="text-h5" align="center" dense>
              <sca-team-identity v-if="ticketsTeam.id !== UNKNOWN" :value="ticketsTeam.id" />
              <span v-else>
                {{ $t('Undefined') }}
              </span>
              <v-chip v-if="counts[ticketsTeam.id] !== undefined" class="ml-2">
                {{ counts[ticketsTeam.id] }}
              </v-chip>
            </v-row>

            <div slot="content">
              <sca-kanban-board :ref="`kanban-${GROUP_BY_TEAM}-${uuid}`" :graph="graph" :internal-id="ticketsTeam.id" dense :tickets="ticketsTeam.tickets" :sort="sort" :hide-sub-tasks="hideSubTasks" :display-spheres="displaySphere" show-count @open="openTicket" @loaded="updateCounts" @reload="load" />
            </div>
          </cs-expand-panel>
        </div>
      </v-col>

      <v-col v-else-if="groupBy === GROUP_BY_USER" cols="12">
        <div v-for="ticketsUser in ticketsByUser" :key="ticketsUser.id" class="pb-4">
          <cs-expand-panel :ref="`kanban-user-${ticketsUser.id}`" block :expanded="isUserExpanded(ticketsUser.id)" @expand="onExpandUser(ticketsUser.id, $event)">
            <v-row slot="header" class="text-h5" align="center" dense>
              <sca-user-identity v-if="ticketsUser.id !== UNKNOWN" :value="ticketsUser.id" large show-card show-avatar show-company show-role dense link="emit" @link-click="openUser(ticketsUser.id)" />
              <span v-else>
                {{ $t('Unallocated') }}
              </span>
              <v-chip v-if="counts[ticketsUser.id] !== undefined" class="ml-2">
                {{ counts[ticketsUser.id] }}
              </v-chip>
            </v-row>

            <div slot="content">
              <sca-kanban-board :ref="`kanban-${GROUP_BY_USER}-${uuid}`" :graph="graph" :internal-id="ticketsUser.id" dense :tickets="ticketsUser.tickets" :sort="sort" :hide-sub-tasks="hideSubTasks" :display-spheres="displaySphere" show-count @open="openTicket" @loaded="updateCounts" @reload="load" />
            </div>
          </cs-expand-panel>
        </div>
      </v-col>

      <v-col v-else cols="12">
        <sca-kanban-board :ref="`kanban-${GROUP_BY_NONE}-${uuid}`" :graph="graph" dense :tickets="tickets" :sort="sort" :hide-sub-tasks="hideSubTasks" :display-spheres="displaySphere" show-count @open="openTicket" @reload="load" />
      </v-col>
    </v-row>

    <csm-ticket-dialog ref="ticket-dialog" @closeDialog="onCloseTicket" />
    <csm-user-dialog ref="user-dialog" />
    <cs-confirm-dialog ref="confirm-kanban-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

const LOADING_MIN_DURATION = 500
const DEFAULT_REFRESH_DELAY = 5 // Minutes

const ALL = 'ALL'
const UNALLOCATED = 'UNALLOCATED'

const GROUP_BY_UNSELECTED = 0
const GROUP_BY_NONE = 1
const GROUP_BY_TEAM = 2
const GROUP_BY_USER = 3

export default {
  name: 'KanbanGeneric',
  props: {
    defaultTicket: { type: Object, default: null },
    filterTeams: { type: [Array, Number, String], default: null },
    graph: { type: String, default: 'default' },
    hideFilterGroup: { type: Boolean, default: false },
    hideFilterTeam: { type: Boolean, default: false },
    prefKey: { type: String, default: null },
    sort: { type: [Array, Function, String], default: () => ['severity', 'id'] }
  },
  data () {
    return {
      GROUP_BY_NONE,
      GROUP_BY_TEAM,
      GROUP_BY_UNSELECTED,
      GROUP_BY_USER,
      KANBAN_MANDATORY_FIELDS: this.$alto.defines.TICKETS.KANBAN_MANDATORY_FIELDS,
      KANBAN_TICKETS_STATES: this.$alto.defines.TICKETS.KANBAN_TICKETS_STATES,
      TICKET_DEFAULT_VALUES: this.$alto.defines.TICKETS.TICKET_DEFAULT_VALUES,
      TICKET_TYPE_PROJECT: this.$alto.defines.TICKETS.TICKET_TYPE_PROJECT,
      UNALLOCATED,
      UNKNOWN: '~',
      canSavePrefs: false,
      collapsedTeams: [],
      collapsedUsers: [],
      counts: {},
      customerIds: [],
      displaySphere: false,
      groupBy: GROUP_BY_NONE,
      hideSubTasks: false,
      lastRefreshDate: new Date(),
      loadDebounce: this.load,
      loading: false,
      prefRoot: this.preferences,
      prefSlotName: '',
      prefSlots: [],
      projectCodes: [],
      refreshHandler: null,
      teamIds: [],
      tickets: [],
      ticketsByTeam: [],
      ticketsByUser: [],
      userIds: [],
      userSelectedId: null,
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      preferences: '$alto-preferences/preferences'
    }),
    canAdd () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.CREATE)
    },
    isAllExpanded () {
      if (this.groupBy === GROUP_BY_TEAM) return this.collapsedTeams.length === 0 || !this.collapsedTeams.includes(ALL)
      if (this.groupBy === GROUP_BY_USER) return this.collapsedUsers.length === 0 || !this.collapsedUsers.includes(ALL)
      return false
    },
    prefSlotsCount () {
      return this.prefSlots?.length || 0
    }
  },
  beforeDestroy () {
    clearTimeout(this.refreshHandler)
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    await this.$store.dispatch('$alto-preferences/load')

    this.prefRoot = this.preferences
    if (this.prefKey) {
      if (!this.preferences[this.prefKey]) this.preferences[this.prefKey] = {}
      this.prefRoot = this.preferences[this.prefKey]
    }

    if (this.prefRoot?.kanbanFilter) {
      if (this.filterTeams) {
        this.teamIds = Array.isArray(this.filterTeams) ? this.filterTeams : [this.filterTeams]
      } else {
        this.teamIds = (this.prefRoot.kanbanFilter.teamIds || []).includes(ALL) ? [] : this.prefRoot.kanbanFilter.teamIds || []
      }
      this.customerIds = this.prefRoot.kanbanFilter.customerIds || [ALL]
      this.projectCodes = this.prefRoot.kanbanFilter.projectCodes || []
      this.groupBy = this.prefRoot.kanbanFilter.groupBy || GROUP_BY_NONE
      this.displaySphere = this.prefRoot.kanbanFilter.displaySphere || false // Ensure Boolean
      this.hideSubTasks = this.prefRoot.kanbanFilter.hideSubTasks || false // Ensure Boolean
      if (this.prefRoot.kanbanFilter.myTickets) {
        this.userSelectedId = this.me.id
        this.groupBy = GROUP_BY_UNSELECTED
      }
    }
    this.prefSlots = _.compact(this.prefRoot?.kanbanFilterSlots || [])

    this.load({ savePrefs: false, onLoaded: this.applyHiddenPanels })
    this.loadDebounce = _.debounce(this.load, LOADING_MIN_DURATION)
  },
  methods: {
    addSlot () {
      this.savePrefs(this.prefSlotsCount + 1, this.prefSlotName)
    },
    applyHiddenPanels () {
      this.collapsedTeams = this.prefRoot?.kanbanLayout?.teams ? [...this.prefRoot.kanbanLayout.teams] : []
      this.collapsedUsers = this.prefRoot?.kanbanLayout?.users ? [...this.prefRoot.kanbanLayout.users] : []
    },
    applySlot (slotIndex) {
      if (!this.prefRoot?.kanbanFilterSlots?.[slotIndex]?.filters) return

      const filter = { ...this.prefRoot.kanbanFilterSlots[slotIndex].filters }
      this.teamIds = (filter.teamsId || []).includes(ALL) ? [] : filter.teamIds || []
      this.customerIds = filter.customerIds || [ALL]
      this.projectCodes = filter.projectCodes || []
      this.groupBy = filter.groupBy || GROUP_BY_NONE
      this.displaySphere = filter.displaySphere || false // Ensure Boolean
      this.hideSubTasks = filter.hideSubTasks || false // Ensure Boolean
      if (this.prefRoot.kanbanFilter.myTickets) {
        this.userSelectedId = this.me.id
        this.groupBy = GROUP_BY_UNSELECTED
      }

      this.load({ savePrefs: false, forceFilter: true })
    },
    createFilter () {
      const filter = {
        fields: this.KANBAN_MANDATORY_FIELDS,
        states: this.KANBAN_TICKETS_STATES[this.$alto.defines.TICKETS.STATE_GRAPH_DEFAULT],
        types: this.$alto.defines.TICKETS.TICKET_TYPE_BY_GRAPH[this.graph],
        groupBy: this.groupBy,
        displaySphere: this.displaySphere,
        hideSubTasks: this.hideSubTasks
      }

      if (this.teamIds && this.teamIds.length) filter.teamIds = this.teamIds.includes(ALL) ? [] : this.teamIds

      if (this.customerIds && this.customerIds.length) filter.customerIds = this.customerIds.includes(ALL) ? [] : this.customerIds

      if (this.projectCodes && this.projectCodes.length) filter.projectCodes = this.projectCodes.includes(ALL) ? [] : this.projectCodes

      return filter
    },
    createTicket () {
      this.$refs['ticket-dialog'].open({ ...(this.defaultTicket || this.TICKET_DEFAULT_VALUES), code: this.me.company })
    },
    deleteSlot (slotIndex) {
      if (!this.prefRoot.kanbanFilterSlots[slotIndex]) return

      this.$refs['confirm-kanban-dialog']
        .open(this.$t('Delete filter'), this.$t('Delete filter «{name}»?', this.prefRoot.kanbanFilterSlots[slotIndex]), { color: 'danger' })
        .then(confirmed => {
          if (confirmed) {
            this.prefRoot.kanbanFilterSlots.splice(slotIndex, 1)
            this.savePrefs()
            this.prefSlots = [...this.prefRoot.kanbanFilterSlots]
          }
        })
    },
    expandAll () {
      if (this.groupBy === GROUP_BY_TEAM) {
        this.collapsedTeams = this.collapsedTeams.length === 0 ? [ALL] : this.collapsedTeams = []
      } else if (this.groupBy === GROUP_BY_USER) {
        this.collapsedUsers = this.collapsedUsers.length === 0 ? [ALL] : this.collapsedUsers = []
      }
      return false
    },
    isTeamExpanded (teamId) {
      return !this.collapsedTeams.includes(ALL) && !this.collapsedTeams.includes(teamId)
    },
    isUserExpanded (userId) {
      return !this.collapsedUsers.includes(ALL) && !this.collapsedUsers.includes(userId)
    },
    async load ({ savePrefs = true, onLoaded, forceFilter } = {}) {
      const memSelectedUserId = this.groupBy === GROUP_BY_UNSELECTED ? this.userSelectedId : null
      this.userSelectedId = null
      if (this.refreshHandler) clearTimeout(this.refreshHandler)

      try {
        this.canSavePrefs = savePrefs
        const ticketFilter = this.createFilter()
        if (!this.hideFilterTeam && !ticketFilter?.teamIds?.length) return
        this.prefRoot.kanbanFilter = ticketFilter
        if (savePrefs) this.savePrefs()

        // Request for tickets
        this.userSelectedId = null
        if (Object.keys(ticketFilter).length > 2) {
          // Apply filter and load tickets
          this.loading = true

          if (!forceFilter && memSelectedUserId) {
            this.selectUser({ id: memSelectedUserId })
            return // All is done from the method above :)
          } else {
            const response = await this.$store.dispatch('$alto-ticketing/getTickets', ticketFilter)
            this.tickets = response?.results || []
          }

          // Fill by keys
          const byTeam = {}
          const byUser = {}
          this.userIds = []
          if (this.tickets.length) {
            for (const ticket of this.tickets) {
              // Group by team
              if (ticket.id_lord_team) {
                const team = await this.$store.dispatch('$alto-companies/getTeam', { id: ticket.id_lord_team })
                if (!byTeam[ticket.id_lord_team || this.UNKNOWN]) {
                  byTeam[ticket.id_lord_team || this.UNKNOWN] = {
                    id: ticket.id_lord_team || this.UNKNOWN,
                    name: team?.name || '',
                    tickets: []
                  }
                }
                byTeam[ticket.id_lord_team || this.UNKNOWN].tickets.push(ticket)
              }

              // Group by user that are owner of the ticket
              const user = await this.$store.dispatch('$alto-users/getOne', ticket.id_owner)
              if (!byUser[ticket.id_owner || this.UNKNOWN]) {
                byUser[ticket.id_owner || this.UNKNOWN] = {
                  id: ticket.id_owner || this.UNKNOWN,
                  name: user?.lastname ? `${user.firstname} ${user.lastname}` : '',
                  tickets: []
                }
                if (ticket.id_owner) this.userIds.push(ticket.id_owner)
              }
              byUser[ticket.id_owner || this.UNKNOWN].tickets.push(ticket)

              // Add user that are referrer's one by the ticket
              if (ticket.id_referring && ticket.id_owner !== ticket.id_referring) {
                const refUser = await this.$store.dispatch('$alto-users/getOne', ticket.id_referring)
                if (refUser) {
                  if (!byUser[ticket.id_referring]) {
                    byUser[ticket.id_referring] = {
                      id: ticket.id_referring,
                      name: user?.lastname ? `${user.firstname} ${user.lastname}` : '',
                      tickets: []
                    }
                  }
                  byUser[ticket.id_referring].tickets.push(ticket)
                }
              }
            }
          }

          // Sort by team name
          this.ticketsByTeam = _.sortBy(byTeam, 'name')

          // Sort by user name
          this.ticketsByUser = _.sortBy(byUser, 'name')

          this.lastRefreshDate = new Date()

          // Force refresh Kanban components
          this.uuid = this.$stratus.uuid()
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => {
          if (onLoaded) onLoaded()
          this.loading = false
          this.canSavePrefs = true
        }, LOADING_MIN_DURATION)
        this.refreshHandler = setTimeout(() => { this.load() }, DEFAULT_REFRESH_DELAY * 60 * 1000)
      }
    },
    onCloseTicket (ticket) {
      this.load()
    },
    onExpandTeam (teamId, expanded) {
      if (expanded) {
        const removed = _.remove(this.collapsedTeams, team => team === teamId) // Remove team from hidden
        if (removed) _.pull(this.collapsedTeams, ALL)
        else return // nothing change: do not save prefs
      } else {
        if (!this.collapsedTeams.includes(teamId)) this.collapsedTeams.push(teamId)
        else return // nothing change: do not save prefs
      }
      this.savePrefs()
    },
    onExpandUser (userId, expanded) {
      if (expanded) {
        const removed = _.remove(this.collapsedUsers, user => user === userId) // Remove users from hidden
        if (removed) _.pull(this.collapsedUsers, ALL)
        else return // nothing change: do not save prefs
      } else {
        if (!this.collapsedUsers.includes(userId)) this.collapsedUsers.push(userId)
        else return // nothing change: do not save prefs
      }
      this.savePrefs()
    },
    openTicket (ticketId) {
      if (!ticketId) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticketId)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async savePrefs (slotIndex, slotName) {
      if (!this.canSavePrefs) return
      try {
        // Always save current filters
        if (!this.prefRoot.kanbanFilter) this.prefRoot.kanbanFilter = {}
        this.prefRoot.kanbanFilter.groupBy = this.groupBy
        this.prefRoot.kanbanFilter.displaySphere = this.displaySphere
        this.prefRoot.kanbanFilter.hideSubTasks = this.hideSubTasks
        this.prefRoot.kanbanFilter.myTickets = this.userSelectedId === this.me.id
        this.prefRoot.kanbanFilter.teamIds = this.teamIds
        this.prefRoot.kanbanFilter.customerIds = this.customerIds
        this.prefRoot.kanbanFilter.projectCodes = this.projectCodes

        if (slotIndex !== undefined && slotName) {
          if (!this.prefRoot.kanbanFilterSlots) this.prefRoot.kanbanFilterSlots = []
          if (!this.prefRoot.kanbanFilterSlots[slotIndex]) {
            this.prefRoot.kanbanFilterSlots.push({
              name: slotName || this.$t('Filters') + ` ${slotIndex + 1}`,
              filters: this.prefRoot.kanbanFilter
            })
          } else {
            this.prefRoot.kanbanFilterSlots[slotIndex] = {
              name: slotName || this.$t('Filters') + ` ${slotIndex + 1}`,
              filters: this.prefRoot.kanbanFilter
            }
          }
        }
        this.prefRoot.kanbanFilterSlots = _.compact(this.prefRoot.kanbanFilterSlots)

        this.prefRoot.kanbanLayout = {
          teams: [..._.chain(this.collapsedTeams).compact().uniq().value()],
          users: [..._.chain(this.collapsedUsers).compact().uniq().value()]
        }

        await this.$store.dispatch('$alto-preferences/save', { kanbanFilter: this.prefRoot.kanbanFilter, kanbanFilterSlots: this.prefRoot.kanbanFilterSlots, kanbanLayout: this.prefRoot.kanbanLayout })
        this.prefSlots = [...this.prefRoot.kanbanFilterSlots]
        this.prefSlotName = ''
        if (slotName) this.$stratus.services.notify.success('Filter saved.')
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    selectMyTicket () {
      this.selectUser(this.me)
    },
    async selectUser ({ id } = {}) {
      if (this.userSelectedId === id || !id) {
        // Unselect current selected user
        this.userSelectedId = null
        this.groupBy = GROUP_BY_NONE
        this.load()
        return
      } else {
        this.userSelectedId = id
        this.groupBy = GROUP_BY_UNSELECTED
      }

      this.loading = true
      // Hide or show tickets depending on their user
      const visibleProjectTickets = []

      const ticketFilter = this.createFilter()
      if (this.userSelectedId && this.userSelectedId !== UNALLOCATED) {
        ticketFilter.userIds = this.userSelectedId // Owner or referrer
      }
      ticketFilter.noOwnerId = this.userSelectedId === UNALLOCATED // Select unallocated tickets only
      delete ticketFilter.groupBy

      const response = await this.$store.dispatch('$alto-ticketing/getTickets', ticketFilter)
      const tickets = response?.results || []

      _.forEach(tickets, ticket => {
        if (ticket.type === this.TICKET_TYPE_PROJECT && !visibleProjectTickets.includes(ticket.id)) visibleProjectTickets.push(ticket.id)

        // A sub-task that is not hidden must unhide its project ticket
        if (ticket.id_project_ticket && !visibleProjectTickets.includes(ticket.id_project_ticket)) visibleProjectTickets.push(ticket.id_project_ticket)
      })

      // Set hidden flag for all project tickets
      const projectTickets = _.filter(this.tickets, { type: this.$alto.defines.TICKETS.TICKET_TYPE_PROJECT })
      _.forEach(projectTickets, ticket => {
        ticket.hidden = !visibleProjectTickets.includes(ticket.id)
      })

      this.tickets = tickets
      if (this.userSelectedId) this.groupBy = GROUP_BY_UNSELECTED
      else this.groupBy = GROUP_BY_NONE

      this.savePrefs()
      setTimeout(() => {
        this.lastRefreshDate = new Date()
        this.loading = false
      }, LOADING_MIN_DURATION)
    },
    updateCounts ({ internalId, counts }) {
      this.$set(this.counts, internalId, counts.total)
    }
  }
}
</script>
