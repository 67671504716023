import Vue from 'vue'

import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vuetify from 'vuetify'

// Translation provided by Vuetify
import en from 'vuetify/es5/locale/en'
import fr from 'vuetify/es5/locale/fr'

import 'vuetify/dist/vuetify.min.css'
import './styles/variables.scss'
import './styles/common.css'
import './styles/cs-animations.css'
import './styles/cs-opacity.css'
import './styles/cs-theme-light.css'
import './styles/cs-theme-dark.css'

import config from '@/config'
import locales from '@/locales/'
import { register as registerStratus, icons as iconsStratus, theme } from 'stratus-framework-v2'
import { register as registerAlto, icons as iconsAlto } from 'stratus-alto'
import store from './store/'
import icons from './theme/icons'

// make sure to set this synchronously immediately after loading Vue and before `new Vue`
Vue.config.silent = process.env.NODE_ENV === 'production'
Vue.config.productionTip = process.env.NODE_ENV !== 'production'
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

function init () {
  Vue.use(Vuetify)
  const opts = {
    theme,
    icons: {
      iconfont: 'fa',
      values: { ...iconsStratus, ...iconsAlto, ...icons }
    },
    lang: {
      locales: { 'en-US': en, 'fr-FR': fr },
      current: 'en-US'
    },
    // Breakpoints MUST be synchronized with styles/variables.css
    // FullHD screen are now LG
    // Use XL for screen larger than full HD (1920px)
    breakpoint: {
      thresholds: {
        xs: 600,
        sm: 1024,
        md: 1298,
        lg: 1938
      },
      scrollBarWidth: 16 // keep this width for a scroll bar
    }
  }
  const vuetify = new Vuetify(opts)

  const Stratus = registerStratus(Vue, {
    api: config.api,
    appEnv: config.env,
    debug: {
      // infiniteLoop: true,
      traceNotify: 'error'
    },
    auth: {
      cookieDomain: process.env.VUE_APP_AUTH_COOKIE_DOMAIN || '{{ AUTH_COOKIE_DOMAIN }}'
    },
    defaultLocale: config.locale,
    messages: locales.messages,
    LOCALES: locales.LOCALES,
    LOCALES_COUNTRIES: locales.LOCALES_COUNTRIES,
    store,
    vuetify
  })

  const Alto = registerAlto(Vue, {
    PREF_APP_NAME: 'manager',
    PREF_DEFAULTS: {
      catalogDisplayType: 0,
      favoriteProducts: [],
      kanbanFilter: null
    },
    Stratus,
    corporationCode: config.defaults?.corporationCode,
    defaultLocale: config.locale,
    messages: Stratus.i18n.messages,
    LOCALES: locales.LOCALES,
    LOCALES_COUNTRIES: locales.LOCALES_COUNTRIES,
    store
  })

  const _app = require('./main-app').default
  Stratus.services.debug.log('%c Scalair Manager %c started! %c')
  Stratus.services.debug.info(`Vue version: %c ${Vue.version} %c`)
  Stratus.services.debug.info(`Vuetify version: %c ${Vuetify.version} %c`)
  Stratus.services.debug.info(`Vue.config.devtools:  %c ${Vue.config.devtools} %c`)
  Stratus.services.debug.info(`Vue.config.performance:  %c ${Vue.config.performance} %c`)
  _app.init(Vue, vuetify, store, Stratus, Alto)
}

init()
