<template>
  <div class="px-0">
    <v-row v-if="!hideSearch" dense align="center">
      <v-col>
        <cs-search-input @search="onSearch" prepend-inner-icon="icon-magnifier" :placeholder="$t('Search...')" color="primary" clearable />
      </v-col>

      <v-col>
        <sca-customer-select v-model="customer" :label="$t('Customer')" show-sales-person clearable dense hide-details @input="onSearch" show-email show-phone link="emit" @link-click="openCompany(customer)" />
      </v-col>

      <v-col class="shrink text-no-wrap">
        <span>{{ $t('Filter') }}</span>
      </v-col>

      <v-col class="text-left">
        <template v-for="(itemType, index) in ITEM_TYPES">
          <v-tooltip top :key="index">
            <template v-slot:activator="{ on }">
              <v-btn small rounded class="ml-2" v-on="on" :color="isFilterActive(index) ? ITEM_COLORS[itemType] : ''" :loading="loadingFilters" :outlined="!isFilterActive(index)" @click="toggleFilter(index)">
                <v-icon small :class="isFilterActive(index) ? 'black--text' : 'menu-icon--text'">
                  {{ ICON_TYPES[itemType] }}
                </v-icon>
                <span class="ml-2" :class="isFilterActive(index) ? 'black--text' : 'menu-icon--text'">
                  {{ followUp[itemType] ? followUp[itemType].length : '' }}
                </span>
              </v-btn>
            </template>
            {{ $t('Click to show or hide {name}.', { name: $t(`follow-up-type-${itemType}`) }) }}
          </v-tooltip>
        </template>
      </v-col>

      <v-col v-show="loading" class="text-left">
        <cs-icon-loading small left show-text />
      </v-col>

      <v-col v-if="allowAdd" class="shrink">
        <v-menu bottom close-on-content-click offset-y transition="slide-y-transition" min-width="240">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="primary white--text" v-bind="attrs" v-on="on">
              <v-icon>$vuetify.icons.add</v-icon>
            </v-btn>
          </template>

          <v-list flat dense>
            <v-list-item v-show="canAddCall">
              <v-list-item-title class="clickable" @click="addCall()">
                <v-row align="end">
                  <v-col cols="1">
                    <v-icon small left>
                      {{ ICON_TYPES[ITEM_TYPE_CALL] }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    {{ $t('Add a call') }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-show="canAddEvent">
              <v-list-item-title class="clickable" @click="addEvent">
                <v-row align="end">
                  <v-col cols="1">
                    <v-icon small left>
                      {{ ICON_TYPES[ITEM_TYPE_EVENT] }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    {{ $t('Add an event') }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-show="canAddTask">
              <v-list-item-title class="clickable" @click="addTask">
                <v-row align="end">
                  <v-col cols="1">
                    <v-icon small left>
                      {{ ICON_TYPES[ITEM_TYPE_TASK] }}
                    </v-icon>
                  </v-col>
                  <v-col>
                    {{ $t('Add a task') }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row v-if="!hideSearch" no-gutters align="center" justify="center">
      <v-col v-if="!beginAt && !endAt && !customer" class="shrink text-no-wrap">
        <cs-alert-panel :text="$t('Please select a customer and press button refresh to display the follow-ups.')" />
      </v-col>
    </v-row>

    <v-virtual-scroll :bench="1" :items="sortedData" :height="containerHeight" :item-height="ITEM_HEIGHT">
      <template v-slot:default="{ item }">
        <v-hover v-slot="{ hover }">
          <v-sheet :key="item.id" class="d-flex align-center" :class="{ focused: hover }" :height="ITEM_HEIGHT">
            <v-row v-if="item?.$type === ITEM_TYPE_CALL" align="center" no-gutters :class="{ clickable: canUpdateCall }" @click.stop="canUpdateCall ? updateItem(item) : null">
              <v-col cols="1" class="grow text-no-wrap">
                <div class="text-caption">
                  {{ getSortDate(item) }}
                </div>

                <v-chip small label :class="`${getColor(item)} color0--text clickable`">
                  {{ $t('follow-up-type-call-1') | uppercase }}
                </v-chip>
              </v-col>

              <v-col>
                <div class="d-flex align-center">
                  <sca-user-identity v-if="item.id_caller" :value="item.id_caller" class="mr-2" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.id_caller)" />

                  <v-icon small left right>
                    $vuetify.icons.for
                  </v-icon>

                  <sca-user-identity v-if="item.id_user" :value="item.id_user" class="pa-0 ma-0" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.id_user)" />
                  <span v-else>{{ $t('Unknown user') }}</span>
                </div>

                <div v-if="item.subject" class="text-h6">
                  {{ item.subject }}
                </div>
              </v-col>

              <v-col class="shrink text-no-wrap text-right text-caption">
                <div v-if="item.code" class="my-1 mr-1">
                  <sca-company-identity :value="item.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(item.code)" />
                </div>
              </v-col>

              <v-divider vertical class="ml-2 mr-4" />

              <v-col class="shrink text-no-wrap">
                <v-btn v-if="canUpdateCall" small icon rounded class="mr-2" @click.stop="updateItem(item)">
                  <v-icon small color="primary">
                    $vuetify.icons.edit
                  </v-icon>
                </v-btn>

                <v-btn v-if="canDeleteCall" small icon rounded class="mr-2" @click.stop="deleteItem(item)">
                  <v-icon small color="danger">
                    $vuetify.icons.delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else-if="item?.$type === ITEM_TYPE_EMAIL" align="center" no-gutters :class="{ clickable: canUpdateEmail }" @click.stop="canUpdateEmail ? updateItem(item) : null">
              <v-col cols="1" class="grow text-no-wrap">
                <div class="text-caption">
                  {{ getSortDate(item) }}
                </div>

                <v-chip small label :class="`${getColor(item)} color0--text clickable`">
                  {{ $t('follow-up-type-email-1') | uppercase }}
                </v-chip>
              </v-col>

              <v-col>
                <div class="d-flex">
                  <sca-user-identity v-if="item.from_email" :value="item.from_email" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.from_email)" />
                  <div v-if="item.id_user" class="d-flex align-center">
                    <v-icon right left small>
                      $vuetify.icons.for
                    </v-icon>
                    <sca-user-identity :value="item.id_user" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.id_user)" />
                  </div>
                </div>

                <div v-if="item.subject_email" class="text-h6">
                  {{ item.subject_email }}
                </div>
              </v-col>

              <v-col class="shrink text-no-wrap text-right text-caption">
                <div v-if="item.code" class="my-1 mr-1">
                  <sca-company-identity :value="item.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(item.code)" />
                </div>
              </v-col>

              <v-divider vertical class="ml-2 mr-4" />

              <v-col class="shrink text-no-wrap">
                <v-btn v-if="canUpdateEmail" small icon rounded class="mr-2" @click.stop="updateItem(item)">
                  <v-icon small color="primary">
                    $vuetify.icons.edit
                  </v-icon>
                </v-btn>

                <v-btn v-if="canDeleteEmail" small icon rounded class="mr-2" @click.stop="deleteItem(item)">
                  <v-icon small color="danger">
                    $vuetify.icons.delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else-if="item?.$type === ITEM_TYPE_EVENT" align="center" no-gutters :class="{ clickable: canUpdateEvent }" @click.stop="canUpdateEvent ? updateItem(item) : null">
              <v-col cols="1" class="grow text-no-wrap">
                <div class="text-caption">
                  {{ getSortDate(item) }}
                </div>

                <v-chip small label :class="`${getColor(item)} color0--text clickable`">
                  {{ $t('event-type-' + item.type) | uppercase }}
                </v-chip>
              </v-col>

              <v-col>
                <sca-user-identity v-if="item.id_organizer" :value="item.id_organizer" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.id_organizer)" />

                <div class="text-h6">
                  {{ item.subject }}
                </div>
              </v-col>

              <v-col class="shrink text-no-wrap text-right text-caption">
                <div v-if="item.code" class="my-1 mr-1">
                  <sca-company-identity :value="item.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(item.code)" />
                </div>
              </v-col>

              <v-divider vertical class="ml-2 mr-4" />

              <v-col class="shrink text-no-wrap">
                <v-btn v-if="canUpdateEvent" small icon rounded class="mr-2" @click.stop="updateItem(item)">
                  <v-icon small color="primary">
                    $vuetify.icons.edit
                  </v-icon>
                </v-btn>

                <v-btn v-if="canDeleteEvent" small icon rounded class="mr-2" @click.stop="deleteItem(item)">
                  <v-icon small color="danger">
                    $vuetify.icons.delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else-if="item?.$type === ITEM_TYPE_TASK" align="center" no-gutters :class="{ clickable: canUpdateTask }" @click.stop="canUpdateTask ? updateItem(item) : null">
              <v-col cols="1" class="grow text-no-wrap">
                <div class="text-caption">
                  {{ getSortDate(item) }}
                </div>

                <v-chip small label :class="`${getColor(item)} color0--text clickable`">
                  {{ $t('follow-up-type-task-1') | uppercase }}
                </v-chip>
              </v-col>

              <v-col>
                <div class="d-flex align-center">
                  <sca-user-identity v-if="item.id_owner" :value="item.id_owner" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(item.id_owner)" />

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" :class="getPriorityColor(item)" small v-on="on">
                        {{ getPriorityIcon(item) }}
                      </v-icon>
                    </template>
                    <span>{{ $t('Priority: {name}', { name: $t('task-priority-' + item.priority) }) }}</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-2" :class="getStatusColor(item)" small v-on="on">
                        {{ getStatusIcon(item) }}
                      </v-icon>
                    </template>
                    <span>{{ $t('Status: {name}', { name: $t('task-status-' + item.status) }) }}</span>
                  </v-tooltip>

                  <div v-if="getTaskIsNotDone(item)" class="text-caption ml-2" :class="getTaskExpired(item) ? 'danger--text' : ''">
                    {{ $t('Due date: {date}', { date: getDueDate(item) }) }}
                  </div>
                </div>

                <div class="text-wrap text-h6">
                  {{ item.subject }}
                </div>
              </v-col>

              <v-col class="shrink text-no-wrap text-right text-caption">
                <div v-if="item.code" class="my-1 mr-1">
                  <sca-company-identity :value="item.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(item.code)" />
                </div>
              </v-col>

              <v-divider vertical class="ml-2 mr-4" />

              <v-col class="shrink text-no-wrap">
                <v-btn v-if="canUpdateTask" small icon rounded class="mr-2" @click.stop="updateItem(value)">
                  <v-icon small color="primary">
                    $vuetify.icons.edit
                  </v-icon>
                </v-btn>

                <v-btn v-if="canDeleteTask" small icon rounded class="mr-2" @click.stop="deleteItem(value)">
                  <v-icon small color="danger">
                    $vuetify.icons.delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <cs-alert-panel v-else type="error" text="Unknown followup type" />
          </v-sheet>
        </v-hover>
      </template>
    </v-virtual-scroll>

    <cs-confirm-dialog ref="confirm-event-delete" />
    <!-- FOLLOWUPS DIALOGS -->
    <csm-followup-call-dialog-lite ref="followup-call-dialog-lite" @close="onFollowupDialogClose" @close-reopen="onFollowupDialogCloseReopen($event, ITEM_TYPE_CALL)" />
    <csm-followup-email-dialog-lite ref="followup-email-dialog-lite" @close="onFollowupDialogClose" @close-reopen="onFollowupDialogCloseReopen($event, ITEM_TYPE_EMAIL)" />
    <csm-followup-event-dialog-lite ref="followup-event-dialog-lite" @close="onFollowupDialogClose" @close-reopen="onFollowupDialogCloseReopen($event, ITEM_TYPE_EVENT)" />
    <csm-followup-task-dialog-lite ref="followup-task-dialog-lite" @close="onFollowupDialogClose" @close-reopen="onFollowupDialogCloseReopen($event, ITEM_TYPE_TASK)" />

    <sca-pdf-viewer-dialog ref="pdf-document-file-viewer" />
    <csm-company-dialog ref="company-dialog" />
  </div>
</template>

<script>
import _ from 'lodash'
import getClassNameForExtension from 'font-awesome-filetypes'

export default {
  name: 'FollowUpList',
  components: {
    'csm-followup-call-dialog-lite': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpCallDialogLite'),
    'csm-followup-email-dialog-lite': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEmailDialogLite'),
    'csm-followup-event-dialog-lite': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpEventDialogLite'),
    'csm-followup-task-dialog-lite': () => import(/* webpackChunkName: "components" */ '@/components/FollowUps/FollowUpTaskDialogLite')
  },
  props: {
    allowAdd: { type: Boolean, default: false },
    beginAt: { type: [String, Date], default: '' },
    endAt: { type: [String, Date], default: '' },
    hideSearch: { type: Boolean, default: false },
    noDataRequest: { type: Boolean, default: false },
    rawDataCalls: { type: [Array], default: () => [] },
    rawDataEmails: { type: [Array], default: () => [] },
    rawDataEvents: { type: [Array], default: () => [] },
    rawDataTasks: { type: [Array], default: () => [] },
    salesPersons: { type: [Array, String], default: () => [] }
  },
  data () {
    return {
      ITEM_HEIGHT: 70,
      addButtonVisible: false,
      attachmentsMeta: [],
      call: {},
      container: null,
      containerHeight: 0,
      customer: null,
      filter: [],
      footerProps: { 'items-per-page-options': [10, 25, 50, -1] },
      followUp: {},
      loading: false,
      loadingFilters: false,
      loadingAttachments: false,
      loadingPdf: false,
      onResizeHandler: null,
      options: {
        page: 1,
        itemsPerPage: 25
      },
      page: 1,
      search: '',
      sortedData: [],
      tab: 'tab-content',
      uuid: this.$stratus.uuid()
    }
  },
  watch: {
    rawDataCalls: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && !_.isEqual(newValue, oldValue)) this.loadFollowUp()
      }
    },
    rawDataEmails: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && !_.isEqual(newValue, oldValue)) this.loadFollowUp()
      }
    },
    rawDataEvents: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && !_.isEqual(newValue, oldValue)) this.loadFollowUp()
      }
    },
    rawDataTasks: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && !_.isEqual(newValue, oldValue)) this.loadFollowUp()
      }
    },
    salesPersons: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue && !_.isEqual(newValue, oldValue)) this.loadFollowUp()
      }
    }
  },
  computed: {
    canAddCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.CREATE)
    },
    canAddEvent () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.CREATE)
    },
    canAddTask () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.CREATE)
    },
    canDeleteCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDeleteEmail () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDeleteEvent () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.DELETE)
    },
    canDeleteTask () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.DELETE)
    },
    canListCalls () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.LIST)
    },
    canListEmails () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.LIST)
    },
    canListEvents () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.LIST)
    },
    canListTasks () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.LIST)
    },
    canUpdateCall () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_CALL, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdateEmail () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EMAIL, this.$alto.API_PERMISSIONS.UPDATE
      )
    },
    canUpdateEvent () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_EVENT, this.$alto.API_PERMISSIONS.UPDATE)
    },
    canUpdateTask () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.FOLLOWUP_TASK, this.$alto.API_PERMISSIONS.UPDATE)
    },
    ICON_TYPES () { return this.$store.getters['followups/ICON_TYPES'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    ITEM_TYPES () { return this.$store.getters['followups/ITEM_TYPES'] },
    ITEM_COLORS () { return this.$store.getters['followups/ITEM_COLORS'] },
    ITEM_TYPE_CALL () { return this.$store.getters['followups/ITEM_TYPE_CALL'] },
    ITEM_TYPE_EMAIL () { return this.$store.getters['followups/ITEM_TYPE_EMAIL'] },
    ITEM_TYPE_EVENT () { return this.$store.getters['followups/ITEM_TYPE_EVENT'] },
    ITEM_TYPE_TASK () { return this.$store.getters['followups/ITEM_TYPE_TASK'] },
    sortedMeta () {
      return _.sortBy(this.attachmentsMeta, ['name'])
    }
  },
  methods: {
    addCall (data) {
      if (this.canAddCall && this.$refs['followup-call-dialog-lite']) {
        this.$refs['followup-call-dialog-lite'].open(data)
      }
    },
    addEvent (data) {
      if (this.canAddEvent && this.$refs['followup-event-dialog-lite']) {
        this.$refs['followup-event-dialog-lite'].open(data)
      }
    },
    addTask (data) {
      if (this.canAddTask && this.$refs['followup-task-dialog-lite']) {
        this.$refs['followup-task-dialog-lite'].open(data)
      }
    },
    deleteItem (item) {
      if (!item) return

      this.$refs['confirm-event-delete']
        .open(
          this.$t('Delete'),
          this.$t('Do you want to delete {subject}?', { subject: item.subject_email }),
          { color: 'danger' }
        )
        .then(async (confirm) => {
          if (confirm) {
            try {
              if (item?.$type === this.ITEM_TYPE_EMAIL) await this.$store.dispatch('followups/deleteEmail', item.id)
              else if (item?.$type === this.ITEM_TYPE_EVENT) await this.$store.dispatch('followups/deleteEvent', item.id)
              else if (item?.$type === this.ITEM_TYPE_CALL) await this.$store.dispatch('followups/deleteCall', item.id)
              else if (item?.$type === this.ITEM_TYPE_TASK) await this.$store.dispatch('followups/deleteTask', item.id)
              this.onFollowupDialogClose(true)
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }
          }
        })
    },
    isFilterActive (index) {
      return this.filter.length === 0 || this.filter.includes(index)
    },
    getColor (item) { return this.$store.getters['followups/ITEM_COLOR'](item.$type) },
    loadFollowUp: _.debounce(async function () {
      this.loading = true
      this.followUp = {}
      this.followUps = []
      if (this.salesPersons.length === 0) return

      try {
        this.$emit('loading', true)
        let salesPersons
        const sales = Array.isArray(this.salesPersons) ? this.salesPersons : [this.salesPersons]
        if (!sales.includes('ALL')) salesPersons = sales

        // get Email for each sales
        const salesEmail = this.$store.getters['$alto-users/salesPersons']
        const salesPersonEmails = []
        _.forEach(salesPersons, s => {
          const email = _.find(salesEmail, { value: s })?.email
          if (email) salesPersonEmails.push(email)
        })

        if (this.rawDataCalls?.length) {
          // Call events are given
          this.followUp[this.ITEM_TYPE_CALL] = _.map(this.rawDataCalls, item => {
            return { ...item, $type: this.ITEM_TYPE_CALL }
          })
          this.followUps.concat(this.followUp[this.ITEM_TYPE_CALL])
        }

        if (this.rawDataEmails?.length) {
          // Emails are given
          this.followUp[this.ITEM_TYPE_EMAIL] = _.map(this.rawDataEmails, item => {
            return { ...item, $type: this.ITEM_TYPE_EMAIL }
          })
          this.followUps.concat(this.followUp[this.ITEM_TYPE_EMAIL])
        }

        if (this.rawDataEvents?.length) {
          // Generic events are given
          this.followUp[this.ITEM_TYPE_EVENT] = _.map(this.rawDataEvents, item => {
            return { ...item, $type: this.ITEM_TYPE_EVENT }
          })
          this.followUps.concat(this.followUp[this.ITEM_TYPE_EVENT])
        }

        if (this.rawDataTasks?.length) {
          // Tasks are given
          this.followUp[this.ITEM_TYPE_TASK] = _.map(this.rawDataTasks, item => {
            return { ...item, $type: this.ITEM_TYPE_TASK }
          })
          this.followUps.concat(this.followUp[this.ITEM_TYPE_TASK])
        }

        if (!this.noDataRequest) {
          if (!this.beginAt && !this.endAt && !this.customer) {
            this.$stratus.services.notify.warning(this.$t('Please select a customer and press button refresh to display the follow-ups.'))
            return
          }
          // We load data ourself
          if (this.canListCalls) {
            await this.$store.dispatch('followups/calls', { salesPersons, beginAt: this.beginAt, endAt: this.endAt })
            this.followUp[this.ITEM_TYPE_CALL] = this.$store.getters['followups/calls']
            this.followUps.concat(this.followUp[this.ITEM_TYPE_CALL])
          } else this.followUp[this.ITEM_TYPE_CALL] = []

          if (this.canListEmails) {
            await this.$store.dispatch('followups/emails', { fromEmails: salesPersonEmails, beginAt: this.beginAt, endAt: this.endAt, status: 'sorted' })
            this.followUp[this.ITEM_TYPE_EMAIL] = this.$store.getters['followups/emails']
            this.followUps.concat(this.followUp[this.ITEM_TYPE_EMAIL])
          } else this.followUp[this.ITEM_TYPE_EMAIL] = []

          if (this.canListEvents) {
            await this.$store.dispatch('followups/events', { salesPersons, beginAt: this.beginAt, endAt: this.endAt })
            this.followUp[this.ITEM_TYPE_EVENT] = this.$store.getters['followups/events']
            this.followUps.concat(this.followUp[this.ITEM_TYPE_EVENT])
          } else this.followUp[this.ITEM_TYPE_EVENT] = []

          if (this.canListTasks) {
            await this.$store.dispatch('followups/tasks', { salesPersons, beginAt: this.beginAt, endAt: this.endAt })
            this.followUp[this.ITEM_TYPE_TASK] = this.$store.getters['followups/tasks']
            this.followUps.concat(this.followUp[this.ITEM_TYPE_TASK])
          } else this.followUp[this.ITEM_TYPE_TASK] = []
        }

        this.resetFilter()
        this.sortData()
        this.setHeight()
        this.$emit('loaded', this.sortedData.length)
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 1000)
        this.$emit('loading', false)
      }
    }, 1000),
    getDueDate (item) {
      return item.date_due ? this.$stratus.dt(item.date_due).format('l') : this.$t('Undefined')
    },
    getPriorityColor (item) {
      if (item.priority === 'low') return 'lime--text'
      if (item.priority === 'medium') return ''
      return 'danger--text'
    },
    getPriorityIcon (item) {
      if (item.priority === 'low') return '$vuetify.icons.priority_low'
      if (item.priority === 'medium') return '$vuetify.icons.priority_medium'
      return '$vuetify.icons.priority_high'
    },
    getSortDate (item) {
      if (!item.sortDate) return this.$t('Undefined')

      if (this.$stratus.dt(item.sortDate).isAfter(this.$stratus.dt().startOf('year'))) return this.$stratus.dt(item.sortDate).format('DD MMM HH:mm')
      return this.$stratus.dt(item.sortDate).format('L HH:mm')
    },
    getStatusColor (item) {
      if (item.status === 'to_do') return ''
      if (item.status === 'doing') return 'blue--text'
      return 'green--text'
    },
    getStatusIcon (item) {
      if (item.status === 'to_do') return 'icon-task-to-do'
      if (item.status === 'doing') return 'icon-task-in-progress'
      return 'icon-task-completed'
    },
    getTaskExpired (item) {
      return item.date_due && this.$stratus.dt().isAfter(item.date_due)
    },
    getTaskIsNotDone (item) {
      return item.status !== 'done'
    },
    loadFollowUpAttachmentsMeta (attachments) {
      if (!this.salesPersons.length || !attachments || attachments.length === 0) return

      this.loadingAttachments = true
      try {
        const metaList = []
        _.forEach(attachments, async attachment => {
          const meta = await this.$store.dispatch('documents/getFileMeta', attachment.path)
          meta.name = attachment.name
          meta.path = attachment.path
          meta.icon = getClassNameForExtension(attachment.path.split('.').pop())
          meta.url = this.$stratus.services.api.path(`/documents/${attachment.path}?format=raw`)
          metaList.push(meta)
        })
        this.attachmentsMeta = metaList
      } catch (error) {
        console.error(error)
        this.$stratus.services.notify.error(error)
      }
      this.loadingAttachments = false
    },
    onFollowupDialogClose (saved) {
      if (saved) {
        // Reload data
        this.loadFollowUp()
      }
    },
    async onFollowupDialogCloseReopen (saved, dialog) {
      if (saved) {
        // Reload data
        await this.loadFollowUp()
        this.$nextTick(() => {
          if (dialog === this.ITEM_TYPE_CALL) this.addCall({ anotherOne: true })
          if (dialog === this.ITEM_TYPE_EVENT) this.addEvent({ anotherOne: true })
          if (dialog === this.ITEM_TYPE_TASK) this.addTask({ anotherOne: true })
        })
      }
    },
    onSearch (value) {
      this.search = value || ''
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openOpportunity (id) {
      if (this.$refs['opportunity-dialog']) this.$refs['opportunity-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh () {
      this.loadFollowUp()
    },
    resetFilter () {
      const filtered = [] // Use a local var to avoir multiple vue DOM update if we were using this.filter.
      this.ITEM_TYPES.forEach((itemType, index) => {
        filtered.push(index)
      })
      this.filter = filtered
    },
    resetFollowup () {
      this.followUp = {}
      this.ITEM_TYPES.forEach(itemType => {
        this.followUp[itemType] = []
      })
    },
    sanitizeBody (content) {
      if (!content) return ''
      // Sanitize and replace \n by html tag BR
      // Windows use \r\n, Linux/Unix use \n and MacOS use \r
      return this.$stratus.services.strings.stripHtmlTags(content).replace(/\r\n/g, '<br>').replace(/\r/g, '<br>').replace(/\n/g, '<br>')
    },
    setHeight () {
      if (!isNaN(window.innerHeight)) {
        this.containerHeight = window.innerHeight - 245
      }
    },
    sortData () {
      let list = []

      // Filter
      this.ITEM_TYPES.forEach((itemType, index) => {
        if (!this.filter.length || this.filter.includes(index)) list = list.concat(this.followUp[itemType])
      })

      // Filter on selected customer
      if (this.customer) list = _.filter(list, item => { return item && item.code === this.customer })

      // Search
      const s = this.search.toLowerCase()
      const result = this.search.length === 0
        ? list
        : _.filter(list, item => {
          // Yes, sadly it's a big, big filter
          return (String(item?.opportunity_ids || []).includes(s) ||
            (item?.code || '').toLowerCase().includes(s) ||
            (item?.caller || '').toLowerCase().includes(s) ||
            (item?.owner || '').toLowerCase().includes(s) ||
            (item?.organizer || '').toLowerCase().includes(s) ||
            (item?.number_phone_user || '').toLowerCase().includes(s) ||
            (item?.details_call || '').toLowerCase().includes(s) ||
            (item?.email_user || '').toLowerCase().includes(s) ||
            (item?.subject_email || '').toLowerCase().includes(s) ||
            (item?.body_email || '').toLowerCase().includes(s) ||
            (item?.from_email || '').toLowerCase().includes(s) ||
            (item?.to_emails || []).join(' ').toLowerCase().includes(s) ||
            (item?.cc_emails || []).join(' ').toLowerCase().includes(s))
        })

      // Sort
      this.sortedData = _.orderBy(_.compact(result), ['sortDate'], ['desc'])
    },
    toggleFilter (index) {
      this.loadingFilters = true
      // If all is already selected : select only the given one
      if (this.filter.length === this.ITEM_TYPES.length) this.filter = [index]
      // If the given one is the only selected : select all filters
      else if (this.filter.includes(index) && this.filter.length === 1) this.resetFilter()
      // If the given one is already selected : remove it
      else if (this.filter.includes(index)) this.filter = _.remove(this.filter, item => item !== index) // keep all other
      // Add the given filter
      else this.filter.push(index)

      setTimeout(() => {
        this.sortData()
        this.loadingFilters = false
      }, 250)
    },
    updateItem (item) {
      if (item.$type === this.ITEM_TYPE_EMAIL && this.canUpdateEmail && this.$refs['followup-email-dialog-lite']) {
        this.$refs['followup-email-dialog-lite'].open(item)
      } else if (item.$type === this.ITEM_TYPE_EVENT && this.canUpdateEvent && this.$refs['followup-event-dialog-lite']) {
        this.$refs['followup-event-dialog-lite'].open(item)
      } else if (item.$type === this.ITEM_TYPE_CALL && this.canUpdateCall && this.$refs['followup-call-dialog-lite']) {
        this.$refs['followup-call-dialog-lite'].open(item)
      } else if (item.$type === this.ITEM_TYPE_TASK && this.canUpdateTask && this.$refs['followup-task-dialog-lite']) {
        this.$refs['followup-task-dialog-lite'].open(item)
      }
    }
  },
  async created () {
    try {
      await this.$store.dispatch('newsletters/list')
      await this.$store.dispatch('$alto-catalog/list')
      await this.$store.dispatch('$alto-companies/list', 'code,name,type,id_lord_salesperson')
      await this.$store.dispatch('$alto-users/loadSalesPersons')
      await this.$store.dispatch('$alto-users/loadTypes')
      await this.$store.dispatch('$alto-companies/loadPaymentTerms')
      await this.$store.dispatch('$alto-companies/loadPaymentMethods')
    } catch (error) {
      console.error(error)
    } finally {
      this.resetFollowup()
      this.resetFilter()
    }
  },
  beforeDestroy () {
    if (this.onResizeHandler) window.removeEventListener('resize', this.onResizeHandler)
  },
  mounted () {
    if (this.onResizeHandler) window.removeEventListener('resize', this.onResizeHandler)

    this.$nextTick(() => {
      this.setHeight()
      // Hook an event handler to capture window resizing and redraw canvas
      this.onResizeHandler = window.addEventListener('resize', () => {
        this.setHeight()
      })
      setTimeout(() => { this.setHeight() }, 500)
    })
  }
}
</script>
