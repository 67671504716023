import _ from 'lodash'

const ITEM_TYPE_CALL = 'call'
const ITEM_TYPE_CALL_IN = 'in'
const ITEM_TYPE_CALL_OUT = 'out'
const ITEM_TYPE_EMAIL = 'email'
const ITEM_TYPE_EVENT = 'event'
const ITEM_TYPE_TASK = 'task'

const ITEM_TYPES = [ITEM_TYPE_CALL, ITEM_TYPE_EMAIL, ITEM_TYPE_EVENT, ITEM_TYPE_TASK]

const ITEM_COLORS = {
  [ITEM_TYPE_CALL]: 'yellow lighten-1',
  [ITEM_TYPE_EMAIL]: 'blue lighten-4',
  [ITEM_TYPE_EVENT]: 'pink lighten-4',
  [ITEM_TYPE_TASK]: 'lime'
}

const ICON_TYPES = {
  [ITEM_TYPE_CALL]: 'icon-phone',
  [ITEM_TYPE_EMAIL]: 'icon-email',
  [ITEM_TYPE_EVENT]: 'icon-event',
  [ITEM_TYPE_TASK]: 'icon-tasks'
}

const ICON_IN_OUT = {
  [ITEM_TYPE_CALL_IN]: 'icon-incoming-call',
  [ITEM_TYPE_CALL_OUT]: 'icon-outgoing-call'
}

export default {
  namespaced: true,
  state: {
    ITEM_TYPE_CALL,
    ITEM_TYPE_CALL_IN,
    ITEM_TYPE_CALL_OUT,
    ITEM_TYPE_EMAIL,
    ITEM_TYPE_EVENT,
    ITEM_TYPE_TASK,
    ITEM_TYPES,
    ITEM_COLORS,
    ICON_TYPES,
    ICON_IN_OUT,
    calls: [],
    emails: [],
    events: [],
    tasks: []
  },
  getters: {
    ITEM_TYPE_CALL: state => { return state.ITEM_TYPE_CALL },
    ITEM_TYPE_CALL_IN: state => { return state.ITEM_TYPE_CALL_IN },
    ITEM_TYPE_CALL_OUT: state => { return state.ITEM_TYPE_CALL_OUT },
    ITEM_TYPE_EMAIL: state => { return state.ITEM_TYPE_EMAIL },
    ITEM_TYPE_EVENT: state => { return state.ITEM_TYPE_EVENT },
    ITEM_TYPE_TASK: state => { return state.ITEM_TYPE_TASK },
    ITEM_TYPES: state => { return state.ITEM_TYPES },
    ITEM_COLOR: state => itemType => { return state.ITEM_COLORS[itemType] },
    ITEM_COLORS: state => { return state.ITEM_COLORS },
    ICON_TYPE: state => itemType => { return state.ICON_TYPES[itemType] || '' },
    ICON_TYPES: state => { return state.ICON_TYPES },
    ICON_IN_OUT: state => callType => { return state.ICON_IN_OUT[callType] || '' },
    mixed: state => {
      const data = state.calls
        .concat(state.emails)
        .concat(state.events)
        .concat(state.tasks)
      return _.orderBy(data, ['sortDate'], ['desc'])
    },
    calls: state => { return _.orderBy(state.calls, ['sortDate'], ['desc']) || [] },
    emails: state => { return _.orderBy(state.emails, ['sortDate'], ['desc']) || [] },
    events: state => { return _.orderBy(state.events, ['sortDate'], ['desc']) || [] },
    tasks: state => { return _.orderBy(state.tasks, ['sortDate'], ['desc']) || [] }
  },
  actions: {
    businessMealsByMonth ({ commit, rootState }, { company, salesPersons, beginAt, endAt, includeWeekend } = {}) {
      const query = ['type[eq]=lunch']
      if (company) {
        query.push(`code[in]=${Array.isArray(company) ? company.join(',') : company}`)
      }
      if (salesPersons) {
        const salesPersonsList = _.isArray(salesPersons) ? salesPersons.join(',') : salesPersons
        query.push(`id_organizer[in]=${salesPersonsList}`)
      }
      if (beginAt) {
        query.push(`date_begin[gte]=${rootState.$stratus.dt(beginAt).format('YYYY-MM-DD')}`)
      }
      if (endAt) {
        query.push(`date_begin[lte]=${rootState.$stratus.dt(endAt).format('YYYY-MM-DD')}`)
      }
      return rootState.$stratus.services.api.get('/followups/events?fields=id,date_begin,code,id_organizer&limit=0' + (query.length ? `&query=${query.join('!!')}` : ''))
        .then(response => {
          const result = {
            byMonth: {},
            bySalesPerson: {},
            ids: {
              byMonth: {}
            },
            empty: true,
            total: 0
          }

          _.forEach(response.results, event => {
            const d = rootState.$stratus.dt(event.date_begin)
            // Day 0 is sunday and day 6 is saturday
            if (includeWeekend || ![0, 6].includes(d.day())) {
              const key = d.format('YYYY-MM')

              result.total++
              result.empty = false

              // Keep event data
              if (result.ids.byMonth[key]) result.ids.byMonth[key].push(event.id)
              else result.ids.byMonth[key] = [event.id]

              // Count calls by days
              if (result.byMonth[key]) result.byMonth[key]++
              else result.byMonth[key] = 1

              // Count calls by sales person then by day
              if (result.bySalesPerson[event.id_organizer]) {
                if (result.bySalesPerson[event.id_organizer][key]) {
                  result.bySalesPerson[event.id_organizer][key]++
                } else {
                  result.bySalesPerson[event.id_organizer][key] = 1
                }
              } else {
                result.bySalesPerson[event.id_organizer] = { [key]: 1 }
              }
              if (result.bySalesPerson[event.id_organizer].total) {
                result.bySalesPerson[event.id_organizer].total++
              } else {
                result.bySalesPerson[event.id_organizer].total = 1
              }
            }
          })
          return result
        })
        .catch(error => commit('API_FAILURE', error))
    },
    getCallsByIds ({ commit, rootState }, ids) {
      if (!ids || !ids?.length) return

      const idsArray = _.isArray(ids) ? ids : [ids]

      return rootState.$stratus.services.api.get(`/followups/calls?limit=0&query=id[in]=${idsArray.join(',')}`)
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    calls ({ commit, rootState }, { company, salesPersons, beginAt, endAt } = {}) {
      const query = []
      if (company) {
        query.push(`code[in]=${Array.isArray(company) ? company.join(',') : company}`)
      }
      if (salesPersons) {
        query.push(`id_caller[in]=${salesPersons.join(',')}`)
      }
      if (beginAt) {
        query.push(`date_call[gte]=${rootState.$stratus.dt(beginAt).format('YYYY-MM-DD')}`)
      }
      if (endAt) {
        query.push(`date_call[lte]=${rootState.$stratus.dt(endAt).format('YYYY-MM-DD')}`)
      }
      return rootState.$stratus.services.api.get('/followups/calls?limit=0' + (query.length ? `&query=${query.join('!!')}` : ''))
        .then(response => {
          commit('setCalls', response.results)
        })
        .catch(error => commit('API_FAILURE', error))
    },
    callsByWeek ({ commit, rootState }, { company, salesPersons, beginAt, endAt, includeWeekend } = {}) {
      const query = []
      if (company) {
        query.push(`code[in]=${Array.isArray(company) ? company.join(',') : company}`)
      }
      if (salesPersons) {
        const salesPersonsList = _.isArray(salesPersons) ? salesPersons.join(',') : salesPersons
        query.push(`id_caller[in]=${salesPersonsList}`)
      }
      if (beginAt) {
        query.push(`date_call[gte]=${rootState.$stratus.dt(beginAt).format('YYYY-MM-DD')}`)
      }
      if (endAt) {
        query.push(`date_call[lte]=${rootState.$stratus.dt(endAt).format('YYYY-MM-DD')}`)
      }

      return rootState.$stratus.services.api.get('/followups/calls?fields=id,date_call,code,id_caller&limit=0' + (query.length ? `&query=${query.join('!!')}` : ''))
        .then(response => {
          const result = {
            byWeek: {},
            bySalesPerson: {},
            ids: {
              byWeek: {}
            },
            empty: true,
            total: 0
          }

          _.forEach(response.results, call => {
            const d = rootState.$stratus.dt(call.date_call)
            // Day 0 is sunday and day 6 is saturday
            if (includeWeekend || ![0, 6].includes(d.day())) {
              const key = d.format('YYYY') + '-' + d.week() // Number of the week in the year

              result.total++
              result.empty = false

              // Keep call data
              if (result.ids.byWeek[key]) result.ids.byWeek[key].push(call.id)
              else result.ids.byWeek[key] = [call.id]

              // Count calls by days
              if (result.byWeek[key]) result.byWeek[key]++
              else result.byWeek[key] = 1

              // Count calls by sales person then by day
              if (result.bySalesPerson[call.id_caller]) {
                if (result.bySalesPerson[call.id_caller][key]) {
                  result.bySalesPerson[call.id_caller][key]++
                } else {
                  result.bySalesPerson[call.id_caller][key] = 1
                }
              } else {
                result.bySalesPerson[call.id_caller] = { [key]: 1 }
              }
              if (result.bySalesPerson[call.id_caller].total) {
                result.bySalesPerson[call.id_caller].total++
              } else {
                result.bySalesPerson[call.id_caller].total = 1
              }
            }
          })
          return result
        })
        .catch(error => commit('API_FAILURE', error))
    },
    deleteCall ({ commit, rootState }, id) {
      return rootState.$stratus.services.api.delete('/followups/calls/' + id)
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    saveCall ({ commit, rootState }, data) {
      const promise = data.id ? rootState.$stratus.services.api.patch('/followups/calls/' + data.id, data) : rootState.$stratus.services.api.post('/followups/calls', data)
      return promise
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    emails ({ commit, rootState }, { company, fromEmails, beginAt, endAt, status } = {}) {
      const query = []
      if (company) {
        query.push(`code[in]=${Array.isArray(company) ? company.join(',') : company}`)
      }
      if (Array.isArray(fromEmails) && fromEmails?.length > 0) {
        query.push(`from_email[in]=${fromEmails.join(',')}`)
      }
      if (beginAt) {
        query.push(`date_send[gte]=${rootState.$stratus.dt(beginAt).format('YYYY-MM-DD')}`)
      }
      if (endAt) {
        query.push(`date_send[lte]=${rootState.$stratus.dt(endAt).format('YYYY-MM-DD')}`)
      }
      if (status) {
        query.push(`state[eq]=${status}`)
      }
      return rootState.$stratus.services.api.get('/followups/emails?limit=0' + (query.length ? `&query=${query.join('!!')}` : ''))
        .then(response => {
          commit('setEmails', response.results)
        })
        .catch(error => commit('API_FAILURE', error))
    },
    deleteEmail ({ commit, rootState }, id) {
      return rootState.$stratus.services.api.delete('/followups/emails/' + id)
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    saveEmail ({ commit, rootState }, data) {
      return rootState.$stratus.services.api.patch(`/followups/emails/${data.id}`, {
        code: data.code,
        email_user: data.email_user,
        id_user: data.id_user,
        opportunity_ids: data.opportunity_ids
      })
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    eventProspectByWeek ({ commit, rootState }, { salesPersons, beginAt, endAt, includeWeekend } = {}) {
      const query = []
      if (salesPersons) {
        const salesPersonsList = _.isArray(salesPersons) ? salesPersons.join(',') : salesPersons
        query.push(`id_organizer[in]=${salesPersonsList}`)
      }
      if (beginAt) {
        query.push(`date_begin[gte]=${rootState.$stratus.dt(beginAt).format('YYYY-MM-DD')}`)
      }
      if (endAt) {
        query.push(`date_begin[lte]=${rootState.$stratus.dt(endAt).format('YYYY-MM-DD')}`)
      }
      return rootState.$stratus.services.api.get('/followups/events?fields=id,date_begin,code,id_organizer&limit=0&companies-type=prospect' + (query.length ? `&query=${query.join('!!')}` : ''))
        .then(response => {
          const result = {
            byWeek: {},
            bySalesPerson: {},
            ids: {
              byWeek: {}
            },
            empty: true,
            total: 0
          }

          _.forEach(response.results, event => {
            const d = rootState.$stratus.dt(event.date_begin)
            // Day 0 is sunday and day 6 is saturday
            if (includeWeekend || ![0, 6].includes(d.day())) {
              const key = d.format('YYYY') + '-' + d.week() // Number of the week in the year

              result.total++
              result.empty = false

              // Keep event data
              if (result.ids.byWeek[key]) result.ids.byWeek[key].push(event.id)
              else result.ids.byWeek[key] = [event.id]

              // Count calls by days
              if (result.byWeek[key]) result.byWeek[key]++
              else result.byWeek[key] = 1

              // Count calls by sales person then by day
              if (result.bySalesPerson[event.id_organizer]) {
                if (result.bySalesPerson[event.id_organizer][key]) {
                  result.bySalesPerson[event.id_organizer][key]++
                } else {
                  result.bySalesPerson[event.id_organizer][key] = 1
                }
              } else {
                result.bySalesPerson[event.id_organizer] = { [key]: 1 }
              }
              if (result.bySalesPerson[event.id_organizer].total) {
                result.bySalesPerson[event.id_organizer].total++
              } else {
                result.bySalesPerson[event.id_organizer].total = 1
              }
            }
          })
          return result
        })
        .catch(error => commit('API_FAILURE', error))
    },
    getEventsByIds ({ commit, rootState }, ids) {
      if (!ids || !ids?.length) return

      const idsArray = _.isArray(ids) ? ids : [ids]

      return rootState.$stratus.services.api.get(`/followups/events?limit=0&query=id[in]=${idsArray.join(',')}`)
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    events ({ commit, rootState }, { company, salesPersons, beginAt, endAt } = {}) {
      const query = []
      if (company) {
        query.push(`code[in]=${Array.isArray(company) ? company.join(',') : company}`)
      }
      if (salesPersons) {
        query.push(`id_organizer[in]=${salesPersons.join(',')}`)
      }
      if (beginAt) {
        query.push(`date_begin[gte]=${rootState.$stratus.dt(beginAt).format('YYYY-MM-DD')}`)
      }
      if (endAt) {
        query.push(`date_begin[lte]=${rootState.$stratus.dt(endAt).format('YYYY-MM-DD')}`)
      }
      return rootState.$stratus.services.api.get('/followups/events?limit=0' + (query.length ? `&query=${query.join('!!')}` : ''))
        .then(response => {
          commit('setEvents', response.results)
        })
        .catch(error => commit('API_FAILURE', error))
    },
    eventAttachmentAdd ({ commit, state, rootState }, { id, files }) {
      return rootState.$stratus.services.api.sendFilesPatch(`/followups/events/${id}`, files, 'attachments')
        .then(response => {
          return response.body
        })
        .catch(error => commit('API_FAILURE', error))
    },
    eventAttachmentDel ({ commit, state, rootState }, { id, filename }) {
      return rootState.$stratus.services.api.delete(`/followups/events/${id}/attachments/${filename}`)
        .then(response => {
          return response.body
        })
        .catch(error => commit('API_FAILURE', error))
    },
    deleteEvent ({ commit, rootState }, id) {
      return rootState.$stratus.services.api.delete('/followups/events/' + id)
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    saveEvent ({ commit, rootState }, data) {
      const promise = data.id ? rootState.$stratus.services.api.patch('/followups/events/' + data.id, data) : rootState.$stratus.services.api.post('/followups/events', data)
      return promise
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    tasks ({ commit, rootState }, { company, salesPersons, beginAt, endAt } = {}) {
      const query = []
      if (company) {
        query.push(`code[in]=${Array.isArray(company) ? company.join(',') : company}`)
      }
      if (salesPersons) {
        query.push(`id_owner[in]=${salesPersons.join(',')}`)
      }
      if (beginAt) {
        query.push(`date_due[gte]=${rootState.$stratus.dt(beginAt).format('YYYY-MM-DD')}`)
      }
      if (endAt) {
        query.push(`date_due[lte]=${rootState.$stratus.dt(endAt).format('YYYY-MM-DD')}`)
      }
      return rootState.$stratus.services.api.get('/followups/tasks?limit=0' + (query.length ? `&query=${query.join('!!')}` : ''))
        .then(response => {
          commit('setTasks', response.results)
        })
        .catch(error => commit('API_FAILURE', error))
    },
    taskAttachmentAdd ({ commit, state, rootState }, { id, files }) {
      return rootState.$stratus.services.api.sendFilesPatch(`/followups/tasks/${id}`, files, 'attachments')
        .then(response => {
          return response.body
        })
        .catch(error => commit('API_FAILURE', error))
    },
    taskAttachmentDel ({ commit, state, rootState }, { id, filename }) {
      return rootState.$stratus.services.api.delete(`/followups/tasks/${id}/attachments/${filename}`)
        .then(response => {
          return response.body
        })
        .catch(error => commit('API_FAILURE', error))
    },
    deleteTask ({ commit, rootState }, id) {
      return rootState.$stratus.services.api.delete('/followups/tasks/' + id)
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    },
    saveTask ({ commit, rootState }, data) {
      const promise = data.id ? rootState.$stratus.services.api.patch('/followups/tasks/' + data.id, data) : rootState.$stratus.services.api.post('/followups/tasks', data)
      return promise
        .then(response => {
          return response
        })
        .catch(error => commit('API_FAILURE', error))
    }
  },
  mutations: {
    API_FAILURE: (state, error) => {
      /* if (error.status === 401) {
        console.warn('[API] status 401', error)
      } else */ throw error
    },
    setCalls (state, data) {
      state.calls = _.map(data, item => {
        return { ...item, $type: state.ITEM_TYPE_CALL, sortDate: item.date_call }
      })
    },
    setEmails (state, data) {
      state.emails = _.map(data, item => {
        return { ...item, $type: state.ITEM_TYPE_EMAIL, sortDate: item.date_send }
      })
    },
    setEvents (state, data) {
      state.events = _.map(data, item => {
        return { ...item, $type: state.ITEM_TYPE_EVENT, sortDate: item.date_begin }
      })
    },
    setTasks (state, data) {
      state.tasks = _.map(data, item => {
        return { ...item, $type: state.ITEM_TYPE_TASK, sortDate: item.date_due }
      })
    }
  }
}
