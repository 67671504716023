<template>
  <v-row>
    <v-col cols="12">
      <v-tabs hide-slider v-model="tab">
        <v-tab href="#tab-tree">
          {{ $t('Tree') }}
          <cs-icon-loading small right v-if="loading || loadingCache" />
        </v-tab>
        <v-tab href="#tab-list">
          {{ $t('List') }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="pa-3">
        <v-tab-item value="tab-tree" eager>
          <v-row class="px-2" align="baseline">
            <v-col cols="4">
              <cs-search-input :label="$t('Search for customers')" @search="onSearchCompany" :disabled="loading || loadingCache" />
            </v-col>

            <v-col cols="12">
              <v-treeview :items="contractTree" :load-children="loadContracts" item-key="uuid" item-text="text" rounded :active.sync="active" active-class="primary--text" activatable return-object dense>
                <template v-slot:prepend="{ item, open, active: isActive }">
                  <v-icon v-if="item.companyCode || item.children?.length" :color="isActive ? 'primary' : ''" small>
                    {{ open ? '$vuetify.icons.folderOpen' : '$vuetify.icons.folder' }}
                  </v-icon>
                </template>

                <template v-slot:label="{ item }">
                  <v-row dense align="center">
                    <v-col class="shrink text-no-wrap">
                      <sca-company-identity v-if="item.companyCode" :value="item.companyCode" class="ml-1" label-prepend show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(item.companyCode)" />
                      <span v-else>
                        {{ item.text }}
                      </span>

                      <v-chip v-if="isNotStarted(item)" class="ml-4" outlined label small color="info">
                        <v-icon small left>
                          $vuetify.icons.expired
                        </v-icon>
                        {{ $t('Not started') }}
                      </v-chip>

                      <v-chip v-if="isExpired(item)" class="ml-4" outlined label small color="danger">
                        <v-icon small left>
                          $vuetify.icons.expired
                        </v-icon>
                        {{ $t('Finished') }}
                      </v-chip>
                    </v-col>

                    <v-col v-if="item.id && canUpdate" class="shrink">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click.native.stop="loadAndUpdate(item)" class="ml-4" icon right>
                            <v-icon small class="menu-icon--text">
                              $vuetify.icons.update
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('Update') }}</span>
                      </v-tooltip>
                    </v-col>

                    <v-col v-else-if="item.id && canShow" class="shrink">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click.native.stop="loadAndShow(item)" class="ml-4" icon right>
                            <v-icon small class="menu-icon--text">
                              $vuetify.icons.read
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('Show') }}</span>
                      </v-tooltip>
                    </v-col>

                    <v-divider v-if="item.files?.length" vertical />
                    <v-col v-if="item.files?.length">
                      <cs-attachments-list :id="item.id" :value="item.files" quiet dense can-download />
                    </v-col>
                  </v-row>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item value="tab-list" eager>
          <sca-advanced-store-grid :options="gridOptions" :resource="resourceId" :columns="columns" :filters="filters" :custom-search="customSearch" @createItem="createItem" @dataLoaded="dataLoaded" @deleteItem="deleteItem" @selectItems="selectItems" @showItem="showItem" @updateItem="updateItem" @resetFilters="resetFilters" ref="contractGrid" class="mt-4">
            <template slot="search-append">
              <v-row dense align="baseline">
                <v-col v-show="companies.length > 1">
                  <sca-customer-select v-model="companiesSearch" :label="$t('Company')" @input="refreshGrid" clearable dense hide-details show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" />
                </v-col>
              </v-row>
            </template>

            <template v-slot:item-code="{ itemRaw }">
              <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(itemRaw)" />
            </template>

            <template v-slot:item-contract="{ item }">
              <v-chip v-if="!isNaN(item)" label small>
                {{ contracts[item]?.number || $t('None') }}
              </v-chip>
              <span v-else />
            </template>

            <template v-slot:item-additional_clause="{ row }">
              <v-chip v-for="(id, index) in row.additional_clause" :key="index" label small class="ma-1">
                {{ contracts[id]?.number || $t('Unknown') + ' ' + id }}
              </v-chip>
            </template>
          </sca-advanced-store-grid>
        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <csm-contract-form :visible="contractDialog" :contract="contract" :update="updateContracts" :show="showContract" @closeDialog="closeDialog" @change="onContractChange" ref="ContractForm" />
    <sca-pdf-viewer-dialog ref="pdf-contract-file-viewer" />
    <csm-company-dialog ref="company-dialog" />
  </v-row>
</template>

<script>
import _ from 'lodash'
import getClassNameForExtension from 'font-awesome-filetypes'

export default {
  name: 'ContractsList',
  components: {
    'csm-contract-form': () => import('./ContractForm')
  },
  data () {
    return {
      active: [],
      companies: [],
      companiesCache: {},
      companiesSearch: '',
      contractTree: [],
      getClassNameForExtension,
      isLogged: false,
      isReading: false,
      loading: false,
      loadingCache: true,
      resourceId: 'contracts',
      filters: ['code', 'name', 'tags', 'number', 'contract', 'type', 'files'],
      showContract: false,
      updateContracts: false,
      contractDialog: false,
      contract: {},
      tab: 'tab-tree',
      treeFilter: ''
    }
  },
  computed: {
    canShow () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CONTRACTS, this.$alto.API_PERMISSIONS.READ)
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CONTRACTS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    columns () {
      return [{
        text: 'Customer',
        value: 'code'
      }, {
        text: 'Contract\'s number',
        value: 'number'
      }, {
        text: 'Type',
        value: 'type'
      }, {
        text: 'Status',
        value: 'status',
        format: value => {
          return this.$t(value)
        }
      }, {
        text: 'Sign date',
        value: 'sign_date',
        hidden: true,
        format: value => {
          return value ? this.$stratus.dt(value).format('LL') : '<span>' + this.$t('Undefined') + '</span>'
        }
      }, {
        text: 'Begin date',
        value: 'begin_date',
        hidden: true,
        format: value => {
          return value ? this.$stratus.dt(value).format('LL') : '<span>' + this.$t('Undefined') + '</span>'
        }
      }, {
        text: 'End date',
        value: 'end_date',
        hidden: true,
        format: value => {
          return value ? this.$stratus.dt(value).format('LL') : '<span>' + this.$t('Undefined') + '</span>'
        }
      }, {
        text: 'Syntec',
        value: 'indication_syntec',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.BOOLEAN_CHECK
      }, {
        text: 'Principal\'s contract',
        value: 'contract'
      }, {
        text: 'Additional clause',
        value: 'additional_clause',
        hidden: true
      }, {
        text: 'Updated at',
        align: 'right',
        alignValue: 'right',
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }]
    },
    contracts () {
      return this.$store.getters['$alto-contracts/cache'] || {}
    },
    gridOptions () {
      return {
        allowColumnsVisible: true,
        allowContextMenuOnCell: true,
        create: this.isLogged && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.CONTRACTS, this.$alto.API_PERMISSIONS.CREATE),
        delete: false,
        key: 'id',
        foreignFields: ['id'],
        messages: {
          deleteItem: 'Please, confirm the deletion of contract {number}?'
        },
        multiSelect: false,
        notifyErrors: true,
        search: this.isLogged,
        show: false,
        sortBy: 'code',
        sortDescending: false,
        update: false
      }
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] },
    me () { return this.$store.getters['$stratus-states/me'] }
  },
  methods: {
    closeDialog (saved) {
      this.contractDialog = false
      if (saved) {
        this.refreshGrid()
        this.loadCompanyTree()
      }
    },
    async createItem () {
      this.updateContracts = false
      this.showContract = false
      if (this.$refs.ContractForm) await this.$refs.ContractForm.reset()
      this.contract = {}
      this.contractDialog = true
    },
    customSearch () {
      const cusSearch = []
      if (this.companiesSearch) {
        cusSearch.push({
          column: 'code',
          search: this.companiesSearch
        })
      }
      return cusSearch
    },
    dataLoaded ({ success, error, items }) {
      if (error) {
        console.error(error)
      }
    },
    deleteItem ({ success, error, item }) {
      this.$stratus.services.notify.success(this.$t('Contract {number} deleted.', item))
      this.refreshGrid()
    },
    isExpired (item) {
      return item.files?.length && item.end_date && this.$stratus.dt().isAfter(this.$stratus.dt(item.end_date))
    },
    isNotStarted (item) {
      return item.files?.length && item.begin_date && this.$stratus.dt().isBefore(this.$stratus.dt(item.begin_date))
    },
    loadContractTree (contracts, root) {
      _.forEach(contracts, contract => {
        const node = {
          uuid: this.$stratus.uuid(),
          id: contract.id,
          text: contract.number,
          number: contract.number,
          begin_date: contract.begin_date,
          end_date: contract.end_date,
          files: contract.files
        }
        root.push(node)

        if (contract.children?.length) {
          node.children = []
          this.loadContractTree(contract.children, node.children)
        }
      })
    },
    loadContracts (item) {
      return new Promise((resolve, reject) => {
        if (!item.companyCode) resolve([])
        resolve(this.$store.dispatch('$alto-contracts/getForCompany', item.companyCode))
      })
        .then(contracts => {
          const children = []
          this.loadContractTree(contracts, children)
          return children
        })
        .then(data => {
          item.children = data
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    async loadCompanyTree (filter) {
      const sanitizedFilter = (filter || '').toUpperCase()
      this.loading = true
      try {
        const companies = await this.$store.dispatch('$alto-contracts/listCompanies')
        const contracts = []
        _.forEach(companies, code => {
          if (!filter || code.toUpperCase().indexOf(sanitizedFilter) >= 0 || this.companiesCache[code].toUpperCase().indexOf(sanitizedFilter) >= 0) {
            contracts.push({
              uuid: this.$stratus.uuid(),
              companyCode: code,
              text: code + ' — ' + this.companiesCache[code],
              children: []
            })
          }
        })
        this.contractTree = contracts
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
      this.loading = false
    },
    onSearchCompany (value) {
      this.loadCompanyTree(value)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    refreshGrid () {
      if (this.$refs.contractGrid) this.$refs.contractGrid.fetchData()
    },
    selectItems (items) {
      this.selection = items
    },
    showItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.showContract = true
      this.updateContracts = false
      this.$refs.ContractForm.reset(data.item)
      this.contract = data.item
      this.contractDialog = true
    },
    updateItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      this.showContract = false
      this.updateContracts = true
      this.$refs.ContractForm.reset(data.item)
      this.contract = data.item
      this.contractDialog = true
    },
    onContractChange (contract) {
      this.contract = { ...contract }
    },
    async loadAndShow (contract) {
      const item = await this.$store.dispatch('$alto-contracts/getById', contract.id)
      if (item) {
        this.showItem({ success: true, item })
      }
    },
    async loadAndUpdate (contract) {
      const item = await this.$store.dispatch('$alto-contracts/getById', contract.id)
      if (item) {
        this.updateItem({ success: true, item })
      }
    },
    resetFilters () {
      this.companiesSearch = ''
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Contracts'))
    this.isLogged = this.$stratus.services.auth.isLogged()
  },
  async mounted () {
    await this.$store.dispatch('$stratus-states/getMe')
    this.$alto.services.routes.connectionForbidden.call(this, this.me)

    this.loadingCache = true
    try {
      await this.$store.dispatch('$alto-contracts/list')
      await this.$store.dispatch('$alto-companies/list')
      this.companiesCache = this.$store.getters['$alto-companies/cache']()
      this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.companiesCache, { keyInValue: true })
      this.loadCompanyTree()
    } catch (error) {
      this.$stratus.services.notify.error(error)
    } finally {
      this.loadingCache = false
    }
  }
}
</script>
