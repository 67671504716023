<template>
  <div>
    <sca-modal-dialog :visible="visible" :loading="loading" @closeDialog="closeDialog" @saveDialog="saveDialog" :can-save="canSave" :is-saving="isSaving" max-width="75%" :external-id="externalId">
      <template #title>
        {{ subscription.ref }}
        {{ subscription.name }}
      </template>

      <div slot="subtitle" class="d-flex">
        <div class="highlighted-panel">
          {{ $t('Quantity:') }} {{ subscription.quantity }}
        </div>

        <div v-if="subscription.cmdb_datacenter" class="ml-2 highlighted-panel">
          {{ $t('Datacenter') }} {{ subscription.cmdb_datacenter }}
        </div>
      </div>

      <template #buttons-header>
        <cs-action-button v-if="canModify" icon="$vuetify.icons.setup" help="Modify or stop product" @click="modifyProduct" />
        <cs-action-button v-if="canOrderSameProduct" icon="$vuetify.icons.cart" help="Order same product" @click="orderSameProduct" />
        <cs-action-button v-if="canDeliver" icon="$vuetify.icons.complete" :loading="isDelivering" help="Mark this product as delivered." @click="deliverProduct" />

        <div class="action-button-separator" />

        <sca-customer-new-request-incident :tooltip="requestIncidentTooltip" small action-button @create-ticket="createTicket" />

        <div class="action-button-separator" />
      </template>

      <div slot="content">
        <v-form ref="SubscriptionDialog">
          <v-row>
            <v-col cols="12" :md="canShowPrice ? '7' : '12'">
              <v-row align="center" dense>
                <v-col class="text-truncate">
                  <sca-company-identity :value="subscription.code" show-avatar show-email show-phone show-sales-person link="emit" @link-click="openCompany(subscription.code)" />
                </v-col>

                <v-col class="shrink text-no-wrap">
                  <sca-order-identity :value="subscription.cmd_id" :label="$t('Order') + ' ' + subscription.cmd_id" show-avatar link="emit" @link-click="showOrder(subscription.cmd_id)" />
                </v-col>
              </v-row>

              <v-row align="center" dense>
                <v-col cols="6" class="d-flex align-center">
                  <sca-order-state v-if="subscription.state" :value="subscription.state" hide-text full-width />
                  <div class="d-flex align-center text-no-wrap ml-1">
                    <div v-if="subscription.date_begin">
                      {{ $stratus.dt(subscription.date_begin).format('ll') }}
                    </div>
                    <v-icon v-if="subscription.date_end && subscription.date_end !== subscription.date_begin" small left right>
                      $vuetifyicons.for
                    </v-icon>
                    <div v-if="subscription.date_end && subscription.date_end !== subscription.date_begin">
                      {{ $stratus.dt(subscription.date_end).format('ll') }}
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <sca-sphere-select v-model="subscription.id_sphere" :label="$t('Sphere')" :customer="subscription.code" :readonly="!canSave" />
                </v-col>
              </v-row>

              <v-row align="center" dense>
                <v-col cols="6">
                  <v-text-field v-model="subscription.caption" :label="$t('Caption')" :rules="[$stratus.services.form.rules.max(128)]" counter="128" dense :disabled="!isLord" />
                </v-col>

                <v-col cols="6">
                  <v-text-field v-model="subscription.customer_caption" :label="$t('Customer caption')" :rules="[$stratus.services.form.rules.max(128)]" counter="128" dense />
                </v-col>
              </v-row>

              <div class="mt-4 d-flex align-center">
                <v-icon v-if="subscription.date_commitment_end || subscription.nb_days_commitment" small>
                  $vuetify.icons.commitment
                </v-icon>
                <div v-if="subscription.date_commitment_end" class="ml-2 font-weight-bold">
                  {{ $t('Committed until {date}', { date: $stratus.dt(subscription.date_commitment_end).format('LL') }) }}
                </div>
                <div v-if="subscription.nb_days_commitment" class="ml-2">
                  ({{ $tc('{days} days', subscription.nb_days_commitment, { days: subscription.nb_days_commitment }) }})
                </div>
                <sca-user-identity v-if="isLord && subscription.commitment_update_by" :value="subscription.commitment_update_by" show-card show-avatar show-company show-role class="ml-4" link="emit" @link-click="openUser(subscription.commitment_update_by)" />
              </div>
            </v-col>

            <v-col v-if="canShowPrice" cols="12" md="5">
              <v-card outlined>
                <v-row no-gutters align="center">
                  <v-col cols="12" class="button-main button-main-ink--text text-center">
                    {{ $t('Costs') }}
                    <v-divider />
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" class="pa-2">
                  <v-col cols="12">
                    <sca-product-prices-grid :prices="subscription" :periodicity="subscription.periodicity" show-tva dense />
                  </v-col>

                  <cs-expand-panel v-if="subscription.periodicity === 'D'" :header="$t('Simulate the number of uses')">
                    <sca-product-price-simulator slot="content" no-label :customer-id="subscription.code" dense :product="subscription" />
                  </cs-expand-panel>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-tabs hide-slider v-model="tab" class="transparent mt-4">
            <v-tab href="#tab-details">
              <v-icon left>
                $vuetify.icons.details
              </v-icon>
              {{ $t('Details') }}
            </v-tab>
            <v-tab v-if="canShowProductHistory" href="#tab-history">
              <v-icon left>
                $vuetify.icons.history
              </v-icon>
              {{ $t('History') }}
            </v-tab>
            <v-tab v-if="canReadOpportunities" href="#tab-price-trends">
              <v-icon left>
                $vuetify.icons.graph
              </v-icon>
              {{ $t('Trend') }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-details" eager>
              <v-row v-if="isLord" align="start" class="pl-1">
                <v-col cols="12" md="4" class="d-flex align-start">
                  <v-icon>
                    $vuetify.icons.ticket
                  </v-icon>

                  <cs-expand-button v-model="showAllTickets" horizontal />

                  <div class="d-flex align-start">
                    <div v-if="subscription.ids_intern?.tickets" class="d-flex flex-row align-start">
                      <div v-for="(tickets, index) in subscription.ids_intern?.tickets" :key="index" class="mx-1 d-flex flex-wrap">
                        <sca-ticket-identity v-for="ticket in tickets" :key="`${index}-${ticket}`" :value="ticket" class="mr-1 mb-1" :exclude-states="showAllTickets ? [] : [TICKET_STATE_CANCELED, TICKET_STATE_CLOSED]" show-card no-icon link="emit" @link-click="openTicket" />
                      </div>
                    </div>

                    <div v-if="subscription.ids_ext && showAllTickets">
                      <csm-order-tickets :tickets="subscription.ids_ext" hide-label no-icon no-expand />
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="4" v-if="subscription.unique_ids && subscription.unique_ids.length" class="d-flex">
                  <div class="ml-auto mr-auto d-flex align-center">
                    <v-icon>
                      $vuetify.icons.id
                    </v-icon>

                    <cs-expand-list :items="productIdsSorted" row>
                      <template v-slot:expand-list-item="{ item: uProdId }">
                        <sca-identity :value="[uProdId]" can-copy no-icon />
                      </template>
                    </cs-expand-list>
                  </div>
                </v-col>

                <v-col cols="12" md="4" v-if="subscription.opportunity_id && subscription.opportunity_id.length > 0" class="d-flex">
                  <div class="ml-auto d-flex align-center">
                    <v-icon>
                      $vuetify.icons.opportunity
                    </v-icon>

                    <cs-expand-list :items="subscription.opportunity_id" row>
                      <template v-slot:expand-list-item="{ item: opportunity }">
                        <!-- DO NOT USE sca-opportunity-identity to avoid "RangeError: Maximum call stack size exceeded" -->
                        <csm-opportunity-button :value="opportunity" no-icon />
                      </template>
                    </cs-expand-list>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-2" dense>
                <v-col cols="12" :md="showDetails ? 6 : 12">
                  <div :class="showDetails ? '' : 'd-flex'">
                    <div class="mx-2 flex-grow-1">
                      <v-badge v-if="subscription.customer_comment && subscription.customer_comment.length > 0" dot color="primary">
                        {{ $t('Comment') }}
                      </v-badge>
                      <span v-else>
                        {{ $t('Comment') }}
                      </span>

                      <v-textarea v-model="subscription.customer_comment" :counter="COMMENT_MAX_LENGTH" outlined :readonly="!canSave" />
                    </div>

                    <div v-if="isLord" class="mx-2 flex-grow-1">
                      <v-badge v-if="subscription.comment && subscription.comment.length > 0" dot color="primary">
                        {{ $t('Scalair comment') }}
                      </v-badge>
                      <span v-else>
                        {{ $t('Scalair comment') }}
                      </span>

                      <v-textarea v-model="subscription.comment" :counter="CORPORATE_COMMENT_MAX_LENGTH" outlined :readonly="!canSave" />
                    </div>
                  </div>
                </v-col>

                <v-col v-if="showDetails" cols="12" md="6">
                  <sca-product-details :product="productToShow" :company="currentCartCustomer" :show="true" :show-prices="isLord && productToShow.main?.length > 0" load-on-mounted />

                  <cs-alert-panel v-if="subscription.periodicity === 'D' && subscription.detail_daily" dense type="info">
                    <template #content>
                      {{ subscription.detail_daily.month !== undefined ? this.$t('Used this month: {count}{unit}', { count: subscription.detail_daily.month, unit: ' ' + this.$tc('day|day|days', subscription.detail_daily.month) }) : '' }}
                      <span v-show="subscription.detail_daily.last_month !== undefined" class="ml-4 text-caption">
                        ({{ this.$t('Previous month: {count}{unit}', { count: subscription.detail_daily.last_month, unit: ' ' + this.$tc('day|day|days', subscription.detail_daily.last_month) }) }})
                      </span>
                    </template>
                  </cs-alert-panel>
                </v-col>
              </v-row>

              <sca-product-states-history-expand :product="subscription" :bar-months="12" :show-details="isLord" />
            </v-tab-item>

            <v-tab-item v-if="canShowProductHistory" value="tab-history" eager>
              <sca-order-history-timeline :company="currentCartCustomer" :history="subscription.history" :cancellation-history="subscription.history_cancel" :caption-history="subscription.history_caption" :product="subscription" @open-user="openUser" />
            </v-tab-item>

            <v-tab-item v-if="canReadOpportunities" value="tab-price-trends" eager>
              <div v-if="!opportunityData.price" class="text-center">
                {{ $t('No data...') }}
              </div>
              <div v-else>
                <sca-price-trends ref="subscription-price-trend" :data="opportunityData" :disabled="!opportunityData.price" input-period>
                  <v-switch v-if="isLord" slot="button-prepend" v-model="opportunityAccounting" :label="$t('Accounting')" @change="loadPriceTrends" />
                </sca-price-trends>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </div>

      <sca-footer-create-update-at-by v-model="subscription" slot="footer" :link-user="isLord" />
    </sca-modal-dialog>

    <csm-company-dialog ref="company-dialog" />
    <cs-confirm-dialog ref="confirm-subscription-dialog" />
    <csm-user-dialog ref="user-dialog" />
    <csm-order-dialog ref="order-dialog" />
    <csm-ticket-dialog ref="ticket-dialog" />
    <csm-ticket-new-request ref="form-ticket-new-request" :visible="showRequestDialog" :descriptor="descriptor" :subscription="subscription" @closeDialog="closeTicketDialog" />
    <csm-ticket-new-incident ref="form-ticket-new-incident" :visible="showIncidentDialog" :descriptor="descriptor" :subscription="subscription" :product="product" @closeDialog="closeTicketDialog" />

    <csm-subscription-date-dialog ref="subscription-date-dialog">
      <v-form slot="content">
        <div v-if="subscription && refNeedCaption(subscription.ref)">
          <v-text-field v-model="subscription.caption" :label="$t('Caption')" :rules="captionRules(subscription)" counter="128" :disabled="!canDeliver" />
        </div>
        <cs-date-picker v-model="productDate" :min="minDateEnd" :max="maxDateEnd" />
      </v-form>
    </csm-subscription-date-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SubscriptionDialog',
  props: {
    canModify: { type: Boolean, default: false },
    catalogProduct: { type: Object, required: true, default: () => { return {} } },
    loading: { type: Boolean, default: false },
    subscription: { type: Object, required: true, default: () => { return {} } },
    show: { type: Boolean, default: true },
    update: { type: Boolean, default: false },
    visible: { type: Boolean, default: false }
  },
  components: {
    'csm-subscription-date-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Orders/OrderProductDate'),
    'csm-order-tickets': () => import(/* webpackChunkName: "components" */ '@/components/Orders/OrderTickets'),
    'csm-opportunity-button': () => import(/* webpackChunkName: "components" */ '@/components/Opportunities/OpportunityButton'),
    'csm-ticket-new-incident': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewIncident'),
    'csm-ticket-new-request': () => import(/* webpackChunkName: "views" */ '@/components/Tickets/TicketNewRequest')
  },
  data () {
    return {
      COMMENT_MAX_LENGTH: 1024,
      CORPORATE_COMMENT_MAX_LENGTH: 4096,
      TICKET_STATE_CANCELED: this.$alto.defines.TICKETS.TICKET_STATE_CANCELED,
      TICKET_STATE_CLOSED: this.$alto.defines.TICKETS.TICKET_STATE_CLOSED,
      companyName: null,
      descriptor: [],
      orderingSameProduct: false,
      productToShow: {},
      isDelivering: false,
      isSaving: false,
      maxDateEnd: null,
      minDateEnd: null,
      opportunityAccounting: false,
      opportunityData: {},
      order: {},
      product: {},
      productDate: null,
      showAllTickets: false,
      showIncidentDialog: false,
      showRequestDialog: false,
      showOpportunityGraph: false,
      tab: 'tab-details'
    }
  },
  watch: {
    subscription (newValue, oldValue) {
      if (newValue !== oldValue) {
        // Empty graph as we've changed the subscription
        if (this.$refs['subscription-price-trend']) this.$refs['subscription-price-trend'].empty()
        this.loadPriceDetails()
      }
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord'
    }),
    canDeliver () {
      return this.permissionDeliver && this.$store.getters['$alto-orders/STATE_CAN_DELIVER'].includes(this.subscription.state)
    },
    canOrderSameProduct () {
      if (!this.subscription || !this.subscription.ref || !this.catalogProduct || !this.catalogProduct.ref) return false
      if (this.catalogProduct.end_subscription) return false
      return (this.catalogProduct.available || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.CREATE)) && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS, this.$alto.API_PERMISSIONS.CREATE)
    },
    canReadCatalog () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canReadPrices () {
      return !this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.LIST_NO_PRICE)
    },
    canShowPrice () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PRODUCTS_PRICES, this.$alto.API_PERMISSIONS.READ) && this.canReadPrices && !this.catalogProduct?.no_price
    },
    canReadOpportunities () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.OPPORTUNITIES, this.$alto.API_PERMISSIONS.OPPORTUNITIES_ONELINE)
    },
    canSave () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.CREATE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_MODIFY)
    },
    canShowProductHistory () {
      return (this.subscription.history && this.subscription.history.length) || (this.subscription.history_cancel && this.subscription.history_cancel.length) || (this.subscription.history_caption && this.subscription.history_caption.length)
    },
    currentCart () { return this.$store.getters['$alto-quotations/current'] || {} },
    currentCartCustomer () { return this.currentCart.code || this.subscription.code || this.$store.getters['$stratus-states/me'].company },
    dateBegin () { return this.subscription.date_begin ? this.$stratus.dt(this.subscription.date_begin).format('LL') : this.$t('Undefined') },
    dateEnd () { return this.subscription.date_end ? this.$stratus.dt(this.subscription.date_end).format('LL') : this.$t('Undefined') },
    myCompany () { return this.$store.getters['$stratus-states/me'].company },
    permissionDeliver () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE) || this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_LINE, this.$alto.API_PERMISSIONS.ORDER_DELIVER_PAST)
    },
    productIdsSorted () {
      return this.$stratus.services.sort.natural(this.subscription.unique_ids)
    },
    showDetails () { return this.subscription.main && this.subscription.main.length > 0 },
    state () { return this.subscription.state ? this.$t('order-state-' + this.subscription.state) : this.$t('Unknown') }
  },
  methods: {
    captionRules (item) {
      const rules = [this.$stratus.services.form.rules.max(128)]
      if (this.refNeedCaption(item.ref)) {
        rules.push(this.$stratus.services.form.rules.min(1))
      }
      return rules
    },
    closeTicketDialog () {
      this.showIncidentDialog = false
      this.showRequestDialog = false
      if (this.$refs['user-ticket-grid']) this.$refs['user-ticket-grid'].refresh()
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    async createTicket ({ ticketType, descriptor }) {
      this.descriptor = descriptor
      if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT) {
        this.product = await this.$store.dispatch('$alto-catalog/getProductByRef', this.subscription.ref)
        this.$refs['form-ticket-new-incident'].reset()
        this.showIncidentDialog = true
        this.$refs['form-ticket-new-incident'].resetPage()
      } else if (ticketType === this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST) {
        this.$refs['form-ticket-new-request'].reset()
        this.showRequestDialog = true
      }
    },
    deliverProduct () {
      if (this.subscription.state === 'pending' && this.subscription.date_begin) {
        this.$refs['confirm-subscription-dialog']
          .open(this.$t('Confirm delivery'), this.$t('Validate is an irreversible step, do you confirm the validation?'), { color: 'blue' })
          .then(async (confirm) => {
            if (confirm) {
              this.isDelivering = true
              try {
                const updatedSubscription = await this.$store.dispatch('$alto-orders/deliverProduct', { id: this.subscription.id })
                this.$emit('refresh', { ...this.subscription, ...updatedSubscription })
              } catch (error) {
                this.$stratus.services.notify.error(error)
              } finally {
                setTimeout(() => { this.isDelivering = false }, 250)
              }
            }
          })
      } else {
        this.productDate = this.$stratus.dt().toISOString().substr(0, 10)

        this.minDateEnd = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ORDERS_DELIVERY, this.$alto.API_PERMISSIONS.ORDER_DELIVERY_COMPLETE_OVERRIDE) ? this.$stratus.dt().subtract(1, 'years').startOf('year').toDate() : this.$stratus.dt().startOf('month').toDate()
        this.maxDateEnd = this.$stratus.dt().add(1, 'days').startOf('day').toDate()

        this.$refs['subscription-date-dialog'].open(this.$t('Start this product'), `${this.subscription.name} × ${this.subscription.quantity}`)
          .then(async (confirm) => {
            if (confirm) {
              this.isDelivering = true
              try {
                await this.$store.dispatch('$alto-orders/updateSubscription', { id: this.subscription.id, caption: this.subscription.caption || '' })
                const updatedSubscription = await this.$store.dispatch('$alto-orders/deliverProduct', { id: this.subscription.id, date_begin: this.$stratus.dt(this.productDate).format('YYYY-MM-DD') })
                this.$emit('refresh', { ...this.subscription, ...updatedSubscription })
              } catch (error) {
                this.$stratus.services.notify.error(error)
              } finally {
                setTimeout(() => { this.isDelivering = false }, 250)
              }
            }
          })
      }
    },
    externalId () {
      return [this.subscription.ref, this.subscription.id ? 'subscriptions/' + this.subscription.id : '', this.subscription.name]
    },
    async loadPriceDetails () {
      if (!this.canReadCatalog) return
      try {
        let details
        if (this.canReadPrices && !this.catalogProduct?.no_price) {
          details = await this.$store.dispatch('$alto-catalog/getPriceDetails', {
            code: this.subscription.code, // This is the company that owns the product
            ref: this.subscription.ref,
            data: {
              forced: true,
              main: this.subscription.main,
              quantity: this.subscription.quantity,
              per_discount: this.subscription.per_discount || 0,
              per_discount_setup: this.subscription.per_discount_setup || 0,
              price: this.subscription.price || 0,
              price_setup: this.subscription.price_setup || 0,
              buying_price: this.subscription.buying_price || 0,
              buying_price_setup: this.subscription.buying_price_setup || 0
            }
          })
        }
        if (details?.price[this.subscription.ref]?.prices_details) {
          this.productToShow = { ...this.subscription, main: [...details.price[this.subscription.ref].prices_details] }
        } else {
          this.productToShow = { ...this.subscription }
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    async loadPriceTrends ({ dateBegin, dateEnd } = {}) {
      if (!this.canReadOpportunities || !this.subscription.id) return

      try {
        this.opportunityData = await this.$store.dispatch('opportunities/getSubscribedProductsHistory', {
          ids: [this.subscription.id],
          dateBegin: dateBegin || this.$stratus.dt().subtract(6, 'month').startOf('month'),
          dateEnd: dateEnd || this.$stratus.dt(),
          countable: this.opportunityAccounting
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.opportunityData = {}
      }
    },
    modifyProduct () {
      this.$emit('modify-product', this.subscription)
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openTicket (ticket) {
      if (!ticket?.id) return
      if (this.$refs['ticket-dialog']) this.$refs['ticket-dialog'].open(ticket.id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    async orderSameProduct () {
      const cartItem = { ...this.subscription } // A clone is mandatory, saving will delete unwanted fields
      this.$refs['confirm-subscription-dialog'].open(this.$t('Order same product'), this.$t('Do you want to add product «{pname}» to {cname}?', { pname: cartItem.name, cname: this.currentCart?.name || this.$t('a new cart') }))
        .then(async (confirmed) => {
          if (confirmed) {
            this.orderingSameProduct = true
            let newCart
            const quantity = 1

            try {
              // No cart exist, create a brand new one
              if (!this.currentCart?.name) {
                this.$store.dispatch('$alto-quotations/create', { company: this.subscription?.code || this.myCompany })
                // Add product to cart
                await this.$store.dispatch('$alto-quotations/addToCurrent', { products: { ...cartItem, quantity }, company: this.myCompany })
                // Save the new cart
                const { cart, isNew } = await this.$store.dispatch('$alto-quotations/save', { cart: this.currentCart })
                newCart = cart
                if (isNew) {
                  this.$stratus.services.notify.success(this.$t('New cart {name} created.', { name: newCart.name }))
                }
              } else {
                // Add product to existing cart
                newCart = await this.$store.dispatch('$alto-quotations/addProduct', { id: this.currentCart.id, product: { ...cartItem, quantity } })
              }

              if (this.$root['global-cart-menu']) this.$root['global-cart-menu'].refreshList()
              this.$store.dispatch('$alto-quotations/list') // Refresh cart menu
              this.$store.commit('$alto-quotations/setCurrent', newCart) // Force refresh current cart
              this.$stratus.services.notify.success(this.$t('{quantity} × {product} added to cart.', { quantity, product: this.subscription.name || this.subscription.ref }))
            } catch (error) {
              this.$stratus.services.notify.error(error)
            }

            setTimeout(() => { this.orderingSameProduct = false }, 200)
          }
        })
    },
    refNeedCaption (ref) {
      const p = this.$store.getters['$alto-catalog/get'](ref)
      return p && p.need_caption
    },
    saveDialog () {
      const subscription = {
        id: this.subscription.id,
        id_sphere: this.subscription.id_sphere,
        customer_caption: this.subscription.customer_caption || '',
        customer_comment: this.subscription.customer_comment || '',
        comment: this.subscription.comment || ''
      }
      if (this.isLord) subscription.caption = this.subscription.caption || ''

      this.$store.dispatch('$alto-subscriptions/update', subscription)
        .then(response => {
          this.$stratus.services.notify.success(this.$t('Your product has been updated.'))
          this.closeDialog()
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    showOrder (id) {
      if (this.$refs['order-dialog']) this.$refs['order-dialog'].open(id)
    },
    async reset () {
      this.showOpportunityGraph = false
      this.opportunityData = {}
    }
  },
  created () {
    this.requestIncidentTooltip = {
      [this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT]: this.$t('Report an incident on this subscription.'),
      [this.$alto.defines.TICKETS.TICKET_TYPE_REQUEST]: this.$t('Make a free-format request on this subscription.')
    }
  },
  mounted () {
    this.loadPriceTrends()
  }
}
</script>
