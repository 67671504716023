<template>
  <div>
    <v-tabs v-model="tab" class="transparent" hide-slider>
      <v-tab href="#tab-dashboard">
        <v-icon small left>
          $vuetify.icons.dashboardPreSale
        </v-icon>
        {{ $t('Pre-sale Dashboard') }}
      </v-tab>

      <v-tab href="#tab-kanban">
        <v-icon small left>
          $vuetify.icons.kanban
        </v-icon>
        {{ $t('Kanban') }}
      </v-tab>

      <v-tab href="#tab-tickets">
        <v-icon small left>
          {{ TICKET_TYPE_PRE_SALE_ICON }}
        </v-icon>
        {{ $t('Tickets') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="background-blurred">
      <v-tab-item value="tab-kanban" class="px-2 pt-4">
        <div class="d-flex align-center text-h4 mb-4">
          <v-icon>
            $vuetify.icons.ticket
          </v-icon>
          {{ $t('Pre-sale tickets') }}
        </div>
        <csm-kanban-generic graph="pre-sale" :default-ticket="DEFAULT_TICKET" :sort="sort" pref-key="kanban-pre-sale" hide-filter-group hide-filter-team class="ml-6" />

        <cs-expand-panel :header="$t('Other tickets')" icon="$vuetify.icons.ticket" expanded header-cls="py-2 mb-2 text-h4" block>
          <csm-kanban-generic slot="content" graph="default" pref-key="kanban-pre-sale-other" :filter-teams="TEAM_PRE_SALE" />
        </cs-expand-panel>
      </v-tab-item>

      <v-tab-item value="tab-tickets" class="px-2 pt-6">
        <csm-ticket-grid ref="pre-sale-ticket-grid" graph="pre-sale" :options="gridOptions" :columns="gridColumns" :default-ticket="DEFAULT_TICKET" pref-key="ticket-grid-pre-sale" filter-ticket-state hide-global-incident inline-filter />
      </v-tab-item>

      <v-tab-item value="tab-dashboard">
        <csm-pre-sale-dashboard />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KanbanPreSaleView',
  components: {
    'csm-kanban-generic': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/KanbanGeneric'),
    'csm-ticket-grid': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGrid'),
    'csm-pre-sale-dashboard': () => import(/* webpackChunkName: "views" */ './PreSaleDashboard')
  },
  data () {
    return {
      DEFAULT_TICKET: this.$alto.defines.TICKETS.TICKET_DEFAULT_VALUES_PRESALE,
      TEAM_PRE_SALE: this.$alto.defines.TEAMS.TEAM_PRE_SALE,
      TICKET_TYPE_PRE_SALE: this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE,
      TICKET_TYPE_PRE_SALE_ICON: this.$alto.defines.TICKETS.TICKET_TYPE_PRE_SALE_ICON,
      gridOptions: {
        foreignFields: ['id', 'ids_observers', 'date_closure', 'resolution_reason', 'waiting_reason']
      },
      tab: 'tab-dashboard'
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isLord: '$stratus-states/isLord'
    }),
    gridColumns () {
      return [{
        text: 'Type',
        value: 'type',
        hidden: true
      }, {
        text: 'Identifier',
        value: 'id'
      }, {
        text: 'Company',
        value: 'code'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'State',
        value: 'state'
      }, {
        text: 'Sphere',
        value: 'id_sphere'
      }, {
        text: 'Owner',
        value: 'id_owner',
        hidden: true
      }, {
        text: 'Referrer',
        value: 'id_referring',
        hidden: true
      }, {
        text: 'Requested reply date',
        value: 'estimated_date',
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : ''
      }, {
        text: 'Updated at',
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Created at',
        value: 'create_at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Created by',
        value: 'create_by'
      }]
    }
  },
  methods: {
    sort (ticket) {
      // Sort by ascending estimated_date
      return ticket.estimated_date ? this.$stratus.dt(ticket.estimated_date).unix() : 0
    }
  },
  async mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$i18n.t('Pre-sale'))
  }
}
</script>
