<template>
  <v-row>
    <v-col v-if="showErrorPanel" cols="12" class="d-flex justify-center">
      <div class="flex-column justify-center">
        <div class="d-flex align-center text-h5 error--text my-4">
          <v-icon class="error--text" left>
            $vuetify.icons.error
          </v-icon>
          {{ $t('Our ticket platform is not currently available.') }}
        </div>

        <div class="d-flex justify-center">
          <sca-contact-card value="contact@scalair.fr" compact show-border show-avatar show-card show-phone show-email :title="$t('The Scalair teams')" />
        </div>
      </div>
    </v-col>

    <v-col v-else cols="12">
      <sca-advanced-store-grid :options="gridOptions" resource="tickets" :auto-refresh-delay="autoRefreshDelay" :pause="gridOnPause" :store-suffix="uuid" :columns="gridColumns" :filters="filters" :custom-search="customSearch" search-text-col-size="sm" @createItem="onCreateItem" @dataLoaded="onDataLoaded" @deleteItem="onDeleteItem" @resetFilters="resetFilters" @selectItems="selectItems" @showItem="onShowItem" @updateItem="onUpdateItem" :ref="`ticket-grid-${uuid}`">
        <template v-if="title" #legend-top>
          <span class="pl-2 text-h5">
            {{ title }}
          </span>
        </template>

        <template v-if="!hideGlobalIncident" slot="fab-prepend">
          <v-tooltip top>
            <template v-if="canCreateGlobalTicket" v-slot:activator="{ on }">
              <v-btn fab small class="primary" @click="onCreateGlobalTicket" v-on="on">
                <v-icon>
                  $vuetify.icons.ticket
                </v-icon>
              </v-btn>
            </template>{{ $t('Create a global ticket') }}
          </v-tooltip>
        </template>

        <v-row slot="search-append" align="center" dense>
          <v-col class="shrink d-flex align-center">
            <v-btn-toggle v-if="!hideToggles" v-model="filterBy" dense class="transparent" @change="refreshDebounced">
              <v-btn small text :value="FILTER_OWNER" :class="filterBy === FILTER_OWNER ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('My tickets') }}
              </v-btn>
              <v-btn small text v-if="isLord && me?.lord_team_id" :value="FILTER_MY_TEAM" :class="filterBy === FILTER_MY_TEAM ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $tc('Tickets of my team', myTeams.length) }}
              </v-btn>
              <v-btn small text :value="FILTER_CREATOR" :class="filterBy === FILTER_CREATOR ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('Tickets created be me') }}
              </v-btn>
              <v-btn small text :value="FILTER_OBSERVER" :class="filterBy === FILTER_OBSERVER ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('Tracked tickets') }}
              </v-btn>
              <v-btn small text :value="FILTER_NONE" :class="filterBy === FILTER_NONE ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('All') }}
              </v-btn>
            </v-btn-toggle>

            <v-btn-toggle v-if="!hideToggles && isLord" v-model="filterByCompany" dense class="transparent ml-4" @change="refreshDebounced">
              <v-btn small text :value="FILTER_CUSTOMERS" :class="filterByCompany === FILTER_CUSTOMERS ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('Customer tickets') }}
              </v-btn>
              <v-btn small text :value="FILTER_CORPORATE" :class="filterByCompany === FILTER_CORPORATE ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('Internal tickets') }}
              </v-btn>
              <v-btn small text :value="FILTER_NONE" :class="filterByCompany === FILTER_NONE ? 'button-main button-main-ink--text' : ''" :disabled="loading">
                {{ $t('All') }}
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col v-if="inlineFilter && !hideFilters" class="grow d-flex mt-5 align-center">
            <div v-if="(filterAll || filterTicketType)">
              <sca-ticket-type-select v-model="ticketTypeSearch" :label="$t('Type')" clearable dense @change="refreshDebounced" class="ml-2" />
            </div>

            <div v-show="(filterAll || filterCompanies) && companies.length > 1" class="ml-2">
              <sca-customer-select v-model="companiesSearch" :placeholder="$t('Company')" @input="refreshDebounced" clearable show-email dense show-phone link="emit" @link-click="openCompany(companiesSearch)" />
            </div>

            <div v-if="(filterAll || filterTicketSeverity)" class="ml-2">
              <sca-ticket-severity-select v-model="ticketSeveritySearch" :label="$t('Severity')" clearable dense multiple @input="refreshDebounced" />
            </div>

            <div v-if="(filterAll || filterTicketState)" class="ml-2 d-flex">
              <sca-ticket-state-select v-model="ticketStateSearch" :label="$t('State')" single-line :all="isLord" :flat="!isLord" clearable dense multiple @input="refreshDebounced" />

              <v-menu offset-y :close-on-content-click="false" transition="slide-y-transition">
                <template v-slot:activator="{ on: onMenu }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn small icon rounded v-on="{ ...onMenu, ...onTooltip }">
                        <v-icon small color="menu-icon">
                          $vuetify.icons.options
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('Options') }}
                  </v-tooltip>
                </template>

                <v-card tile class="pa-2">
                  <v-switch v-model="hideCanceledOrClosed" :label="$t('Hide canceled or closed tickets')" @change="toggleTicketCanceledOrClosed" dense hide-details class="mt-0" />
                </v-card>
              </v-menu>
            </div>

            <div v-if="(filterAll || filterTeam)" class="ml-2">
              <sca-teams-select v-model="teamSearch" :label="$t('Team')" clearable dense multiple @selectionChange="refreshDebounced" />
            </div>

            <div v-if="(filterAll || filterProject) && canReadProjects" class="ml-2">
              <!-- Project codes in all tickets -->
              <sca-sphere-select v-model="projectsSearch" :customer="companiesSearch" :label="$t('Sphere')" clearable dense multiple source-tickets @input="refreshDebounced" />
            </div>
          </v-col>

          <v-col v-show="!hideToggles && lastRefreshDate" class="text-caption">
            ({{ $t('Last refresh: {date}', { date: $stratus.dt(lastRefreshDate).format('LT') }) }})
          </v-col>
        </v-row>

        <div v-if="!inlineFilter && !hideFilters" slot="search-row">
          <v-row align="center" dense class="my-0 py-0 px-2">
            <v-col v-if="(filterAll || filterTicketType)" cols="12" md="6" lg="2">
              <sca-ticket-type-select v-model="ticketTypeSearch" :label="$t('Type')" clearable dense @change="refreshDebounced" />
            </v-col>

            <v-col v-show="(filterAll || filterCompanies) && companies.length > 1" cols="12" md="6" lg="2">
              <sca-customer-select v-model="companiesSearch" :label="$t('Company')" clearable dense show-email show-phone link="emit" @link-click="openCompany(companiesSearch)" @input="refreshDebounced" />
            </v-col>

            <v-col v-if="(filterAll || filterTicketSeverity)" cols="12" md="6" lg="2">
              <sca-ticket-severity-select v-model="ticketSeveritySearch" :label="$t('Severity')" clearable dense multiple @input="refreshDebounced" />
            </v-col>

            <v-col v-if="(filterAll || filterTicketState)" cols="12" md="6" lg="2" class="d-flex">
              <sca-ticket-state-select v-model="ticketStateSearch" :label="$t('State')" :all="isLord" :flat="!isLord" clearable dense multiple single-line @input="refreshDebounced" />

              <v-menu offset-y :close-on-content-click="false" transition="slide-y-transition">
                <template v-slot:activator="{ on: onMenu }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn small icon rounded v-on="{ ...onMenu, ...onTooltip }">
                        <v-icon small color="menu-icon">
                          $vuetify.icons.options
                        </v-icon>
                      </v-btn>
                    </template>
                    {{ $t('Options') }}
                  </v-tooltip>
                </template>

                <v-card tile class="pa-2">
                  <v-switch v-model="hideCanceledOrClosed" :label="$t('Hide canceled or closed tickets')" @change="toggleTicketCanceledOrClosed" dense hide-details class="mt-0" />
                </v-card>
              </v-menu>
            </v-col>

            <v-col v-if="(filterAll || filterTeam)" cols="12" md="6" lg="2">
              <sca-teams-select v-model="teamSearch" :label="$t('Team')" clearable dense multiple @selectionChange="refreshDebounced" />
            </v-col>

            <v-col v-if="(filterAll || filterProject) && canReadProjects" cols="12" md="6" lg="2">
              <!-- Project codes in all tickets -->
              <sca-sphere-select v-model="projectsSearch" :customer="companiesSearch" :label="$t('Sphere')" clearable dense multiple source-tickets @input="refreshDebounced" />
            </v-col>
          </v-row>
        </div>

        <template v-slot:item-id="{ row }">
          <sca-ticket-identity :value="row" show-card />
        </template>

        <template v-slot:item-id_owner="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-id_referring="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-id_lord_team="{ itemRaw }">
          <sca-team-identity v-if="itemRaw" :value="itemRaw" />
          <span v-else />
        </template>

        <template v-slot:item-create_by="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-update_by="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-last_comment_by="{ itemRaw }">
          <sca-user-identity v-if="itemRaw" :value="itemRaw" show-card show-avatar show-company show-email show-phone show-role link="emit" @link-click="openUser(itemRaw)" />
          <span v-else />
        </template>

        <template v-slot:item-state="{ itemRaw, row }">
          <sca-ticket-state small show-label :value="itemRaw" />
          <span v-if="row.waiting_reason && itemRaw === TICKET_STATE_WAITING" class="text-tiny">
            {{ $t(`ticket-waiting-reason-${row.waiting_reason}`) }}
            <span v-if="(row.waiting_reason === TICKET_WAITING_REASON_PLANNED)" class="text-tiny">
              {{ $stratus.dt(row.date_intervention).format('l') }}
            </span>
          </span>
          <span v-if="row.resolution_reason && (itemRaw === TICKET_STATE_CLOSED || itemRaw === TICKET_STATE_DONE)" class="text-tiny">
            {{ $t(`ticket-resolution-reason-${row.resolution_reason}`) }}
          </span>
        </template>

        <template v-slot:item-resolution_reason="{ itemRaw }">
          {{ itemRaw ? $t(`ticket-resolution-reason-${itemRaw}`) : '' }}
        </template>

        <template v-slot:item-waiting_reason="{ itemRaw, row }">
          {{ itemRaw ? $t(`ticket-waiting-reason-${itemRaw}`) : '' }}
          <span v-if="(itemRaw === TICKET_WAITING_REASON_PLANNED)" class="text-tiny">
            {{ $stratus.dt(row.date_intervention).format('ll') }}
          </span>
        </template>

        <template v-slot:item-severity="{ itemRaw }">
          <sca-ticket-severity :value="itemRaw" />
        </template>

        <template v-slot:item-type="{ itemRaw }">
          <sca-ticket-type :value="itemRaw" />
        </template>

        <template v-slot:item-id_sphere="{ itemRaw }">
          <sca-sphere-identity :value="itemRaw" link="emit" @link-click="openSphere(itemRaw)" />
        </template>

        <template v-slot:item-estimated_time="{ itemRaw }">
          {{ itemRaw ? $stratus.services.format.secondsToDays(60 * itemRaw, SECONDS_BY_WORK_DAY) : '-' }}
        </template>

        <template v-slot:item-passed_time="{ itemRaw }">
          {{ itemRaw ? $stratus.services.format.secondsToDays(60 * itemRaw, SECONDS_BY_WORK_DAY) : '-' }}
        </template>

        <template v-slot:item-passed_time_billable="{ itemRaw }">
          {{ itemRaw ? $stratus.services.format.secondsToDays(60 * itemRaw, SECONDS_BY_WORK_DAY) : '-' }}
        </template>

        <template v-slot:item-code="{ itemRaw }">
          <sca-company-identity :value="itemRaw" show-avatar show-email show-phone show-sales-person :text-resume="20" link="emit" @link-click="openCompany(itemRaw)" />
        </template>

        <template v-slot:item-origin="{ itemRaw }">
          {{ $t(`ticket-origin-${itemRaw}`) }}
        </template>

        <template v-slot:item-id_launcher="{ itemRaw }">
          <sca-ticket-launcher-identity :value="itemRaw" dense link="emit" @link-click="openTicketLauncher(itemRaw)" />
        </template>

        <div slot="multi-select">
          <v-form :disabled="isActionRunning">
            <v-row dense align="center">
              <v-col class="shrink">
                <v-icon small>
                  $vuetify.icons.run
                </v-icon>
              </v-col>

              <v-col cols="4" md="3">
                <v-select dense v-model="selectionAction" :items="actionList" clearable hide-details :placeholder="$t('Select an action to perform...')" @change="validateSelection" />
              </v-col>

              <v-col v-show="selectionAction === LINE_ACTIONS.CHANGE_TICKET_OWNER">
                <sca-users-select v-model="selectedOwner" filter-lord show-team show-company :label="$t('Owner')" dense clearable hide-details @change="validateSelection" link="emit" @link-click="openUser(selectedOwner)" />
              </v-col>

              <v-col v-show="selectionAction === LINE_ACTIONS.CHANGE_TICKET_REFERRER">
                <sca-users-select v-model="selectedReferrer" filter-lord show-team show-company :label="$t('Referrer')" dense clearable hide-details @change="validateSelection" link="emit" @link-click="openUser(selectedReferrer)" />
              </v-col>

              <v-col v-if="selectionAction === LINE_ACTIONS.CHANGE_TICKET_STATE" class="d-flex align-center">
                <sca-ticket-state-select v-model="selectedState" :label="$t('State')" flat clearable />

                <div v-if="selectedState === TICKET_STATE_WAITING" class="mx-2">
                  <sca-ticket-waiting-reason-select v-model="ticketWaitingReason" :label="$t('Waiting reason')" :ticket-type="selection?.[0]?.type" :rules="waitingReasonRules" :class="waitingReasonRequired" select-first />
                  <!-- Planned waiting reason must provide a date of intervention -->
                  <cs-date-time-picker v-if="ticketWaitingReason === TICKET_WAITING_REASON_PLANNED && ticketStateMandatoryFields(selectedState).includes(TICKET_FIELD_WAITING_REASON)" v-model="ticketDateIntervention" :rules="[$stratus.services.form.rules.required]" class="required" dense />
                </div>

                <div v-if="resolutionReasons(selection?.[0]?.type, selectedState) > 0">
                  <sca-ticket-resolution-select v-model="ticketResolutionReason" :grph="graph" :ticket-state="selection?.[0]?.state" :ticket-type="selection?.[0]?.type" />
                </div>
              </v-col>

              <v-col class="shrink text-no-wrap">
                {{ $tc('on selected line | on {count} lines', selection.length, { count: selection.length }) }}
              </v-col>

              <v-col>
                <v-btn rounded small color="main-button" :disabled="!canRunAction" :loading="isActionRunning" @click="actionRun">
                  {{ $t('Run...') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense justify="center">
              <v-col class="shrink text-no-wrap">
                <cs-alert-panel v-if="selectionError" text dense type="error">
                  <template #content>
                    {{ selectionError }}
                  </template>
                </cs-alert-panel>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </sca-advanced-store-grid>
    </v-col>

    <csm-company-dialog ref="company-dialog" />
    <csm-sphere-dialog ref="sphere-dialog" />
    <csm-user-dialog ref="user-dialog" />
    <csm-ticket-dialog @closeDialog="closeDialog" ref="ticket-dialog" />
    <csm-ticket-launcher-dialog ref="ticket-launcher-dialog" />
    <csm-ticket-global ref="form-ticket-global" :visible="showGlobalTicketDialog" @closeDialog="closeDialog" />

    <cs-confirm-dialog ref="confirm-ticket-action-dialog" />
  </v-row>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

import config from '@/config'

const FILTER_CREATOR = 1
const FILTER_OBSERVER = 2
const FILTER_OWNER = 3
const FILTER_MY_TEAM = 4
const FILTER_CUSTOMERS = 5
const FILTER_CORPORATE = 7
const FILTER_NONE = 10

const LOADING_MIN_DURATION = 500
const FORCE_REFRESH_DELAY = 5 * 60 * 1000 // 5 minutes

export default {
  name: 'TicketsGrid',
  components: {
    'csm-ticket-global': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketGlobal.vue'),
    'csm-ticket-launcher-dialog': () => import(/* webpackChunkName: "components" */ '@/components/Tickets/TicketAutomationDialog.vue')
  },
  props: {
    autoRefreshDelay: { type: Number, default: 0 }, // Refresh every «autoRefreshDelay» minutes
    defaultTicket: { type: Object, default: null },
    hideFilters: { type: Boolean, default: false },
    hideGlobalIncident: { type: Boolean, default: false },
    hideToggles: { type: Boolean, default: false },
    columns: { type: [Array, Object], default: null },
    filterAll: { type: Boolean, default: false },
    filterCompanies: { type: Boolean, default: false },
    filterProject: { type: Boolean, default: false },
    filterTeam: { type: Boolean, default: false },
    filterTicketSeverity: { type: Boolean, default: false },
    filterTicketState: { type: [Array, Boolean], default: false },
    filterTicketType: { type: Boolean, default: false },
    graph: { type: String, default: 'default' },
    inlineFilter: { type: Boolean, default: false },
    options: { type: Object, default: () => {} },
    prefKey: { type: String, default: 'ticketList' },
    query: { type: String, default: '' },
    savePreferences: { type: Boolean, default: true },
    title: { type: String, default: '' }
  },
  data () {
    return {
      CORPORATION_CODE: config.defaults.corporationCode,
      FILTER_CORPORATE,
      FILTER_CREATOR,
      FILTER_CUSTOMERS,
      FILTER_MY_TEAM,
      FILTER_NONE,
      FILTER_OBSERVER,
      FILTER_OWNER,
      ICONS_TICKET_TYPE: this.$alto.defines.TICKETS.ICONS_TICKET_TYPE,
      TICKET_ORIGIN_LAUNCHER: this.$alto.defines.TICKETS.TICKET_ORIGIN_LAUNCHER,
      LINE_ACTIONS: {
        CHANGE_TICKET_OWNER: 10,
        CHANGE_TICKET_REFERRER: 20,
        DELETE_SELECTED: 30,
        ASSIGN_SPRINT: 40,
        FOLLOW_TICKET: 50,
        UNFOLLOW_TICKET: 70,
        CHANGE_TICKET_STATE: 60
      },
      SECONDS_BY_WORK_DAY: this.$alto.defines.TICKETS.SECONDS_BY_WORK_DAY,
      TICKET_DEFAULT_VALUES: this.$alto.defines.TICKETS.TICKET_DEFAULT_VALUES,
      TICKET_FIELD_WAITING_REASON: this.$alto.defines.TICKETS.TICKET_FIELD_WAITING_REASON,
      TICKET_STATE_CLOSED: this.$alto.defines.TICKETS.TICKET_STATE_CLOSED,
      TICKET_STATE_DONE: this.$alto.defines.TICKETS.TICKET_STATE_DONE,
      TICKET_STATE_WAITING: this.$alto.defines.TICKETS.TICKET_STATE_WAITING,
      TICKET_STATE_RESOLUTION_REASON: this.$alto.defines.TICKETS.TICKET_STATE_RESOLUTION_REASON,
      TICKET_TYPES_FOR_SELECT: this.$alto.defines.TICKETS.TICKET_TYPES_FOR_SELECT,
      TICKET_TYPE_FACT: this.$alto.defines.TICKETS.TICKET_TYPE_FACT,
      TICKET_TYPE_INCIDENT: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT,
      TICKET_TYPE_FACT_COLOR: this.$alto.defines.TICKETS.TICKET_TYPE_FACT_COLOR.html,
      TICKET_TYPE_INCIDENT_COLOR: this.$alto.defines.TICKETS.TICKET_TYPE_INCIDENT_COLOR.html,
      TICKET_WAITING_REASON_PLANNED: this.$alto.defines.TICKETS.TICKET_WAITING_REASON_PLANNED,
      companies: [],
      companiesSearch: '',
      filters: ['id', 'name', 'description', 'create_by', 'update_by'],
      filterBy: null,
      filterByCompany: FILTER_NONE,
      globalTicketType: this.$alto.defines.TICKETS.TICKET_TYPE_FACT,
      gridColumns: [],
      gridOnPause: true,
      hideCanceledOrClosed: false,
      isActionRunning: false,
      loading: false,
      lastRefreshDate: null,
      myTeams: [],
      projectsSearch: [],
      refreshDebounced: this.refreshAndSavePrefs,
      refreshHandler: null,
      showGlobalTicketDialog: false,
      selectedOwner: null,
      selectedReferrer: null,
      selectedState: null,
      selection: [],
      selectionAction: 0,
      selectionError: null,
      showErrorPanel: false,
      teamSearch: [],
      ticketDateIntervention: null,
      ticketWaitingReason: '',
      ticketResolutionReason: '',
      ticketSeveritySearch: [],
      ticketStateSearch: Array.isArray(this.filterTicketState) ? this.filterTicketState : [],
      ticketTypeSearch: '',
      uuid: this.$stratus.uuid()
    }
  },
  computed: {
    ...mapGetters({
      me: '$stratus-states/me',
      isDark: '$stratus-states/isDark',
      isLord: '$stratus-states/isLord',
      preferences: '$alto-preferences/preferences',
      ticketStateMandatoryFields: '$alto-ticketing/ticketStateMandatoryFields'
    }),
    actionList () {
      let actions = [
        { text: this.$t('Follow tickets'), value: this.LINE_ACTIONS.FOLLOW_TICKET },
        { text: this.$t('Unfollow tickets'), value: this.LINE_ACTIONS.UNFOLLOW_TICKET }
      ]
      if (this.isLord) {
        // FUTURE { text: this.$t('Assign a sprint'), value: this.LINE_ACTIONS.ASSIGN_SPRINT }
        actions = actions.concat([
          { text: this.$t('Change tickets owner'), value: this.LINE_ACTIONS.CHANGE_TICKET_OWNER },
          { text: this.$t('Change tickets referrer'), value: this.LINE_ACTIONS.CHANGE_TICKET_REFERRER },
          { text: this.$t('Change tickets state'), value: this.LINE_ACTIONS.CHANGE_TICKET_STATE, disabled: !this.canChangeStateMultiple() },
          { text: this.$t('Delete tickets'), value: this.LINE_ACTIONS.DELETE_SELECTED, disabled: !this.canDeleteMultiple() }
        ])
      }
      return actions
    },
    canCreateGlobalTicket () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.TICKET_GLOBAL_TICKET)
    },
    canReadLauncher () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS_LAUNCHERS, this.$alto.API_PERMISSIONS.READ)
    },
    canReadProjects () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.PROJECTS, this.$alto.API_PERMISSIONS.LIST)
    },
    canRunAction () {
      const action = _.find(this.actionList, { value: this.selectionAction })
      return !this.selectionError && !action?.disabled
    },
    canUpdate () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETING, this.$alto.API_PERMISSIONS.UPDATE)
    },
    gridOptions () {
      return {
        advancedSearchFieldsSchemaBaseUrl: '/docs/tickets',
        advancedSearchFieldsSchemaName: 'Ticket',
        allowContextMenuOnCell: true,
        allowColumnsVisible: true,
        create: this.isLogged && this.$store.getters['$alto-roles/canI'](this.$store.getters['$alto-roles/API_CONTEXTS'].TICKETING, this.$store.getters['$alto-roles/API_PERMISSIONS'].CREATE),
        clipboardURL: {
          path: 'tickets',
          value: 'id'
        },
        customUpdateItem: true,
        delete: false,
        foreignFields: ['id', 'ids_observers'],
        foreignFieldsFilter: true,
        key: 'id',
        messages: {
          deleteItem: 'Please, confirm the deletion of ticket {id} {name}?'
        },
        multiSelect: false,
        notifyErrors: true,
        searchVisibleColumns: false,
        show: true,
        sortBy: 'update_at',
        sortDescending: true,
        update: true,
        ...this.options
      }
    },
    isAdmin () { return this.me && this.me.role === this.$alto.USER_ROLES.ADMIN },
    waitingReasonRequired () {
      return this.$store.getters['$alto-ticketing/ticketStateMandatoryFields'](this.ticket?.state).includes(this.TICKET_FIELD_WAITING_REASON) ? 'required' : ''
    },
    waitingReasonRules () {
      return this.$store.getters['$alto-ticketing/ticketStateMandatoryFields'](this.ticket?.state).includes(this.TICKET_FIELD_WAITING_REASON) ? [this.$stratus.services.form.rules.required] : []
    }
  },
  watch: {
    query: {
      immediate: true,
      handler (newValue, oldValue) {
        if (newValue !== oldValue) this.refreshDebounced()
      }
    }
  },
  methods: {
    actionRun () {
      switch (this.selectionAction) {
        case this.LINE_ACTIONS.CHANGE_TICKET_OWNER:
          this.selectionChangeOwner()
          break
        case this.LINE_ACTIONS.CHANGE_TICKET_REFERRER:
          this.selectionChangeReferrer()
          break
        case this.LINE_ACTIONS.DELETE_SELECTED:
          this.selectionDelete()
          break
        case this.LINE_ACTIONS.ASSIGN_SPRINT:
          // todo: implementer l'action
          this.$nextTick(() => { this.resetSelections() })
          break
        case this.LINE_ACTIONS.FOLLOW_TICKET:
          this.selectionFollow(true)
          break
        case this.LINE_ACTIONS.UNFOLLOW_TICKET:
          this.selectionFollow(false)
          break
        case this.LINE_ACTIONS.CHANGE_TICKET_STATE:
          this.selectionChangeState()
          break
      }
    },
    canChangeStateMultiple () {
      // All ticket MUST be of same types
      let canChange = true
      let prevType = null
      let i = 0
      while (canChange && i < this.selection.length) {
        canChange = !prevType || this.selection[i].type === prevType
        prevType = this.selection[i].type
        i++
      }
      return canChange
    },
    canDeleteMultiple () {
      let deletableCount = 0
      _.forEach(this.selection, selected => {
        if (this.$store.getters['$alto-roles/can'](selected.$can, this.$alto.API_PERMISSIONS.DELETE)) deletableCount++
      })
      return this.selection.length === deletableCount
    },
    async checkTicketsAvailable () {
      try {
        await this.$store.dispatch('$alto-ticketing/listTickets', { ids: ['fake'], forceReload: true })
        this.showErrorPanel = false
      } catch (error) {
        console.error(error)
        setTimeout(() => { this.checkTicketsAvailable() }, FORCE_REFRESH_DELAY)
      }
    },
    closeDialog () {
      this.showGlobalTicketDialog = false
      this.refreshDebounced()
    },
    createGridColumns () {
      if (Array.isArray(this.columns)) return this.columns

      let cols = [{
        text: 'Type',
        value: 'type'
      }, {
        text: 'Identifier',
        value: 'id'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Company',
        value: 'code'
      }, {
        text: 'Severity',
        value: 'severity'
      }, {
        text: 'State',
        value: 'state'
      }, {
        text: 'ticket-field-waiting_reason',
        value: 'waiting_reason',
        hidden: true
      }, {
        text: 'ticket-field-resolution_reason',
        value: 'resolution_reason',
        hidden: true
      }, {
        text: 'Team',
        value: 'id_lord_team'
      }, {
        text: 'Owner',
        value: 'id_owner'
      }, {
        text: 'Referrer',
        value: 'id_referring'
      }, {
        text: 'Sphere',
        value: 'id_sphere',
        hidden: true
      }, {
        text: 'Estimated time',
        value: 'estimated_time'
      }, {
        text: 'Passed time',
        value: 'passed_time'
      }, {
        text: 'Billable',
        value: 'billable',
        alignValue: 'center',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.BOOLEAN_CHECK_IF_TRUE
      }, {
        text: 'Billable passed time',
        value: 'passed_time_billable',
        hidden: true
      }, {
        text: 'Estimated delivery date',
        value: 'estimated_date',
        hidden: true,
        format: v => v ? this.$stratus.services.fieldRenderers.DATE_SHORT(v) : ''
      }, {
        text: 'Last comment on',
        value: 'last_comment_at',
        hidden: true,
        format: v => v ? this.$stratus.services.fieldRenderers.DATETIME_SHORT(v) : ''
      }, {
        text: 'Closure date',
        value: 'date_closure',
        hidden: true,
        format: v => v ? this.$stratus.services.fieldRenderers.DATETIME_SHORT(v) : ''
      }, {
        text: 'Last comment by',
        value: 'last_comment_by',
        hidden: true
      }]

      if (this.isLord) {
        cols = cols.concat([{
          text: 'Origin',
          value: 'origin',
          hidden: true
        }, {
          text: 'Launcher',
          value: 'id_launcher',
          hidden: true
        }])
      }

      cols = cols.concat([{
        text: 'Created at',
        value: 'create_at',
        hidden: true,
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Created by',
        value: 'create_by',
        hidden: false
      }, {
        text: 'Updated at',
        value: 'update_at',
        format: this.$stratus.services.fieldRenderers.DATE_SHORT
      }, {
        text: 'Updated by',
        value: 'update_by',
        hidden: true
      }])

      if (!this.columns) return cols // Return default

      return _.map(this.columns, (hidden, value) => {
        return { ..._.find(cols, { value }), hidden }
      })
    },
    customSearch () {
      const cusSearch = []
      if (this.query) {
        cusSearch.raw = this.query.replace('query=', '')
      } else cusSearch.raw = ''

      function add (qry) {
        if (cusSearch.raw) cusSearch.raw += '!!'
        cusSearch.raw += qry
      }

      if (this.ticketTypeSearch?.length) {
        cusSearch.push({
          column: 'type',
          search: Array.isArray(this.ticketTypeSearch) ? this.ticketTypeSearch.join(',') : this.ticketTypeSearch,
          operator: Array.isArray(this.ticketTypeSearch) ? 'in' : 'eq'
        })
      } else if (this.graph && this.$alto.defines.TICKETS.TICKET_TYPE_BY_GRAPH[this.graph]) {
        cusSearch.push({
          column: 'type',
          search: this.$alto.defines.TICKETS.TICKET_TYPE_BY_GRAPH[this.graph].join(','),
          operator: 'in'
        })
      }

      // Use filter inputs
      if (this.companiesSearch?.length) {
        cusSearch.push({
          column: 'code',
          search: Array.isArray(this.companiesSearch) ? this.companiesSearch.join(',') : this.companiesSearch,
          operator: Array.isArray(this.companiesSearch) ? 'in' : 'eq'
        })
      }

      if ([FILTER_CUSTOMERS, FILTER_CORPORATE].includes(this.filterByCompany)) {
        cusSearch.push({
          column: 'code',
          search: this.CORPORATION_CODE,
          operator: this.filterByCompany === FILTER_CUSTOMERS ? 'ne' : 'eq'
        })
      }

      if (this.projectsSearch?.length) {
        cusSearch.push({
          column: 'id_sphere',
          search: Array.isArray(this.projectsSearch) ? this.projectsSearch.join(',') : this.projectsSearch,
          operator: Array.isArray(this.projectsSearch) ? 'in' : 'eq'
        })
      }

      if (this.ticketStateSearch?.length) {
        cusSearch.push({
          column: 'state',
          search: this.ticketStateSearch.join(','),
          operator: 'in'
        })
      }

      if (this.ticketSeveritySearch?.length) {
        cusSearch.push({
          column: 'severity',
          search: Array.isArray(this.ticketSeveritySearch) ? this.ticketSeveritySearch.join(',') : this.ticketSeveritySearch,
          operator: Array.isArray(this.ticketSeveritySearch) ? 'in' : 'eq'
        })
      }

      if (this.teamSearch?.length) {
        cusSearch.push({
          column: 'id_lord_team',
          search: Array.isArray(this.teamSearch) ? this.teamSearch.join(',') : this.teamSearch,
          operator: Array.isArray(this.teamSearch) ? 'in' : 'eq'
        })
      }

      switch (this.filterBy) {
        case FILTER_OBSERVER:
          cusSearch.push({ column: 'ids_observers', search: this.me.id, operator: 'rg' })
          break
        case FILTER_MY_TEAM:
          if (this.me.lord_team_id) cusSearch.push({ column: 'id_lord_team', search: this.myTeams, operator: 'in' })
          break
        case FILTER_OWNER:
          add(`id_owner=${this.me.id}||id_referring=${this.me.id}`)
          break
        case FILTER_CREATOR:
          cusSearch.push({ column: 'create_by', search: this.me.email })
          break
      }

      return cusSearch
    },
    loadCompanies () {
      this.$store.dispatch('$alto-companies/list')
        .then(() => {
          this.companies = this.$stratus.services.fields.ObjectToSelectItems(this.$store.getters['$alto-companies/cache'](), { keyInValue: true })
          if (this.isLord && this.me?.lord_team_id) {
            return this.$store.dispatch('$alto-companies/getTeam', { id: this.me.lord_team_id })
          }
        })
        .then(team => {
          this.myTeams = [this.me.lord_team_id].concat(team?.ids_team_child)
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    async loadPreferences () {
      await this.$store.dispatch('$alto-preferences/load')
      if (this.savePreferences && this.preferences?.[this.prefKey]) {
        this.filterBy = this.preferences?.[this.prefKey].filterBy || FILTER_NONE
        this.ticketTypeSearch = this.preferences?.[this.prefKey].ticketTypeSearch || ''
        this.companiesSearch = this.preferences?.[this.prefKey].companiesSearch || ''
        this.ticketSeveritySearch = this.preferences?.[this.prefKey].ticketSeveritySearch || []
        this.ticketStateSearch = this.preferences?.[this.prefKey].ticketStateSearch || []
        this.hideCanceledOrClosed = this.preferences?.[this.prefKey].hideCanceledOrClosed || false
        this.teamSearch = this.preferences?.[this.prefKey].teamSearch || []
        this.projectsSearch = this.preferences?.[this.prefKey].projectsSearch || []
      }
    },
    notifyResult (response, selection) {
      if (response?.updated?.length === selection.length) {
        this.$stratus.services.notify.success(this.$t('All tickets were processed.'))
      } else {
        if (!response?.updated?.length) {
          this.$stratus.services.notify.error(this.$t('No tickets were processed.'))
        } else {
          this.$stratus.services.notify.warning(this.$t('Only {count} tickets on {total} were processed.', { count: response?.updated?.length, total: selection.length }))
        }
      }
    },
    onCreateGlobalTicket () {
      this.$refs['form-ticket-global'].reset()
      this.showGlobalTicketDialog = true
    },
    async onCreateItem () {
      this.$refs['ticket-dialog'].open({ ...(this.defaultTicket || this.TICKET_DEFAULT_VALUES), code: this.me.company })
    },
    onDataLoaded ({ success, error }) {
      setTimeout(() => { this.loading = false }, LOADING_MIN_DURATION)
      this.lastRefreshDate = new Date()
      this.showErrorPanel = !success
      if (this.showErrorPanel) setTimeout(() => { this.checkTicketsAvailable() }, FORCE_REFRESH_DELAY)
    },
    onDeleteItem ({ success, error, item }) {
      if (success) {
        this.$stratus.services.notify.success(this.$t('Ticket "{id} {name}" deleted.', item))
      } else {
        if (error && error.status === 403) {
          this.$stratus.services.notify.warning(this.$t('You\'re not allowed to delete ticket "{name}".', item))
        } else {
          this.$stratus.services.notify.error(error)
        }
      }
    },
    async onShowItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      if (data.newTab) window.open(window.location.origin + `/#/tickets/${data.item.id}`, `ticket_${data.item.id}`)
      else this.$refs['ticket-dialog'].open(data.item.id)
    },
    async onUpdateItem (data) {
      if (!data.success) {
        if (data.error.status === 403) this.$stratus.services.notify.error(this.$t('You do not have the right.'))
        else this.$stratus.services.notify.error(data.error)
        return
      }
      if (data.newTab) window.open(window.location.origin + `/#/tickets/${data.item.id}`, `ticket_${data.item.id}`)
      else {
        this.$refs['ticket-dialog'].open(data.item.id)
      }
    },
    openCompany (id) {
      if (this.$refs['company-dialog']) this.$refs['company-dialog'].open(id)
    },
    openSphere (id) {
      if (this.$refs['sphere-dialog'] && id) this.$refs['sphere-dialog'].open(id)
    },
    openTicketLauncher (id) {
      if (this.$refs['ticket-launcher-dialog'] && id) this.$refs['ticket-launcher-dialog'].open(id)
    },
    openUser (id) {
      if (this.$refs['user-dialog']) this.$refs['user-dialog'].open(id)
    },
    refresh () {
      if (this.$refs[`ticket-grid-${this.uuid}`]) this.$refs[`ticket-grid-${this.uuid}`].fetchData()
    },
    refreshAndSavePrefs () {
      if (this.$refs[`ticket-grid-${this.uuid}`]) {
        this.loading = true
        this.savePrefs()
        if (this.filterBy === FILTER_NONE) {
          this.filterBy = 0
        }
        this.refresh()
      }
    },
    resetFilters () {
      this.projectsSearch = []
      this.teamSearch = []
      this.ticketSeveritySearch = []
      this.ticketStateSearch = []
      this.hideCanceledOrClosed = false
      this.ticketTypeSearch = ''
      this.ticketWaitingReason = ''
      this.ticketDateIntervention = null
      this.ticketResolutionReason = ''
      this.filterBy = FILTER_NONE
      this.filterByCompany = FILTER_NONE
    },
    resetSelections () {
      this.selection = []
      this.selectedOwner = null
      this.selectedReferrer = null
      this.selectedState = null
      this.selectionAction = 0
      this.selectionError = null
      if (this.$refs[`ticket-grid-${this.uuid}`]) this.$refs[`ticket-grid-${this.uuid}`].clearSelectedRows()
    },
    resolutionReasons (type, state) {
      return this.$store.getters['$alto-ticketing/ticketResolutionReasons'](type, state, this.graph)
    },
    async savePrefs () {
      if (!this.savePreferences || !this.prefKey) return

      try {
        if (!this.preferences[this.prefKey]) this.preferences[this.prefKey] = {}
        this.preferences[this.prefKey].filterBy = this.filterBy
        this.preferences[this.prefKey].ticketTypeSearch = this.ticketTypeSearch
        this.preferences[this.prefKey].companiesSearch = this.companiesSearch
        this.preferences[this.prefKey].ticketSeveritySearch = this.ticketSeveritySearch
        this.preferences[this.prefKey].ticketStateSearch = this.ticketStateSearch
        this.preferences[this.prefKey].hideCanceledOrClosed = this.hideCanceledOrClosed
        this.preferences[this.prefKey].teamSearch = this.teamSearch
        this.preferences[this.prefKey].projectsSearch = this.projectsSearch
        await this.$store.dispatch('$alto-preferences/save', { [this.prefKey]: this.preferences[this.prefKey] })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    async selectionChangeOwner () {
      try {
        this.actionRun = true
        const newOwner = await this.$store.dispatch('$alto-users/getOne', this.selectedOwner)
        const confirmed = await this.$refs['confirm-ticket-action-dialog']
          .open(this.$t('Change tickets owner'), this.$t('Confirm new owner {name} for {count} ticket(s)?', { name: `${newOwner.firstname} ${newOwner.lastname}`, count: this.selection.length }))
        if (confirmed) {
          const ids = _.map(this.selection, 'id')
          const response = await this.$store.dispatch('$alto-ticketing/updateTickets', { tickets: ids, id_owner: this.selectedOwner })
          this.notifyResult(response, this.selection)
          this.$nextTick(() => { this.resetSelections() })
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.refreshDebounced()
        this.actionRun = false
      }
    },
    async selectionChangeReferrer () {
      try {
        this.actionRun = true
        const newOwner = await this.$store.dispatch('$alto-users/getOne', this.selectedReferrer)
        const confirmed = await this.$refs['confirm-ticket-action-dialog']
          .open(this.$t('Change tickets owner'), this.$t('Confirm new owner {name} for {count} ticket(s)?', { name: `${newOwner.firstname} ${newOwner.lastname}`, count: this.selection.length }))
        if (confirmed) {
          const ids = _.map(this.selection, 'id')
          const response = await this.$store.dispatch('$alto-ticketing/updateTickets', { tickets: ids, id_referring: this.selectedReferrer })
          this.notifyResult(response, this.selection)
          this.$nextTick(() => { this.resetSelections() })
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.refreshDebounced()
        this.actionRun = false
      }
    },
    async selectionChangeState () {
      try {
        this.actionRun = true
        const confirmed = await this.$refs['confirm-ticket-action-dialog']
          .open(this.$t('Change tickets state'), this.$t('Confirm new state {state} for {count} ticket(s)?', { state: this.$t(`ticket-state-${this.selectedState}`), count: this.selection.length }))
        if (confirmed) {
          const data = {
            tickets: _.map(this.selection, 'id'),
            state: this.selectedState
          }
          if (this.selectedState === this.TICKET_STATE_WAITING && this.ticketWaitingReason) {
            data.waiting_reason = this.ticketWaitingReason
            if (this.ticketWaitingReason === this.TICKET_WAITING_REASON_PLANNED && this.ticketDateIntervention) {
              data.date_intervention = this.ticketDateIntervention
            }
          }
          if (this.resolutionReasons(this.selection?.[0]?.type, this.selectedState)) {
            data.resolution_reason = this.ticketResolutionReason
          }
          const response = await this.$store.dispatch('$alto-ticketing/updateTickets', data)
          this.notifyResult(response, this.selection)
          this.$nextTick(() => { this.resetSelections() })
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.refreshDebounced()
        this.actionRun = false
      }
    },
    async selectionDelete () {
      try {
        this.actionRun = true
        const confirmed = await this.$refs['confirm-ticket-action-dialog']
          .open(this.$t('Delete tickets'), this.$t('Confirm deletion of {count} ticket(s)?', { count: this.selection.length }))
        if (confirmed) {
          const ids = _.map(this.selection, 'id')
          const response = await this.$store.dispatch('$alto-ticketing/delTickets', ids)
          this.notifyResult(response, this.selection)
          this.$nextTick(() => { this.resetSelections() })
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.refreshDebounced()
        this.actionRun = false
      }
    },
    async selectionFollow (follow) {
      try {
        this.actionRun = true
        const confirmed = await this.$refs['confirm-ticket-action-dialog']
          .open(
            this.$t('Tracked tickets'),
            follow
              ? this.$t('Confirm the follow-up for {count} ticket(s)?', { count: this.selection.length })
              : this.$t('Can you confirm that you will no longer be following these {count} ticket(s)?', { count: this.selection.length })
          )
        if (confirmed) {
          const ids = _.map(this.selection, 'id')
          const response = await this.$store.dispatch('$alto-ticketing/updateTickets', { tickets: ids, ids_observers: follow ? [this.me.id] : [] })
          this.notifyResult(response, this.selection)
          this.$nextTick(() => { this.resetSelections() })
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      } finally {
        this.refreshDebounced()
        this.actionRun = false
      }
    },
    selectItems (items) {
      this.selection = items || []
      this.validateSelection()
    },
    toggleTicketCanceledOrClosed () {
      if (this.hideCanceledOrClosed) {
        if (!this.ticketStateSearch?.length) {
          this.ticketStateSearch = this.$alto.defines.TICKETS.TICKETS_STATES_WORKING
        } else {
          let found = _.indexOf(this.ticketStateSearch, this.$alto.defines.TICKETS.TICKET_STATE_CANCELED)
          if (found >= 0) this.ticketStateSearch.splice(found, 1)
          found = _.indexOf(this.ticketStateSearch, this.$alto.defines.TICKETS.TICKET_STATE_CLOSED)
          if (found >= 0) this.ticketStateSearch.splice(found, 1)
        }
      } else {
        if (this.ticketStateSearch?.length) {
          if (!this.ticketStateSearch.includes(this.$alto.defines.TICKETS.TICKET_STATE_CANCELED)) this.ticketStateSearch.push(this.$alto.defines.TICKETS.TICKET_STATE_CANCELED)
          if (!this.ticketStateSearch.includes(this.$alto.defines.TICKETS.TICKET_STATE_CLOSED)) this.ticketStateSearch.push(this.$alto.defines.TICKETS.TICKET_STATE_CLOSED)
        }
      }
      this.refreshAndSavePrefs()
    },
    validateSelection () {
      this.selectionError = null
    }
  },
  async created () {
    this.gridColumns = this.createGridColumns()
    this.gridOnPause = true
    this.refreshDebounced = _.debounce(this.refreshAndSavePrefs, LOADING_MIN_DURATION)
    this.isLogged = this.$stratus.services.auth.isLogged()

    await this.$store.dispatch('$stratus-states/getMe')
    this.loadCompanies()
  },
  beforeDestroy () {
    clearTimeout(this.refreshHandler)
  },
  mounted () {
    setTimeout(async () => {
      this.loading = true
      await this.loadPreferences()
      this.$nextTick(() => {
        this.gridOnPause = false
      })
    }, 1500)
  }
}
</script>
