import { render, staticRenderFns } from "./ProductSubDiscount.vue?vue&type=template&id=8edd1a5c&v-slot%3Aexpand=props"
import script from "./ProductSubDiscount.vue?vue&type=script&lang=js"
export * from "./ProductSubDiscount.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports